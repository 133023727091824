import * as React from "react";

const SvgWarning = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 20h18L12 4 3 20Zm9.818-2.526h-1.636V15.79h1.636v1.684Zm0-3.369h-1.636v-3.368h1.636v3.368Z"
      fill={color}
    />
  </svg>
);

export default SvgWarning;
