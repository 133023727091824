import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getAssignedLocations } from "api/locations";

import { initialAPIListValue } from "utils/constants";

import useStyles from "./styles";

export default function AssignedLocations() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [assignedLocations, setAssignedLocations] = useState(
    initialAPIListValue,
  );

  useEffect(() => {
    if (assignedLocations.isFetching) {
      getAssignedLocations({
        params: {
          is_counted: false,
          is_cancelled: false,
          limit: 1000,
        },
        setFunc: setAssignedLocations,
      });
    }
  }, [assignedLocations.isFetching]);

  return (
    <Card>
      <CardContent>
        <Typography className={classes.heading}>
          {`${t("cycle_count")} ${t("locations")} (${assignedLocations.count})`}
        </Typography>
      </CardContent>
      <CardActionArea>
        <Button
          fullWidth
          component={Link}
          to={{
            pathname: `/app/count/`,
          }}
          variant="contained"
          color="primary"
        >
          Go to Cycle Count
        </Button>
      </CardActionArea>
    </Card>
  );
}
