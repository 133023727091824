import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  title: {
    textAlign: "center",
    padding: "5px",
    backgroundColor: "white",
    borderRadius: "0.3rem",
  },
  message: {
    color: "white",
  },
  content: {
    textAlign: "center",
    paddingTop: "2em",
    paddingBottom: "1em",
  },
  closeButton: {
    color: "#fff",
    borderColor: "#fff",
    borderWidth: "0.12rem",
    width: "9rem",
  },
  numberValue: {
    fontSize: "1.5rem",
    fontWeight: 900,
    margin: 0,
    padding: 0,
  },
  numberTitle: {
    margin: 0,
    padding: 0,
    display: "block",
    filter: "brightness(85%)",
    fontSize: "0.625rem",
    fontWeight: 600,
  },
  mobileButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      width: "50%",
      maxWidth: "200px",
    },
  },
  radioGroup: {
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  radioButton: {
    "& > .MuiFormControlLabel-label": {
      transform: "inherit !important",
      top: "auto !important",
      bottom: "auto !important",
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(13),
      flexGrow: "0",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    "& > .MuiButtonBase-root": {
      padding: theme.typography.pxToRem(2),
    },
  },
}));
