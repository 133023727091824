import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";

import useStyles from "./styles";

// Checks link validity and gets session id
async function fetchData(uid, token, setInvalid, setCSRFToken) {
  const url = `${process.env.REACT_APP_API_URL}accounts/reset/${uid}/${token}/`;
  const options = {
    method: "GET",
    credentials: "include",
  };

  try {
    const res = await fetch(url, options);
    if (res.status === 200) {
      const jsonResponse = await res.json().catch(() => null);
      // checks if link is valid
      if (jsonResponse && jsonResponse.status === "success") {
        setInvalid(false);
        setCSRFToken(jsonResponse.csrfToken);
        // clean token from url
        window.history.replaceState(
          null,
          "Tayse | Warehouse Management System",
          `/#/password_confirm/${uid}`,
        );
      } else {
        throw new Error("Link is not valid.");
      }
    } else {
      throw new Error("Server unavailable, admins notified.");
    }
  } catch (e) {
    console.error(e.message);
    setInvalid(true);
  }
}

// Returns the first error from list
const getError = (textResponse) => {
  const errors = document.createElement("div");
  errors.innerHTML = textResponse;
  return errors
    .getElementsByClassName("errorlist")[0]
    .getElementsByTagName("li")[0].innerHTML;
};

// Sets new password
async function confirmPassword(
  uid,
  csrfToken,
  new_password1,
  new_password2,
  setIsLoading,
  setError,
  setSuccess,
) {
  const url = `${process.env.REACT_APP_API_URL}accounts/reset/${uid}/set-password/`;
  const options = {
    method: "POST",
    body: new URLSearchParams({
      new_password1,
      new_password2,
    }),
    headers: {
      "X-CSRFToken": csrfToken,
    },
    credentials: "include",
  };
  setIsLoading(true);

  try {
    const res = await fetch(url, options);
    setIsLoading(false);

    if (res.status === 200) {
      // check html response if password set was successful
      const isJSON = res.headers
        .get("content-type")
        .startsWith("application/json");
      const response = isJSON ? await res.json() : await res.text();
      if (response && response.status === "success") {
        return setSuccess(true);
      } else if (response && response.includes('class="errorlist"')) {
        return setError(getError(response));
      }
      throw new Error("An unexpected error occured.");
    } else {
      throw new Error("Server unavailable, admins notified.");
    }
  } catch (e) {
    setError(e.message);
    setIsLoading(false);
  }
}

// Splits pathname to get uid and token
const getUIDToken = (pathname) =>
  pathname.replace("/password_confirm/", "").split("/");

// Password confirm page
function PasswordConfirm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("");
  const [csrfToken, setCSRFToken] = useState("");

  const [uid, token] = getUIDToken(location.pathname);

  const confirmDisabled = () => {
    return (
      passwordValue.length === 0 ||
      passwordConfirmValue.length === 0 ||
      isLoading
    );
  };

  const confirm = () => {
    confirmPassword(
      uid,
      csrfToken,
      passwordValue,
      passwordConfirmValue,
      setIsLoading,
      setError,
      setSuccess,
    );
  };

  useEffect(() => {
    fetchData(uid, token, setInvalid, setCSRFToken);
  }, [uid, token]);

  return (
    <Grid container className={classes.container}>
      {invalid && (
        <div className={classes.messageContainer}>
          <Typography variant="h5" className={classes.message}>
            {t("password_reset_unsuccessful")}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t("password_link_invalid")}
          </Typography>
          <Link to="/login" className={classes.button}>
            <Button variant="contained" color="primary" size="large">
              Home
            </Button>
          </Link>
        </div>
      )}
      {success && (
        <div className={classes.messageContainer}>
          <Typography variant="h5" className={classes.message}>
            {t("password_reset_complete")}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t("password_set")}
          </Typography>
          <Link to="/login" className={classes.button}>
            <Button variant="contained" color="primary" size="large">
              Login
            </Button>
          </Link>
        </div>
      )}
      {!success && invalid === false && (
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Enter new password" classes={{ root: classes.tab }} />
            </Tabs>
            <Typography variant="subtitle1" className={classes.message}>
              {t("password_confirm_message")}
            </Typography>
            <Fragment>
              <Fade in={!!error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              <TextField
                fullWidth
                id="new_password1"
                type="password"
                placeholder={t("password_new")}
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                disabled={isLoading}
              />
              <TextField
                fullWidth
                id="new_password2"
                type="password"
                placeholder={t("password_confirm")}
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordConfirmValue}
                onChange={(e) => setPasswordConfirmValue(e.target.value)}
                onKeyPress={({ key }) =>
                  key === "Enter" && !confirmDisabled() && confirm()
                }
                margin="normal"
                disabled={isLoading}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loader} />
                ) : (
                  <Button
                    disabled={confirmDisabled()}
                    onClick={confirm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {isLoading ? "Loading.." : "Change my password"}
                  </Button>
                )}
              </div>
            </Fragment>
          </div>
        </div>
      )}
    </Grid>
  );
}

export default withRouter(PasswordConfirm);
