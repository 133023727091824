import { useTheme } from "@material-ui/styles";

import { Circle } from "components/Charts/Circle";
import { SHIPMENT_STATUS_ORDER } from "utils/constants";

export function StatusCircle({ status, content, secondStatus, selected }) {
  const theme = useTheme();

  const currentIndex = SHIPMENT_STATUS_ORDER.indexOf(status);
  const previousStatus = SHIPMENT_STATUS_ORDER[currentIndex - 1];
  let statusData = content[status];
  const previousStatusData = content[previousStatus];
  let currentTotal = statusData ? statusData.length : 0;
  if (secondStatus) {
    currentTotal += content
      ? content[secondStatus].reduce((acc, sd) => acc + sd.quantity, 0)
      : 0;
  }
  let previousTotal = previousStatusData ? previousStatusData.length : 0;

  if (status === "Late") {
    statusData = content[status];
    previousTotal = 0;
  }

  return (
    <Circle
      number={currentTotal}
      series={[currentTotal + previousTotal, currentTotal]}
      title={status}
      color={
        status === "Unassigned"
          ? theme.palette.colors.slate400
          : status === "Assigned"
          ? theme.palette.colors.amber400
          : status === "Picked"
          ? theme.palette.colors.blue400
          : status === "Shipped"
          ? theme.palette.colors.emerald400
          : status === "Invoiced"
          ? theme.palette.colors.emerald700
          : status === "Cancelled"
          ? theme.palette.colors.rose400
          : status === "Alternate_Picks"
          ? theme.palette.colors.amber500
          : status === "Late_Shipments"
          ? theme.palette.colors.rose600
          : theme.palette.colors.rose400
      }
      size={250}
      isSelected={selected === status}
    />
  );
}
