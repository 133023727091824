import { useCallback, useEffect, useState } from "react";

import { CircularProgress, debounce, Grid } from "@material-ui/core";

import { createInventorySnapshot, getInventorySnapshots } from "api/inventory";

import { StatusLabel } from "components/Custom";
import { Button, DatePickerGroup, InputContainer } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { initialAPIListValue } from "utils/constants";
import { timezoneFormat } from "utils/date";
import { toTitleCase } from "utils/string";

import SnapshotDetail from "./SnapshotDetail";

const headers = [
  {
    id: "created_at",
    sortable: true,
    render: (r) => timezoneFormat(r.created_at),
  },
  {
    id: "created_by",
    sortable: true,
    render: (r) => toTitleCase(r.created_by.username),
  },
  {
    id: "is_used",
    sortable: true,
    render: (r) => <StatusLabel status={toTitleCase(r.is_used.toString())} />,
  },
];

export default function InventorySnapShot() {
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const [params, setParams] = useState({
    start_date: "",
    end_date: "",
  });
  const [snapshots, setSnapshots] = useState(initialAPIListValue);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [isFetching, setIsFetching] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState({});
  const hasPermission = permissions.includes("warehouse.add_inventorysnapshot");

  useEffect(() => {
    if (snapshots.isFetching) {
      let filters = {
        ...params,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }

      getInventorySnapshots({
        params: filters,
        setFunc: setSnapshots,
        paginated: true,
      });
    }
  }, [
    params,
    snapshots.isFetching,
    rowsPerPage,
    page,
    orderDirection,
    orderBy,
  ]);

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams({ ...params, [prop]: value });
    debouncedSearchHandler();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setSnapshots((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSnapshots((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSnapshots(initialAPIListValue);
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setSnapshots((prev) => ({ ...prev, isFetching: true }));
  };

  const handleCreateSnapshot = () => {
    setIsFetching(true);
    createInventorySnapshot({
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        setSnapshots((prev) => ({ ...prev, isFetching: true }));
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleDetailClose = (status) => {
    if (status) {
      setSnapshots((prev) => ({ ...prev, isFetching: true }));
    }
    setSelectedSnapshot({});
  };

  return (
    <>
      <PageTitle
        title="Inventory Snapshots"
        button={
          hasPermission ? (
            <Button
              color="blue"
              onClick={handleCreateSnapshot}
              disabled={isFetching}
            >
              {isFetching ? <CircularProgress /> : "Create Snapshot"}
            </Button>
          ) : null
        }
      />
      <InputContainer>
        <Grid container spacing={2}>
          <DatePickerGroup
            startDateValue={params.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={params.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
          />
        </Grid>
      </InputContainer>
      <Grid container>
        <DefaultTable
          headers={headers}
          data={snapshots}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortActions={{ onSort, orderBy, orderDirection }}
          rowOnClick={(r) => setSelectedSnapshot(r)}
        />
        {Object.keys(selectedSnapshot).length ? (
          <SnapshotDetail
            open={!!Object.keys(selectedSnapshot).length}
            onClose={handleDetailClose}
            snapshot={selectedSnapshot}
          />
        ) : null}
      </Grid>
    </>
  );
}
