import * as React from "react";

const SvgExclamation = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.931 9v5M5.003 20h13.856c1.54 0 2.502-1.667 1.732-3L13.663 5c-.77-1.333-2.694-1.333-3.464 0L3.271 17c-.77 1.333.192 3 1.732 3Z"
      />
      <circle fill={color} cx={12} cy={12} r={1} />
    </g>
  </svg>
);

export default SvgExclamation;
