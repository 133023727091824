import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  arrowLeft: {
    marginRight: theme.spacing(1),
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "-1.5rem",
    marginTop: "-1.2rem",
    marginRight: "-1.5rem",
    backgroundColor: theme.palette.colors.dark_green_grey,
    minHeight: "100px",
    color: "#fff",
  },
  pageTitleBorderContainer: {
    display: "flex",
    minHeight: "30px",
    backgroundColor: "#F6F7FF",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "-3.4rem",
    marginTop: "-1.3rem",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
    justifyContent: "flex-end",
  },
  pageTitleTextContainer: {
    marginLeft: "2rem",
    marginTop: "-25px",
    display: "flex",
    alignItems: "center",
  },
  pageTitleText: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.7),
  },
  helpText: {
    marginLeft: "10px",
    marginTop: "6px",
    fontSize: "16px",
    color: "#0ab6ff",
    marginBottom: theme.spacing(0.7),
  },
  iconContainer: {
    display: "inline",
    marginRight: "10px",
    marginTop: "2px",
  },
  profileContainer: {
    display: "flex",
    padding: "10px 10px 0 0",
    marginRight: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      marginLeft: "2rem",
    },
  },
  logoContainer: {
    width: "35px",
    height: "35px",
    marginTop: "-2px",
    padding: "5px",
    backgroundColor: theme.palette.colors.dark_grey_blue,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "0.4rem",
  },
  logo: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "#59807b",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  name: {
    color: "#FFF",
    textDecoration: "none",
    margin: "5px 10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  buttonContainer: {
    marginTop: "-3.5rem",
    paddingRight: "1.2rem",
    width: "auto",
  },
  logoutContainer: {
    marginTop: "0.2rem",
    marginLeft: "0.4rem",
  },
  searchButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem",
  }
}));
