import * as React from "react";

const SvgRefresh = ({ color, size }) => (
  <svg
    viewBox="0 0 46 47"
    width={`${28 * (size || 1)}px`}
    height={`${28 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.829 12.413c-2.78-2.779-6.594-4.504-10.83-4.504-8.471 0-15.313 6.862-15.313 15.334 0 8.471 6.842 15.333 15.314 15.333 7.149 0 13.11-4.888 14.816-11.5h-3.987c-1.572 4.466-5.827 7.666-10.83 7.666-6.343 0-11.5-5.155-11.5-11.5 0-6.344 5.156-11.5 11.5-11.5 3.182 0 6.019 1.323 8.089 3.412l-6.172 6.172h13.417V7.909l-4.504 4.504z"
      fill={color}
    />
  </svg>
);

export default SvgRefresh;
