import * as React from "react";

const SvgPhotograph = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="m4 16 4.586-4.586a2 2 0 0 1 2.828 0L16 16" />
      <path d="m14 14 1.586-1.586a2 2 0 0 1 2.828 0L20 14" />
      <path d="M6 20h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2Z" />
    </g>
  </svg>
);

export default SvgPhotograph;
