import * as React from "react";

const SvgSpeed = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Express</title>
    <path
      d="M19.5401 8.97496L18.4313 10.5976C18.966 11.6352 19.2278 12.7857 19.1932 13.946C19.1586 15.1063 18.8287 16.2401 18.233 17.2458H5.73861C4.9644 15.9391 4.64359 14.4244 4.82375 12.9263C5.0039 11.4282 5.67543 10.0264 6.73877 8.92885C7.80212 7.83127 9.20067 7.09629 10.7271 6.83286C12.2535 6.56943 13.8266 6.79159 15.2131 7.46638L16.8808 6.38757C15.1828 5.32825 13.1688 4.85356 11.1608 5.03944C9.15288 5.22532 7.267 6.06103 5.80486 7.41289C4.34273 8.76475 3.38881 10.5546 3.0957 12.4963C2.8026 14.4379 3.18723 16.4191 4.18809 18.1229C4.3454 18.388 4.57129 18.6085 4.8433 18.7623C5.11531 18.9162 5.42398 18.9981 5.73861 19H18.224C18.5417 19.0012 18.854 18.9207 19.1295 18.7667C19.4049 18.6127 19.6337 18.3906 19.7925 18.1229C20.6231 16.723 21.0399 15.127 20.997 13.5112C20.954 11.8953 20.453 10.3224 19.5491 8.96619L19.5401 8.97496ZM10.7147 14.9742C10.8822 15.1373 11.081 15.2667 11.2999 15.3549C11.5188 15.4432 11.7534 15.4887 11.9903 15.4887C12.2272 15.4887 12.4618 15.4432 12.6807 15.3549C12.8996 15.2667 13.0984 15.1373 13.2659 14.9742L18.3682 7.52778L10.7147 12.4921C10.5471 12.655 10.4141 12.8484 10.3234 13.0614C10.2326 13.2743 10.1859 13.5026 10.1859 13.7331C10.1859 13.9636 10.2326 14.1919 10.3234 14.4049C10.4141 14.6178 10.5471 14.8113 10.7147 14.9742V14.9742Z"
      fill={color}
    />
  </svg>
);

export default SvgSpeed;
