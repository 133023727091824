import * as React from "react";

const SvgTimer = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.43 9.39A7.02 7.02 0 0 1 19 13.778a7.016 7.016 0 0 1-1.542 4.396 7.523 7.523 0 0 1-4.014 2.58 7.792 7.792 0 0 1-4.82-.304 7.413 7.413 0 0 1-3.628-3.064 6.96 6.96 0 0 1-.928-4.551 7.098 7.098 0 0 1 2.155-4.151 7.627 7.627 0 0 1 4.345-2.029V4.8h1.875v1.854a7.575 7.575 0 0 1 3.656 1.459l1.369-1.315 1.33 1.279-1.368 1.305v.009Zm-5.925 9.812a5.746 5.746 0 0 0 3.977-1.582A5.294 5.294 0 0 0 17.13 13.8a5.293 5.293 0 0 0-1.647-3.819 5.746 5.746 0 0 0-3.978-1.581 5.746 5.746 0 0 0-3.977 1.581 5.293 5.293 0 0 0-1.648 3.82c0 1.431.593 2.805 1.648 3.818a5.746 5.746 0 0 0 3.977 1.582ZM8.693 3h5.625v1.8H8.693V3Zm4.8 7.615 1.33 1.278-3.318 3.168-1.322-1.26 3.31-3.186Z"
      fill={color}
    />
  </svg>
);

export default SvgTimer;
