import * as React from "react";

const SvgKeyboardHide = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 2H4a1.99 1.99 0 0 0-1.99 2L2 14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm-9 3h2v2h-2V5Zm0 3h2v2h-2V8ZM8 5h2v2H8V5Zm0 3h2v2H8V8Zm-1 2H5V8h2v2Zm0-3H5V5h2v2Zm9 7H8v-2h8v2Zm0-4h-2V8h2v2Zm0-3h-2V5h2v2Zm3 3h-2V8h2v2Zm0-3h-2V5h2v2Zm-7 15 4-4H8l4 4Z"
      fill={color}
    />
  </svg>
);

export default SvgKeyboardHide;
