import { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

import PageTitle from "components/PageTitle";
import Widget from "components/Widget";
import { SetBuggyToLocal } from "components/Custom";
import { Button, TextInput } from "components/Form";
import { LockClosed } from "icons";

import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { passwordValidator, toTitleCase } from "utils/string";

import useStyles from "./styles";

const languages = [
  {
    name: "ENG",
    locale: "en",
  },
  {
    name: "ESP",
    locale: "es",
  },
];

const localeLang = localStorage.getItem("i18nextLng");
const lang =
  localeLang && (localeLang === "en-US" || localeLang === "en") ? "en" : "es";

const initialPasswordValues = {
  currentPass: "",
  newPass: "",
  confirmPass: "",
};

export default function Profile() {
  const [language, setLanguage] = useState(lang);
  const [isPassSame, setIsPassSame] = useState(true);
  const [oldPassValid, setOldPassValid] = useState(true);
  const [newPassValid, setNewPassValid] = useState(true);
  const [passwordValues, setPasswordValues] = useState(initialPasswordValues);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const classes = useStyles();

  const access = getWithExpiry("access");
  const parsedUserInfo = parseJwt(access);
  const first_name = parsedUserInfo.firstname;
  const last_name = parsedUserInfo.lastname;
  const username = parsedUserInfo.username;
  const title = parsedUserInfo.title;

  const onPasswordValueChanges = (e) => {
    setPasswordValues({
      ...passwordValues,
      [e.target.name]: e.target.value,
    });
  };

  const changeLanguage = ({ target: { value } }) => {
    i18n.changeLanguage(value);
    setLanguage(value);
  };

  const changePassword = () => {
    var data = new FormData();
    data.append("old_password", passwordValues.currentPass);
    data.append("new_password", passwordValues.newPass);
    data.append("confirm_password", passwordValues.confirmPass);
    let url = `${process.env.REACT_APP_API_URL}api/change_password/${parsedUserInfo.user_id}/`;
    const method = "PUT";
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      mimeType: "multipart/form-data",
      body: data,
    };
    fetch(url, options).then(async (r) => {
      if (r.status === 401) {
        if (localStorage.getItem("access")) {
          return await changePassword();
        }
        return;
      }
      const content = await r.json();
      if (r.status === 200) {
        NotificationManager.success(t("password_reset_complete"));
        setPasswordValues(initialPasswordValues);
        setOldPassValid(true);
      } else {
        if (content.old_password) {
          setOldPassValid(false);
        } else if (content.new_password) {
          var errMsg = "";
          for (let i = 0; i < content.new_password.length; i++) {
            errMsg = errMsg + content.new_password[i] + "\n";
          }
          NotificationManager.warning(errMsg);
        }
      }
    });
  };

  const isResetDisabled = () =>
    passwordValues.currentPass === "" ||
    passwordValues.confirmPass === "" ||
    !isPassSame ||
    !newPassValid;

  useEffect(() => {
    if (
      passwordValues.confirmPass &&
      passwordValues.confirmPass !== passwordValues.newPass
    ) {
      setIsPassSame(false);
    } else {
      setIsPassSame(true);
    }
    if (
      passwordValues.newPass &&
      !passwordValidator.test(passwordValues.newPass)
    ) {
      setNewPassValid(false);
    } else {
      setNewPassValid(true);
    }
  }, [passwordValues]);

  return (
    <>
      <PageTitle title={t("profile")} />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Widget>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SetBuggyToLocal />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  <b>{t("firstname")}:</b> {first_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  <b>{t("lastname")}:</b> {last_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  <b>{t("title")}:</b> {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  <b>{t("username")}:</b> {toTitleCase(username)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.language}>
                  <Typography variant="h5" className={classes.languageText}>
                    <b>{t("language")}:</b>
                  </Typography>
                  <FormControl fullWidth className={classes.languageForm}>
                    <Select
                      labelId="language"
                      id="language"
                      value={language}
                      onChange={changeLanguage}
                    >
                      {languages.map((l) => (
                        <MenuItem key={l.locale} value={l.locale}>
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Widget>
          <Widget style={{ marginTop: "1rem", paddingBottom: "3rem" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <b>{t("password_change")}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="currentPass"
                  type="password"
                  label={t("password_current")}
                  LeftIcon={LockClosed}
                  value={passwordValues.currentPass}
                  onChange={onPasswordValueChanges}
                  error={!oldPassValid && t("invalid_password_current")}
                  fullWidth
                  hasBorder
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="newPass"
                  type="password"
                  label={t("password_new")}
                  LeftIcon={LockClosed}
                  value={passwordValues.newPass}
                  onChange={onPasswordValueChanges}
                  error={!newPassValid && t("invalid_password")}
                  fullWidth
                  hasBorder
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="confirmPass"
                  type="password"
                  label={t("password_confirm")}
                  LeftIcon={LockClosed}
                  value={passwordValues.confirmPass}
                  onChange={onPasswordValueChanges}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !isResetDisabled()) {
                      changePassword();
                    }
                  }}
                  error={!isPassSame && t("invalid_password_confirm")}
                  fullWidth
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Button
                  color="blue"
                  onClick={changePassword}
                  disabled={isResetDisabled()}
                  containerStyle={{ marginLeft: "0rem" }}
                >
                  <b>{t("update")}</b>
                </Button>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
