import * as React from "react";

const SvgClipboard = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.007 5.117A1 1 0 0 0 11 6h2a1 1 0 0 0 1-1l-.007-.117A1 1 0 0 0 13 4h-2l-.117.007A1 1 0 0 0 10 5ZM8.17 6 7 6l-.117.007A1 1 0 0 0 6 7v12l.007.117A1 1 0 0 0 7 20h10a1 1 0 0 0 1-1V7l-.007-.117A1 1 0 0 0 17 6h-1.171A3.001 3.001 0 0 1 13 8h-2l-.176-.005A3.002 3.002 0 0 1 8.17 6ZM13 2c1.306 0 2.418.835 2.83 2H17l.176.005A3 3 0 0 1 20 7v12l-.005.176A3 3 0 0 1 17 22H7l-.176-.005A3 3 0 0 1 4 19V7l.005-.176A3 3 0 0 1 7 4h1.17A3.001 3.001 0 0 1 11 2Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgClipboard;
