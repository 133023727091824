import ReactApexChart from "react-apexcharts";

export function DonutChart({ series, labels, title, colors }) {
  let chartOptions = {
    chart: {
      type: "donut",
    },
    series,
    labels,
    title: {
      align: "center",
      text: title,
    },
    colors,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, w }) {
        return w.config.series[seriesIndex];
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "40%",
          labels: {
            total: {
              show: true,
              label: "Total",
            },
          },
        },
      },
    },
  };

  return <ReactApexChart options={chartOptions} series={series} type="donut" />;
}
