import * as React from "react";

const SvgGroup = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.649 13.297c-1.217 0-2.19.325-2.92.73 1.136.973 1.622 2.108 1.703 2.19l.082.161v1.784H21v-1.621c-.081 0-1.378-3.244-5.351-3.244ZM15.649 11.676a2.838 2.838 0 1 0 0-5.676 2.838 2.838 0 0 0 0 5.676ZM5.432 8.838C5.432 7.298 6.73 6 8.27 6s2.838 1.297 2.838 2.838c0 1.54-1.297 2.838-2.838 2.838-1.54 0-2.838-1.298-2.838-2.838Zm2.838 4.46C4.297 13.297 3 16.54 3 16.54v1.621h10.54v-1.621s-1.297-3.244-5.27-3.244Z"
      fill={color}
    />
  </svg>
);

export default SvgGroup;
