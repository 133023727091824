export function toTitleCase(str) {
  if (!str) {
    return "-";
  }
  // remove characters before the last double underscores"__"
  if (str.includes("__")) {
    str = str.replace(/.*__/, "");
  }
  return str
    .split(" ")
    .map((w) => {
      if (w) {
        if (w.indexOf("_") !== -1) {
          return w
            .split("_")
            .map((x) => x[0].toUpperCase() + x.substr(1).toLowerCase())
            .join(" ");
        }
        return w[0].toUpperCase() + w.substr(1).toLowerCase();
      }
      return w;
    })
    .join(" ");
}

export function formatLocationCode(str) {
  // format location code entered without dashes (-)
  const pattern = new RegExp("([Dd]?\\d{3})[-]?(\\w{3})[-]?(\\d{2})", "g");
  const groups = pattern.exec(str);
  if (groups) {
    str = `${groups[1]}-${groups[2]}-${groups[3]}`;
  }
  // replace all special characters except dashes (-) and spaces
  return str.replace(/[^a-zA-Z0-9-\s]/g, "");
}

export function formatProductSKU(str) {
  str = str.toUpperCase();
  const parts = str.split(" ");
  if (parts.length > 1) {
    parts[parts.length - 1] = parts[parts.length - 1].replace("X", "x");
    str = parts.join(" ");
  } else {
    str = str.replace("X", "x");
  }
  return str.replace("*", "x");
}

export function separateQtyFromValue(str) {
  const parts = str.split(",");
  const value = parts[0].trim();
  let qty = null;
  parts.forEach((part) => {
    part = part.trim();
    if (part.startsWith("Q") || part.startsWith("q")) {
      part = parseInt(part.replace("Q", "").replace("q", ""));
      if (part <= 999) {
        qty = part.toString();
      }
    }
  });
  return { qty, value };
}

export const formatCurrency = (value, minFractionDigits = 0) => {
  return parseInt(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minFractionDigits,
  });
};

export const passwordValidator = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
);

export const sliceText = (str, length) => {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
}
