import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getReceiptLines = ({
  setFunc,
  setError,
  paginated,
  responseSetter,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("receipt-line/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getReceiptRelations = ({ setError, params, responseSetter }) => {
  api.list({
    endpoint: `receipt-relation/${params.id}/`,
    setError,
    responseSetter,
  });
};

const getReceipts = ({
  setFunc,
  setError,
  paginated,
  responseSetter,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("receipts/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const printReceiptPDF = ({ params, responseSetter, setError }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/receipt-label/", params),
    setError,
    responseSetter,
  });
};

const publishReceipt = ({ id, responseSetter, setError }) => {
  api.update({
    endpoint: `custom/publish-receipt/${id}/`,
    setError,
    responseSetter,
  });
};

const receiptAvailableDocks = ({ body, responseSetter, setError }) => {
  api.list({
    endpoint: `custom/receipt-available-docks/`,
    body,
    setError,
    responseSetter,
  });
};

const receiptTransfer = ({ body, responseSetter, setError }) => {
  api.update({
    endpoint: `custom/receipt-transfer/`,
    body,
    setError,
    responseSetter,
  });
};

const receivingReport = ({ params, responseSetter, setError }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/receiving-report/", params),
    setError,
    responseSetter,
  });
};

const updateReceipt = ({ id, body, responseSetter, setError }) => {
  api.update({
    endpoint: `receipts/${id}/`,
    body,
    setError,
    responseSetter,
  });
};

export {
  getReceiptLines,
  getReceiptRelations,
  getReceipts,
  printReceiptPDF,
  publishReceipt,
  receiptAvailableDocks,
  receiptTransfer,
  receivingReport,
  updateReceipt,
};
