import * as React from "react";

const SvgBarChart = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 12.895h3.111V20H5v-7.105Zm10.889-3.158H19V20h-3.111V9.737ZM10.444 5h3.112v15h-3.112V5Z"
      fill={color}
    />
  </svg>
);

export default SvgBarChart;
