import { toTitleCase } from "./string";

const convertDataToTableView = (items, cells) => {
  return items.map((item) => {
    let row = {};
    cells
      .filter((cell) => cell.export !== false)
      .map((cell) => {
        const cellTitle =
          cell.exportLabel || toTitleCase(cell.label || cell.id);
        row[cellTitle] = cell.render ? cell.render(item, true) : item[cell.id];
        return cell;
      });
    return row;
  });
};

export { convertDataToTableView };
