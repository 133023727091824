import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getContainerRecords = ({
  setFunc,
  setError,
  paginated,
  params,
  mergedFilters,
  responseSetter,
  id,
}) => {
  api.list({
    endpoint: id
      ? `purchase/incoming-container/${id}/`
      : addParamsToEndpoint(`purchase/incoming-container/`, params) +
        mergedFilters,
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const createContainerRecord = ({ setError, body, setFunc }) => {
  api.create({
    endpoint: addParamsToEndpoint("purchase/incoming-container/"),
    body,
    setError,
    setFunc,
  });
};

const updateContainerRecord = ({ id, body, setFunc, setError }) => {
  api.update({
    endpoint: `purchase/incoming-container/${id}/`,
    body,
    setError,
    setFunc,
  });
};

const createContainerFile = ({ body, responseSetter }) => {
  api.create({
    endpoint: "purchase/create-container-file/",
    body,
    responseSetter,
  });
};

const createContainerReceipt = ({ body, responseSetter }) => {
  api.create({
    endpoint: "purchase/receipt/",
    body,
    responseSetter,
  });
};

const validateContainerReceipt = ({ body, responseSetter }) => {
  api.create({
    endpoint: "purchase/validate/",
    body,
    responseSetter,
  });
};

const readContainerReceipt = ({ body, responseSetter }) => {
  api.create({
    endpoint: `purchase/read-receipt/`,
    body,
    responseSetter,
  });
};

const deleteContainerFile = ({ id, responseSetter }) => {
  api.delete({
    endpoint: `purchase/delete-container-file/`,
    body: { id },
    responseSetter,
  });
};

export {
  getContainerRecords,
  createContainerRecord,
  updateContainerRecord,
  createContainerFile,
  createContainerReceipt,
  validateContainerReceipt,
  readContainerReceipt,
  deleteContainerFile,
};
