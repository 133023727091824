import React, { useCallback, useEffect, useRef, useState } from "react";

import _ from "lodash";
import { Avatar, Grid, IconButton, InputAdornment } from "@material-ui/core";

import { dashboardTransactions } from "api/shipment";
import {
  Class,
  FolderSpecial,
  QrCode2,
  Speed,
  TextSnippet,
  X as Clear,
} from "icons";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import { Spacer, StatusLabel } from "components/Custom";
import { StatusCircle } from "components/Dashboard/StatusCircle";
import { Dropdown, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import DetailTimeline from "pages/shipments/ShipmentDetailTimeline";
import { getCarrierTrackingLink } from "pages/shipments/utils";

import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";

import { isExpress, isUps } from "utils/carrier";
import { toTime } from "utils/date";
import { updateDocumentTitle } from "utils/dom";

import useStyles from "./styles";

export default function OfficeDashboard() {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [selected, setSelected] = useState("Late_Shipments");
  const [details, setDetails] = useState({
    Unassigned: [],
    Assigned: [],
    Picked: [],
    Shipped: [],
    Invoiced: [],
    Pending_Cancellation: [],
    Late_Shipments: [],
    Alternate_Picks: [],
  });
  const [aggregatedResults, setAggregatedResults] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    customer: "",
    po_number: "",
    sku: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("shipment_number");

  const [timelineRow, setTimelineRow] = useState({});
  const [headerCounts, setHeaderCounts] = useState({});

  updateDocumentTitle("Office DB");

  const handleSelect = (selectedCard) => {
    setSelected(selectedCard);
  };

  const getDetails = useCallback(async () => {
    dashboardTransactions({
      body: {
        status: "Unassigned",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Unassigned: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Assigned",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Assigned: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Picked",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Picked: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Shipped",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Shipped: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Late_Shipments",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Late_Shipments: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Invoiced",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Invoiced: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Pending_Cancellation",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Pending_Cancellation: res.details,
        }));
      },
    });
    dashboardTransactions({
      body: {
        status: "Alternate_Picks",
      },
      responseSetter: (res) => {
        setDetails((prev) => ({
          ...prev,
          Alternate_Picks: res.details,
        }));
      },
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getDetails();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [getDetails]);

  useEffect(() => {
    setAggregatedResults(
      _.uniqBy(
        [
          ...details.Assigned,
          ...details.Picked,
          ...details.Shipped,
          ...details.Invoiced,
          ...details.Pending_Cancellation,
          ...details.Late_Shipments,
          ...details.Alternate_Picks,
        ],
        "id",
      ),
    );
  }, [details]);

  const updateResults = useCallback(
    (row) => {
      if (filters.status && row.status !== filters.status) {
        return false;
      }
      if (
        filters.customer &&
        !row.customer.toUpperCase().includes(filters.customer.toUpperCase())
      ) {
        return false;
      }
      if (
        filters.po_number &&
        !row.po_number.toLowerCase().includes(filters.po_number.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.sku &&
        !row.product_sku.toLowerCase().includes(filters.sku.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.ship_via &&
        !row.ship_via.toLowerCase().includes(filters.ship_via.toLowerCase())
      ) {
        return false;
      }
      return true;
    },
    [filters],
  );

  const cells = [
    {
      id: "shipment_number",
      render: (r) => {
        const totalQuantity = aggregatedResults
          .filter((i) => i.shipment_number === r.shipment_number)
          .reduce((acc, curr) => acc + curr.quantity, 0);
        return (
          <InternalLink to={`/app/shipments/${r.shipment_id}`}>
            {r.shipment_number} {totalQuantity > 1 ? `(${totalQuantity})` : ""}
          </InternalLink>
        );
      },
      sortable: true,
    },
    {
      id: "customer",
      sortable: true,
    },
    {
      id: "po_number",
      sortable: true,
    },
    {
      id: "product_sku",
      label: "SKU",
      sortable: true,
    },
    {
      id: "location",
      render: (r) => {
        return (
          <>
            <span style={{ padding: 0, margin: 0 }}>{r.location}</span>
            {r.pick_location !== r.location ? (
              <span className={classes.alternatePick} title="Picked from">
                {` (${r.pick_location})`}
              </span>
            ) : null}
          </>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      render: (r) => <StatusLabel status={r.status} />,
    },
    {
      id: "ship_via",
      render: (r) => {
        return isUps(r) ? (
          <div className={classes.carrierContainer}>
            <Avatar src={UpsLogo} variant="square" />
            {isExpress(r.ship_via) && <Speed color="red" />}
          </div>
        ) : (
          <div className={classes.carrierContainer}>
            <Avatar src={FedexLogo} variant="square" />
            {isExpress(r.ship_via) && <Speed color="red" />}
          </div>
        );
      },
    },
    {
      id: "tracking_number",
      render: (r) => (
        <ExternalLink
          to={getCarrierTrackingLink(r.ship_via, r.tracking_number)}
          children={r.tracking_number}
        />
      ),
    },
    {
      id: "updated_at",
      label: "Time",
      render: (r) => {
        return (
          <span
            onClick={(e) => {
              tableRef.current = e.target;
              setTimelineRow(r);
            }}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {toTime(r.updated_at)}
          </span>
        );
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    const items = details[selected].filter(updateResults);
    const headerCounts = {
      shipment_number: _.size(_.groupBy(items, "shipment_id")),
      customer: _.size(_.groupBy(items, "customer")),
      product_sku: _.size(_.groupBy(items, "product_sku")),
      location: _.size(_.groupBy(items, "location")),
    };
    setHeaderCounts(headerCounts);
  }, [details, selected, updateResults]);

  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <Widget>
          <Grid
            container
            spacing={0}
            className={classes.containerCenter}
            style={{ cursor: "pointer" }}
          >
            <Grid item xs={6} lg={3} onClick={() => handleSelect("Unassigned")}>
              <StatusCircle
                status="Unassigned"
                content={details}
                selected={selected}
              />
            </Grid>
            <Grid item xs={6} lg={3} onClick={() => handleSelect("Picked")}>
              <StatusCircle
                status="Picked"
                content={details}
                selected={selected}
              />
            </Grid>
            <Grid item xs={6} lg={3} onClick={() => handleSelect("Shipped")}>
              <StatusCircle
                status="Shipped"
                content={details}
                selected={selected}
              />
            </Grid>
            <Grid item xs={6} lg={3} onClick={() => handleSelect("Invoiced")}>
              <StatusCircle
                status="Invoiced"
                content={details}
                selected={selected}
              />
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Widget>
          <Grid
            container
            spacing={0}
            className={classes.containerCenter}
            style={{ cursor: "pointer" }}
          >
            <Grid
              item
              xs={6}
              lg={3}
              onClick={() => handleSelect("Pending_Cancellation")}
            >
              <StatusCircle
                status="Pending_Cancellation"
                content={details}
                selected={selected}
                size={500}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              onClick={() => handleSelect("Late_Shipments")}
            >
              <StatusCircle
                status="Late_Shipments"
                content={details}
                selected={selected}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              onClick={() => handleSelect("Alternate_Picks")}
            >
              <StatusCircle
                status="Alternate_Picks"
                content={details}
                selected={selected}
              />
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      <Spacer height="2rem" />
      <Grid item lg={12} xs={12}>
        <InputContainer>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Dropdown
              id="status"
              title="Status"
              value={filters.status}
              onChange={({ target }) =>
                setFilters({ ...filters, status: target.value })
              }
              items={[
                "Unassigned",
                "Assigned",
                "Picked",
                "Shipped",
                "Invoiced",
                "Pending_Cancellation",
              ].map((item) => ({
                value: item,
                label: item.split("_").join(" "),
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Customer"
              variant="outlined"
              value={filters.customer || ""}
              onChange={({ target }) => {
                setFilters({ ...filters, customer: target.value });
                aggregatedResults.filter(updateResults);
              }}
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  aggregatedResults.filter(updateResults);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilters({ ...filters, customer: "" });
                      }}
                      edge="end"
                    >
                      {filters.customer && filters.customer.length > 0 && (
                        <Clear />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="PO #"
              variant="outlined"
              value={filters.po_number || ""}
              onChange={({ target }) =>
                setFilters({ ...filters, po_number: target.value })
              }
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  aggregatedResults.filter(updateResults);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilters({ ...filters, po_number: "" });
                      }}
                      edge="end"
                    >
                      {filters.po_number && filters.po_number.length > 0 && (
                        <Clear />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="SKU"
              variant="outlined"
              value={filters.sku || ""}
              onChange={({ target }) =>
                setFilters({ ...filters, sku: target.value })
              }
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  aggregatedResults.filter(updateResults);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilters({ ...filters, sku: "" });
                      }}
                      edge="end"
                    >
                      {filters.sku && filters.sku.length > 0 && <Clear />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Ship Via"
              variant="outlined"
              value={filters.ship_via || ""}
              onChange={({ target }) =>
                setFilters({ ...filters, ship_via: target.value })
              }
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  aggregatedResults.filter(updateResults);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilters({ ...filters, ship_via: "" });
                      }}
                      edge="end"
                    >
                      {filters.ship_via && filters.ship_via.length > 0 && (
                        <Clear />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}></Grid>
        </InputContainer>
      </Grid>
      <Grid item lg={12} md={12}>
        {details && details[selected] ? (
          <DefaultTable
            data={{ items: details[selected].filter(updateResults) }}
            headers={cells}
            headerCounts={headerCounts}
            sortActions={{
              onSort: (header) => {
                const isAsc = orderBy === header && orderDirection === "asc";
                const newDirection = isAsc ? "desc" : "asc";
                setOrderDirection(newDirection);
                setOrderBy(header);
                setDetails((prev) => ({
                  ...prev,
                  [selected]: _.orderBy(
                    prev[selected],
                    [header],
                    [newDirection],
                  ),
                }));
              },
              orderBy,
              orderDirection,
            }}
            filters={filters}
          />
        ) : null}
      </Grid>
      <Grid item lg={12} xs={12}>
        {timelineRow && timelineRow.id ? (
          <DetailTimeline
            detailId={timelineRow.id}
            anchorRef={tableRef}
            onClose={() => setTimelineRow({})}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
