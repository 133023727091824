import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { useTheme } from "@material-ui/styles";

import { signOut, useUserDispatch } from "context/UserContext";
import { useLayoutState } from "context/LayoutContext";

import { Logout1 } from "icons";

import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import useStyles from "./styles";

export default function Profile(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const userDispatch = useUserDispatch();
  const { firstname, lastname } = parseJwt(getWithExpiry("access"));
  const { isSidebarOpened } = useLayoutState();

  return (
    <div
      className={classNames(classes.profile, {
        [classes.drawerClosedProfile]: !isSidebarOpened,
      })}
    >
      <div className={classes.photo}>
        <p style={{ margin: 0 }}>
          {firstname && lastname ? `${firstname[0]} ${lastname[0]}` : null}
        </p>
      </div>
      {isSidebarOpened ? (
        <>
          <div className={classes.detail}>
            <p className={classes.name}>{`${firstname} ${lastname}`}</p>
            <p className={classes.link}>
              <Link to="/app/profile">{t("view_profile")}</Link>
            </p>
          </div>
          <div
            className={classes.logout}
            onClick={() => signOut(userDispatch, props.history)}
          >
            <Logout1 color={theme.palette.colors.light_hover} />
          </div>
        </>
      ) : null}
    </div>
  );
}
