import { useEffect, useState } from "react";
import _ from "lodash";
import { Popover } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import {
  ClipboardList,
  ShoppingCart,
  Truck,
  Receipt,
  X,
  Archive2,
} from "icons";
import { timezoneFormat } from "utils/date";
import { toTitleCase } from "utils/string";
import { getShipmentDetailByID } from "api/shipment";

const ActionTypeIcons = {
  Assigned: <ClipboardList />,
  Picked: <ShoppingCart color="#000" />,
  Shipped: <Truck color="#000" />,
  Invoiced: <Receipt />,
  Cancelled: <X />,
  Packed: <Archive2 color="#000" />,
};

export default function DetailTimeline({
  detailId,
  anchorRef,
  onClose,
  isMobile,
}) {
  const theme = useTheme();
  const layoutDispatch = useLayoutDispatch();
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (detailId && !selectedRow.id) {
      getShipmentDetailByID({
        id: detailId,
        responseSetter: (res) => setSelectedRow(res),
        setError: (err) =>
          popupNotification({
            dispatch: layoutDispatch,
            message: err.message,
          }),
      });
    }
  }, [detailId, layoutDispatch, selectedRow.id]);

  return selectedRow && selectedRow.id ? (
    <Popover
      open={!!selectedRow.transactions}
      anchorEl={anchorRef ? anchorRef.current : null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      style={{
        marginTop: isMobile ? "-3rem" : "0.5rem",
        marginLeft: "0.5rem",
      }}
    >
      <Timeline
        position="left"
        style={{
          backgroundColor:
            selectedRow.status === "Unassigned"
              ? `${theme.palette.colors.slate200}50`
              : selectedRow.status === "Assigned"
              ? `${theme.palette.colors.amber200}50`
              : selectedRow.status === "Picking"
              ? `${theme.palette.colors.amber300}50`
              : selectedRow.status === "Picked"
              ? `${theme.palette.colors.blue200}50`
              : selectedRow.status === "Packed"
              ? `${theme.palette.colors.pastelBlue}50`
              : selectedRow.status === "Shipped"
              ? `${theme.palette.colors.emerald200}50`
              : selectedRow.status === "Invoiced"
              ? `${theme.palette.colors.emerald500}50`
              : `${theme.palette.colors.rose200}50`,
        }}
      >
        {_.orderBy(selectedRow.transactions, "transaction_time", "asc").map(
          (transaction, index) => (
            <TimelineItem key={transaction.id}>
              <TimelineOppositeContent color="text.secondary">
                {timezoneFormat(transaction.transaction_time)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  {ActionTypeIcons[transaction.action_type]}
                </TimelineDot>
                {index <
                  selectedRow.transactions.length -
                    (selectedRow.shipment.invoice_number ? 0 : 1) && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>{`${transaction.action_type} ${
                transaction.action_type === "Assigned" ? "to" : "by"
              } ${toTitleCase(transaction.user.username)}`}</TimelineContent>
            </TimelineItem>
          ),
        )}
        {selectedRow.shipment.invoice_number && (
          <>
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                {selectedRow.shipment.invoiced_at
                  ? timezoneFormat(selectedRow.shipment.invoiced_at)
                  : "-"}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  {ActionTypeIcons["Invoiced"]}
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>Invoiced</TimelineContent>
            </TimelineItem>
          </>
        )}
      </Timeline>
    </Popover>
  ) : null;
}
