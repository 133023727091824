import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";

export default function SwitchButton({
  children,
  value,
  selectedValue,
  onClick,
  onClear,
  xs,
  sm,
  md,
  lg,
  color,
  defaultBg,
  selectedBg,
  itemSize,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      onClick={() => {
        if (onClear && selectedValue === value) {
          onClear();
        } else {
          onClick(value);
        }
      }}
      className={classes.switchButton}
      style={{
        background:
          selectedValue === value
            ? selectedBg || theme.palette.colors.blue400
            : defaultBg || theme.palette.colors.white,
        color:
          selectedValue === value && color !== "default"
            ? theme.palette.colors.white
            : theme.palette.colors.blue700,
        width: itemSize ? `${100 / itemSize}%` : null,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
}
