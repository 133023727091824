import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { ProductTypeFilter } from "components/Custom";
import {
  Checkbox,
  DatePickerGroup,
  Dropdown,
  RangeInput,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import { QrCode2, Selector, SpaceBar, CloudUpload, Person } from "icons";
import { LOCATION_POSITIONS } from "utils/constants";

export default function CycleCountFilter({
  handleChangeFilterInput,
  params,
  clearAdornment,
  debouncedSearchHandler,
  setParams,
  children,
}) {
  const [t] = useTranslation();

  return (
    <Grid container>
      <DatePickerGroup
        startDateValue={params.start_date}
        startDateOnChange={handleChangeFilterInput("start_date")}
        endDateValue={params.end_date}
        endDateOnChange={handleChangeFilterInput("end_date")}
        itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
      />
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <TextInput
          fullWidth
          id="sku"
          label={t("product_sku")}
          variant="outlined"
          value={params.product_sku}
          onChange={handleChangeFilterInput("product_sku")}
          onKeyUp={debouncedSearchHandler}
          LeftIcon={QrCode2}
          InputProps={clearAdornment("product_sku")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="location"
          label={`${t("location")} (*)`}
          variant="outlined"
          title="Searches from beginning"
          value={params.location_code_start}
          onChange={handleChangeFilterInput("location_code_start")}
          onKeyUp={debouncedSearchHandler}
          isLocationCode={true}
          LeftIcon={SpaceBar}
          InputProps={clearAdornment("location_code_start")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Dropdown
          id="location_position"
          title={t("position")}
          value={params.location_position}
          onChange={handleChangeFilterInput("location_position")}
          items={LOCATION_POSITIONS}
          LeftIcon={Selector}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Dropdown
          id={"publish"}
          title={t("Published")}
          value={params.is_published}
          onChange={handleChangeFilterInput("is_published")}
          items={[
            { value: "True", label: "Published" },
            { value: "False", label: "Not Published" },
          ]}
          LeftIcon={CloudUpload}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="created_by"
          label="Created By"
          variant="outlined"
          value={params.created_by_username}
          onChange={handleChangeFilterInput("created_by_username")}
          onKeyUp={debouncedSearchHandler}
          LeftIcon={Person}
          InputProps={clearAdornment("created_by_username")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Checkbox
          checked={params.show_only_different === "True"}
          onChange={() => {
            setParams((prev) => ({
              ...prev,
              show_only_different:
                prev.show_only_different === "True" ? "False" : "True",
            }));
            debouncedSearchHandler();
          }}
          color="blue"
          label="Only Diffs"
          title="Hide if counted quantity is equal to previous quantity. If both quantity are the same, no transfer/adjustment will be created."
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <RangeInput
          label="Diff"
          minKey="diff_min"
          maxKey="diff_max"
          params={params}
          onChange={handleChangeFilterInput}
        />
      </Grid>
      <ProductTypeFilter
        value={params.product_type}
        setValue={(data) => {
          setParams({ ...params, product_type: data });
          debouncedSearchHandler();
        }}
        onChange={handleChangeFilterInput("product_type")}
        always_visible={true}
      />
      {children}
    </Grid>
  );
}
