export function downloadPDF(base64, title) {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const fileName = `${title}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function toBase64Handler(files) {
  const filePathsPromises = [];
  files.forEach((file) => {
    filePathsPromises.push(toBase64(file));
  });
  const results = await Promise.all(filePathsPromises);
  return results;
}
