import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@material-ui/core";

import themes from "themes";

import {
  createInspectLocation,
  getInspectLocations,
  completeInspectLocation,
} from "api/locations";

import { Circle } from "components/Charts/Circle";
import { ConfirmDialog, StatusLabel } from "components/Custom";
import Spacer from "components/Custom/Spacer";
import { Button } from "components/Form";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { ExternalLink } from "icons";

import useStyles from "pages/dashboard/styles";

import { initialAPIListValue, INSPECTION_REASONS } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";

import { exportToExcel } from "utils/exportToExcel";
import { toTitleCase } from "utils/string";

const initialInspectLocationValue = {
  inspect_id: "",
  code: "",
};

const initialParams = {
  limit: 1000,
  is_inspected: false,
  is_cancelled: false,
};

const exportHeaders = [
  {
    id: "location",
    render: (r) => r.location.code,
  },
  {
    id: "reason",
    render: (r) => toTitleCase(r.reason),
  },
  {
    id: "sku",
    render: (r) => r.product?.sku,
  },
  {
    id: "qty",
    render: (r) => r.qty,
  },
  {
    id: "note",
    render: (r) => r.note,
  },
  {
    id: "created_at",
    render: (r) => timezoneFormat(r.created_at),
  },
  {
    id: "created_by",
    render: (r) => toTitleCase(r.created_by.username),
  },
];

export default function InspectionWidget() {
  const classes = useStyles();
  const { permissions } = useUserState();
  const layoutDispatch = useLayoutDispatch();
  const [t] = useTranslation();
  const [locationInspections, setLocationInspections] = useState(
    initialAPIListValue,
  );
  const [productInspections, setProductInspections] = useState(
    initialAPIListValue,
  );
  const [selectedInspect, setSelectedInspect] = useState(
    initialInspectLocationValue,
  );
  const [selectedTab, setSelectedTab] = useState("location-based");
  const [createInspection, setCreateInspection] = useState(false);
  const [modal, setModal] = useState(false);
  const hasPermission = permissions.includes("warehouse.view_inspectlocation");

  useEffect(() => {
    if (locationInspections.isFetching) {
      getInspectLocations({
        setFunc: (res) => {
          const { items } = res;
          const productBased = [];
          const locationBased = [];
          items.forEach((item) => {
            if (!item.is_location_based) {
              return productBased.push(item);
            }
            locationBased.push(item);
          });
          setLocationInspections({
            isFetching: false,
            items: locationBased,
            count: locationBased.length,
          });
          setProductInspections({
            isFetching: false,
            items: productBased,
            count: productBased.length,
          });
        },
        params: initialParams,
        paginated: true,
      });
    }
  }, [locationInspections.isFetching]);

  const handleOpen = (inspection) => {
    setSelectedInspect({
      inspect_id: inspection.id,
      code: inspection.location.code,
    });
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    setSelectedInspect(initialInspectLocationValue);
  };

  const handleSubmit = () => {
    const { inspect_id } = selectedInspect;
    completeInspectLocation({
      body: {
        inspect_id,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        setLocationInspections(initialAPIListValue);
        setProductInspections(initialAPIListValue);
      },
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `${toTitleCase(selectedTab)}-Inspections-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, exportHeaders),
    );
  };

  const handleExportData = () => {
    const dataArray =
      selectedTab === "location-based"
        ? locationInspections
        : productInspections;
    if (dataArray.items.length === dataArray.count) {
      exportData(dataArray.items);
    } else {
      getInspectLocations({
        params: {
          ...initialParams,
          limit: dataArray.count,
        },
        setFunc: ({ items }) => exportData(items),
        paginated: true,
      });
    }
  };

  const handleSelectTab = (tab) => (e) => {
    setSelectedTab(tab);
  };

  const handleCloseCreateModal = (status = false) => {
    if (status) {
      setLocationInspections((prev) => ({ ...prev, isFetching: true }));
    }
    setCreateInspection(false);
  };

  return (
    <Widget>
      {locationInspections.isFetching || productInspections.isFetching ? (
        <CircularProgress />
      ) : (
        <>
          {modal && hasPermission && (
            <ConfirmDialog
              title={`Location inspected ? (${selectedInspect.code})`}
              open={true}
              onClose={handleClose}
              onConfirm={handleSubmit}
              confirmText="Yes"
            />
          )}
          {createInspection ? (
            <CreatInspection
              onClose={(status) => handleCloseCreateModal(status)}
              base_type={selectedTab}
            />
          ) : null}
          <Grid
            container
            spacing={0}
            className={classes.containerCenter}
            style={{ cursor: "pointer" }}
          >
            <Grid item xs={6} lg={4}>
              <Circle
                title="Product-based Inspections"
                number={productInspections.count}
                color={themes.default.palette.colors.rose600}
                isSelected={selectedTab === "product-based"}
                size={450}
                series={[productInspections.count]}
                onClick={() => handleSelectTab("product-based")}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <Circle
                title="Location-based Inspections"
                number={locationInspections.count}
                color={themes.default.palette.colors.blue_button}
                isSelected={selectedTab === "location-based"}
                size={450}
                series={[locationInspections.count]}
                onClick={() => handleSelectTab("location-based")}
              />
            </Grid>
          </Grid>
          <Spacer height="2rem" />
          <Grid
            container
            spacing={0}
            className={[classes.container, classes.tableContainer].join(" ")}
            style={{ minHeight: "1rem" }}
          >
            {selectedTab === "location-based" ? (
              <LocationBasedInspections
                inspections={locationInspections}
                handleOpen={handleOpen}
              />
            ) : (
              <ProductBasedInspections
                inspections={productInspections}
                handleOpen={handleOpen}
              />
            )}
          </Grid>
          {hasPermission && (
            <Grid container>
              <Grid item lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateInspection(true)}
                >
                  Request
                </Button>
              </Grid>
              <Grid item lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="blue"
                  onClick={() => handleExportData()}
                  disabled={
                    selectedTab === "location-based"
                      ? !locationInspections.count
                      : !productInspections.count
                  }
                >
                  Export Data
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Widget>
  );
}

function LocationBasedInspections({ inspections, handleOpen }) {
  const classes = useStyles();

  return (
    <Grid container>
      {inspections.items.length > 0
        ? inspections.items.map((inspection, index) => (
            <Grid
              item
              lg={12}
              key={inspection.id}
              className={[
                classes.tableRow,
                index % 2 === 0 ? classes.rowEven : classes.rowOdd,
              ].join(" ")}
            >
              <Grid container>
                <Grid item lg={3} xs={3} style={{ paddingLeft: "10px" }}>
                  <LocationCodeLink
                    handleOpen={handleOpen}
                    inspection={inspection}
                  />
                </Grid>
                <Grid item lg={3} xs={3}>
                  {toTitleCase(inspection.reason)}
                </Grid>
                <Grid item lg={3} xs={3}>
                  {timezoneFormat(inspection.created_at)}
                </Grid>
                <Grid item lg={3} xs={3}>
                  {toTitleCase(inspection.created_by.username)}
                </Grid>
              </Grid>
            </Grid>
          ))
        : null}
    </Grid>
  );
}

function ProductBasedInspections({ inspections, handleOpen }) {
  const classes = useStyles();
  const [grouppedByProduct, setGrouppedByProduct] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (inspections) {
      setGrouppedByProduct(
        _.groupBy(inspections.items, (inspection) => inspection.product.sku),
      );
    }
  }, [inspections]);

  return (
    <Grid container spacing={1}>
      {Object.keys(selectedRow).length ? (
        <ListInspections
          inspections={selectedRow}
          onClose={() => setSelectedRow({})}
          handleOpen={handleOpen}
        />
      ) : null}
      {Object.keys(grouppedByProduct).map((sku, index) => (
        <Grid
          lg={12}
          item
          key={index}
          className={[
            classes.tableRow,
            index % 2 === 0 ? classes.rowEven : classes.rowOdd,
          ].join(" ")}
        >
          <Grid container>
            <Grid item lg={4} xs={6} style={{ paddingLeft: "10px" }}>
              {sku}
            </Grid>
            <Grid item lg={4}>
              {toTitleCase(grouppedByProduct[sku][0].reason)}
            </Grid>
            <Grid item lg={4} xs={6}>
              <span
                className={classes.selectorClass}
                onClick={() => setSelectedRow(grouppedByProduct[sku])}
              >
                Locations: {grouppedByProduct[sku].length}
                <ExternalLink
                  color={themes.default.palette.colors.blue_button}
                  size={0.8}
                />
              </span>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

function ListInspections({ inspections, onClose, handleOpen }) {
  const headers = [
    {
      id: "code",
      render: (r) => (
        <LocationCodeLink handleOpen={handleOpen} inspection={r} />
      ),
    },
    {
      label: "Sales Allowed / Include Available Qty",
      render: (r) => (
        <StatusLabel status={r.location.is_sales_allowed ? "Yes" : "No"} />
      ),
    },
  ];
  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
      <DialogTitle>Locations</DialogTitle>
      <DialogContent>
        <DefaultTable
          headers={headers}
          data={{
            items: inspections,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CreatInspection({ onClose, base_type }) {
  const layoutDispatch = useLayoutDispatch();
  const [formData, setFormData] = useState({
    location_code: "",
    reason: "",
    sales_allowed: false,
    sku: "",
    note: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSwitch = ({ target: { name, checked } }) => {
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChangeText = ({ target: { name, value } }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    createInspectLocation({
      body: {
        ...formData,
        is_location_based: base_type === "location-based",
      },
      responseSetter: (res) => {
        setIsLoading(false);
        const { success, message } = res;
        if (!success) {
          setFormData((prev) => ({ ...prev, sku: "" }));
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return;
        }
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
        onClose(true);
      },
    });
  };

  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>
        Create Inspection by{" "}
        {base_type === "location-based" ? "Location" : "Product"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {base_type === "product-based" ? (
            <Grid item lg={12}>
              <TextField
                label="Product"
                name="sku"
                variant="outlined"
                value={formData.sku}
                onChange={handleChangeText}
                fullWidth
                autoFocus
                disabled={isLoading}
              />
            </Grid>
          ) : (
            <Grid item lg={12}>
              <TextField
                label="Location"
                name="location_code"
                variant="outlined"
                value={formData.location_code}
                onChange={handleChangeText}
                fullWidth
                autoFocus
                disabled={isLoading}
              />
            </Grid>
          )}
          <Grid item lg={12}>
            <TextField
              fullWidth
              label="Note"
              name="note"
              variant="outlined"
              value={formData.note}
              onChange={handleChangeText}
              disabled={isLoading}
            />
          </Grid>
          <Grid item lg={12}>
            <FormControl component="fieldset" variant="outlined">
              <FormLabel component="legend"> Reason:</FormLabel>
              <RadioGroup
                aria-label="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChangeText}
                row
              >
                {Object.entries(INSPECTION_REASONS).map(([value, label]) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.sales_allowed}
                  onChange={handleChangeSwitch}
                  name="sales_allowed"
                />
              }
              label="Sales Allowed"
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          variant="contained"
          color="danger"
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading || !formData.reason}
        >
          {isLoading && <CircularProgress size={12} />}
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function LocationCodeLink({ handleOpen, inspection }) {
  const classes = useStyles();
  return (
    <span
      className={classes.selectorClass}
      onClick={() => handleOpen(inspection)}
    >
      {inspection.location.code}
      <ExternalLink
        color={themes.default.palette.colors.blue_button}
        size={0.8}
      />
    </span>
  );
}
