import React from "react";
import { useLayoutState } from "context/LayoutContext";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";
import _ from "lodash";
/**
 * Table with pagination and custom render methods
 * @param headers array of objects with header config
 * @param data object with items, count and isFetching attributes
 * @param rowsPerPage pagination parameter
 * @param page pagination paramter
 * @param handleChangePage function to assign new data for given page
 * @param handleChangeRowsPerPage function to assign rowsPerPage attribute with going back to first page
 * @param hasCheckBox boolean to determine showing checkboxes on the left
 * @param onRowChecked function to run when row is selected
 * @param selected list of selected items
 * @param disableExportData boolean value, designates whather user can export see the export data button
 * @param sortActions takes { onSort, orderBy, orderDirection }, onSort-function: is the function for sorting operation
 * @param exportDisabled true if we want to hide export button or disable it
 * @param setExportDisabled state handler to enable/disable export button
 * @param filters applied filters to show if the result is empty list
 * @param headerFilters array of objects with header filter config
 * @param onFilter function to run when header filter is applied
 * @param selectedFilters object to keep track of selected header filters
 * @param setSelectedFilters function to set selected header filters
 * @param highlightColor highlight background color
 * @param highlightFunc filter function to change background color
 * @param highlightStyle style object to override row style
 * @param headerVisible flag to show/hide table header
 * @param footerVisible flag to show/hide table footer
 * @param rowsPerPageOptions overrides default rows per page options [10, 25, 50, 100]
 * orderBy-str: field name for the soring orderDirection-str: asc or desc, Sorting is only evaluated for desktop table
 */
export default function DefaultTable({
  headers,
  headerCounts,
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  hasCheckBox,
  onRowChecked,
  setSelected,
  selected,
  handleExportData,
  rowOnClick,
  disableExportData,
  sortActions,
  exportDisabled = false,
  setExportDisabled = () => {},
  filters,
  headerFilters,
  onFilter,
  selectedFilters,
  setSelectedFilters,
  highlightColor,
  highlightFunc,
  highlightStyle,
  headerVisible = true,
  footerVisible = true,
  rowsPerPageOptions,
  uniqueIdCount,
  style,
}) {
  const layoutState = useLayoutState();
  const selectUnselectAll = () => {
    const data_ids = data.items.map((i) => i.id);
    const check_in_selected = selected.filter((s) => data_ids.includes(s.id));
    if (check_in_selected.length === (uniqueIdCount || data.items.length)) {
      return setSelected(
        selected.filter(
          (s) => !check_in_selected.map((i) => i.id).includes(s.id),
        ),
      );
    }

    const unique_array = _.uniqBy([...selected, ...data.items], function (e) {
      return e.id;
    });

    setSelected(unique_array);
  };

  const handleOnRowChecked = (row) => {
    if (onRowChecked) {
      onRowChecked(row);
      return;
    }

    let rowIndex = selected.map((s) => s.id).indexOf(row.id);
    if (rowIndex >= 0) {
      selected.splice(rowIndex, 1);
    } else {
      selected.push(row);
    }
    setSelected([...selected]);
  };

  if (!layoutState.isMobile) {
    return (
      <DesktopTable
        headers={headers}
        headerCounts={headerCounts}
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        hasCheckBox={hasCheckBox}
        onRowChecked={handleOnRowChecked}
        selected={selected}
        selectUnselectAll={selectUnselectAll}
        handleExportData={handleExportData}
        rowOnClick={rowOnClick}
        disableExportData={disableExportData}
        sortActions={sortActions}
        exportDisabled={exportDisabled}
        setExportDisabled={setExportDisabled}
        filters={filters}
        headerFilters={headerFilters}
        onFilter={onFilter}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        highlightColor={highlightColor}
        highlightFunc={highlightFunc}
        highlightStyle={highlightStyle}
        headerVisible={headerVisible}
        footerVisible={footerVisible}
        rowsPerPageOptions={rowsPerPageOptions}
        uniqueIdCount={uniqueIdCount}
        style={style}
      />
    );
  } else {
    return (
      <MobileTable
        headers={headers}
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        hasCheckBox={hasCheckBox}
        onRowChecked={handleOnRowChecked}
        selected={selected}
        selectUnselectAll={selectUnselectAll}
        rowOnClick={rowOnClick}
      />
    );
  }
}
