import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { X as Close } from "icons";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingTop: 20,
    paddingBottom: 20,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  fedexDownloadButton: {
    border: "none",
    width: "98%",
    backgroundColor: "#29007c",
    color: "#ff5a00",
    marginBottom: "0.25rem",
    "&:hover": {
      border: "none",
      backgroundColor: "#3c199d",
    },
  },
  upsDownloadButton: {
    width: "98%",
    border: "none",
    backgroundColor: "#341b14",
    color: "#ffb406",
    marginBottom: "0.25rem",
    "&:hover": {
      border: "none",
      backgroundColor: "#5e3a2f",
    },
  },
  avatar: {
    borderRadius: "0",
    marginTop: theme.spacing(1),
    width: "100% !important",
    ".Mui-selected & > img": {
      opacity: "1",
    },
  },
  fedexLogo: {
    "& > img": {
      height: "48px !important",
      width: "72px !important",
      objectFit: "contain",
    },
  },
  upsLogo: {
    "& > img": {
      height: "38px !important",
      width: "32px !important",
      objectFit: "contain",
    },
  },
}));

const MorningBatchModal = (props) => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date().toLocaleDateString("en-CA"));
  const layoutDispatch = useLayoutDispatch();
  const [notifyModal, setNotifyModal] = useState({
    open: false,
    fedexSingle: [],
    fedexMulti: [],
    upsSingle: [],
    upsMulti: [],
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/custom/morning-batch`, {
      method: "GET",
    }).then(async (r) => {
      const res = await r.json();
      const { fedexSingle, fedexMulti, upsSingle, upsMulti } = res;
      if (fedexSingle && fedexMulti && upsSingle && upsMulti) {
        let open = false;
        if (
          fedexSingle.length > 0 ||
          fedexMulti.length > 0 ||
          upsSingle.length > 0 ||
          upsMulti.length > 0
        ) {
          open = true;
        }
        setNotifyModal({
          open,
          fedexSingle,
          fedexMulti,
          upsSingle,
          upsMulti,
        });
      }
    });
  }, [layoutDispatch]);

  const handleDownload = (carrier, type) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/custom/morning-batch?carrier=${carrier}&type=${type}&date=${date}`,
      {
        method: "GET",
      },
    )
      .then((r) => r.json())
      .then((response) => {
        if (response.downloadUrl) {
          var a = document.createElement("a");
          a.href = response.downloadUrl;
          a.download = `${carrier}-${new Date().toLocaleDateString(
            "en-CA",
          )}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          throw popupNotification({
            dispatch: layoutDispatch,
            message: "Morning batch download failed",
          });
        }
      })
      .catch(() => {
        props.onClose();
      });
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        scroll="paper"
        open={props.open}
        keepMounted
        aria-labelledby="item-label-dialog-title"
        onClose={(_, reason) => {
          if (reason === "backdropClick") {
            props.onClose();
          }
        }}
      >
        <DialogTitle
          id="item-label-dialog-title"
          className={classes.dialogTitle}
          color="primary"
        >
          Download Morning Batch PDF
        </DialogTitle>
        <DialogContent dividers={true} className={classes.contentContainer}>
          <div className={classes.container}>
            <TextField
              id="date"
              label={"Date"}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: "2023-03-21",
                  max: new Date().toLocaleDateString("en-CA"),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid container>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Avatar
                    src={FedexLogo}
                    className={[classes.avatar, classes.fedexLogo].join(" ")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("FedEx", "SinglePiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.fedexDownloadButton}
                  >
                    Single Piece
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("FedEx", "MultiPiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.fedexDownloadButton}
                  >
                    Multi Piece
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Avatar
                    src={UpsLogo}
                    className={[classes.avatar, classes.upsLogo].join(" ")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("UPS", "SinglePiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.upsDownloadButton}
                  >
                    Single Piece
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("UPS", "MultiPiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.upsDownloadButton}
                  >
                    Multi Piece
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogTitle} role="close">
          <Button
            onClick={props.onClose}
            variant="contained"
            color="primary"
            size="small"
          >
            <Close color="white" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={notifyModal.open}>
        <DialogTitle
          id="item-label-dialog-title"
          className={classes.dialogTitle}
          color="primary"
        >
          Morning Batch PDFs Not Downloaded
        </DialogTitle>
        <DialogContent dividers={true}>
          <Paper square variant="elevation">
            <Grid container spacing={1} className={classes.container}>
              {notifyModal.fedexSingle.length > 0 && (
                <Grid item xs={12}>
                  <b>FedEx Single Piece</b> -{" "}
                  {notifyModal.fedexSingle.join(", ")}
                </Grid>
              )}
              {notifyModal.fedexMulti.length > 0 && (
                <Grid item xs={12}>
                  <b>FedEx Multi Piece</b> - {notifyModal.fedexMulti.join(", ")}
                </Grid>
              )}
              {notifyModal.upsSingle.length > 0 && (
                <Grid item xs={12}>
                  <b>UPS Single Piece</b> - {notifyModal.upsSingle.join(", ")}
                </Grid>
              )}
              {notifyModal.upsMulti.length > 0 && (
                <Grid item xs={12}>
                  <b>UPS Multi Piece</b> - {notifyModal.upsMulti.join(", ")}
                </Grid>
              )}
            </Grid>
          </Paper>
          <DialogActions className={classes.dialogTitle} role="close">
            <Button
              onClick={() => setNotifyModal({ ...notifyModal, open: false })}
              variant="contained"
              color="primary"
              size="small"
            >
              <Close color="white" />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MorningBatchModal;
