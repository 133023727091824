import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  containerCenter: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    maxHeight: "16rem",
    minHeight: "16rem",
    overflowY: "auto",
    border: "1px solid",
    borderColor: theme.palette.colors.table_header_green,
  },
  tableRow: {
    borderBottom: "1px solid #dedede",
    fontSize: "0.9rem",
    fontWeight: 600,
    lineHeight: "2.2rem",
    "&:hover": {
      backgroundColor: `${theme.palette.colors.slate200} !important`,
    },
  },
  rowEven: { backgroundColor: `${theme.palette.colors.white}` },
  rowOdd: {
    backgroundColor: `${theme.palette.colors.table_item_green}`,
  },
  user: {
    fontWeight: "bold",
    float: "left",
    width: "4rem",
    textAlign: "left",
    marginLeft: "1rem",
  },
  carrierContainer: {
    justifyContent: "start",
    alignItems: "center",
    display: "flex",
  },
  filterContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: "center",
    display: "flex",
    lineHeight: 1.25,
  },
  alternatePick: {
    color: "crimson",
    padding: 0,
    margin: 0,
  },
  selectorClass: {
    color: theme.palette.colors.blue_button,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
