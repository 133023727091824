import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@material-ui/core";

import { getInventories } from "api/inventory";
import { getLocations } from "api/locations";

import { TextInput } from "components/Form";
import { Button } from "components/Wrappers/Wrappers";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { SettingsRemote } from "icons";

import { BUGGY_USE_TYPES } from "utils/constants";
import { getWithExpiry, setWithExpiry, DAYINMILLISECONDS } from "utils/storage";

const SetBuggyToLocal = ({
  hasClearButton,
  picked,
  total,
  onAssign,
  updateInventory,
  useType = BUGGY_USE_TYPES.EcommercePick,
}) => {
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [active, setActive] = useState(!getWithExpiry("buggyId") || "");
  const [buggyCode, setBuggyCode] = useState(getWithExpiry("buggyCode") || "");
  const [inBuggyCount, setInBuggyCount] = useState(0);

  const clearBuggy = () => {
    localStorage.removeItem("buggyId");
    localStorage.removeItem("buggyCode");
    localStorage.removeItem("pickedLabels");
    setBuggyCode("");
    setActive(true);
  };

  const setBuggyToProfile = () => {
    if (active) {
      getLocations({
        responseSetter: (res) => {
          if (res.results) {
            const buggy = res.results.find(
              (b) => b.code.toLowerCase() === buggyCode.toLowerCase(),
            );
            if (buggy) {
              if (buggy.use_type && buggy.use_type !== useType) {
                const use_type_display = Object.entries(BUGGY_USE_TYPES).find(
                  ([_, value]) => value === buggy.use_type,
                )[0];
                return popupNotification({
                  dispatch: layoutDispatch,
                  message: `The buggy is being used for ${use_type_display}. Please make sure the buggy is empty.`,
                });
              }
              setWithExpiry("buggyId", buggy.id, DAYINMILLISECONDS);
              setWithExpiry("buggyCode", buggy.code, DAYINMILLISECONDS);
              popupNotification({
                dispatch: layoutDispatch,
                message: t("successful_operation"),
                status: "success",
              });
              setActive(false);
              if (onAssign) {
                onAssign(buggy);
              }
            } else {
              popupNotification({
                dispatch: layoutDispatch,
                message: t("buggy_code_not_found"),
              });
              clearBuggy();
            }
          } else {
            popupNotification({
              dispatch: layoutDispatch,
              message: t("buggy_code_not_found"),
            });
            clearBuggy();
          }
        },
        params: {
          code: buggyCode,
          location_type: 4,
        },
        paginated: true,
      });
    }
  };

  useEffect(() => {
    if (!active) {
      getInventories({
        params: {
          location_id: getWithExpiry("buggyId"),
          limit: 1000,
        },
        paginated: true,
        responseSetter: (data) => {
          if (!data.results.length) {
            return;
          }
          setInBuggyCount(
            data.results.reduce((res, inv) => {
              res += inv.qty;
              return res;
            }, 0) - (picked || 0),
          );
        },
      });
    }
  }, [active, picked, updateInventory]);

  return (
    <Grid
      container
      style={{
        padding: hasClearButton ? "0 1rem" : "0 0 1rem 0",
      }}
      spacing={0}
    >
      {active ? (
        <Grid item xs={12}>
          <TextInput
            size="medium"
            name="buggy"
            label={t("scan_buggy_barcode")}
            variant="outlined"
            fullWidth
            autoFocus
            value={buggyCode}
            onChange={({ target: { value } }) =>
              setBuggyCode(value.replace("-", " ").replace("#", ""))
            }
            onKeyPress={(e) => (e.key === "Enter" ? setBuggyToProfile() : null)}
            onBlur={() => {
              if (buggyCode) {
                setBuggyToProfile();
              }
            }}
            LeftIcon={SettingsRemote}
            hasBorder
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={hasClearButton ? 9 : 12}
          sm={hasClearButton ? 10 : 12}
          md={hasClearButton ? 10 : 12}
          lg={hasClearButton ? 10 : 12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={total ? 6 : 12}
            sm={total ? 6 : 12}
            md={total ? 6 : 12}
            lg={total ? 6 : 12}
          >
            <Typography style={{ marginTop: "10px" }}>
              {getWithExpiry("buggyCode")}
              <span style={{ fontSize: "0.8rem", display: "block" }}>
                {inBuggyCount + (picked || 0)} in Buggy
              </span>
            </Typography>
          </Grid>
          {!!total && (
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <Typography
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                }}
              >
                {picked} of {total}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {hasClearButton && !active && (
        <Grid item xs={3} sm={2} md={2} lg={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={clearBuggy}
            fullWidth
            style={{ marginTop: "10px" }}
          >
            {t("clear")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SetBuggyToLocal;
