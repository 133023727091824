import * as React from "react";

const SvgDone = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.082 15.469-3.795-3.73L4 13.003 9.082 18 20 7.266 18.712 6l-9.63 9.469Z"
      fill={color}
    />
  </svg>
);

export default SvgDone;
