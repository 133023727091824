import {
  deepOrange,
  deepPurple,
  blueGrey,
  red,
  grey,
} from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    width: "25rem",
  },
  title: {
    color: theme.palette.getContrastText(blueGrey[900]),
    backgroundColor: blueGrey[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1em 0",
  },
  detailFrame: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
    "& .MuiListItem-dense.MuiListItem-gutters": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
  },

  paper: {
    backgroundColor: red[100],
    width: "auto",
    margin: "0",
    borderRadius: "0",
  },
  secondaryActButtonWrapper: {
    margin: `2px ${theme.spacing(1)}px`,
    position: "relative",
    display: "inline-flex",
    height: "100%",
    width: "auto",
  },
  secondaryActRenewButton: {
    marginLeft: theme.spacing(1),
    color: red[500],
    backgroundColor: grey[300],
    "&:hover,&:active,&:focus": {
      color: red[300],
      backgroundColor: grey[100],
    },
  },
  secondaryActPrintButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepPurple[800]),
      backgroundColor: deepPurple[800],
    },
  },
  secondaryActHideButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepOrange[800]),
      backgroundColor: deepOrange[800],
    },
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    borderRadius: 0,
  },
}));
