import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.colors.blue_grey,
    },
    fontWeight: "bold",
    borderRadius: "7px",
    height: "40px",
    width: "210px",
  },
  mainMenu: {
    padding: "0 0 0 12px",
    backgroundColor: theme.palette.colors.dark_grey_blue,
    margin: "10px",
    height: "40px",
    width: "210px",
  },
  mainMenuOpen: {
    backgroundColor: theme.palette.colors.blue_grey,
  },
  externalLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  linkActive: {
    backgroundColor: theme.palette.colors.light_hover,
    color: theme.palette.colors.dark_active_grey,
  },
  linkNested: {
    paddingLeft: "6px",
    margin: "5px 35px 5px",
    "&:hover": {
      backgroundColor: theme.palette.colors.blue_grey,
    },
  },
  linkNestedBg: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.colors.blue_grey,
    },
    fontWeight: "bold",
    borderRadius: "7px",
    height: "40px",
    width: "185px",
  },
  linkNestedActive: {
    backgroundColor: theme.palette.colors.light_hover,
    color: theme.palette.colors.dark_active_grey,
    "&:hover": {
      backgroundColor: theme.palette.colors.light_hover,
      color: theme.palette.colors.dark_active_grey,
    },
  },
  linkIcon: {
    margin: -theme.spacing(1),
    color: theme.palette.colors.dark_active_grey,
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconDesktopNested: {
    marginRight: -theme.spacing(3),
    color: theme.palette.colors.light_icon,
    transition: theme.transitions.create("color"),
    width: 18,
    display: "flex",
    justifyContent: "left",
  },
  linkIconActive: {
    color: theme.palette.colors.dark_active_grey,
  },
  linkText: {
    paddingLeft: 0,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
    fontWeight: "bold",
  },
  linkTextInner: {
    color: theme.palette.colors.light_text,
  },
  linkTextActive: {
    color: theme.palette.colors.dark_active_grey,
  },
  linkTextHidden: {
    opacity: 0,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D8",
  },
}));
