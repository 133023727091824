import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import { getShipmentDetails, markWrongShipped } from "api/shipment";

import { StatusLabel } from "components/Custom";
import { ExternalLink } from "components/Custom/Links";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import {
  popupNotification,
  useLayoutDispatch,
  useLayoutState,
} from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { Exclamation } from "icons";

import DetailTimeline from "pages/shipments/ShipmentDetailTimeline";
import { getCarrierTrackingLink } from "pages/shipments/utils";
import {
  ACUMATICA_BASE_URL,
  initialAPIListValue,
  INVOICE_URL,
  ORDER_URL,
  SHIPMENT_URL,
} from "utils/constants";
import { timezoneFormat } from "utils/date";

import useStyles from "./styles";

export default function ShipmentPreview({ shipment, setHasShippedItem }) {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();
  const { permissions } = useUserState();
  const { isMobile } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [details, setDetails] = useState(initialAPIListValue);
  const [timelineRow, setTimelineRow] = useState({});
  const [selectedDetail, setSelectedDetail] = useState({});
  const [cause, setCause] = useState("");

  const popoverContainer = useRef(null);
  const hasUpdatePermission = permissions.includes(
    "shipment.change_shipmentdetail",
  );
  const previewHeaders = [
    {
      id: "sku",
      render: (item) => (
        <Grid container justifyContent="center" alignItems="center">
          {item.is_wrong_shipped ? (
            <Grid item>
              <Exclamation color="red" size={0.8} />
            </Grid>
          ) : null}
          <Grid item>
            <span style={{ whiteSpace: "nowrap" }}>
              {item.quantity > 1
                ? `${item.product.sku}  (${item.quantity})`
                : item.product.sku}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "tracking_number",
      render: (item) => (
        <ExternalLink
          to={getCarrierTrackingLink(shipment.ship_via, item.tracking_number)}
          children={item.tracking_number}
        />
      ),
    },
    {
      id: "location",
      render: (item) => (
        <span style={{ whiteSpace: "nowrap" }}>{item.location.code}</span>
      ),
    },
    {
      id: "status",
      render: (item) => <StatusLabel status={item.status} />,
    },
    {
      id: "box_size",
      render: (item) =>
        item.product.boxes.length ? (
          <span
            style={{ whiteSpace: "nowrap" }}
          >{`${item.product.boxes[0].length}″ x ${item.product.boxes[0].width}″ x ${item.product.boxes[0].height}″`}</span>
        ) : (
          "Box not found!"
        ),
      style: { whiteSpace: "nowrap" },
    },
    {
      id: "action",
      render: (item) => (
        <Grid container justifyContent="space-between">
          <Grid item>
            {["Shipped", "Invoiced"].includes(item.status) &&
            hasUpdatePermission ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setSelectedDetail(item)}
              >
                {item.is_wrong_shipped ? "Update" : "Wrong"}
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => setTimelineRow(item)}
            >
              Detail
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    if (details.isFetching) {
      getShipmentDetails({
        params: {
          shipment_id: shipment.id,
        },
        responseSetter: (res) => {
          setDetails({ items: res.results, isFetching: false });
          if (setHasShippedItem) {
            setHasShippedItem(
              res.results.find((detail) => detail.status === "Shipped")
                ? true
                : false,
            );
          }

          if (!isMobile && res.results.length > 0) {
            setTimelineRow(res.results[0]);
          }
        },
      });
    }
  }, [shipment.id, details.isFetching, isMobile, setHasShippedItem]);

  useEffect(() => {
    if (selectedDetail.cause_of_wrong) {
      setCause(selectedDetail.cause_of_wrong);
    }
  }, [selectedDetail.cause_of_wrong]);

  const handleMarkWrongShipped = () => {
    markWrongShipped({
      id: selectedDetail.id,
      body: {
        is_wrong_shipped: true,
        cause_of_wrong: cause,
      },
      responseSetter: () => {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Done!",
          status: "success",
        });
        setCause("");
        setSelectedDetail({});
        setDetails((prev) => ({ ...prev, isFetching: true }));
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} xs={12}>
        <Widget title={t("shipment")} style={{ minHeight: "12rem" }}>
          <Grid container>
            <Grid item lg={3} xs={12}>
              <div>
                <FormLabel component="h6" className={classes.label}>
                  {t("shipment_number")}
                </FormLabel>
                {shipment.shipment_number ? (
                  <ExternalLink
                    className={classes.link}
                    to={`${ACUMATICA_BASE_URL}${SHIPMENT_URL}${shipment.shipment_number}`}
                    children={shipment.shipment_number}
                  />
                ) : (
                  <span>-</span>
                )}
              </div>
            </Grid>
            <Grid item lg={3} xs={12}>
              <div>
                <FormLabel component="h6" className={classes.label}>
                  {t("order_number")}
                </FormLabel>
                {shipment.order && shipment.order.number ? (
                  <ExternalLink
                    className={classes.link}
                    to={`${ACUMATICA_BASE_URL}${ORDER_URL}${shipment.order.number}`}
                    children={shipment.order.number}
                  />
                ) : (
                  <span>-</span>
                )}
              </div>
            </Grid>
            <Grid item lg={3} xs={12}>
              <div>
                <FormLabel component="h6" className={classes.label}>
                  {t("invoice_number")}
                </FormLabel>
                {shipment.invoice_number ? (
                  <ExternalLink
                    className={classes.link}
                    to={`${ACUMATICA_BASE_URL}${INVOICE_URL}${shipment.invoice_number}`}
                    children={shipment.invoice_number}
                  />
                ) : (
                  <span>-</span>
                )}
              </div>
            </Grid>
            <Grid item lg={3} xs={12}>
              <div>
                <FormLabel component="h6" className={classes.label}>
                  {t("customer")}
                </FormLabel>
                <span>{shipment.order ? shipment.order.customer_id : "-"}</span>
              </div>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("status")}
              </FormLabel>
              <StatusLabel status={shipment.status} />
              {shipment.cancellation_option ? (
                <span
                  style={{
                    color: theme.palette.colors.rose700,
                    borderRadius: "1rem",
                    padding: "0.25rem 1rem",
                    minWidth: "6rem",
                    marginLeft: "1.8rem",
                    display: "block",
                  }}
                >
                  {shipment.cancellation_option === "OOS"
                    ? "Out of Stock"
                    : shipment.cancellation_option === "Customer"
                    ? "Customer"
                    : ""}
                </span>
              ) : null}
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("invoiced_at")}
              </FormLabel>
              <span>
                {shipment.invoiced_at
                  ? timezoneFormat(shipment.invoiced_at)
                  : "-"}
              </span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("created_at")}
              </FormLabel>
              <span>{timezoneFormat(shipment.created_date)}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("ship_via")}
              </FormLabel>
              <span>{shipment.ship_via || "-"}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("shipment_cost")}
              </FormLabel>
              <span>{`$${shipment.shipment_cost}`}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("delivery_address")}
              </FormLabel>
              <span>
                {shipment.address.line_1 + (shipment.address.line_2 || "")}
              </span>
              <br />
              <span>{shipment.address.city + ", "}</span>
              <span>{shipment.address.state + ", "}</span>
              <span>{shipment.address.country + ", "}</span>
              <span>{shipment.address.zip_code}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("contact_name")}
              </FormLabel>
              <span>{shipment.address.customer}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("customer_order_number")}
              </FormLabel>
              <span>{shipment.order.po_number}</span>
            </Grid>
            <Grid item lg={3} xs={12}>
              <FormLabel component="h6" className={classes.label}>
                {t("customer_reference_number")}
              </FormLabel>
              <span>{shipment.order.reference_number || "-"}</span>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Widget title={t("items")}>
          {details.isFetching ? (
            <CircularProgress />
          ) : !details.items.length ? (
            t("no_results_found")
          ) : (
            <>
              {Object.keys(selectedDetail).length ? (
                <Dialog
                  open={true}
                  maxWidth="sm"
                  onClose={() => setSelectedDetail({})}
                  fullWidth
                >
                  <DialogTitle>Mark As Wrong</DialogTitle>
                  <DialogContent>
                    <TextField
                      fullWidth
                      autoFocus
                      value={cause}
                      variant="outlined"
                      placeholder="Cause of wrong shipped"
                      onChange={({ target: { value } }) => setCause(value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setSelectedDetail({})}>Close</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleMarkWrongShipped()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
              <Grid container spacing={0}>
                <DefaultTable
                  data={details}
                  headers={previewHeaders}
                  selected={[timelineRow]}
                />
              </Grid>
            </>
          )}
        </Widget>
      </Grid>
      <Grid item lg={4} xs={12} ref={popoverContainer}>
        {timelineRow && timelineRow.id ? (
          <DetailTimeline
            detailId={timelineRow.id}
            anchorRef={popoverContainer}
            onClose={() => setTimelineRow({})}
            isMobile={isMobile}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
