import React, { createContext, useContext, useReducer } from "react";

var LayoutStateContext = createContext();
var LayoutDispatchContext = createContext();

const initialPopup = {
  message: "",
  title: "",
  status: "",
};

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "IS_MOBILE":
      return { ...state, isMobile: action.status };
    case "SUB_MENU_ITEMS":
      return { ...state, subMenuItems: action.subMenuItems };
    case "SET_POPUP_MESSAGE":
      return {
        ...state,
        popup: {
          message: action.message,
          title: action.title,
          status: action.status,
          afterCloseFunction: action.afterCloseFunction,
        },
      };
    case "CLEAR_POPUP_NOTIFICATION":
      return {
        ...state,
        popup: initialPopup,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = useReducer(layoutReducer, {
    isSidebarOpened: true,
    isMobile: false,
    subMenuItems: [],
    internetStatus: window.navigator.onLine,
    popup: initialPopup,
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}

function setIsMobileDevice(dispatch, status) {
  dispatch({
    type: "IS_MOBILE",
    status,
  });
}

function setSubMenuItems(dispatch, subMenuItems) {
  dispatch({
    type: "SUB_MENU_ITEMS",
    subMenuItems,
  });
}

function popupNotification({
  dispatch,
  message,
  title,
  status,
  afterCloseFunction,
}) {
  dispatch({
    type: "SET_POPUP_MESSAGE",
    message,
    title,
    status,
    afterCloseFunction,
  });
}

function clearPopupNotification(dispatch) {
  dispatch({
    type: "CLEAR_POPUP_NOTIFICATION",
  });
}

export {
  LayoutProvider,
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  setIsMobileDevice,
  setSubMenuItems,
  popupNotification,
  clearPopupNotification,
};
