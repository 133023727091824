import * as React from "react";

const SvgMarkUnreadMailbox = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#MarkUnreadMailbox_svg__a)" fill={color}>
      <path opacity={0.3} d="M10 12.5H6v-4H4v12h16v-12H10v4z" />
      <path d="M20 6.5H10v2h10v12H4v-12h2v4h2v-8h6v-4H6v6H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2z" />
    </g>
    <defs>
      <clipPath id="MarkUnreadMailbox_svg__a">
        <path fill={color} transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMarkUnreadMailbox;
