import * as React from "react";

const SvgListAlt = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M5 19.5h14v-14H5v14zm6-12h6v2h-6v-2zm0 4h6v2h-6v-2zm0 4h6v2h-6v-2zm-4-8h2v2H7v-2zm0 4h2v2H7v-2zm0 4h2v2H7v-2z"
      fill={color}
    />
    <path
      d="M11 7.5h6v2h-6v-2zm0 4h6v2h-6v-2zm0 4h6v2h-6v-2zm-4-8h2v2H7v-2zm0 4h2v2H7v-2zm0 4h2v2H7v-2zm13.1-12H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V4.4c0-.5-.5-.9-.9-.9zm-1.1 16H5v-14h14v14z"
      fill={color}
    />
  </svg>
);

export default SvgListAlt;
