import React, { useRef, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  bulkShip,
  cancelRequest,
  cancelShipment,
  deleteShipments,
  hideShipment,
  revertShipmentStatus,
  shipByShipmentNumber,
} from "api/shipment";

import { ConfirmDialog, Spacer } from "components/Custom";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { DotsVertical, Reply, Trash, X as Clear } from "icons";

export function ShipmentCancelModal({ shipment_id, close, afterCancelled }) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelShipment = (is_delete = false) => {
    setIsLoading(true);
    cancelShipment({
      body: {
        shipment_id: shipment_id,
        delete: is_delete ? "true" : null,
      },
      responseSetter: (response) => {
        const { success, message } = response;

        popupNotification({
          dispatch: layoutDispatch,
          message: success ? "Shipment cancelled." : message,
          status: success ? "success" : "error",
        });

        if (afterCancelled) {
          afterCancelled();
        }

        setIsLoading(false);
        close();
      },
    });
  };

  return (
    <Dialog
      open={!!shipment_id}
      onClose={() => (!isLoading ? close(false) : null)}
    >
      <DialogTitle>Confirm Cancelation</DialogTitle>
      <DialogContent>
        <Typography>
          This action will delete the shipment and cancel the order record on
          Acumatica. Label and the products should be pulled if they are loaded
          to the truck. Please confirm the action?
        </Typography>
        <Alert severity="info">
          <Typography variant="subtitle2">
            Error messages will be sent to{" "}
            <Link
              href="https://teams.microsoft.com/l/channel/19%3a59271a7ed6e64b20a3e35c043e034054%40thread.skype/General?groupId=d2d79ef3-7dea-4809-864f-49d2e11ea8c7&tenantId=a62c6aa2-a016-4844-ac38-d16bfaf930c8"
              target="_blank"
            >
              Teams (Shipping & Warehouse channel)
            </Link>
            , please check the channel in 1-2 minutes.
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="contained"
              onClick={() => close(false)}
              disabled={isLoading}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => handleCancelShipment()}
            >
              {isLoading && <CircularProgress size={20} />}
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "red",
                marginLeft: "1rem",
              }}
              disabled={isLoading}
              onClick={() => handleCancelShipment(true)}
            >
              {isLoading && <CircularProgress size={20} />}
              Cancel & Delete
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export function ActionsButton({
  onCancelRequestClick,
  onCancelClick,
  onShipClick,
  onRevertClick,
  onDeleteClick,
  status,
  hasShippedItem = false,
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const { permissions } = useUserState();

  return !["Invoiced", "Shipped"].includes(status) ? (
    status === "Pending_Cancellation" || status === "Errored" ? (
      <>
        <Button
          variant="contained"
          onClick={onRevertClick}
          style={{
            padding: "0.2rem 0.4rem",
            backgroundColor: theme.palette.colors.amber500,
            color: theme.palette.colors.white,
          }}
        >
          <Reply color="white" />
          <Typography> Revert </Typography>
        </Button>{" "}
        <Button
          variant="contained"
          onClick={onCancelClick}
          style={{
            padding: "0.2rem 0.4rem",
            backgroundColor: theme.palette.colors.rose700,
            color: theme.palette.colors.white,
          }}
        >
          <Clear color="white" />
          <Typography> Cancel </Typography>
        </Button>
      </>
    ) : status === "Cancelled" ? (
      <Button
        variant="contained"
        onClick={onDeleteClick}
        style={{
          padding: "0.2rem 0.4rem",
          backgroundColor: theme.palette.colors.rose700,
          color: theme.palette.colors.white,
        }}
      >
        <Trash color="white" />
        <Typography> Delete </Typography>
      </Button>
    ) : (
      <Button
        variant="contained"
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          backgroundColor: theme.palette.colors.emerald700,
          color: theme.palette.colors.white,
          padding: "0.2rem 0.2rem 0.2rem 0.8rem",
          textTransform: "none",
        }}
      >
        <Typography>Actions</Typography>
        <DotsVertical color="white" />
        <Popover
          open={isOpen}
          anchorEl={buttonRef ? buttonRef.current : null}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => setIsOpen(!isOpen)}
        >
          {!hasShippedItem && (
            <>
              <Typography style={{ textAlign: "center" }}>
                Request Cancellation:
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  maxWidth: "15rem",
                  padding: "1rem",
                }}
              >
                <Grid item lg={12} xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => onCancelRequestClick("OOS")}
                    fullWidth
                    style={{
                      backgroundColor: theme.palette.colors.rose700,
                      color: theme.palette.colors.white,
                      textTransform: "none",
                    }}
                  >
                    <Typography> Out of Stock </Typography>
                  </Button>
                </Grid>
                <Spacer height="2rem" />
                <Grid item lg={12} xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => onCancelRequestClick("Customer")}
                    fullWidth
                    style={{
                      backgroundColor: theme.palette.colors.rose700,
                      color: theme.palette.colors.white,
                      textTransform: "none",
                    }}
                  >
                    <Typography> Customer </Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {permissions.includes(
            "contenttypes.shipment.ship_by_shipment_number",
          ) && !["Open", "Confirmed"].includes(status) ? (
            <>
              <Typography style={{ textAlign: "center" }}>
                Move Actions:
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  maxWidth: "15rem",
                  padding: "1rem",
                }}
              >
                <Grid item lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: theme.palette.colors.slate500,
                      color: theme.palette.colors.white,
                      textTransform: "none",
                      minWidth: "13rem",
                    }}
                    onClick={onShipClick}
                  >
                    <Typography>Ship</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Popover>
      </Button>
    )
  ) : null;
}

export function RequestCancellationDialog({
  shipment_id,
  close,
  afterCancelled,
  option,
}) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelRequest = () => {
    setIsLoading(true);
    cancelRequest({
      body: {
        shipment_id: shipment_id,
        option: option,
      },
      responseSetter: (response) => {
        const { success, message } = response;

        popupNotification({
          dispatch: layoutDispatch,
          message: success ? "Cancelation requested." : message,
          status: success ? "success" : "error",
        });

        if (afterCancelled) {
          afterCancelled();
        }

        setIsLoading(false);
        close();
      },
    });
  };
  return (
    <ConfirmDialog
      open={!!shipment_id}
      title="Request Cancelation"
      children={
        <>
          <Typography>
            This action will request the cancelation of the shipment. Please
            confirm the action?
          </Typography>
          <Alert severity="info">
            <Typography variant="subtitle2">
              Acumatica and Ship Flow actions will be triggered in the next
              step.
            </Typography>
          </Alert>
        </>
      }
      onConfirm={handleCancelRequest}
      onClose={!isLoading && close}
      isLoading={isLoading}
    />
  );
}

export function ShipTheShipmentDialog({ shipment_number, close }) {
  const [isLoading, setIsLoading] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const handleSubmit = () => {
    setIsLoading(true);
    shipByShipmentNumber({
      params: {
        shipment_number,
      },
      responseSetter: ({ success, message }) => {
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return setIsLoading(false);
        }
        setIsLoading(false);

        popupNotification({
          dispatch: layoutDispatch,
          message: "Done!",
          status: "success",
        });

        close(true);
      },
    });
  };

  const handleSubmitMultiple = () => {
    setIsLoading(true);
    bulkShip({
      body: {
        shipment_numbers: shipment_number,
      },
      responseSetter: ({ success, message }) => {
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return setIsLoading(false);
        }
        setIsLoading(false);

        popupNotification({
          dispatch: layoutDispatch,
          message: "Done!",
          status: "success",
        });

        close(true);
      },
    });
  }


  return (
    <Dialog
      open={!!shipment_number}
      onClose={() => (!isLoading ? close(false) : null)}
      aria-labelledby="ship-the-shipment-dialog"
    >
      <DialogTitle id="ship-the-shipment-dialog">Ship The Shipment</DialogTitle>
      <DialogContent>
        {!Array.isArray(shipment_number) ? `You're trying to ship the shipment #${shipment_number}, are you sure?` : (
          `You're trying to ship ${shipment_number.length} shipments, are you sure?`
        )}
        {isLoading && (
          <Alert severity="warning">
            Please wait until the process is complete.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => close(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={Array.isArray(shipment_number) ? handleSubmitMultiple : handleSubmit}
          color="primary"
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={20} />}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function RevertShipmentDialog({ shipment_id, close }) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleRevertShipmentStatus = () => {
    setIsLoading(true);
    revertShipmentStatus({
      id: shipment_id,
      responseSetter: (response) => {
        const { success, message } = response;

        popupNotification({
          dispatch: layoutDispatch,
          message: success ? "Cancelation requested." : message,
          status: success ? "success" : "error",
        });

        setIsLoading(false);
        close(success);
      },
    });
  };
  return (
    <ConfirmDialog
      open={!!shipment_id}
      title="Revert to Last Status of Shipment"
      children={
        <>
          <Typography>
            This action will revert the shipment to its last status. Please
            confirm the action?
          </Typography>
        </>
      }
      onConfirm={handleRevertShipmentStatus}
      onClose={!isLoading ? () => close(false) : null}
      isLoading={isLoading}
    />
  );
}

export function DeleteShipmentDialog({ shipment_number, close, afterDeleted }) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteShipment = () => {
    setIsLoading(true);
    popupNotification({
      dispatch: layoutDispatch,
      message: "Deleting shipment record.Please wait..",
      status: "info",
    });
    deleteShipments({
      body: {
        shipment_ids: [shipment_number],
      },
      responseSetter: (response) => {
        const { success, message } = response;

        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: success ? "success" : "error",
        });

        if (afterDeleted) {
          afterDeleted();
        }

        setIsLoading(false);
        close(true);
      },
    });
  };
  return (
    <ConfirmDialog
      open={!!shipment_number}
      title="Delete Shipment"
      children={
        <Typography>
          This action will delete the shipment on WMS. Please confirm the
          action?
        </Typography>
      }
      onConfirm={handleDeleteShipment}
      onClose={() => close(false)}
      confirmText="Delete"
      isLoading={isLoading}
    />
  );
}

export function HideShipmentsDialog({ shipment_id, close, afterHidden }) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleHideShipment = () => {
    setIsLoading(true);
    popupNotification({
      dispatch: layoutDispatch,
      message: "Hiding shipment. Please wait..",
      status: "info",
    });
    hideShipment({
      body: {
        shipment_id: shipment_id,
      },
      responseSetter: (response) => {
        const { success, message } = response;

        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: success ? "success" : "error",
        });

        if (afterHidden) {
          afterHidden();
        }

        setIsLoading(false);
        close(true);
      },
    });
  };
  return (
    <ConfirmDialog
      open={!!shipment_id}
      title="Hide Shipment"
      children={
        <Typography>
          This action will hide the shipment on the Warning tab. Please confirm
          the action?
        </Typography>
      }
      onConfirm={handleHideShipment}
      onClose={() => close(false)}
      confirmText="Hide"
      isLoading={isLoading}
    />
  );
}
