import { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Button, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

import { getLocationSKUReport, locationSKUBulkUpdate } from "api/inventory";
import { getAisles } from "api/locations";

import {
  Checkbox,
  DatePickerGroup,
  InputContainer,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import { ConfirmDialog, StatusLabel } from "components/Custom";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { FormatListNumbered, QrCode2, SpaceBar } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";
import { timezoneFormat } from "utils/date";

const cells = [
  {
    id: "location__code",
    label: "location",
    render: (r) => r.location && r.location.code,
    sortable: true,
  },
  {
    id: "product__sku",
    label: "product_sku",
    exportLabel: "Inventory Id",
    render: (r) => (r.product ? r.product.sku : "Empty"),
    sortable: true,
  },
  {
    id: "should_check_location",
    label: "locations_to_check",
    render: (r, exported) =>
      exported ? (
        r.should_check_location ? (
          "Check"
        ) : (
          "-"
        )
      ) : (
        <StatusLabel status={r.should_check_location ? "Check" : "-"} />
      ),
    sortable: true,
  },
  {
    id: "created_by",
    label: "created_by",
    render: (r) => toTitleCase(r.created_by && r.created_by.username),
    sortable: true,
  },
  {
    id: "created_at",
    label: "created_at",
    render: (r) => timezoneFormat(r.created_at),
    sortable: true,
  },
];

export default function LocationSKUReport() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();

  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [skuLocations, setSKULocations] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product__sku: "",
    location__code: "",
    location__aisle: "",
    start_date: "",
    end_date: "",
    is_empty: null,
    should_check_location: true,
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [selected, setSelected] = useState([]);
  const [checkedModal, setCheckedModal] = useState(false);
  const [aisles, setAisles] = useState(initialAPIListValue);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  useEffect(() => {
    getAisles({ setFunc: setAisles });
  }, []);

  useEffect(() => {
    if (skuLocations.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getLocationSKUReport({
        params,
        setError,
        setFunc: (res) => {
          setSKULocations(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    skuLocations.isFetching,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setSKULocations({ ...skuLocations, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSKULocations({ ...skuLocations, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.checked || null });
    debouncedSearchHandler();
  };

  const handleChangeAutoCompleteFilterInput = (prop) => (_e, item) => {
    setSearch({ ...search, [prop]: item ? item.value : null });
    debouncedSearchHandler();
  };

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
    setSKULocations({ ...skuLocations, isFetching: true });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `SKU-Locations-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (skuLocations.items.length === skuLocations.count) {
      exportData(skuLocations.items);
    } else {
      getLocationSKUReport({
        params: {
          limit: skuLocations.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    setPage(0);
    setSKULocations({ ...skuLocations, isFetching: true });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setSKULocations((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleClickSetAsChecked = () => {
    locationSKUBulkUpdate({
      body: {
        ids: selected.map((s) => s.id),
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        setSelected([]);
        setSKULocations((prev) => ({ ...prev, isFetching: true }));
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  return (
    <>
      <PageTitle
        title={t("sku_locations")}
        button={
          search.should_check_location ? (
            <Button
              variant="contained"
              color="primary"
              disabled={!selected.length}
              onClick={() => setCheckedModal(true)}
            >
              Set As Checked
            </Button>
          ) : null
        }
      />
      <InputContainer>
        <Grid container>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product__sku}
              onChange={handleChangeFilterInput("product__sku")}
              onKeyUp={debouncedSearchHandler}
              LeftIcon={QrCode2}
              InputProps={clearAdornment("product__sku")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="location"
              label={t("location")}
              variant="outlined"
              value={search.location__code}
              onChange={handleChangeFilterInput("location__code")}
              onKeyUp={debouncedSearchHandler}
              isLocationCode={true}
              LeftIcon={SpaceBar}
              InputProps={clearAdornment("location__code")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="location__aisle"
              onChange={handleChangeAutoCompleteFilterInput("location__aisle")}
              options={
                aisles.items.length
                  ? aisles.items.map((item) => ({
                      value: item.aisle,
                      label: item.aisle,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.aisle === value.aisle
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("aisle")}
                  variant="outlined"
                  LeftIcon={FormatListNumbered}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={1}>
            <Checkbox
              label={t("empty")}
              checked={search.is_empty}
              onChange={handleChangeCheckbox("is_empty")}
              popup={t("empty_locations")}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={1}>
            <Checkbox
              label={t("check")}
              checked={search.should_check_location}
              onChange={handleChangeCheckbox("should_check_location")}
              popup={t("locations_to_check")}
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={skuLocations}
            rowsPerPage={rowsPerPage}
            page={page}
            hasCheckBox={true}
            selected={selected}
            setSelected={setSelected}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
        </Grid>
        <ConfirmDialog
          open={checkedModal}
          title={`Set as Checked (${selected.length} Items)`}
          children={
            <Typography>
              The status of selected items will be checked.
            </Typography>
          }
          onClose={() => setCheckedModal(false)}
          onConfirm={handleClickSetAsChecked}
        />
      </Grid>
    </>
  );
}
