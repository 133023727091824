import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: theme.typography.pxToRem(54),
    justifyContent: "center",
    // remove background color for chrome when selecting text from history
    "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active": {
      "-webkit-transition-delay": "999999s",
    },
    padding: theme.typography.pxToRem(12),
    paddingTop: theme.typography.pxToRem(5),
    paddingBottom: theme.typography.pxToRem(5),
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  containerBordered: {
    border: "1px solid #e0e0e0",
    borderRadius: "0.25rem",
    backgroundColor: "#f5f5f550",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "start",
    width: "100%",
    marginBottom: theme.typography.pxToRem(15),
    borderRadius: theme.typography.pxToRem(8),
    boxShadow:
      "0 2px 2px 0 rgba(159, 162, 191, 0.32), 0 9px 16px 0 rgba(159, 162, 191, 0.18)",
    backgroundColor: "white",
    "& > div > div": {
      border: `${theme.typography.pxToRem(1)} solid rgba(34, 51, 84, 0.1)`,
      borderTop: "none",
      borderLeft: "none",
    },
    overflow: "hidden",
  },
  leftIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.typography.pxToRem(5),
    zIndex: 1,
    pointerEvents: "none",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.typography.pxToRem(10),
    paddingBottom: theme.typography.pxToRem(10),
    "& > .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  button: {
    border: "none",
    minHeight: theme.typography.pxToRem(34),
    width: "75%",
    flexGrow: "0",
    borderRadius: theme.typography.pxToRem(6),
    transition: "0.25s",
    "& > span": {
      textTransform: "none",
      flexGrow: "0",
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(13),
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
    },
  },
  buttonPrimary: {
    boxShadow: theme.customShadows.buttonPrimary,
    backgroundColor: theme.palette.primary.main,
    "& > span": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonSecondary: {
    boxShadow: theme.customShadows.buttonSecondary,
    backgroundColor: theme.palette.secondary.main,
    "& > span": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonDanger: {
    boxShadow: theme.customShadows.buttonDanger,
    backgroundColor: theme.palette.error.main,
    "& > span": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonBlue: {
    boxShadow: theme.customShadows.buttonBlue,
    backgroundColor: theme.palette.colors.blue_button,
    "& > span": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.colors.blue_button,
    },
  },
  textLabel: {
    transform: "inherit !important",
    top: "auto !important",
    bottom: "auto !important",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(13),
    color: "#a1a1a1",
    flexGrow: "0",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    whiteSpace: "nowrap",
    transition: "0.25s",
  },
  textField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > .MuiInput-formControl": {
      marginTop: "auto",
      marginBottom: "auto",
    },
    "& > .MuiInputLabel-shrink": {
      top: `${theme.typography.pxToRem(3)} !important`,
      fontSize: theme.typography.pxToRem(10),
    },
    "& > div > input": {
      width: "100% !important",
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(13),
      color: "#484848",
      flexGrow: "0",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      whiteSpace: "nowrap",
      position: "relative",
      marginTop: theme.typography.pxToRem(5),
      bottom: theme.typography.pxToRem(-5),
    },
    // for Autocomplete input
    "& > .MuiAutocomplete-inputRoot": {
      position: "relative",
      "& > .MuiAutocomplete-tag": {
        position: "relative",
        top: theme.typography.pxToRem(15),
        height: theme.typography.pxToRem(22),
        padding: 0,
        "& > span": {
          fontFamily: "Roboto",
          fontSize: theme.typography.pxToRem(11),
          color: "#484848",
          flexGrow: "0",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "left",
          whiteSpace: "nowrap",
        },
        "& > svg": {
          marginRight: theme.typography.pxToRem(1),
        },
      },
    },
  },
  formControl: {
    display: "flex",
    justifyContent: "center",
    "& > .MuiInput-formControl": {
      marginTop: "auto",
      marginBottom: "auto",
    },
    "& > div > input": {
      width: "100% !important",
    },
    "& > .MuiInputLabel-shrink": {
      top: `${theme.typography.pxToRem(3)} !important`,
      fontSize: theme.typography.pxToRem(10),
    },
  },
  dropdown: {
    "& > div": {
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(13),
      color: "#484848",
      flexGrow: "0",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      whiteSpace: "nowrap",
      backgroundColor: "transparent !important",
      position: "relative",
      marginTop: theme.typography.pxToRem(5),
      bottom: theme.typography.pxToRem(-5),
    },
    "& > svg": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  dateInput: {
    width: "100%",
    left: theme.typography.pxToRem(-29),
    "& > div": {
      "& > input": {
        fontFamily: "Roboto",
        fontSize: theme.typography.pxToRem(13),
        color: "#484848",
        flexGrow: "0",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        overflow: "visible",
        display: "flex",
        whiteSpace: "nowrap",
        marginLeft: "30px",
        "&::-webkit-calendar-picker-indicator": {
          position: "absolute",
          left: 0,
          top: 0,
          marginTop: "-0.65rem",
          marginLeft: "-0.3rem",
          backgroundColor: theme.palette.colors.blue_icon,
          background: "transparent",
          opacity: "0",
          width: theme.typography.pxToRem(30),
          height: theme.typography.pxToRem(30),
          borderRadius: theme.typography.pxToRem(20),
          transition: "0.25s",
          cursor: "pointer",
        },
        "&::-webkit-calendar-picker-indicator:hover": {
          opacity: "0.1",
        },
        "&::-webkit-calendar-picker-indicator:active": {
          opacity: "0.3",
        },
      },
      "& > div": {
        marginBottom: theme.typography.pxToRem(15),
        marginRight: theme.typography.pxToRem(0),
        "& > button": {
          color: theme.palette.colors.blue_icon,
          padding: theme.typography.pxToRem(6),
        },
      },
    },
    "& > .MuiInputLabel-shrink": {
      top: theme.typography.pxToRem(7),
      bottom: "0px",
      fontSize: theme.typography.pxToRem(14),
    },
  },
  datePickerLabel: {
    marginLeft: theme.typography.pxToRem(30),
    fontSize: `${theme.typography.pxToRem(10)} !important`,
    top: `${theme.typography.pxToRem(9)} !important`,
    whiteSpace: "nowrap",
  },
  error: {
    color: "red",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(11),
    flexGrow: "0",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    marginLeft: theme.typography.pxToRem(12),
  },
  switch: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    marginLeft: theme.typography.pxToRem(14),
    "& > label > .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(13),
      color: "#484848",
      flexGrow: "0",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
  },
  switchBlue: {
    "& > label > .Mui-checked > span > svg": {
      color: theme.palette.colors.blue_icon,
    },
  },
  switchButtonGroup: {
    border: "1px solid blue",
    borderRight: "none",
    borderRadius: "0.25rem",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  switchButton: {
    display: "block",
    width: "50%",
    cursor: "pointer",
    borderRight: "1px solid blue",
  },
}));
