import useStyles from "./styles";

export default function InputContainer({ children, style }) {
  const classes = useStyles();
  return (
    <div className={classes.inputContainer} style={style}>
      {children}
    </div>
  );
}
