import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { debounce } from "lodash";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import * as XLSX from "xlsx";

import { getAisles, getLevels, getLocations } from "api/locations";

import {
  Button,
  Checkbox,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import {
  CalendarToday,
  CloudUpload,
  Done,
  Download,
  FormatListNumbered,
  Info,
  ListAlt,
  Selector,
  SpaceBar,
  TextSnippet,
  X as Clear,
} from "icons";

import {
  initialAPIListValue,
  LocationTypes,
  LOCATION_POSITIONS,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import LocationUpdate from "./LocationUpdate";
import LocationUpdateModal from "./LocationUpdateModal";

const cells = [
  { id: "code", sortable: true },
  { id: "aisle", sortable: true },
  { id: "bin", sortable: true },
  { id: "level", sortable: true },
  {
    id: "location_type",
    render: (r) => r.location_type_display,
    sortable: true,
  },
  {
    id: "last_counted_at",
    render: (r) => (r.last_counted_at ? timezoneFormat(r.last_counted_at) : ""),
    sortable: true,
  },
  {
    id: "countable",
    render: (r, exported) =>
      exported ? (
        r.countable
      ) : r.countable ? (
        <Done color="#25C12A" />
      ) : (
        <Clear color="#F54428" />
      ),
  },
  {
    id: "is_deleted",
    label: "active",
    render: (r, exported) =>
      exported ? (
        !r.is_deleted
      ) : r.is_deleted ? (
        <Clear color="#F54428" />
      ) : (
        <Done color="#25C12A" />
      ),
  },
];

export default function Location(props) {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const isManager = parseJwt(getWithExpiry("access")).is_manager;
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [locations, setLocations] = useState(initialAPIListValue);
  const [aisles, setAisles] = useState(initialAPIListValue);
  const [levels, setLevels] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    aisle: "",
    code: "",
    empty: false,
    is_deleted: "",
    is_sales_allowed: "",
    is_multiple_sku_allowed: "",
    last_count_period: "",
    level: "",
    location_type: "",
    position: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});
  const [updateData, setUpdateData] = useState([]);
  const [info, setInfo] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    getAisles({ setFunc: setAisles });
    getLevels({ setFunc: setLevels });
  }, []);

  useEffect(() => {
    if (locations.isFetching && (isManager || search.product_type)) {
      const additionalParams = addAdditionalParams(search);
      let params = {
        ...additionalParams,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getLocations({
        params,
        setError,
        setFunc: (res) => {
          setLocations(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    locations.isFetching,
    isManager,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const addAdditionalParams = (search) => {
    return {
      ...search,
      empty: search.empty ? true : null,
      is_deleted: search.is_deleted ? true : null,
      is_multiple_sku_allowed: search.is_multiple_sku_allowed ? true : null,
      is_sales_allowed: search.is_sales_allowed ? false : null,
    };
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setLocations({ ...locations, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLocations({ ...locations, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeAutoCompleteFilterInput = (prop) => (e, item) => {
    setSearch({ ...search, [prop]: item ? item.value : null });
    setPage(0);
    setLocations({ ...locations, isFetching: true });
  };

  const handleCheckBox = (prop) => () => {
    setSearch({ ...search, [prop]: !search[prop] });
    setPage(0);
    setLocations({ ...locations, isFetching: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLocations({
      items: locations.items,
      isFetching: true,
      count: locations.count,
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Locations-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, [
        ...cells,
        {
          exportLabel: "Sales Allowed",
          render: (r) => (r.is_sales_allowed ? "True" : "False"),
        },
      ]),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (locations.items.length === locations.count) {
      exportData(locations.items);
    } else {
      const additionalParams = addAdditionalParams(search);
      getLocations({
        params: {
          ...additionalParams,
          limit: locations.count,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setLocations((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleFileChange = (e) => {
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const values = XLSX.utils
        .sheet_to_json(ws, {
          raw: false,
        })
        .filter((v) => v["Code"]);

      if (!values.length) {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Please add Code column in your excel file",
        });
        return;
      }

      if (values.length > 100) {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Values count cannot be more than 100.",
        });
        return;
      }

      setUpdateData(values);
    };
    reader.readAsBinaryString(f);
    e.target.value = "";
  };

  const handleLocationUpdateClose = (reload) => {
    setUpdateData([]);
    if (reload) {
      setLocations({ ...locations, isFetching: true });
    }
  };

  const handleSelectedRow = (location) => {
    setSelectedRow(location);
  };

  const handleUpdateModalClose = (status) => {
    if (status) {
      setLocations((prev) => ({ ...prev, isFetching: true }));
    }
    setSelectedRow({});
  };

  return (
    <>
      <PageTitle title={t("locations")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="code"
              label={t("code")}
              variant="outlined"
              value={search.code}
              onChange={handleChangeFilterInput("code")}
              isLocationCode={true}
              InputProps={clearAdornment("code")}
              LeftIcon={SpaceBar}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="location_type"
              title={t("location_type")}
              value={search.location_type}
              onChange={handleChangeFilterInput("location_type")}
              items={LocationTypes}
              LeftIcon={ListAlt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="last_count_period"
              title={t("last_count_period")}
              value={search.last_count_period}
              onChange={handleChangeFilterInput("last_count_period")}
              items={[
                // use month values only, otherwise change backend if you want to add new values
                { label: "1 month", value: 1 },
                { label: "3 months", value: 3 },
                { label: "6 months", value: 6 },
                { label: "12 months", value: 12 },
              ]}
              LeftIcon={CalendarToday}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="aisle"
              onChange={handleChangeAutoCompleteFilterInput("aisle")}
              options={
                aisles.items.length
                  ? aisles.items.map((item) => ({
                      value: item.aisle,
                      label: item.aisle,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.aisle === value.aisle
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("aisle")}
                  variant="outlined"
                  LeftIcon={FormatListNumbered}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="level"
              onChange={handleChangeAutoCompleteFilterInput("level")}
              options={
                levels.items.length
                  ? levels.items.map((item) => ({
                      value: item.level,
                      label: item.level,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("level")}
                  variant="outlined"
                  LeftIcon={TextSnippet}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="position"
              onChange={handleChangeAutoCompleteFilterInput("position")}
              options={LOCATION_POSITIONS}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("position")}
                  variant="outlined"
                  LeftIcon={Selector}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={search.empty}
              onChange={handleCheckBox("empty")}
              color="blue"
              label={t("empty")}
              popup={`${t("empty_locations")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={search.is_deleted}
              onChange={handleCheckBox("is_deleted")}
              color="blue"
              label={t("inactive")}
              popup={`${t("inactive")} ${t("locations")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={search.is_multiple_sku_allowed}
              onChange={handleCheckBox("is_multiple_sku_allowed")}
              color="blue"
              label={t("multiple")}
              popup={`${t("multiple_sku_allowed")} ${t("locations")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={search.is_sales_allowed}
              onChange={handleCheckBox("is_sales_allowed")}
              color="blue"
              label={t("not_sales_allowed")}
              popup={t("not_sales_allowed")}
            />
          </Grid>
          {permissions.includes("warehouse.change_location") ? (
            <>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <Grid container justifyContent="center">
                  <Grid item xs={1}>
                    <IconButton
                      variant="contained"
                      style={{
                        marginTop: "0.2rem",
                        marginLeft: "0.3rem",
                      }}
                      onClick={() => setInfo(true)}
                    >
                      <Info />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        exportToExcel(
                          [
                            {
                              Code: "",
                              "Location Type": "",
                              Countable: "",
                              Active: "",
                              "Multiple SKU Allowed": "",
                              Position: "",
                            },
                          ],
                          "location_update template",
                        )
                      }
                    >
                      <span style={{ paddingRight: "2px" }}>
                        <Download color="#fff" size={0.8} />
                      </span>
                      Download Template
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <label htmlFor="file_picker">
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    id="file_picker"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component="span"
                    disabled={false}
                  >
                    <span style={{ paddingRight: "2px" }}>
                      <CloudUpload color="#fff" size={0.8} />
                    </span>
                    Upload File
                  </Button>
                </label>{" "}
              </Grid>
            </>
          ) : null}
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={locations}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            disableExportData={
              !permissions.includes("warehouse.export_data.locations")
            }
            rowOnClick={handleSelectedRow}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
          {Object.keys(selectedRow).length ? (
            <LocationUpdateModal
              onClose={handleUpdateModalClose}
              location={selectedRow}
            />
          ) : null}
        </Grid>
      </Grid>
      {updateData.length ? (
        <LocationUpdate
          updateData={updateData}
          onClose={handleLocationUpdateClose}
        />
      ) : null}
      {info ? (
        <Dialog open={true} onClose={() => setInfo(false)}>
          <DialogTitle> Valid Values List For Location Update </DialogTitle>
          <DialogContent>
            <ul>
              <li>
                <strong>Code:</strong> Location Code (Ex. 102-002-20)
              </li>
              <li>
                <strong>Location Type:</strong> Rug, Furniture, Dock, Buggy,
                Others
              </li>
              <li>
                <strong>Position:</strong> Low, High, Top
              </li>
              <li>
                <strong>Countable:</strong> True, False
              </li>
              <li>
                <strong>Active:</strong> True, False
              </li>
              <li>
                <strong>Multiple SKU Allowed:</strong> True, False
              </li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setInfo(false)} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}
