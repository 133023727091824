import * as React from "react";

const SvgLockclosed = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M16 11v1h1v-1zM8 11H7v1h1zM13 15a1 1 0 1 0-2 0h2ZM11 17a1 1 0 1 0 2 0h-2Z" />
      <path d="M6 12h12v-2H6zM19 13v6h2v-6zM18 20H6v2h12zM5 19v-6H3v6zM6 20a1 1 0 0 1-1-1H3a3 3 0 0 0 3 3v-2ZM19 19a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2ZM18 12a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM6 10a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Z" />
      <path d="M15 7v4h2V7z" />
      <path d="M16 10H8v2h8z" />
      <path d="M9 11V7H7v4zM12 4a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2ZM12 2a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3V2ZM11 15v2h2v-2z" />
    </g>
  </svg>
);

export default SvgLockclosed;
