import { makeStyles } from "@material-ui/styles";
const Style = (props) =>
  makeStyles((theme) => ({
    cardContainer: {
      borderStyle: "solid",
      borderColor: props.bgColor,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: "4px",
      alignItems: "center",
      justifyContent: "center",
    },
    cardIcon: {
      fontSize: "2em",
      textAlign: "center",
    },
    cardValue: {
      fontWeight: "bold",
      fontSize: "2em",
    },
    cardInfo: {
      paddingLeft: "10px",
      color: "gray",
    },
  }));

export default Style;
