import { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";

import { cancelAdjustment } from "api/adjustment";

import { ConfirmDialog, ProductTypeFilter } from "components/Custom";
import { Button, DatePickerGroup, Dropdown } from "components/Form";
import { FormattedTextField } from "components/FormElements";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { Class, QrCode2, Share, SpaceBar, X } from "icons";

import { PUBLISH_TYPES } from "utils/constants";

import AdjustmentPublishModal from "./PublishModal";

export default function AdjustmentFilter({
  handleChangeFilterInput,
  params,
  clearAdornment,
  debouncedSearchHandler,
  setParams,
  selectedAdjustments = [],
  headers = [],
  afterSuccessPublish,
}) {
  const layoutDispatch = useLayoutDispatch();
  const [publish, setPublish] = useState(false);
  const [cancel, setCancel] = useState(false);
  const { permissions } = useUserState();
  const hasPublishPermission = permissions.includes(
    "contenttypes.adjustment.publish",
  );
  const hasCancelPermission = permissions.includes(
    "warehouse.adjustment.cancel",
  );

  const handleClosePublishModal = (status) => {
    if (status) {
      afterSuccessPublish();
    }
    setPublish(false);
  };

  const handleAdjustmentCancel = () => {
    cancelAdjustment({
      body: {
        ids: selectedAdjustments.map((s) => s.id),
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        afterSuccessPublish();

        return popupNotification({
          dispatch: layoutDispatch,
          message: message,
          status: "success",
        });
      },
    });
  };

  return (
    <Grid container>
      <DatePickerGroup
        startDateValue={params.start_date}
        startDateOnChange={handleChangeFilterInput("start_date")}
        endDateValue={params.end_date}
        endDateOnChange={handleChangeFilterInput("end_date")}
        itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
      />
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="sku"
          label="SKU"
          variant="outlined"
          value={params.product__sku}
          onChange={handleChangeFilterInput("product__sku")}
          InputProps={clearAdornment("product__sku")}
          LeftIcon={QrCode2}
          isProductSKU={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="location_code"
          label="Location"
          variant="outlined"
          value={params.from_location__code}
          onChange={handleChangeFilterInput("location__code")}
          InputProps={clearAdornment("location__code")}
          LeftIcon={SpaceBar}
          isProductSKU={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Dropdown
          id="is_published"
          title="Is Published"
          value={params.is_published}
          onChange={handleChangeFilterInput("is_published")}
          items={[
            {
              value: true,
              label: "Published",
            },
            {
              value: false,
              label: "Not Published",
            },
          ]}
          LeftIcon={Class}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={2}
        style={{
          border: !params.type ? "1px solid #ff5555" : "inherit",
        }}
      >
        <Dropdown
          id="type"
          title="Adjustment Type"
          value={params.type}
          onChange={handleChangeFilterInput("type")}
          items={PUBLISH_TYPES}
          LeftIcon={Class}
        />
      </Grid>
      <ProductTypeFilter
        value={params.product__product_type}
        setValue={(data) => {
          setParams({ ...params, product__product_type: data });
          debouncedSearchHandler();
        }}
        onChange={handleChangeFilterInput("product__product_type")}
      />
      <Hidden mdDown>
        <Grid item lg={hasCancelPermission && hasPublishPermission ? 6 : 8} />
      </Hidden>
      {hasCancelPermission ? (
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            color="danger"
            disabled={!!params.is_published || !selectedAdjustments.length}
            onClick={() => setCancel(true)}
          >
            <X color="white" />
            Cancel
          </Button>
        </Grid>
      ) : null}
      {hasPublishPermission ? (
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            color="blue"
            disabled={
              !!params.is_published ||
              !selectedAdjustments.length ||
              !params.type
            }
            onClick={() => setPublish(true)}
          >
            <Share color="white" />
            Publish
          </Button>
        </Grid>
      ) : null}

      {publish && (
        <AdjustmentPublishModal
          onClose={handleClosePublishModal}
          adjustments={selectedAdjustments}
          headers={headers}
        />
      )}
      <ConfirmDialog
        open={cancel}
        title="Cancel Adjustments"
        children={`${selectedAdjustments.length} adjustment records will be cancelled`}
        onClose={() => setCancel(false)}
        onConfirm={handleAdjustmentCancel}
      />
    </Grid>
  );
}
