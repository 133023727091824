import * as React from "react";

const SvgPencil = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="m20.268 3.73.707-.707zM6.5 21.002V22c.265 0 .52-.105.707-.292l-.707-.706ZM3 21.002H2A1 1 0 0 0 3 22v-.998ZM3 17.437l-.707-.706a.997.997 0 0 0-.293.706h1ZM17.44 4.435a1.502 1.502 0 0 1 2.12 0l1.415-1.412a3.504 3.504 0 0 0-4.95 0l1.414 1.412Z" />
      <path d="M19.56 4.435a1.495 1.495 0 0 1 0 2.118l1.415 1.411a3.49 3.49 0 0 0 0-4.94L19.56 4.434Z" />
      <path d="M19.56 6.553 5.794 20.296l1.414 1.412L20.975 7.964z" />
      <path d="M6.5 20.004H3V22h3.5zM16.025 3.023 2.293 16.731l1.414 1.412L17.44 4.435z" />
      <path d="M2 17.437v3.565h2v-3.565zM14.525 5.932l3.536 3.53 1.414-1.412-3.536-3.53z" />
    </g>
  </svg>
);

export default SvgPencil;
