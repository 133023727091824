import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: "18px 24px 24px 24px",
    width: `calc(100vw - 240px)`,
    minHeight: "calc(100vh - 70px)",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },
  isOffline: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: "auto",
    background: "#1E608E",
    opacity: "80%",
    zIndex: 999999999,
  },
  center: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12vw",
    textAlign: "center",
    color: "#fff",
  },
}));
