import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const ConfirmDialog = ({
  title,
  confirmText,
  children,
  headerColor,
  open,
  onClose,
  onConfirm,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">
        <span style={{ color: headerColor || null }}>{title}</span>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color="primary"
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={20} />}
          {confirmText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
