import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import { SetBuggyToLocal, Spacer } from "components/Custom";
import PageTitle from "components/PageTitle";

import AssignedLocations from "./AssignedLocations";
import PickList from "./PickList";
import LTLPickList from "pages/ltl/PickList";

export default function MyWork() {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title="My Tasks" helpText={`${t("assigned_to_you")}:`} />
      <Spacer height="3rem" />
      <Grid container spacing={4}>
        <SetBuggyToLocal hasClearButton />
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <PickList />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <LTLPickList />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <AssignedLocations />
        </Grid>
      </Grid>
      <Spacer height="40px" />
    </>
  );
}
