import {
  clearPopupNotification,
  useLayoutDispatch,
  useLayoutState,
} from "context/LayoutContext";
import React, { useEffect, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { alertSound } from "utils/sound";

export default function PopupNotification() {
  const classes = useStyles();
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const { popup, isMobile } = useLayoutState();
  const [openDialog, setOpenDialog] = useState(false);
  const [notify, setNotify] = useState({});
  const [afterClose, setAfterClose] = useState({});

  useEffect(() => {
    if (popup.message || popup.title) {
      if (isMobile && popup.status !== "success") {
        setOpenDialog(true);
        setNotify(popup);
        if (popup.status !== "info") {
          alertSound();
        }
      } else {
        createNotification({
          ...popup,
          ...(isMobile ? { timeout: 2000 } : null),
        });
        if (popup.afterCloseFunction) {
          popup.afterCloseFunction();
        }
      }
      clearPopupNotification(layoutDispatch);
    }
  }, [popup, isMobile, layoutDispatch]);

  useEffect(() => {
    if (!openDialog && afterClose.function) {
      afterClose.function();
    }
  }, [openDialog, afterClose]);

  const createNotification = ({ message, status, timeout }) => {
    switch (status) {
      case "info":
        NotificationManager.info(message, null, timeout);
        break;
      case "success":
        NotificationManager.success(message, null, timeout);
        break;
      case "warning":
        NotificationManager.warning(message, null, timeout);
        break;
      default:
        NotificationManager.error(message, null, timeout);
    }
  };

  const handleCloseDialog = () => {
    const { afterCloseFunction } = notify;
    setOpenDialog(false);
    setNotify({});
    setAfterClose({ function: afterCloseFunction });
  };

  const statusBgColors = {
    success: "#22bb33",
    info: "#5bc0de",
    warning: "#f0ad4e",
    error: "#bb2124",
  };

  return openDialog ? (
    <div>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            backgroundColor:
              statusBgColors[notify.status] || statusBgColors.error,
          }}
        >
          {notify.title && (
            <DialogTitle className={classes.title}>
              <Typography variant="h5">{notify.title}</Typography>
            </DialogTitle>
          )}
          {notify.message && (
            <DialogContentText className={classes.content}>
              <Typography
                component={"span"}
                variant="h3"
                className={classes.message}
              >
                {notify.message}
              </Typography>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            justifyContent: "center",
            backgroundColor:
              statusBgColors[notify.status] || statusBgColors.error,
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            className={classes.closeButton}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
}
