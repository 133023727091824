import React, { useEffect } from "react";

import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";

import { ProductTypes } from "utils/constants";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import useStyles from "./styles";

export default function ProductTypeFilter({
  value,
  setValue,
  onChange,
  xs = 12,
  sm = 6,
  md = 4,
  lg = 2,
}) {
  const classes = useStyles();
  const claims = parseJwt(getWithExpiry("access"));
  const { is_manager, related_product } = claims;

  useEffect(() => {
    if (related_product && !value) {
      setValue(related_product);
    }
  }, [related_product, setValue, value, is_manager]);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <RadioGroup
        row
        aria-labelledby="product_type_control"
        name="product_type_group"
        className={classes.radioGroup}
        value={value.toString()}
        onChange={onChange}
      >
        {ProductTypes.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.radioButton}
            style={index === 0 ? { marginLeft: 0 } : null}
            value={item.value.toString()}
            control={<Radio />}
            label={item.label}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </Grid>
  );
}
