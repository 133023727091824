import { API, addParamsToEndpoint } from "utils/api";

const getUsers = ({ setFunc, setError, paginated, params }) => {
  const api = new API();
  api.list({
    endpoint: addParamsToEndpoint("accounts/", params),
    setError,
    setFunc,
    paginated,
  });
};

const getGroups = ({ setFunc }) => {
  const api = new API();
  api.list({
    endpoint: "custom/get-groups/",
    setFunc,
  });
};

const updateUser = ({ body, responseSetter }) => {
  const api = new API();
  api.create({
    endpoint: "custom/update-user/",
    body,
    responseSetter,
  });
};

const createUser = ({ body, responseSetter }) => {
  const api = new API();
  api.create({
    endpoint: "accounts/",
    body,
    responseSetter,
  });
};

export { getUsers, getGroups, updateUser, createUser };
