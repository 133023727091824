import React, { useRef, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
  IconButton,
  RadioGroup,
  Radio,
  FormLabel,
  Grid,
  Button,
} from "@material-ui/core";

import { TextInput } from "components/Form";

import { useLayoutState } from "context/LayoutContext";

import {
  Keyboard,
  KeyboardHide,
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "icons";

import themes from "themes";

import { formatLocationCode, formatProductSKU } from "utils/string";

import useStyles from "./styles";
import useCarrierStyles from "./carrierStyles";

export function SelectBox({
  id,
  title,
  onChange,
  value,
  items,
  disabled,
  all_option_active = true,
}) {
  const classes = useStyles();
  const label = `${id}_label`;

  return (
    <div>
      <FormControl fullWidth className={classes.formControl} variant="outlined">
        <InputLabel id={label}>{title}</InputLabel>
        <Select
          autoWidth
          labelId={label}
          id={id}
          value={value}
          label={title}
          onChange={onChange}
          disabled={disabled}
        >
          {all_option_active && (
            <MenuItem key={"type_all"} value={""}>
              All
            </MenuItem>
          )}
          {items.map((item, i) => (
            <MenuItem key={`type_${item.value}_${i}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export function DateTimePicker({ id, title, value, onChange }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth className={classes.formControl} variant="outlined">
        <TextField
          id={id}
          label={title}
          type="datetime-local"
          variant="outlined"
          value={value}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </div>
  );
}

export function DatePicker({ id, title, value, onChange }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth className={classes.formControl} variant="outlined">
        <TextField
          id={id}
          label={title}
          type="date"
          variant="outlined"
          value={value}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.dateInput}
        />
      </FormControl>
    </div>
  );
}

export function FormattedTextField({
  label,
  variant,
  value,
  onChange,
  onKeyPress,
  InputProps,
  isLocationCode,
  isUpperCase,
  isProductSKU,
  hasBorder,
  LeftIcon,
  ...props
}) {
  return (
    <TextInput
      fullWidth
      autoComplete="off"
      label={label}
      variant={variant}
      value={value}
      onChange={({ target: { value } }) => {
        if (isLocationCode) {
          onChange({ target: { value: formatLocationCode(value) } });
        } else if (isProductSKU) {
          onChange({ target: { value: formatProductSKU(value) } });
        } else if (isUpperCase) {
          onChange({ target: { value: value.toUpperCase() } });
        } else {
          onChange({ target: { value } });
        }
      }}
      onKeyPress={onKeyPress}
      InputProps={InputProps}
      LeftIcon={LeftIcon}
      hasBorder={hasBorder}
      {...props}
    />
  );
}

export function Input({ id, title, value, onChange, type, ...props }) {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <InputLabel htmlFor={id}>{title}</InputLabel>
      <OutlinedInput
        id={id}
        value={value}
        onChange={onChange}
        type={type}
        {...props}
      />
    </FormControl>
  );
}

export function FormCheckBox({
  id,
  title,
  value,
  onChange,
  isChecked,
  ...props
}) {
  return (
    <FormControlLabel
      key={id}
      value={value}
      control={
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          color="primary"
          {...props}
        />
      }
      label={title}
      {...props}
    />
  );
}

export function TextInputWithKeyboard({
  id,
  variant = "outlined",
  fullWidth = true,
  autoFocus,
  inputRef,
  inputMode = "text",
  dark_mode = true,
  label,
  placeholder,
  value,
  onChange,
  onKeyPress,
  LeftIcon,
  onIconClick,
  disabled,
  hasBorder,
}) {
  const inputEl = useRef(null);
  const elementRef = inputRef ? inputRef : inputEl;
  const [inputModeState, setInputMode] = useState("none");
  const { isMobile } = useLayoutState();

  const handleChangeInputMode = (prop) => {
    setInputMode(inputModeState === inputMode ? "none" : inputMode);
    elementRef.current.focus();
    const event = document.createEvent("StorageEvent");
    event.initStorageEvent(
      "storage",
      false,
      false,
      "keyboardState",
      inputModeState,
      inputModeState === inputMode ? "none" : inputMode,
      null,
      window.localStorage,
    );
    window.dispatchEvent(event);
  };

  return (
    <>
      <TextInput
        id={id}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        inputProps={{
          inputMode: inputModeState,
        }}
        InputProps={{
          endAdornment: isMobile ? (
            <KeyboardComponent
              dark_mode={dark_mode}
              inputModeState={inputModeState}
              handleChangeInputMode={handleChangeInputMode}
            />
          ) : null,
        }}
        inputRef={elementRef}
        variant={variant}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        LeftIcon={LeftIcon}
        onIconClick={onIconClick}
        disabled={disabled}
        hasBorder={hasBorder}
      />
    </>
  );
}

function KeyboardComponent({
  inputModeState,
  handleChangeInputMode,
  dark_mode,
}) {
  const color = dark_mode ? "#000" : "#FFF";

  return (
    <InputAdornment position="end">
      <IconButton edge="end" onClick={handleChangeInputMode}>
        {inputModeState === "none" ? (
          <Keyboard color={color} />
        ) : (
          <KeyboardHide color={color} />
        )}
      </IconButton>
    </InputAdornment>
  );
}

export function RadioGroupInput({ items, row = true, label, onChange }) {
  return (
    <FormControl fullWidth>
      <FormLabel id={`${label}-label`}>{label}</FormLabel>
      <RadioGroup
        fullWidth
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
        row={row}
        name={`${label}-radio-buttons-group`}
        onChange={onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            fullWidth
            key={item.id || item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function CarrierRadioGroupInput({
  onChange,
  value,
  fedexLabel,
  upsLabel,
}) {
  const carrierStyles = useCarrierStyles();
  const carriers = [
    {
      name: "FEDEX",
      label: fedexLabel,
      className: carrierStyles.fedex,
    },
    {
      name: "UPS",
      label: upsLabel,
      className: carrierStyles.ups,
    },
  ];

  return (
    <FormControl
      component="fieldset"
      style={{ color: value ? "#fff" : "#000" }}
      fullWidth
    >
      <Grid container spacing={4}>
        {carriers.map((carrier) => (
          <Grid item key={`${carrier.name}`} lg={6} md={6} sm={6} xs={6}>
            <Button
              fullWidth
              className={carrier.className}
              onClick={() => onChange(carrier.name)}
              startIcon={
                value === carrier.name ? (
                  <RadioButtonChecked
                    color={themes.default.palette.common.white}
                  />
                ) : (
                  <RadioButtonUnchecked
                    color={themes.default.palette.common.white}
                  />
                )
              }
            >
              {carrier.label || carrier.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
}
