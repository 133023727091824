import * as React from "react";

const SvgSnippetFolder = ({ color, size }) => (
  <svg
    viewBox="0 0 25 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M9.17 6.5H4v12h16.002v-10H11.17l-2-2z"
      fill={color}
    />
    <path
      d="M20.002 6.5H12l-2-2H4c-1.1 0-1.991.9-1.991 2L2 18.5c0 1.1.9 2 2 2h16.002c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12H4v-12h5.17l2 2h8.832v10zm-2.5-5.88V16h-3v-5h1.38l1.62 1.62zm-1-3.12h-3.5v8h6V12l-2.5-2.5z"
      fill={color}
    />
  </svg>
);

export default SvgSnippetFolder;
