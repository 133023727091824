import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

import {
  getUnassignableShipmentDetails,
  unassignShipments,
} from "api/shipment";

import { ExternalLink, InternalLink } from "components/Custom/Links";
import { PickersWithAssignedQty, ProductTypeFilter } from "components/Custom";
import { Button, Dropdown, InputContainer, TextInput } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import {
  LocalShipping,
  QrCode2,
  SnippetFolder,
  SpaceBar,
  TextSnippet,
} from "icons";

import ReassignShipments from "pages/ecommerce/ReassignShipments";
import { getCarrierTrackingLink } from "pages/shipments/utils";

import { carriers, initialAPIListValue } from "utils/constants";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { toTitleCase } from "utils/string";

const initialParams = {
  carrier: "",
  tracking_number: "",
  location_code: "",
  product_sku: "",
  shipment_number: "",
  customer: "",
};

const headers = [
  {
    id: "tracking_number",
    render: (r) => (
      <ExternalLink
        to={getCarrierTrackingLink(r.detail.ship_via, r.detail.tracking_number)}
      >
        {r.detail.tracking_number}
      </ExternalLink>
    ),
  },
  {
    id: "location",
    render: (r) => r.detail.location_code,
  },
  {
    id: "product",
    render: (r) => r.detail.product_sku,
  },
  {
    id: "shipment_number",
    render: (r) => (
      <InternalLink to={`/app/shipments/${r.shipment_id}`}>
        {r.detail.shipment_number}
      </InternalLink>
    ),
  },
  {
    id: "customer",
    render: (r) => r.detail.customer,
  },
  {
    id: "assignee",
    render: (r) => toTitleCase(r.user && r.user.username),
  },
];

export default function EcommerceUnassign() {
  const layoutDispatch = useLayoutDispatch();
  const { related_product } = parseJwt(getWithExpiry("access"));
  const [transactions, setTransactions] = useState(initialAPIListValue);
  const [reloadPickers, setReloadPickers] = useState(false);
  const [selectedTransactions, setSelectectedTransactions] = useState([]);
  const [selectedPicker, setSelectedPicker] = useState({});
  const [records, setRecords] = useState([]);
  const [params, setParams] = useState({
    ...initialParams,
    detail__product_type: related_product || 1,
  });
  const [aisles, setAisles] = useState([]);
  const [unassignIsLoading, setUnassignIsLoading] = useState(false);
  const [reassignModal, setReassignModal] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  const searchFilter = useCallback(
    (row) => {
      if (selectedPicker && selectedPicker.id) {
        if (selectedPicker.user.id !== row.user.id) {
          return false;
        }
      }

      if (
        aisles.length &&
        row.detail &&
        row.detail.location_code &&
        !aisles.includes(row.detail.location_code.split("-")[0])
      ) {
        return false;
      }

      if (
        params.detail__product_type &&
        Number(params.detail__product_type) !== Number(row.detail.product_type)
      ) {
        return false;
      }

      if (
        params.carrier &&
        row.detail.carrier.toLowerCase() !== params.carrier
      ) {
        return false;
      }

      if (params.tracking_number) {
        if (row.detail.tracking_number.indexOf(params.tracking_number) === -1) {
          return false;
        }
      }

      if (params.location_code) {
        if (
          row.detail.location_code
            .toLowerCase()
            .indexOf(params.location_code.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (params.product_sku) {
        if (
          row.detail.product_sku
            .toLowerCase()
            .indexOf(params.product_sku.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (params.shipment_number) {
        if (row.detail.shipment_number.indexOf(params.shipment_number) === -1) {
          return false;
        }
      }

      if (params.customer) {
        if (
          row.detail.customer
            .toLowerCase()
            .indexOf(params.customer.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      return true;
    },
    [params, selectedPicker, aisles],
  );

  useEffect(() => {
    if (transactions.isFetching) {
      getUnassignableShipmentDetails({
        params: {
          is_small_carrier: true,
        },
        responseSetter: (res) => {
          setTransactions({
            isFetching: false,
            items: res,
          });
        },
      });
    }
  }, [transactions]);

  useEffect(() => {
    if (transactions.items.length) {
      setSelectectedTransactions([]);
      setRecords(groupByAisle(transactions.items));
    }
  }, [transactions.items, searchFilter]);

  useEffect(() => {
    const items = transactions.items.filter(searchFilter);
    const headerCounts = {
      customer: _.size(_.groupBy(items, "detail.customer")),
      product: _.size(_.groupBy(items, "detail.product_sku")),
      location: _.size(_.groupBy(items, "detail.location_code")),
    };
    setHeaderCounts(headerCounts);
  }, [searchFilter, transactions.items]);

  const groupByAisle = (items) => {
    const groupped = items.reduce((result, item) => {
      let aisle = item["detail"]["location_code"].split("-")[0];
      return {
        ...result,
        [aisle]: [...(result[aisle] || []), item],
      };
    }, {});

    return groupped;
  };

  const handleSelectAisle = (key) => {
    if (aisles.includes(key)) {
      setAisles(aisles.filter((aisle) => aisle !== key));
    } else {
      setAisles([...aisles, key]);
    }
  };

  const handleSelectPicker = (e, picker) => {
    if (picker) {
      setSelectedPicker(picker);
    } else {
      setSelectedPicker({});
    }
  };

  const handleChangeParams = (prop) => ({ target: { value } }) => {
    setParams({ ...params, [prop]: value });
  };

  const handleUnassignShipments = () => {
    const ids = selectedTransactions.map((s) => s.id);
    const container = selectedTransactions.map((t) => ({
      detail_id: t.detail.id,
      user_id: t.user.id,
      quantity: t.quantity,
    }));
    setUnassignIsLoading(true);
    unassignShipments({
      container,
      responseSetter: (data) => {
        const { success, message } = data;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          setUnassignIsLoading(false);
          return;
        }

        const filteredTransactions = transactions.items.filter(
          (t) => !ids.includes(t.id),
        );

        if (!filteredTransactions.length) {
          setTransactions(initialAPIListValue);
          setRecords([]);
        } else {
          setTransactions({
            ...transactions,
            items: filteredTransactions,
          });
        }

        setSelectedPicker({});
        setReloadPickers(true);
        setParams(initialParams);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Shipment(s) unassigned.",
          status: "success",
        });
        setUnassignIsLoading(false);
      },
    });
  };

  const handleCloseReassingModal = (is_succesfull) => {
    if (is_succesfull) {
      setTransactions(initialAPIListValue);
      setSelectedPicker({});
      setParams(initialParams);
    }
    setReassignModal(false);
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  return (
    <>
      <PageTitle title="Unassign" helpText="Ecommerce" />
      {transactions.isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <InputContainer>
            <Grid container>
              <PickersWithAssignedQty
                product_type={params.detail__product_type}
                onChange={handleSelectPicker}
                selectedPicker={selectedPicker}
                reload={reloadPickers}
                lg={2}
                md={4}
                sm={6}
                xs={12}
              />
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <Dropdown
                  id="carrier"
                  title="Carrier"
                  value={params.carrier || ""}
                  onChange={handleChangeParams("carrier")}
                  items={carriers.map((carrier) => ({
                    value: carrier.toLowerCase(),
                    label: carrier,
                  }))}
                  LeftIcon={TextSnippet}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Tracking Number"
                  variant="outlined"
                  value={params.tracking_number}
                  onChange={handleChangeParams("tracking_number")}
                  InputProps={clearAdornment("tracking_number")}
                  isUpperCase={true}
                  LeftIcon={LocalShipping}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  value={params.location_code}
                  onChange={handleChangeParams("location_code")}
                  InputProps={clearAdornment("location_code")}
                  isLocationCode={true}
                  LeftIcon={SpaceBar}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Product SKU"
                  variant="outlined"
                  value={params.product_sku}
                  onChange={handleChangeParams("product_sku")}
                  InputProps={clearAdornment("product_sku")}
                  isProductSKU={true}
                  LeftIcon={QrCode2}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Shipment Number"
                  variant="outlined"
                  value={params.shipment_number}
                  onChange={handleChangeParams("shipment_number")}
                  InputProps={clearAdornment("shipment_number")}
                  LeftIcon={SnippetFolder}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Customer"
                  variant="outlined"
                  value={params.customer || ""}
                  onChange={handleChangeParams("customer")}
                  InputProps={clearAdornment("customer")}
                  isUpperCase={true}
                  LeftIcon={TextSnippet}
                />
              </Grid>
              <ProductTypeFilter
                value={params.detail__product_type || ""}
                onChange={handleChangeParams("detail__product_type")}
                setValue={(data) =>
                  setParams({ ...params, detail__product_type: data })
                }
                lg={2}
                md={4}
                sm={6}
                xs={12}
              />
              <Box
                component={Grid}
                item
                lg={4}
                display={{
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                }}
                style={{ borderRight: "none" }}
              />
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
                style={{ display: "flex" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={!selectedTransactions.length || unassignIsLoading}
                  onClick={() => setReassignModal(true)}
                >
                  {unassignIsLoading ? <CircularProgress size={10} /> : null}
                  Re-assign
                </Button>
              </Grid>
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
                style={{ display: "flex" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="blue"
                  disabled={!selectedTransactions.length || unassignIsLoading}
                  onClick={handleUnassignShipments}
                >
                  {unassignIsLoading ? <CircularProgress size={10} /> : null}
                  Unassign
                </Button>
              </Grid>
              <Box
                component={Grid}
                item
                sm={6}
                display={{
                  xs: "none",
                  sm: "block",
                  md: "none",
                  lg: "none",
                }}
                style={{ borderRight: "none" }}
              />
            </Grid>
          </InputContainer>
          <Grid container spacing={4}>
            <Grid item lg={2} md={3} sm={4} xs={12}>
              <Card>
                <CardContent>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Aisles
                      </ListSubheader>
                    }
                  >
                    {Object.keys(records)
                      .filter(
                        (key) =>
                          records[key]
                            .filter(
                              (item) =>
                                item.detail.ship_via
                                  .toLowerCase()
                                  .indexOf(params.carrier.toLowerCase()) > -1,
                            )
                            .reduce((t, r) => t + r.quantity, 0) > 0,
                      )
                      .map((key) => (
                        <ListItem
                          key={key}
                          onClick={() => handleSelectAisle(key)}
                          selected={aisles.includes(key)}
                        >
                          <ListItemText primary={key} />
                          <ListItemSecondaryAction>
                            (
                            {records[key]
                              .filter(
                                (item) =>
                                  item.detail.carrier
                                    .toLowerCase()
                                    .indexOf(params.carrier.toLowerCase()) > -1,
                              )
                              .reduce((t, r) => t + r.quantity, 0)}
                            )
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={10} md={9} sm={8}>
              <DefaultTable
                headers={headers}
                headerCounts={headerCounts}
                data={{
                  items: transactions.items.filter(searchFilter),
                }}
                hasCheckBox={true}
                selected={selectedTransactions}
                setSelected={setSelectectedTransactions}
                filters={{
                  ...params,
                  picker:
                    selectedPicker &&
                    selectedPicker.user &&
                    selectedPicker.user.username,
                }}
              />
            </Grid>
          </Grid>
          {reassignModal ? (
            <ReassignShipments
              selectedTransactions={selectedTransactions}
              product_type={params.detail__product_type}
              handleClose={handleCloseReassingModal}
            />
          ) : null}
        </>
      )}
    </>
  );
}
