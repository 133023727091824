import * as React from "react";

const SvgLocationOn2 = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3C8.134 3 5 5.806 5 9.266 5 13.913 12 21 12 21s7-7.087 7-11.734C19 5.806 15.866 3 12 3Zm0 8.91c-1.631 0-2.954-1.184-2.954-2.644 0-1.46 1.323-2.644 2.954-2.644 1.631 0 2.954 1.183 2.954 2.644 0 1.46-1.323 2.643-2.954 2.643Z"
      fill={color}
    />
  </svg>
);

export default SvgLocationOn2;
