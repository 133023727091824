import * as React from "react";

const SvgRefresh = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.21 14a1 1 0 0 1 .993.883l.006.117v5a1 1 0 0 1-1.993.117L19.209 20v-2.34a9.001 9.001 0 0 1-15.93-4.536 1 1 0 1 1 1.984-.248A7.001 7.001 0 0 0 17.955 16H15.21a1 1 0 0 1-.116-1.993l.116-.007h5Zm-8-11a9.001 9.001 0 0 1 8.93 7.876 1 1 0 1 1-1.985.248A7.001 7.001 0 0 0 6.462 8L9.21 8a1 1 0 0 1 .117 1.993L9.209 10h-5a1 1 0 0 1-.993-.883L3.209 9V4a1 1 0 0 1 1.994-.117L5.209 4v2.342a8.997 8.997 0 0 1 7-3.342Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgRefresh;
