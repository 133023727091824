import * as React from "react";

const SvgFormatListNumbered = ({ color, size }) => (
  <svg
    viewBox="0 0 23 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.658 13.5H2.98l1.677-2.1v-.9H1.863v1H3.54l-1.677 2.1v.9h2.795v-1zm1.863-8h13.04v2H6.522v-2zm-1.863 11H1.863v1h1.863v.5h-.931v1h.931v.5H1.863v1h2.795v-4zm1.863 1h13.04v2H6.522v-2zm-3.726-9h.931v-4H1.863v1h.932v3zm3.726 3h13.04v2H6.522v-2z"
      fill={color}
    />
  </svg>
);

export default SvgFormatListNumbered;
