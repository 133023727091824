import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  language: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(1),
    width: "100%",
    "& .MuiInput-underline:before": {
      border: "none",
    },
  },
  languageForm: {
    float: "left",
    width: "90px",
    backgroundColor: theme.palette.colors.light_green_grey,
    paddingLeft: theme.spacing(1.4),
    paddingRight: theme.spacing(1.4),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1),
    borderRadius: "7px",
    "& .MuiSelect-selectMenu": {
      color: "white",
      border: "none",
      paddingRight: theme.spacing(2),
    },
  },
  languageText: {
    marginRight: theme.spacing(8),
  },
  passwordReset: {
    marginTop: theme.spacing(1),
  },
}));
