import { makeStyles } from "@material-ui/styles";
import { blueGrey, green, purple, red, brown } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingBottom: 20,
    width: "50vw",
  },
  actionButton: {
    width: "10rem",
    float: "right",
  },
  mt10: {
    marginTop: "10px !important",
  },
  mr10: {
    marginRight: "10px !important",
  },
  mb10: {
    marginBottom: "10px !important",
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  hint: {
    marginLeft: "10px !important",
    color: "#a1a1a1",
  },
  greenBadge: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: green[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  purpleBadge: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: purple[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  redBadge: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: red[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  brownBadge: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: brown[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  greenBadgeModal: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: green[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
      marginRight: "1rem",
    },
  },
  purpleBadgeModal: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: purple[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
      marginRight: "1rem",
    },
  },
  redBadgeModal: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: red[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
      marginRight: "1rem",
    },
  },
  brownBadgeModal: {
    "& span": {
      minWidth: "2.25rem",
      backgroundColor: brown[600],
      fontFamily: "Roboto",
      fontWeight: "bold",
      marginRight: "1rem",
    },
  },
  subtitle: {
    fontSize: "0.6rem",
    opacity: 0.6,
  },
  mlAuto: {
    marginLeft: "auto",
  },
  matrixContainer: {
    padding: "0.5rem",
    marginBottom: "1rem",
  },
  matrixItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem",

    "&:hover": {
      backgroundColor: "#f3f3f3",
    },
  },
  matrixItemTitle: {
    fontSize: "0.8125rem",
    fontFamily: "Roboto",
    color: "white",
  },
  matrixItemValue: {
    fontSize: "1.25rem",
    fontFamily: "Roboto",
    color: "white",
  },
  greenMatrixItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem",
    backgroundColor: green[400],
    "&:hover": {
      backgroundColor: green[300],
    },
  },
  purpleMatrixItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem",
    backgroundColor: purple[400],
    "&:hover": {
      backgroundColor: purple[300],
    },
  },
  brownMatrixItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem",
    backgroundColor: brown[400],
    "&:hover": {
      backgroundColor: brown[300],
    },
  },
  redMatrixItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem",
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[300],
    },
  },
  productItem: {
    backgroundColor: "#f3f3f3",
  },
  detailsTitle: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  detailsText: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  policyTitle: {
    width: "46%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  policyText: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  emoji: {
    fontSize: "0.5rem",
    marginRight: "0.2rem",
    bottom: "0.1rem",
    position: "relative",
  },
  productHeader: {
    padding: "1rem",
    backgroundColor: "#e3e3e3",
  },
  nonStockProgress: {
    height: "2px",
    width: "50%",
    backgroundColor: "#e3e3e3",
    "& > div": {
      backgroundColor: "red",
    },
  },
}));
