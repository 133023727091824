import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { getShipments } from "api/shipment";

import DefaultTable from "components/Table";

import { initialAPIListValue } from "utils/constants";
import { toTitleCase } from "utils/string";
import { Spacer } from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";

const headers = [
  {
    id: "shipment_number",
    render: (r) => (
      <span style={{ color: "blue", fontWeight: "bold" }}>
        {r.shipment_number}
      </span>
    ),
  },
  {
    id: "customer",
    render: (r) => toTitleCase(r.order.customer_id),
  },
];

export default function LTLPickList() {
  const history = useHistory();
  const [data, setData] = useState(initialAPIListValue);
  const [shipmentNumber, setShipmentNumber] = useState("");

  useEffect(() => {
    if (data.isFetching) {
      getShipments({
        params: {
          limit: 1000,
          is_small_carrier: false,
          status: "Open,Picking",
        },
        setFunc: setData,
        paginated: true,
      });
    }
  }, [data.isFetching]);

  const handleRowOnClick = (row) => {
    history.push(`/app/ltl/pick/${row.id}`);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h3"> Pick List (LTL) </Typography>
        </CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TextInputWithKeyboard
              size="medium"
              name="shipment_number"
              variant="outlined"
              label="Shipment Number"
              fullWidth
              autoFocus
              hasBorder
              value={shipmentNumber}
              onChange={({ target: { value } }) => setShipmentNumber(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultTable
              headers={headers}
              data={{
                count: data.count,
                isFetching: data.isFetching,
                items: data.items.filter((row) =>
                  row.shipment_number.includes(shipmentNumber),
                ),
              }}
              rowOnClick={handleRowOnClick}
            />
          </Grid>
        </Grid>
        <Spacer height="2rem" />
      </Card>
    </>
  );
}
