import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getProducts = ({
  setFunc,
  setError,
  paginated,
  responseSetter,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("products/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getProductsWithAttributes = ({
  setError,
  setFunc,
  responseSetter,
  paginated,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("product-attributes/", params),
    setError,
    setFunc,
    responseSetter,
    paginated,
  });
};

const createProductAttribute = ({ body, responseSetter }) => {
  api.create({
    endpoint: "product-attributes/",
    body,
    responseSetter,
  });
};

const createOrUpdateProductAttributeRequest = ({
  body,
  responseSetter,
  setError,
}) => {
  api.create({
    endpoint: "product-attribute-requests/",
    body,
    responseSetter,
    setError,
  });
};

const getProductAttributeRequests = ({
  setError,
  setFunc,
  paginated,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("product-attribute-requests/", params),
    setFunc,
    setError,
    paginated,
  });
};

const getProductImage = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/get-product-image/", params),
    responseSetter,
  });
};

const updateProductAttributeRequests = ({ body, responseSetter }) => {
  api.update({
    endpoint: "custom/update-product-attribute-req/",
    body,
    responseSetter,
  });
};

const getCollectionNames = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/get-product-collections/", params),
    responseSetter,
  });
};

export {
  createProductAttribute,
  createOrUpdateProductAttributeRequest,
  getCollectionNames,
  getProductImage,
  getProducts,
  getProductsWithAttributes,
  getProductAttributeRequests,
  updateProductAttributeRequests,
};
