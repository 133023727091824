import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import useStyles from "./styles";
import { X as Close, Edit, Save2 } from "icons";
import ReactApexChart from "react-apexcharts";
import {
  getDaysInMonth,
  getMonths,
  getSales,
  getForecast,
  getDate,
  getMonthlySales,
  getTotalSales,
  getTotalForecast,
  MONTH_VALUES,
} from "utils/stock";
import {
  updateForecast,
  updateProductPolicy,
  getProductPolicy,
} from "api/stock";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

const thisMonth = `${new Date().getFullYear()}-${MONTH_VALUES[getMonths()[0]]}`;

const COLORS = ["#FF4560", "#008FFB", "#00E396", "#FEB019"];

const StockItemModal = ({ open, onClose, product, t }) => {
  const classes = useStyles();
  const [activeSeries, setActiveSeries] = useState([false, false, true, true]);
  const [isEdit, setIsEdit] = useState(false);
  const [editValues, setEditValues] = useState([]);
  const [isPolicyEdit, setIsPolicyEdit] = useState(false);
  const [editPolicyValues, setEditPolicyValues] = useState({});
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [productPolicy, setProductPolicy] = useState({
    lead_time: 0,
    safety_stock: 0,
    replenishment_cycle: 0,
  });
  const [units, setUnits] = useState({
    leadTime: 0,
    safetyStock: 0,
    replenishmentCycle: 0,
  });

  useEffect(() => {
    setIsEdit(false);
    setEditValues(product ? getForecast(product.product.forecast) : []);
    if (product) {
      getProductPolicy({
        productId: product.product.id,
        setError: (_) => {
          popupNotification({
            dispatch: layoutDispatch,
            message: "An error occurred!",
          });
        },
        setFunc: (res) => {
          if (res.items && res.items.success) {
            setProductPolicy(res.items.data);
            setEditPolicyValues({
              lead_time: res.items.data.lead_time,
              safety_stock: res.items.data.safety_stock,
              replenishment_cycle: res.items.data.replenishment_cycle,
            });
            updateUnits(
              res.items.data.lead_time,
              res.items.data.safety_stock,
              res.items.data.replenishment_cycle,
              getForecast(product.product.forecast),
            );
          }
        },
      });
    }
  }, [product, layoutDispatch]);

  if (!product) return <></>;

  const getClassification = (hmlValue, abcValue) => {
    const value = abcValue + hmlValue;
    if (["AH", "AM", "BH"].includes(value)) {
      return classes.greenBadgeModal;
    } else if (["BM", "BL", "CM"].includes(value)) {
      return classes.purpleBadgeModal;
    } else if (["AL", "CH"].includes(value)) {
      return classes.brownBadgeModal;
    }
    return classes.redBadgeModal;
  };

  const handleActiveSeries = (index) => {
    const newActiveSeries = [...activeSeries];
    newActiveSeries[index] = !newActiveSeries[index];
    setActiveSeries(newActiveSeries);
  };

  const handleEditForecast = () => {
    setIsLoading(true);
    updateForecast({
      setError: (_) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        popupNotification({
          dispatch: layoutDispatch,
          message: "Successfully updated.",
          status: "success",
        });
      },
      body: {
        product_id: product.product.id,
        forecast: editValues,
      },
    });
    setIsEdit(false);
  };

  const handlePolicyUpdate = () => {
    setIsLoading(true);
    updateProductPolicy({
      setError: (_) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        popupNotification({
          dispatch: layoutDispatch,
          message: "Successfully updated.",
          status: "success",
        });
      },
      body: {
        product_id: product.product.id,
        lead_time: editPolicyValues.lead_time,
        safety_stock: editPolicyValues.safety_stock,
        replenishment_cycle: editPolicyValues.replenishment_cycle,
      },
    });
    setIsPolicyEdit(false);
  };

  const updateUnits = (leadTime, safetyStock, replenishmentCycle, forecast) => {
    const leadTimeMonths = Math.floor(leadTime / 30);
    const safetyStockMonths = Math.floor(safetyStock / 30);
    const replenishmentCycleMonths = Math.floor(replenishmentCycle / 30);
    let leadTimeUnits = 0;
    let safetyStockUnits = 0;
    let replenishmentCycleUnits = 0;
    let index = 0;
    for (let i = 0; i < leadTimeMonths; i++) {
      leadTimeUnits += forecast[index];
      index++;
    }
    for (let i = 0; i < safetyStockMonths; i++) {
      safetyStockUnits += forecast[index];
      index++;
    }
    for (let i = 0; i < replenishmentCycleMonths; i++) {
      replenishmentCycleUnits += forecast[index];
      index++;
    }
    let useLastMonth = false;
    if (leadTimeMonths + safetyStockMonths + replenishmentCycleMonths >= 12) {
      useLastMonth = true;
    }

    const dayOfMonth = Math.min(30, new Date().getDate());
    const leadMinus = Math.round((forecast[0] * dayOfMonth) / 30);
    const leadSum = Math.round((forecast[leadTimeMonths] * dayOfMonth) / 30);
    const safetySum = Math.round(
      (forecast[leadTimeMonths + safetyStockMonths] * dayOfMonth) / 30,
    );
    const replenishmentSum = Math.round(
      (forecast[
        useLastMonth
          ? 11
          : leadTimeMonths + safetyStockMonths + replenishmentCycleMonths
      ] *
        dayOfMonth) /
        30,
    );

    setUnits({
      leadTime: leadTimeUnits - leadMinus + leadSum,
      safetyStock: safetyStockUnits - leadSum + safetySum,
      replenishmentCycle:
        replenishmentCycleUnits - safetySum + replenishmentSum,
    });
  };

  const getOrderRecommendation = () => {
    const recommendation = Math.max(
      0,
      units.leadTime +
        units.safetyStock +
        units.replenishmentCycle -
        product.stock.qty_available -
        product.stock.qty_on_dock -
        product.stock.qty_on_po -
        product.stock.qty_on_ocean,
    );
    return (
      recommendation +
      " (" +
      (recommendation * product.product.area_sqrmtr).toFixed(0) +
      " m²)"
    );
  };

  return (
    <Dialog
      maxWidth="xl"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        {product.product.sku}
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Paper className={classes.productItem}>
                  <Typography
                    variant="subtitle2"
                    className={classes.productHeader}
                  >
                    <strong>Details</strong>
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Classification"
                      />
                      <ListItemText
                        className={classes.detailsTitle}
                        primary={
                          <Badge
                            color="error"
                            badgeContent={product.abc_value + product.hml_value}
                            className={getClassification(
                              product.hml_value,
                              product.abc_value,
                            )}
                          />
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Area"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={
                          product.product.area_sqrmtr
                            ? product.product.area_sqrmtr + " m²"
                            : "-"
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Category"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.category}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Collection"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.collection}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Size"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.short_size}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Created Date"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.creation_date}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Age"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.age}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Return Count"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.qty_return}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.detailsTitle}
                        secondary="Last Cost"
                      />
                      <ListItemText
                        className={classes.detailsText}
                        primary={product.product.last_cost}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.productItem}>
                  <Typography
                    variant="subtitle2"
                    className={classes.productHeader}
                  >
                    <strong>Order Recommendation</strong>
                    <div
                      style={{
                        float: "right",
                        position: "relative",
                        bottom: "0.75rem",
                      }}
                    >
                      {!isPolicyEdit ? (
                        <IconButton
                          onClick={() => {
                            setIsPolicyEdit(true);
                          }}
                        >
                          <Edit color="#555" />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton
                            disabled={isLoading}
                            onClick={handlePolicyUpdate}
                          >
                            <Save2 color="#555" />
                          </IconButton>
                          <IconButton
                            disabled={isLoading}
                            onClick={() => {
                              setIsPolicyEdit(false);
                            }}
                          >
                            <Close color="#555" />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        primary=""
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        secondary="Days"
                      />
                      <ListItemText
                        className={classes.policyText}
                        secondary="Units"
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary={
                          <>
                            <span className={classes.emoji}>🟢</span>Lead time
                          </>
                        }
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          isPolicyEdit ? (
                            <TextField
                              value={editPolicyValues.lead_time}
                              disabled={isLoading}
                              onChange={(e) => {
                                setEditPolicyValues({
                                  ...editPolicyValues,
                                  lead_time: e.target.value,
                                });
                              }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: 5,
                                  margin: 0,
                                  fontSize: "13px",
                                  textAlign: "right",
                                },
                              }}
                              style={{ float: "right", width: `80%` }}
                            />
                          ) : (
                            productPolicy.lead_time
                          )
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={units.leadTime}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary={
                          <>
                            <span className={classes.emoji}>🟤</span>Safety
                            stock
                          </>
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="+"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          isPolicyEdit ? (
                            <TextField
                              value={editPolicyValues.safety_stock}
                              disabled={isLoading}
                              onChange={(e) => {
                                setEditPolicyValues({
                                  ...editPolicyValues,
                                  safety_stock: e.target.value,
                                });
                              }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: 5,
                                  margin: 0,
                                  fontSize: "13px",
                                  textAlign: "right",
                                },
                              }}
                              style={{ float: "right", width: `80%` }}
                            />
                          ) : (
                            productPolicy.safety_stock
                          )
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={units.safetyStock}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary={
                          <>
                            <span className={classes.emoji}>🟣</span>
                            Replenishment cycle
                          </>
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="+"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          isPolicyEdit ? (
                            <TextField
                              value={editPolicyValues.replenishment_cycle}
                              disabled={isLoading}
                              onChange={(e) => {
                                setEditPolicyValues({
                                  ...editPolicyValues,
                                  replenishment_cycle: e.target.value,
                                });
                              }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: 5,
                                  margin: 0,
                                  fontSize: "13px",
                                  textAlign: "right",
                                },
                              }}
                              style={{ float: "right", width: `80%` }}
                            />
                          ) : (
                            productPolicy.replenishment_cycle
                          )
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={units.replenishmentCycle}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary="Order up to"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="="
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          productPolicy.lead_time +
                          productPolicy.safety_stock +
                          productPolicy.replenishment_cycle
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          units.leadTime +
                          units.safetyStock +
                          units.replenishmentCycle
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary="Qty Available"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="-"
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={product.stock.qty_available || 0}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary="Qty on Dock"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="-"
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={product.stock.qty_on_dock || 0}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary="Qty on PO"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="-"
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={product.stock.qty_on_po || 0}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        secondary="Qty on Ocean"
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="-"
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={product.stock.qty_on_ocean || 0}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        className={classes.policyTitle}
                        primary={
                          <span style={{ fontWeight: "bold" }}>
                            Order Recommendation
                          </span>
                        }
                      />
                      <ListItemText
                        className={classes.policyText}
                        primary="="
                      />
                      <ListItemText className={classes.policyText} primary="" />
                      <ListItemText
                        className={classes.policyText}
                        primary={
                          <span
                            style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                          >
                            {getOrderRecommendation()}
                          </span>
                        }
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.productItem}>
                  <Typography
                    variant="subtitle2"
                    className={classes.productHeader}
                  >
                    <strong>Forecast</strong>
                    <div
                      style={{
                        float: "right",
                        position: "relative",
                        bottom: "0.75rem",
                      }}
                    >
                      {!isEdit ? (
                        <IconButton
                          onClick={() => {
                            setIsEdit(true);
                          }}
                        >
                          <Edit color="#555" />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton
                            disabled={isLoading}
                            onClick={handleEditForecast}
                          >
                            <Save2 color="#555" />
                          </IconButton>
                          <IconButton
                            disabled={isLoading}
                            onClick={() => {
                              setIsEdit(false);
                            }}
                          >
                            <Close color="#555" />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText secondary="" style={{ width: "5%" }} />
                      {getMonths().map((month) => {
                        return <ListItemText secondary={month} />;
                      })}
                      <ListItemText
                        style={{ width: "5%" }}
                        primary={
                          <span style={{ fontWeight: "bold" }}>Total</span>
                        }
                      />
                    </ListItem>
                    {product.product.forecast &&
                      product.product.forecast.year_labels.map(
                        (year, index) => (
                          <ListItem>
                            <ListItemText
                              secondary={
                                <>
                                  <span style={{ fontSize: "10px" }}>
                                    {index === 3 ? "🟠 " : "🟣 "}
                                  </span>
                                  {year}
                                </>
                              }
                              style={{ width: "5%" }}
                            />
                            {getMonths().map((month, monthIndex) => {
                              return (
                                <ListItemText
                                  primary={
                                    index !== 3 ? (
                                      <span
                                        title={`In-Stock days: ${
                                          getDaysInMonth(year, month) -
                                          (product.product.stock_out_history.find(
                                            (r) =>
                                              r.date === getDate(year, month),
                                          )?.days || 0)
                                        }`}
                                      >
                                        <span>
                                          {getSales(
                                            product.product.sales_history,
                                            year,
                                            month,
                                          )}
                                        </span>
                                        <span>
                                          <LinearProgress
                                            color="secondary"
                                            variant="determinate"
                                            value={
                                              (Math.min(
                                                product.product.stock_out_history.find(
                                                  (r) =>
                                                    r.date ===
                                                    getDate(year, month),
                                                )?.days || 0,
                                                getDaysInMonth(year, month),
                                              ) /
                                                getDaysInMonth(year, month)) *
                                              100
                                            }
                                            className={classes.nonStockProgress}
                                          />
                                        </span>
                                      </span>
                                    ) : !isEdit ? (
                                      <span style={{ fontWeight: "bold" }}>
                                        {monthIndex === 0
                                          ? getMonthlySales(
                                              product.product.sales_history,
                                              thisMonth,
                                            ) + "/"
                                          : ""}
                                        {
                                          getForecast(product.product.forecast)[
                                            getMonths().indexOf(month)
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      <TextField
                                        value={
                                          editValues[getMonths().indexOf(month)]
                                        }
                                        disabled={isLoading}
                                        onChange={(e) => {
                                          const newValues = [...editValues];
                                          newValues[
                                            getMonths().indexOf(month)
                                          ] = parseInt(e.target.value);
                                          setEditValues(newValues);
                                        }}
                                        variant="outlined"
                                        inputProps={{
                                          style: {
                                            padding: 5,
                                            margin: 0,
                                            fontSize: "13px",
                                          },
                                        }}
                                        style={{ width: `70%` }}
                                      />
                                    )
                                  }
                                  style={{ width: `2%` }}
                                />
                              );
                            })}
                            <ListItemText
                              secondary={
                                <span style={{ fontWeight: "bolder" }}>
                                  {index !== 3
                                    ? getTotalSales(
                                        product.product.sales_history,
                                        year,
                                      )
                                    : getTotalForecast(
                                        product.product.forecast,
                                      )}
                                </span>
                              }
                              style={{ width: `5%` }}
                            />
                          </ListItem>
                        ),
                      )}
                  </List>
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "basic-bar",
                        zoom: {
                          enabled: false,
                        },
                        toolbar: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        categories: getMonths() || [],
                      },
                      stroke: {
                        width: [2, 2, 2, 5, 1],
                        curve: "straight",
                        dashArray: [0, 0, 0, 10, 0],
                      },
                      fill: {
                        type: [
                          "gradient",
                          "gradient",
                          "gradient",
                          "solid",
                          "solid",
                        ],
                      },
                      legend: {
                        show: false,
                      },
                      tooltip: {
                        theme: "dark",
                      },
                    }}
                    series={
                      product.product.forecast && [
                        ...product.product.forecast.year_labels.map(
                          (year, index) => ({
                            name: year,
                            data: activeSeries[index]
                              ? index !== 3
                                ? getMonths().map((month) =>
                                    getSales(
                                      product.product.sales_history,
                                      year,
                                      month,
                                    ),
                                  )
                                : getForecast(product.product.forecast)
                              : [],
                            color: COLORS[index],
                            dataLabels: {
                              enabled: true,
                            },
                            visible: false,
                          }),
                        ),
                        {
                          name: `AVG (${product.product.forecast.year_labels[2]})`,
                          data: Array(13)
                            .join(1)
                            .split("")
                            .map(
                              () =>
                                product.product.forecast.monthly_sales_average,
                            ),
                          color: "#00CC00",
                          dataLabels: {
                            enabled: true,
                          },
                          visible: true,
                        },
                      ]
                    }
                    type="line"
                    height={250}
                    width="100%"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.5rem",
                      paddingBottom: "0.5rem",
                    }}
                  >
                    {[0, 1, 2, 3].map((index) => (
                      <div
                        onClick={() => handleActiveSeries(index)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          opacity: activeSeries[index] ? 1 : 0.4,
                          cursor: "pointer",
                          width: "max-content",
                        }}
                      >
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: COLORS[index],
                            borderRadius: "50%",
                            marginRight: "3px",
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          style={{ fontSize: "12px" }}
                        >
                          {product.product.forecast.year_labels[index]}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockItemModal;
