import * as React from "react";

const SvgLocalShipping = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M3 15.5h.78c.55-.61 1.34-1 2.22-1 .88 0 1.67.39 2.22 1H15v-9H3v9z"
      fill={color}
    />
    <path
      d="M17 8.5v-4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4h-3zm-11 10c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm9-3H8.22c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H3v-9h12v9zm3 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-6V10h2.5l1.96 2.5H17z"
      fill={color}
    />
  </svg>
);

export default SvgLocalShipping;
