import * as React from "react";

const SvgSpaceBar = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 13.5H6v-4H4v6h16v-6h-2v4z" fill={color} />
  </svg>
);

export default SvgSpaceBar;
