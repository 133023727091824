import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import _ from "lodash";

import { adjustmentPublish } from "api/adjustment";

import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { ExpandMore } from "icons";

import themes from "themes";

import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import { toTitleCase } from "utils/string";

export default function AdjusmentPublishModal({
  onClose,
  adjustments,
  headers,
}) {
  const layoutDispatch = useLayoutDispatch();
  const [validAdjustments, setValidAdjustments] = useState([]);
  const [invalidAdjustments, setInvalidAdjustments] = useState([]);
  const [publish, setPublish] = useState(false);
  const [expanded, setExpanded] = useState("valid-adjustments");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const valid_adjustments = adjustments.filter(
      (a) =>
        a.product.status !== "Inactive" &&
        a.product.status !== "Marked for Deletion" &&
        !a.location.is_deleted &&
        !a.is_published,
    );
    setValidAdjustments(valid_adjustments);
    setInvalidAdjustments(_.xorBy(adjustments, valid_adjustments, "id"));
  }, [adjustments]);

  const handlePublish = () => {
    setPublish(true);
    adjustmentPublish({
      body: {
        adjustment_ids: validAdjustments
          .map((va) => va.id)
          .concat(invalidAdjustments.map((ia) => ia.id)),
      },
      responseSetter: (res) => {
        setPublish(false);
        const { success, message } = res;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return;
        }

        popupNotification({
          dispatch: layoutDispatch,
          message: "Adjustments published.",
          status: "success",
        });
        onClose(true);
      },
    });
  };

  const handleExportData = (transfers) => {
    const today = new Date();
    exportToExcel(
      [],
      `${toTitleCase(expanded)}-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(transfers, [
        {
          id: "id",
        },
        ...headers,
      ]),
    );
  };

  return (
    <Dialog
      open={true}
      onClose={!publish ? () => onClose() : null}
      aria-labelledby="adjustment-counts"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="adjustment-counts">Adjustment Publish</DialogTitle>
      <DialogContent>
        {publish ? (
          <Alert severity="info">
            <CircularProgress size={15} /> Please wait until the process is
            complete.
          </Alert>
        ) : (
          <>
            <Accordion
              expanded={expanded === "invalid-adjustments"}
              onChange={handleChange("invalid-adjustments")}
              style={{
                backgroundColor: invalidAdjustments.length
                  ? themes.default.palette.warning.main
                  : "",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="invalid-adjustment-content"
                id="invalid-adjustment-id"
              >
                <Typography variant="h4">
                  Invalid Adjustments ({invalidAdjustments.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item lg={12}>
                    <Alert severity="warning">
                      Products in <strong>Inactive</strong>,{" "}
                      <strong>Marked for Deletion</strong> statuses and{" "}
                      <strong>published</strong> adjustments cannot be publish.
                      These records will not be included in the adjustment
                      request.
                    </Alert>
                  </Grid>
                  <Grid item lg={12}>
                    <DefaultTable
                      data={{
                        items: invalidAdjustments,
                        count: invalidAdjustments.length,
                        isFetching: false,
                      }}
                      headers={headers}
                      handleExportData={() =>
                        handleExportData(invalidAdjustments)
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "valid-adjustments"}
              onChange={handleChange("valid-adjustments")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="valid-adjustments-content"
                id="valid-adjustments-id"
              >
                <Typography variant="h4">
                  Valid Adjustments ({validAdjustments.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item lg={12}>
                    <DefaultTable
                      data={{
                        items: validAdjustments,
                        count: validAdjustments.length,
                        isFetching: false,
                      }}
                      headers={headers}
                      handleExportData={() =>
                        handleExportData(validAdjustments)
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={!publish ? () => onClose() : null}
          color="default"
          disabled={publish}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={handlePublish}
          color="primary"
          disabled={
            (!validAdjustments.length && !invalidAdjustments.length) || publish
          }
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
}
