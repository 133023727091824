import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

import themes from "themes";

// components
import { Typography } from "components/Wrappers/Wrappers";
import Search from "components/Search";

import { useLayoutState } from "context/LayoutContext";

import { ArrowLeft } from "icons";

import { updateDocumentTitle } from "utils/dom";

// styles
import useStyles from "./styles";

export default function PageTitle({
  title,
  helpText,
  backLink,
  button,
  icon,
  style,
  secondaryLink = {},
  multipleButtons = false,
  useHistoryBack = false,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { isMobile } = useLayoutState();
  updateDocumentTitle(title);

  return (
    <div id="main">
      <div id="top">
        <div className={classes.pageTitleContainer} style={style}>
          <Typography className={classes.pageTitleTextContainer} variant="h4">
            {useHistoryBack ? (
              <div
                className={classes.arrowLeft}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.length > 1 ? history.goBack() : history.push(backLink)
                }
              >
                <ArrowLeft color={themes.default.palette.common.white} />
              </div>
            ) : (
              backLink && (
                <Link to={backLink} className={classes.arrowLeft}>
                  <ArrowLeft color={themes.default.palette.common.white} />
                </Link>
              )
            )}
            {icon && <div className={classes.iconContainer}>{icon}</div>}
            <span className={classes.pageTitleText}>{title}</span>
            <div className={classes.helpText}>{helpText}</div>
            {secondaryLink && secondaryLink["link"] && (
              <div style={{ marginBottom: "0.5rem" }}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    history.push(secondaryLink["link"]);
                  }}
                  startIcon={secondaryLink["icon"]}
                >
                  {secondaryLink["label"]}
                </Button>
              </div>
            )}
          </Typography>
          {isMobile && !button && (
            <span className={classes.searchButton}>
              <Search />
            </span>
          )}
        </div>
      </div>
      <div className={classes.pageTitleBorderContainer}>
        <div className={classes.buttonContainer}>
          {button &&
            React.cloneElement(button, {
              style: {
                border: multipleButtons ? "none" : "2px solid #FFF",
                backgroundColor: multipleButtons ? "transparent" : "#59817b",
              },
            })}
        </div>
      </div>
    </div>
  );
}
