import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  mobileCardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  heading: {
    color: "#223354",
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
  },
}));
