import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  getNationalCountGroups,
  updateNationalCountGroupById,
  createNationalCountGroup,
} from "api/inventory";

import { ConfirmDialog } from "components/Custom";
import {
  Checkbox,
  DatePickerGroup,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import DefaultTable from "components/Table";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";
import { Button } from "components/Wrappers/Wrappers";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { Class, FormatListNumbered, SnippetFolder } from "icons";

import { initialAPIListValue, NationalCountStatuses } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";

function ActionButtons({ id, status, setNewStatus }) {
  const [t] = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Link
          component={Button}
          to={`/app/item-counts-group/${id}`}
          variant="contained"
          color="primary"
        >
          {t("list")}
        </Link>
      </Grid>
      {status === "Created" && (
        <>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={() => setNewStatus({ id, status: "Completed" })}
            >
              {t("complete")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={() => setNewStatus({ id, status: "Cancelled" })}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

const initalCountGroupData = {
  type: "Other",
  number: "",
  note: "",
};

export default function NationalInventoryCountGroup() {
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [page, setPage] = useState(0);
  const [newStatus, setNewStatus] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [groups, setGroups] = useState(initialAPIListValue);
  const [params, setParams] = useState({
    type: "",
    number: "",
    status: "",
    start_date: "",
    end_date: "",
    hide_completed: true,
  });
  const [newCountGroup, setNewCountGroup] = useState(false);
  const [countGroupData, setCountGroupData] = useState(initalCountGroupData);
  const [exportDisabled, setExportDisabled] = useState(false);

  const headers = [
    {
      id: "number",
    },
    {
      id: "note",
    },
    {
      id: "created_by",
      render: (r) => toTitleCase(r.created_by.username),
    },
    {
      id: "created_at",
      render: (r) => timezoneFormat(r.created_at),
    },
    {
      id: "actions",
      export: false,
      render: (r) => (
        <ActionButtons
          id={r.id}
          status={r.status}
          setNewStatus={setNewStatus}
        />
      ),
    },
  ];

  useEffect(() => {
    if (groups.isFetching) {
      getNationalCountGroups({
        params: {
          ...params,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
        },
        setFunc: setGroups,
        paginated: true,
      });
    }
  }, [params, rowsPerPage, page, groups.isFetching]);

  useEffect(() => {
    if (!newCountGroup) {
      setCountGroupData(initalCountGroupData);
    }
  }, [newCountGroup]);

  const handleChangeFilterInput = (prop) => (e) => {
    setParams({ ...params, [prop]: e.target.value });
    debouncedSearchHandler();
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.checked || null });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setGroups({ ...groups, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setGroups({ ...groups, isFetching: true });
  };

  const handleSubmitCountGroupData = () => {
    createNationalCountGroup({
      body: {
        ...countGroupData,
      },
      setFunc: (res) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        setNewCountGroup(false);
        setGroups({ ...groups, isFetching: true });
      },
      setError: (e) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: e.message,
        });
      },
    });
  };

  const handleUpdateStatus = () => {
    const { id, status } = newStatus;

    updateNationalCountGroupById({
      id,
      body: {
        status,
      },
      setFunc: (data) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        setNewStatus(false);
        setGroups({ ...groups, isFetching: true });
      },
      setError: (e) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: e.message,
        });
        setNewStatus(false);
      },
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Item-Count-Groups-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, headers),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (groups.items.length === groups.count) {
      exportData(groups.items);
    } else {
      getNationalCountGroups({
        params: {
          limit: groups.count,
          ...params,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setGroups({ ...groups, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle
        title={t("item_counts")}
        button={
          <Button
            color="primary"
            variant="contained"
            onClick={() => setNewCountGroup(true)}
          >
            {t("create")}
          </Button>
        }
      />
      <Widget>
        {newCountGroup ? (
          <Dialog
            open={newCountGroup}
            onClose={() => setNewCountGroup(false)}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{t("create")}</DialogTitle>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextInput
                    fullWidth
                    autoFocus
                    label={t("number")}
                    variant="outlined"
                    value={countGroupData.number}
                    onChange={({ target: { value } }) =>
                      setCountGroupData({ ...countGroupData, number: value })
                    }
                    LeftIcon={SnippetFolder}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextInput
                    fullWidth
                    label={t("note")}
                    variant="outlined"
                    value={countGroupData.note}
                    onChange={({ target: { value } }) =>
                      setCountGroupData({ ...countGroupData, note: value })
                    }
                    LeftIcon={Class}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setNewCountGroup(false)}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmitCountGroupData}
                color="primary"
              >
                {t("confirm")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        <InputContainer>
          <Grid container>
            <DatePickerGroup
              startDateValue={params.start_date}
              startDateOnChange={handleChangeFilterInput("start_date")}
              endDateValue={params.end_date}
              endDateOnChange={handleChangeFilterInput("end_date")}
              itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
            />
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInput
                fullWidth
                variant="outlined"
                id="number"
                label={t("number")}
                value={params.number}
                onChange={handleChangeFilterInput("number")}
                InputProps={clearAdornment("number")}
                LeftIcon={FormatListNumbered}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Dropdown
                id="status"
                title={t("status")}
                items={NationalCountStatuses.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={params.status}
                onChange={handleChangeFilterInput("status")}
                LeftIcon={Class}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Checkbox
                checked={params.hide_completed}
                label={t("hide_completed")}
                onChange={handleChangeCheckbox("hide_completed")}
              />
            </Grid>
          </Grid>
        </InputContainer>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DefaultTable
              headers={headers}
              data={groups}
              page={page}
              handleChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleExportData={handleExportData}
              exportDisabled={exportDisabled}
              setExportDisabled={setExportDisabled}
              filters={params}
            />
            <ConfirmDialog
              open={newStatus}
              title="Update Count Group Status"
              onClose={() => setNewStatus(false)}
              onConfirm={handleUpdateStatus}
            >
              Are you sure want to{" "}
              {newStatus.status === "Cancelled" ? "cancel" : "complete"}?
            </ConfirmDialog>
          </Grid>
        </Grid>
      </Widget>
    </>
  );
}
