import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

import { Grid } from "@material-ui/core";
import {
  ListAlt,
  LocalShipping,
  QrCode2,
  SnippetFolder,
  SpaceBar,
  TextSnippet,
} from "icons";

import { getShipmentDetails } from "api/shipment";

import PageTitle from "components/PageTitle";
import { ProductTypeFilter } from "components/Custom";
import { Dropdown, InputContainer, TextInput } from "components/Form";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";

import { getCarrierTrackingLink } from "pages/shipments/utils";
import { initialAPIListValue, ProductClasses } from "utils/constants";
import { convertUSDate } from "utils/date";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

const headers = [
  {
    id: "shipment__shipment_number",
    label: "Shipment Number",
    render: (r) => (
      <InternalLink to={`/app/shipments/${r.shipment.id}`}>
        {r.shipment.shipment_number}
      </InternalLink>
    ),
    sortable: true,
  },
  {
    id: "shipment__order__customer_id",
    label: "Customer",
    render: (r) => r.customer,
    sortable: true,
  },
  {
    id: "tracking_number",
    render: (r) => (
      <ExternalLink
        to={getCarrierTrackingLink(r.shipment.ship_via, r.tracking_number)}
      >
        {r.tracking_number}
      </ExternalLink>
    ),
  },
  {
    id: "location__code",
    label: "Location",
    render: (r) => r.location.code,
    sortable: true,
  },
  {
    id: "product__sku",
    label: "Product",
    render: (r) => r.product.sku,
    sortable: true,
  },
  {
    id: "quantity",
    sortable: true,
  },
  {
    id: "shipment__created_date",
    label: "Date",
    render: (r) =>
      convertUSDate(new Date(r.shipment.created_date.split(" ")[0])),
    sortable: true,
  },
];

const initialParams = {
  shipment__is_small_carrier: true,
  tracking_number: "",
  location__code: "",
  product__sku: "",
  shipment__shipment_number: "",
  shipment__order__customer_id: "",
  product__class_name: "",
};

export default function SKUCheck() {
  const { related_product } = parseJwt(getWithExpiry("access"));
  const [shipmentDetails, setShipmentDetails] = useState(initialAPIListValue);
  const [params, setParams] = useState({
    product_type: related_product || 1,
    ...initialParams,
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("shipment__shipment_number");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    if (shipmentDetails.isFetching) {
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getShipmentDetails({
        params: {
          ...params,
          is_typed_on_pick: true,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
        },
        setFunc: (res) => {
          setShipmentDetails(res);
        },
        paginated: true,
      });
    }
  }, [shipmentDetails, params, page, rowsPerPage, orderBy, orderDirection]);

  const handleChangeFilterInput = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setShipmentDetails({
      items: shipmentDetails.items,
      isFetching: true,
      count: shipmentDetails.count,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShipmentDetails({ ...shipmentDetails, isFetching: true });
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setShipmentDetails((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setShipmentDetails({ ...shipmentDetails, isFetching: true });
  };

  return (
    <>
      <PageTitle title="Manually Typed SKUs" />
      <Grid container spacing={4} justifyContent="space-between"></Grid>
      <InputContainer style={{ marginTop: 20 }}>
        <Grid container>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Customer"
              variant="outlined"
              value={params.shipment__order__customer_id}
              onChange={handleChangeFilterInput("shipment__order__customer_id")}
              InputProps={clearAdornment("shipment__order__customer_id")}
              isUpperCase={true}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextInput
              fullWidth
              label="Shipment Number"
              variant="outlined"
              value={params.shipment__shipment_number}
              onChange={handleChangeFilterInput("shipment__shipment_number")}
              InputProps={clearAdornment("shipment__shipment_number")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Tracking Number"
              variant="outlined"
              value={params.tracking_number}
              onChange={handleChangeFilterInput("tracking_number")}
              InputProps={clearAdornment("tracking_number")}
              isUpperCase={true}
              LeftIcon={LocalShipping}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Location"
              variant="outlined"
              value={params.location__code}
              onChange={handleChangeFilterInput("location__code")}
              InputProps={clearAdornment("location__code")}
              isLocationCode={true}
              LeftIcon={SpaceBar}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Product SKU"
              variant="outlined"
              value={params.product__sku}
              onChange={handleChangeFilterInput("product__sku")}
              InputProps={clearAdornment("product__sku")}
              isProductSKU={true}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Dropdown
              id="class_name"
              title="Size"
              value={params.product__class_name}
              onChange={handleChangeFilterInput("product__class_name")}
              items={ProductClasses}
              LeftIcon={ListAlt}
            />
          </Grid>
          <ProductTypeFilter
            lg={2}
            md={4}
            sm={6}
            xs={12}
            value={params.product_type}
            onChange={handleChangeFilterInput("product_type")}
          />
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <DefaultTable
            headers={headers}
            data={shipmentDetails}
            filters={params}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortActions={{ onSort, orderBy, orderDirection }}
          />
        </Grid>
      </Grid>
    </>
  );
}
