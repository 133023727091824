import * as React from "react";

const SvgExpandMore = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3492 8L12 12.9467L6.65083 8L5 9.52664L12 16L19 9.52664L17.3492 8Z"
      fill={color}
    />
  </svg>
);

export default SvgExpandMore;
