import useStyle from "./styles";

export default function QuantityResult({ value, label }) {
  const classes = useStyle();

  return (
    <div>
      <span className={classes.numberValue}>{value}</span>
      <span className={classes.numberTitle}>{label}</span>
    </div>
  );
}
