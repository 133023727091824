import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { getInventories } from "api/inventory";
import { createOrderPick } from "api/movement";
import {
  createOrUpdateProductAttributeRequest,
  getProductsWithAttributes,
} from "api/products";
import {
  getAssignedShipmentDetail,
  getShipmentDetails,
  getUnassignedShipmentDetail,
} from "api/shipment";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import {
  ConfirmDialog,
  SetBuggyToLocal,
  MarkLocationAsInspected,
  MobileResetButton,
} from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { LocalShipping, QrCode2, SpaceBar } from "icons";

import { formatTrackingNumber, tracking_re } from "utils/carrier";
import {
  initialAPIListValue,
  LocationTypes,
  ProductAttributeTypes,
} from "utils/constants";
import { clearInput } from "utils/dom";
import { DAYINMILLISECONDS, getWithExpiry, setWithExpiry } from "utils/storage";
import {
  locationCodeValidation,
  productAttributeValidation,
} from "utils/validations";

const initialParams = {
  tracking_number: "",
  location_id: "",
  location_code: "",
  product_id: "",
  product_sku: "",
  quantity: 1,
  step: "tracking_number",
};

export default function Pick({ location }) {
  const initialTrackingNumber =
    location && location.state ? location.state.tracking_number : "";
  const layoutDispatch = useLayoutDispatch();
  const [t] = useTranslation();

  let buggyCode = getWithExpiry("buggyCode");
  let buggyId = getWithExpiry("buggyId");
  let skuRefValue = "";
  let skuKeyPressCount = 0;
  const [params, setParams] = useState(initialParams);
  const [buggy, setBuggy] = useState({ id: buggyId, code: buggyCode });
  const [assigned, setAssigned] = useState(initialAPIListValue);
  const [pendingDetails, setPendingDetails] = useState(initialAPIListValue);
  const [matches, setMatches] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState([]);
  const numberEl = useRef(null);
  const locationEl = useRef(null);
  const productEl = useRef(null);
  const [picked, setPicked] = useState(getWithExpiry("pickedLabels") || 0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAssignedShipmentDetail({
      responseSetter: (data) => {
        setAssigned({ items: data, isFetching: false, count: data.length });
      },
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getShipmentDetails({
        params: {
          status: "Pending_Cancellation",
        },
        responseSetter: (data) => {
          setPendingDetails({
            items: data.results,
            isFetching: false,
            count: data.count,
          });
        },
      });
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (pendingDetails.items.length === 0 || assigned.items.length === 0) {
      return;
    }
    pendingDetails.items.forEach((item) => {
      const foundDetail = assigned.items.find(
        (assignedItem) => assignedItem.detail.id === item.id,
      );
      if (foundDetail) {
        foundDetail.detail.status = "Pending_Cancellation";
      }
    });
  }, [assigned.items, pendingDetails.items]);

  useEffect(() => {
    if (!assigned.isFetching) {
      if (initialTrackingNumber) {
        handleTrackingSubmit(initialTrackingNumber);
      } else {
        if (numberEl.current) {
          numberEl.current.focus();
        }
      }
    }
  }, [assigned.isFetching, initialTrackingNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTrackingSubmit = (value) => {
    if (value.search(tracking_re) !== -1) {
      // If carrier is FEDEX we need to just last 12 char.
      let number = formatTrackingNumber(value);
      let inAssigned = false;
      const assignedButNotPicked = assigned.items.reduce((res, a) => {
        if (a.detail.tracking_number === number) {
          inAssigned = true;
          res += a.detail.quantity - a.detail.picked_quantity;
        }
        return res;
      }, 0);
      if (inAssigned) {
        if (assignedButNotPicked === 0) {
          setParams({
            ...params,
            tracking_number: "",
            step: "tracking_number",
          });
          clearInput(numberEl);
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("you_picked_the_label"),
          });
        } else {
          const isCancelled = assigned.items.find(
            (assignedItem) =>
              assignedItem.detail.tracking_number === number &&
              (assignedItem.detail.status === "Cancelled" ||
                assignedItem.detail.status === "Pending_Cancellation"),
          );
          if (isCancelled) {
            clearInput(numberEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("canceled_shipment_pick_message"),
            });
          }
          setParams({ ...params, tracking_number: number, step: "location" });
          updateMatchesByTracking(number);
          return;
        }
      }
      getUnassignedShipmentDetail({
        params: {
          tracking_number: number,
        },
        responseSetter: (data) => {
          if (data && !data.length) {
            setMatches([]);
            setParams(initialParams);
            clearInput(numberEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("invalid_tracking"),
            });
          } else if (data.length === 1) {
            const record = data[0];
            const { detail } = record;
            if (detail.status === "Picked") {
              clearInput(numberEl);
              return popupNotification({
                dispatch: layoutDispatch,
                message: t("already_picked"),
              });
            }
            if (detail.status in ["Cancelled", "Pending_Cancellation"]) {
              clearInput(numberEl);
              return popupNotification({
                dispatch: layoutDispatch,
                title: t("canceled"),
                message: t("canceled_shipment_pick_message"),
              });
            }
            if (detail.status === "Shipped") {
              clearInput(numberEl);
              return popupNotification({
                dispatch: layoutDispatch,
                message: t("already_shipped"),
              });
            }
            setMatches(data);
            setAssigned({ ...assigned, items: [...assigned.items, record] });
            setParams({ ...params, tracking_number: number, step: "location" });
          } else {
            clearInput(numberEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("shipment_not_found"),
            });
          }
        },
      });
    } else {
      clearInput(numberEl);
      return popupNotification({
        dispatch: layoutDispatch,
        message: t("invalid_tracking"),
        status: "error",
      });
    }
  };

  const handleLocationSubmit = (value) => {
    let location_code = locationCodeValidation(value.trim());
    if (!location_code) {
      location_code = value.trim();
    }
    const assignedLabel = matches.find((item) => {
      return item.detail.location_code === location_code;
    });
    if (assignedLabel) {
      setParams({
        ...params,
        location_id: assignedLabel.detail.location_id,
        location_code,
        step: "product",
      });
      updateMatchesByLocation(assignedLabel.detail.location_id);
      return;
    } else {
      setParams({
        ...params,
        location_id: "",
        location_code: "",
      });
      clearInput(locationEl);
      return popupNotification({
        dispatch: layoutDispatch,
        message: t("wrong_location"),
        status: "error",
      });
    }
  };

  const handleAlternateLocationSubmit = (value) => {
    let location_code = locationCodeValidation(value.trim());
    if (!location_code) {
      location_code = value.trim();
    }
    const matchedLocation = locationList.find((l) => l.code === location_code);
    if (!matchedLocation) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Location code not found in alternate locations.",
      });
      locationEl.current.value = "";
      return;
    }
    setParams({
      ...params,
      location_id: matchedLocation.id,
      location_code: matchedLocation.code,
      product_id: "",
      product_sku: "",
      step: "product",
    });
    setLocationList([]);
    setTimeout(() => {
      clearInput(productEl);
    }, 1000);
  };

  const handleProductSubmit = (value) => {
    setIsLoading(true);
    const formatted_value = productAttributeValidation(value);
    const attribute = formatted_value ? formatted_value : value;
    const assignedLabel = matches.find((item) => {
      return (
        item.detail.product_sku === attribute ||
        item.detail.product_sku === attribute.split(",")[0]
      );
    });
    if (assignedLabel) {
      setParams((prev) => ({
        ...prev,
        product_id: assignedLabel.detail.product_id,
        product_sku: attribute,
        step: "quantity",
        quantity: 1,
        ready: true,
      }));
      validateAndSubmit();
      return;
    }
    if (matches.length > 1) {
      setParams(initialParams);
      clearInput(productEl);
      clearInput(numberEl);
      clearInput(locationEl);
      return popupNotification({
        dispatch: layoutDispatch,
        message: t("duplicate_tracking"),
        status: "error",
      });
    }
    getProductsWithAttributes({
      params: {
        value: attribute,
      },
      responseSetter: (data) => {
        const { results } = data;
        if (!results.length) {
          clearInput(productEl);
          popupNotification({
            dispatch: layoutDispatch,
            message: t("product_not_found"),
          });
          if (matches[0].detail) {
            if (!formatted_value) {
              return;
            }
            createOrUpdateProductAttributeRequest({
              body: {
                value: formatted_value,
                product_id: matches[0].detail.product_id,
                code_type: getProductAttributeType(value),
              },
              responseSetter: (res) => {
                const { success, message } = res;
                if (!success) {
                  popupNotification({
                    dispatch: layoutDispatch,
                    message,
                  });
                }
              },
            });
          }
        } else {
          const label = matches[0];
          const products = data.results.filter((attribute) => {
            if (label.detail.product_sku === attribute.product_sku) {
              return true;
            }
            return false;
          });
          if (products.length === 0) {
            clearInput(productEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("wrong_product"),
              status: "error",
            });
          } else {
            const attribute = products[0];
            setParams((prev) => ({
              ...prev,
              product_id: attribute.product,
              product_sku: attribute.product_sku,
              serial_number: attribute.code_type === 3 ? attribute.value : "",
              quantity: 1,
            }));
            validateAndSubmit();
          }
        }
      },
    });
  };

  const searchAlternativeLocations = () => {
    let location_type = LocationTypes[0];
    const selectedDetail = matches.length ? matches[0].detail : null;
    if (!selectedDetail) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Invalid Operation",
      });
    }
    if (matches.length) {
      location_type = LocationTypes.find(
        (location) => location.label === selectedDetail.product_type_display,
      );
    }
    getInventories({
      params: {
        product_sku_iexact: selectedDetail.product_sku,
        location__location_type: location_type.value,
        limit: 100,
      },
      responseSetter: (data) => {
        if (!data.results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("no_results_found"),
            status: "error",
          });
        } else if (data.results.length === 1) {
          const { location } = data.results[0];
          setParams((prev) => ({
            ...prev,
            location_id: location.id,
            location_code: location.code,
          }));
          productEl.current.focus();
        } else {
          const otherLocations = data.results.filter(
            (i) => i.location.code !== params.location_code,
          );
          if (!otherLocations.length) {
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("no_results_found"),
              status: "error",
            });
          }
          const locations = otherLocations
            .map((item) => ({
              ...item.location,
              qty: item.qty,
            }))
            .sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 0));
          setLocationList(_.uniqBy(locations, "code"));
          locationEl.current.focus();
        }
      },
    });
  };

  const updateMatchesByTracking = (number) => {
    const matchesByNumber = assigned.items.filter(
      (a) =>
        a.detail.tracking_number === number &&
        a.detail.picked_quantity < a.detail.quantity,
    );
    setMatches(matchesByNumber);
  };

  const updateMatchesByLocation = (location_id) => {
    const filteredMatches = matches.filter(
      (m) =>
        m.detail.location_id === location_id &&
        m.detail.picked_quantity < m.detail.quantity,
    );
    setMatches(filteredMatches);
  };

  const clearForm = () => {
    skuRefValue = "";
    skuKeyPressCount = 0;
    setMatches([]);
    setLocationList([]);
    setParams(initialParams);
    setConfirmDialog([]);
    if (numberEl.current) numberEl.current.focus();
  };

  const validateAndSubmit = () => {
    const messages = [],
      errors = [];
    if (matches.length === 0) {
      errors.push(t("no_results_found"));
    } else if (matches.length === 1) {
      const { detail } = matches[0];
      if (detail.status === "Picked") {
        errors.push(t("already_picked"));
      }
      if (detail.status === "Cancelled") {
        errors.push(t("canceled_shipment_pick_message"));
      }
      if (detail.status === "Shipped") {
        errors.push(t("already_shipped"));
      }
      if (params.location_id !== detail.location_id) {
        messages.push(
          `Location changed. ${detail.location_code} to ${params.location_code}`,
        );
      }
      const remainingQty =
        Number(detail.quantity) - Number(detail.picked_quantity);
      if (Number(params.quantity) < remainingQty) {
        popupNotification({
          dispatch: layoutDispatch,
          message: `${t("please_pick_the_next_item")} ${detail.product_sku}`,
          status: "success",
        });
      }
    }
    if (errors.length) {
      setIsLoading(false);
      return popupNotification({
        dispatch: layoutDispatch,
        message: errors.join("\n"),
        status: "error",
      });
    }
    if (messages.length) {
      setIsLoading(false);
      setConfirmDialog(messages);
      return;
    }

    submitPick();
  };

  const submitPick = () => {
    setConfirmDialog([]);

    if (!buggyCode) {
      setIsLoading(false);
      return popupNotification({
        dispatch: layoutDispatch,
        message: t("please_select_a_buggy"),
      });
    }
    if (matches.length === 0) {
      setIsLoading(false);
      return popupNotification({
        dispatch: layoutDispatch,
        message: t("no_results_found"),
        status: "error",
      });
    }

    const { detail } = matches[0];
    const data = {
      detail_id: detail.id,
      quantity: Number(params.quantity),
      location_id: params.location_id,
      product_id: params.product_id,
      buggy_id: buggyId,
      serial_number: params.serial_number,
    };

    if (skuKeyPressCount > 5) {
      data.is_typed_on_pick = true;
    }

    createOrderPick({
      body: data,
      responseSetter: ({ success, message }) => {
        setIsLoading(false);
        if (!success) {
          if (message === "Product not found in the location!") {
            popupNotification({
              dispatch: layoutDispatch,
              message: t("product_not_found_in_location"),
              status: "error",
            });
          }
          clearForm();
          return popupNotification({
            dispatch: layoutDispatch,
            message,
            status: "error",
          });
        }
        setPicked(picked + data.quantity);
        setWithExpiry(
          "pickedLabels",
          picked + data.quantity,
          DAYINMILLISECONDS,
        );
        clearForm();
        setAssigned({
          ...assigned,
          items: assigned.items.map((a) => {
            if (a.detail.id === detail.id) {
              a.detail.picked_quantity = detail.picked_quantity + data.quantity;
            }
            return a;
          }),
        });

        popupNotification({
          dispatch: layoutDispatch,
          message: `${t("pick")} ${t("successful")}`,
          status: "success",
        });
      },
    });
  };

  const getProductAttributeType = (value) => {
    const UPC = ProductAttributeTypes[0].value;
    const SKU = ProductAttributeTypes[1].value;
    const OTHERS = ProductAttributeTypes[6].value;

    const valueToNumber = Number(value);
    if (Number.isInteger(valueToNumber)) {
      if (value.length === 12 || value.length === 13) {
        return UPC;
      } else {
        return OTHERS;
      }
    }

    return SKU;
  };

  return (
    <>
      <PageTitle title={t("pick")} helpText="Ecommerce" />
      <Grid container spacing={4}>
        {assigned.isFetching || isLoading ? (
          <CircularProgress size={150} />
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SetBuggyToLocal
                  // until we develop a end of day clearance system, we just use unpicked shipments as total value here
                  // we are also showing item count on buggy to avoid confusion
                  picked={picked || 0}
                  total={
                    assigned.items.reduce((acc, item) => {
                      acc += item.detail.quantity - item.detail.picked_quantity;
                      return acc;
                    }, 0) + picked
                  }
                  onAssign={setBuggy}
                />
              </Grid>
            </Grid>
            {buggy.id && (
              <Widget>
                <Grid container spacing={0}>
                  {params.step === "tracking_number" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextInputWithKeyboard
                        fullWidth
                        autoFocus
                        inputRef={numberEl}
                        id="number"
                        variant="outlined"
                        label={t("tracking_number")}
                        onKeyPress={({ target, key }) => {
                          if (key === "Enter")
                            handleTrackingSubmit(target.value);
                        }}
                        LeftIcon={LocalShipping}
                        hasBorder
                      />
                    </Grid>
                  )}
                  {params.tracking_number && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1">
                        {t("tracking_number")}:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                          {params.tracking_number}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {params.step === "location" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <LocationCodeTextInput
                        placeholder={
                          matches.length ? matches[0].detail.location_code : ""
                        }
                        handleSubmit={handleLocationSubmit}
                        locationEl={locationEl}
                      />
                    </Grid>
                  )}
                  {params.location_code && (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item lg={12} xs={12}>
                        <MarkLocationAsInspected
                          location_id={params.location_id}
                          location_code={params.location_code}
                          afterConfirm={searchAlternativeLocations}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {locationList.length ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <List>
                          {locationList.slice(0, 3).map((location) => (
                            <ListItem key={location.id}>
                              <ListItemText
                                primary={`${location.code} (${location.qty})`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <LocationCodeTextInput
                          locationEl={locationEl}
                          handleSubmit={handleAlternateLocationSubmit}
                          placeholder={"Please scan location code"}
                        />
                      </Grid>
                    </>
                  ) : null}
                  {params.step === "product" && !locationList.length && (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextInputWithKeyboard
                          autoFocus
                          fullWidth
                          inputRef={productEl}
                          id="product_sku"
                          variant="outlined"
                          label={t("product")}
                          placeholder={
                            matches.length ? matches[0].detail.product_sku : ""
                          }
                          onKeyPress={({ target, key }) => {
                            if (target.value.length > skuRefValue.length) {
                              skuKeyPressCount += 1;
                            }
                            skuRefValue = target.value;
                            if (key === "Enter") {
                              handleProductSubmit(target.value);
                            }
                          }}
                          LeftIcon={QrCode2}
                          hasBorder
                        />
                      </Grid>
                      {params.product_sku && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={8}>
                              <Typography variant="subtitle1">
                                {t("product_sku")}:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {params.product_sku}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <ConfirmDialog
                  open={!!confirmDialog.length}
                  onClose={() => setConfirmDialog([])}
                  onConfirm={submitPick}
                >
                  {confirmDialog.map((message, index) => (
                    <Alert key={index} severity="info">
                      {message}
                    </Alert>
                  ))}
                </ConfirmDialog>
              </Widget>
            )}
          </Grid>
        )}
      </Grid>
      <MobileResetButton onClick={clearForm} />
    </>
  );
}

function LocationCodeTextInput({ placeholder, handleSubmit, locationEl }) {
  const [t] = useTranslation();
  return (
    <TextInputWithKeyboard
      fullWidth
      autoFocus
      inputRef={locationEl}
      id="location_code"
      variant="outlined"
      label={t("location")}
      placeholder={placeholder}
      onKeyPress={({ target, key }) => {
        if (key === "Enter") handleSubmit(target.value);
      }}
      LeftIcon={SpaceBar}
      hasBorder
    />
  );
}
