import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@material-ui/core";

import {
  createProductAttribute,
  getProductsWithAttributes,
} from "api/products";

import { TextInput } from "components/Form";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { TextSnippet, Save2 as Save } from "icons";
import { initialAPIListValue } from "utils/constants";

import themes from "themes";

const ProductAttributesDialog = ({ product, open, onClose }) => {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [productAttributes, setProductAttributes] = useState(
    initialAPIListValue,
  );
  const [value, setValue] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  const cells = [{ id: "value" }];

  useEffect(() => {
    if (productAttributes.isFetching) {
      const params = { product: product.id };
      getProductsWithAttributes({
        params,
        setFunc: setProductAttributes,
        paginated: true,
      });
    }
  }, [productAttributes, product]);

  const handleCreate = () => {
    if (!product.id || !value) {
      popupNotification({
        dispatch: layoutDispatch,
        message: t("value_and_type_must_be_filled"),
      });
      return;
    }
    if (
      productAttributes.items.some(
        (item) => item.value.toLowerCase() === value.toLowerCase().trim(),
      )
    ) {
      popupNotification({
        dispatch: layoutDispatch,
        message: `${t("attribute_already_exists")}: ${value}`,
      });
      return;
    }
    createProductAttribute({
      body: { value: value.trim(), product: product.id },
      responseSetter: (data) => {
        const { success, message } = data;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        setProductAttributes(initialAPIListValue);
        setValue("");
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attributes-dialog"
      fullWidth
    >
      <DialogTitle id="attributes-dialog">{product.sku}</DialogTitle>
      <DialogContent>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <DefaultTable
                  headers={cells}
                  data={{
                    ...productAttributes,
                    items: productAttributes.items.filter(
                      (item) => item.value !== product.sku,
                    ),
                  }}
                  exportDisabled={exportDisabled}
                  setExportDisabled={setExportDisabled}
                  headerVisible={false}
                  footerVisible={false}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Tooltip
                  title={t("fill_below_fields_then_press_save_icon")}
                  placement={"top"}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextInput
                        id="value"
                        fullWidth
                        label={t("value")}
                        variant="outlined"
                        value={value}
                        onChange={({ target: { value } }) => setValue(value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleCreate();
                        }}
                        LeftIcon={TextSnippet}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        size={"large"}
                        onClick={handleCreate}
                        color="primary"
                        disabled={!value}
                      >
                        <Save color={themes.default.palette.common.white} />
                      </Button>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size={"small"} onClick={onClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductAttributesDialog;
