import React from "react";
import { ArrowUpward, ArrowDownward } from "icons";

export default function TwoWayArrow({ style }) {
  return (
    <div style={{ ...style, width: "1.6rem" }}>
      <ArrowUpward
        style={{
          marginBottom: "4px",
          width: "1rem",
          height: "1.2rem",
        }}
        color="#909090"
      />
      <ArrowDownward
        style={{
          marginLeft: "-7px",
          marginTop: "4px",
          width: "1rem",
          height: "1.2rem",
        }}
        color="#909090"
      />
    </div>
  );
}
