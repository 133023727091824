import * as React from "react";

const SvgUnassignedShipments = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M18 3a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Zm0 2h-2v5a1 1 0 0 1-1.458.89l-.097-.058L12 9.202l-2.445 1.63a1 1 0 0 1-1.55-.72L8 10V5H6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Zm-4 0h-4v3.13l1.445-.962a1 1 0 0 1 .994-.066l.116.066L14 8.13V5Z"
        fill={color}
        fillRule="nonzero"
      />
      <g transform="translate(10 10)">
        <circle fill="#E11D48" cx={7} cy={7} r={7} />
        <path
          d="M6.408 3.99a.5.5 0 0 0 .492.41h1a.5.5 0 0 0 .5-.5l-.008-.09A.5.5 0 0 0 7.9 3.4h-1l-.09.008a.5.5 0 0 0-.41.492Zm-.922.41H4.9l-.09.008a.5.5 0 0 0-.41.492v6l.008.09a.5.5 0 0 0 .492.41h5a.5.5 0 0 0 .5-.5v-6l-.008-.09A.5.5 0 0 0 9.9 4.4h-.586a1.5 1.5 0 0 1-1.414 1h-1l-.144-.007a1.501 1.501 0 0 1-1.27-.992Zm2.414-2a1.5 1.5 0 0 1 1.415 1H9.9l.144.007A1.5 1.5 0 0 1 11.4 4.9v6l-.007.144A1.5 1.5 0 0 1 9.9 12.4h-5l-.144-.007A1.5 1.5 0 0 1 3.4 10.9v-6l.007-.144A1.5 1.5 0 0 1 4.9 3.4h.585a1.5 1.5 0 0 1 1.415-1Z"
          fill="#F8FAFC"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default SvgUnassignedShipments;
