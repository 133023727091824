import * as React from "react";

const SvgLogin = ({ color, size }) => (
  <svg
    viewBox="0 0 18 18"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 5.25 7.2 6.3l1.95 1.95H1.5v1.5h7.65L7.2 11.7l1.05 1.05L12 9 8.25 5.25zm6.75 9H9v1.5h6c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5H9v1.5h6v10.5z"
      fill={color}
    />
  </svg>
);

export default SvgLogin;
