import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { receiptAvailableDocks } from "api/receipt";
import { Button, Checkbox, DatePickerGroup, Dropdown } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";
import { Class, Style, TextSnippet, ViewGrid } from "icons";
import { useTranslation } from "react-i18next";

const headers = [
  {
    id: "code",
  },
];

export default function ReceiptFilter({
  handleChangeCheckbox,
  handleChangeFilterInput,
  params,
  clearAdornment,
}) {
  const { t } = useTranslation();
  const [availableDocks, setAvailableDocks] = useState([]);
  const [dockModalOpen, setDockModalOpen] = useState(false);

  useEffect(() => {
    receiptAvailableDocks({
      responseSetter: (response) => {
        setAvailableDocks(response.data);
      },
    });
  }, []);

  return (
    <Grid container>
      <DatePickerGroup
        startDateValue={params.start_date}
        startDateOnChange={handleChangeFilterInput("start_date")}
        endDateValue={params.end_date}
        endDateOnChange={handleChangeFilterInput("end_date")}
        itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
      />
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="number"
          label="Number"
          variant="outlined"
          value={params.number}
          onChange={handleChangeFilterInput("number")}
          InputProps={clearAdornment("number")}
          LeftIcon={TextSnippet}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="container"
          label="Container"
          variant="outlined"
          value={params.container}
          onChange={handleChangeFilterInput("container")}
          InputProps={clearAdornment("container")}
          LeftIcon={Style}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <FormattedTextField
          fullWidth
          id="dock_id"
          label="Dock"
          variant="outlined"
          value={params.dock_id}
          onChange={handleChangeFilterInput("dock_id")}
          InputProps={clearAdornment("dock_id")}
          LeftIcon={ViewGrid}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Dropdown
          id="status"
          title="Status"
          value={params.status}
          onChange={handleChangeFilterInput("status")}
          items={["Open", "In_Progress", "Completed"].map((status) => ({
            value: status,
            label: status.split("_").join(" "),
          }))}
          LeftIcon={Class}
        />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <Checkbox
          checked={params.hide_completed}
          label={t("hide_completed")}
          onChange={handleChangeCheckbox("hide_completed")}
        />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <Button
          color="primary"
          onClick={() => {
            setDockModalOpen(true);
          }}
        >
          {t("show_available_docks")}
        </Button>
      </Grid>
      {dockModalOpen && (
        <Dialog
          open={true}
          onClose={() => setDockModalOpen(false)}
          aria-labelledby="xref-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="xref-dialog">
            <Grid container>
              <Grid item xs={9}>
                {t("receipt_available_docks")}
              </Grid>
              <Grid item xs={3}>
                <Button color="primary" onClick={() => setDockModalOpen(false)}>
                  {t("close")}
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DefaultTable
              headers={headers}
              data={{
                count: availableDocks.length,
                items: availableDocks,
                isFetching: false,
              }}
              headerVisible={false}
              footerVisible={false}
              style={{ maxHeight: "none" }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
}
