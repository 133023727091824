import * as React from "react";

const WMSLogo = ({ colors }) => (
  <>
    <svg
      width="84"
      height="22"
      viewBox="0 0 84 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#a)">
        <path
          transform="translate(55,0)"
          d="M6.8 7.322c0 .547.362.99 1.086 1.333.725.341 1.606.642 2.645.902s2.077.587 3.116.983c1.039.397 1.92 1.06 2.645 1.989.724.93 1.086 2.091 1.086 3.485 0 2.105-.786 3.724-2.357 4.859C13.449 22.007 11.303 22 8.952 22H.527l-.14-5.03h8.791c1.859 0 2.788-.319 2.788-.956 0-.547-.362-.99-1.086-1.332-.725-.342-1.606-.65-2.645-.923a27.65 27.65 0 0 1-3.116-1.025 6.397 6.397 0 0 1-2.645-1.947c-.724-.889-1.086-2.003-1.086-3.342 0-2.023.745-3.615 2.235-4.777C5.113 1.507 6.964.926 9.178.926c1.667 0 3.184.376 4.551 1.127 1.367.752 2.446 1.825 3.239 3.219l-4.51 2.46c-.656-1.394-1.75-2.091-3.28-2.091-.683 0-1.25.15-1.701.451-.451.3-.677.71-.677 1.23z"
          fill={colors[2]}
          fillRule="nonzero"
        />
        <path
          d="M25.662.926c2.35 0 4.23.765 5.638 2.296 1.407 1.53 2.111 3.58 2.111 6.15V22h-5.289V9.741c0-1.23-.3-2.187-.902-2.87-.601-.683-1.449-1.025-2.542-1.025-1.203 0-2.139.396-2.808 1.189-.67.793-1.005 1.94-1.005 3.444V22h-5.289V9.741c0-1.23-.3-2.187-.902-2.87-.601-.683-1.449-1.025-2.542-1.025-1.175 0-2.112.396-2.809 1.189-.697.793-1.045 1.94-1.045 3.444V22H.123L2.989 1.5h5.289v2.173c1.23-1.831 3.13-2.747 5.699-2.747 2.515 0 4.373.984 5.576 2.952C20.92 1.91 22.956.926 25.662.926z"
          transform="translate(23,0)"
          fill={colors[1]}
          fillRule="nonzero"
        />
        <path
          d="M25.502.5h5.617L24.6 21h-5.043L15.785 8.577 12.013 21H6.97L.451.5h5.617l3.485 12.587L13.284.5h5.002l3.731 12.628z"
          transform="translate(0,1)"
          fill={colors[0]}
          fillRule="nonzero"
        />
      </g>

      <defs>
        <filter
          id="a"
          x={0}
          y={0}
          width={84}
          height={22}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={15} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.575069 0 0 0 0 0.704167 0 0 0 0 0.684606 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_194_28089"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_194_28089"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </>
);

export default WMSLogo;
