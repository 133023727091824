import * as React from "react";

const SvgArrowDownward = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 16 20"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7584 10.4234L13.8468 10.335L13.7584 10.2466L12.8181 9.30632L12.7298 9.21801L12.6414 9.30624L9.12687 12.8145L9.12687 5L9.12687 4.875L9.00187 4.875L7.66812 4.875L7.54312 4.875L7.54312 5L7.54312 12.8137L4.03543 9.29973L3.94704 9.21118L3.85857 9.29965L2.91161 10.2466L2.82322 10.335L2.91161 10.4234L8.24661 15.7584L8.335 15.8468L8.42339 15.7584L13.7584 10.4234Z"
      fill={color}
    />
  </svg>
);

export default SvgArrowDownward;
