import React, { useEffect, useState } from "react";
import { generateAWSURL, getSignedMediaURL } from "utils/image";

const title = "tayse-logo";

export default function WMSImageLogo({ className, dark = false }) {
  const [src, setSrc] = useState("");

  useEffect(() => {
    const url = generateAWSURL(`static/tayse-${dark ? "dark" : "light"}.png`);
    setSrc(getSignedMediaURL(url, title));
  }, [dark]);

  return <img src={src} alt={title} className={className} />;
}
