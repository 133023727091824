import { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import { cycleCountByFile, getInventorySnapshots } from "api/inventory";

import { StatusLabel } from "components/Custom";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { CloudUpload, Download, SpaceBar } from "icons";

import { timezoneFormat } from "utils/date";
import getClearAdornment from "utils/form";

import * as XLSX from "xlsx";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import { exportToExcel } from "utils/exportToExcel";

export default function SnapshotDetail({ open, onClose, snapshot }) {
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const [locationSku, setLocationSku] = useState({});
  const [inventory, setInventory] = useState({});
  const [count, setCount] = useState({});
  const [differences, setDifferences] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [params, setParams] = useState({
    location_code: "",
  });
  const [fetching, setIsFetching] = useState(false);
  const hasPermission = permissions.includes("warehouse.add_inventorysnapshot");

  const diffHeaders = [
    {
      id: "location_code",
      label: "Location",
    },
    {
      id: "sku",
      label: "SKU",
      render: (r) =>
        locationSku[r.location_code]
          ? locationSku[r.location_code].join()
          : "-",
    },
    {
      id: "count_qty",
      label: "Count Qty.",
    },
    {
      id: "inv_qty",
      label: "Inventory Qty.",
    },
    {
      id: "diff",
      label: "Difference",
      render: (r) => (
        <StatusLabel
          text={r.diff.toString()}
          status={r.diff === 0 ? "-" : r.diff > 0 ? "Positive" : "Default"}
        />
      ),
    },
  ];

  useEffect(() => {
    if (snapshot.id) {
      getInventorySnapshots({
        params: {
          id: snapshot.id,
          with_details: true,
        },
        responseSetter: (res) => {
          const { results } = res;
          const inventory_data = results[0].inventory_data;
          const count_data = results[0].count_data;
          let data = {};
          let skuData = {};
          if (inventory_data) {
            Object.entries(inventory_data).forEach(([key, qty]) => {
              const [location_code, sku] = key.split("|");
              if (data[location_code]) {
                data[location_code] += qty;
              } else {
                data[location_code] = qty;
              }

              if (skuData[location_code]) {
                skuData[location_code].push(sku);
              } else {
                skuData[location_code] = [sku];
              }
            });
            setInventory(data);
            setLocationSku(skuData);
          }
          if (count_data) {
            setCount(count_data);
            calculateDiffs(data, count_data);
          }
        },
      });
    }
  }, [snapshot.id]);

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    return array;
  };

  const handleFileChange = (e) => {
    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let values = [];
      const extension = f.name.split(".").pop();
      if (extension === "csv") {
        values = csvFileToArray(data);
      } else {
        let readedData = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "yyyy-mm-dd",
        });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        values = XLSX.utils.sheet_to_json(ws, {
          raw: false,
        });
      }

      /* Convert array to json*/
      values = values.filter(
        (v) => v["Location"] && v["CVC"] && v["Status"] === "approved",
      );

      if (!values.length) {
        popupNotification({
          dispatch: layoutDispatch,
          message:
            "Please add Location, CVC and Status columns in your excel file",
        });
        return;
      }
      let count_data = {};
      values.forEach((r) => (count_data[r["Location"]] = r["CVC"]));
      setUploadedFile(f);
      setCount(count_data);
      calculateDiffs(inventory, count_data, count, true);
    };
    reader.readAsBinaryString(f);
    e.target.value = "";
  };

  const calculateDiffs = (
    inventory_data,
    incoming_count,
    count,
    from_file = false,
  ) => {
    let counts = {};
    const diffs = [];
    if (from_file) {
      const newLocations = Object.keys(incoming_count).filter(
        (key) => !Object.keys(count).includes(key),
      );
      newLocations.forEach((nl) => {
        counts[nl] = incoming_count[nl];
      });
    } else {
      counts = incoming_count;
    }
    Object.entries(counts).forEach(([location_code, qty]) => {
      const inv_qty = inventory_data[location_code];
      if (!isNaN(inv_qty)) {
        diffs.push({
          location_code: location_code,
          count_qty: qty,
          inv_qty: inv_qty,
          diff: qty - inv_qty,
          from_file,
        });
      }
    });
    setDifferences(_.orderBy(diffs, (r) => Math.abs(r.diff), "desc"));
  };

  const clearHandler = (input) => {
    setParams((prev) => ({ ...prev, [input]: "" }));
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  const handleSubmit = () => {
    setIsFetching(true);
    cycleCountByFile({
      body: {
        snapshot_id: snapshot.id,
        counts: count,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        onClose(true);
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleDownloadInventoryData = () => {
    exportToExcel(
      [],
      `Inventory Snapshot - ${timezoneFormat(snapshot.created_at)}`,
      true,
      Object.entries(inventory).map(([location, qty]) => ({
        Location: location,
        Qty: qty,
      })),
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => (!fetching ? onClose(false) : null)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Inventory Snapshot - ({timezoneFormat(snapshot.created_at)})
      </DialogTitle>
      {fetching ? (
        <CircularProgress />
      ) : (
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={9}>
              <Alert severity="info">
                Please don't forget to publish transfers after submitted.
              </Alert>
            </Grid>

            <Grid item lg={3}>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                component="span"
                onClick={handleDownloadInventoryData}
              >
                <span>
                  <Download color="#fff" />
                </span>
                Inventory Data
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={3}>
              <LocationFilter
                location_code={params.location_code}
                handleChangeFilterInput={handleChangeFilterInput}
                clearFilter={clearAdornment}
              />
            </Grid>
            <Grid item xs={3}>
              {!uploadedFile ? (
                <label htmlFor="file_picker">
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                    style={{ display: "none" }}
                    id="file_picker"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component="span"
                  >
                    <span style={{ paddingRight: "2px" }}>
                      <CloudUpload color="#fff" size={0.8} />
                    </span>
                    Upload Count File
                  </Button>
                </label>
              ) : null}
            </Grid>
          </Grid>
          <Grid container>
            {Object.keys(inventory).length && Object.keys(count).length ? (
              <DefaultTable
                headers={diffHeaders}
                data={{
                  items: differences.filter((r) =>
                    r.location_code.includes(params.location_code),
                  ),
                }}
              />
            ) : null}
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          variant="contained"
          color="secondary"
          disabled={fetching}
        >
          Close
        </Button>
        {differences.filter((d) => d.from_file).length && hasPermission ? (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={fetching}
          >
            Submit
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

function LocationFilter({
  location_code,
  handleChangeFilterInput,
  clearFilter,
}) {
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <FormattedTextField
          fullWidth
          id="location"
          label="Location"
          variant="outlined"
          value={location_code}
          onChange={handleChangeFilterInput("location_code")}
          InputProps={clearFilter("location_code")}
          LeftIcon={SpaceBar}
          isLocationCode
        />
      </Grid>
    </Grid>
  );
}
