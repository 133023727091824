import * as React from "react";

const SvgEye = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M15 12.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
      <path d="M2 12c1.365-4.057 5.426-7 10.224-7 4.797 0 8.858 2.943 10.224 7-1.366 4.057-5.427 7-10.224 7C7.426 19 3.365 16.057 2 12Z" />
    </g>
  </svg>
);

export default SvgEye;
