import { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { getAdjustmentCycleCountsById } from "api/adjustment";

import { StatusLabel } from "components/Custom";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { initialAPIListValue } from "utils/constants";
import { timezoneFormat } from "utils/date";
import { toTitleCase } from "utils/string";

const headers = [
  {
    id: "location",
  },
  {
    id: "product_sku",
  },
  {
    id: "count",
  },
  {
    id: "old_count",
  },
  {
    id: "difference",
  },
  {
    id: "created_at",
    render: (r) => (r.created_at ? timezoneFormat(r.created_at) : "-"),
  },
  {
    id: "created_by",
    render: (r) => toTitleCase(r.created_by),
  },
  {
    id: "status",
    render: (r) => <StatusLabel status={toTitleCase(r.status)} />,
  },
];

export default function AdjustmentCountsModal({ onClose, adjustmentId }) {
  const layoutDispatch = useLayoutDispatch();
  const [counts, setCounts] = useState(initialAPIListValue);

  useEffect(() => {
    if (adjustmentId) {
      getAdjustmentCycleCountsById({
        id: adjustmentId,
        responseSetter: (res) => {
          const { counts, message, success } = res;
          if (!success) {
            popupNotification({
              dispatch: layoutDispatch,
              message,
            });
            setCounts((prev) => ({ ...prev, isFetching: false }));
            return;
          }

          setCounts({
            items: counts,
            count: counts.length,
            isFetching: false,
          });
        },
      });
    }
  }, [adjustmentId, layoutDispatch]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="adjustment-counts"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="adjustment-counts">Adjustment Cycle Counts</DialogTitle>
      <DialogContent>
        {counts.isFetching ? (
          <CircularProgress size={30} />
        ) : (
          <DefaultTable data={counts} headers={headers} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
