import * as React from "react";

const SvgAssignmentReturn = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M5 5.5v14h14v-14H5zm11 9h-4v3l-5-5 5-5v3h4v4z"
      fill={color}
    />
    <path
      d="m12 7.5-5 5 5 5v-3h4v-4h-4v-3zm7-4h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.24-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zm7 16.25H5v-14h14v14z"
      fill={color}
    />
  </svg>
);

export default SvgAssignmentReturn;
