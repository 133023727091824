import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { getCollectionNames } from "api/products";
import { createContainerRecord, updateContainerRecord } from "api/purchase";
import { DatePicker, TextInput } from "components/Form";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import getClearAdornment from "utils/form";

import {
  X as Close,
  MergeType,
  Receipt,
  SnippetFolder,
  FolderSpecial,
  LocationMarker,
  ReceiptLong,
  AssistantPhoto,
  ClipboardList,
  Style,
  ClipboardCopy,
  OfficeBuilding,
  Trash,
  Info,
} from "icons";

import {
  PO_TYPES,
  LINES,
  CUSTOMERS,
  IMPORTERS,
  DESTINATIONS,
  VENDORS_MANUFACTURERS,
  SHIP_TO_PORTS,
} from "./constants";

import useStyles from "./styles";

const initialAutocomplete = {
  po_type: null,
  manufacturer: null,
  vendor: null,
  importer: "Tayse",
  customer: null,
  line: null,
  ship_to_port: null,
  final_destination: null,
  group: "",
};

const initialParams = {
  po_type: "",
  po_number: "",
  container_number: "",
  invoice_number: "",
  bol_number: "",
  manufacturer: "",
  vendor: "",
  importer: "Tayse",
  customer: "",
  collections: [],
  line: "",
  port_and_forwarder: "",
  ship_to_port: "",
  final_destination: "",
  notes: "",
  vessel: "",
  hc40_freight_cost: "",
  scheduled_date: "",
  etd: "",
  eta: "",
  ata: "",
  receipt_uploaded: false,
  loading_method: "Floor_Loaded",
};

const CreateModal = ({ open, onClose, onCreate, onEdit, editContainer, t }) => {
  const classes = useStyles();
  const [params, setParams] = useState(initialParams);
  const [groupName, setGroupName] = useState("");
  const [autocompleteValues, setAutocompleteValues] = useState(
    initialAutocomplete,
  );
  const [isLoading, setIsLoading] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const [collectionNames, setCollectionNames] = useState([]);

  useEffect(() => {
    if (editContainer) {
      setParams({
        ...editContainer,
        collections: JSON.parse(editContainer.collections) || [],
      });
      setAutocompleteValues({
        po_type: editContainer.po_type,
        manufacturer: editContainer.manufacturer,
        vendor: editContainer.vendor,
        importer: editContainer.importer,
        customer: editContainer.customer,
        line: editContainer.line,
        ship_to_port: editContainer.ship_to_port,
        final_destination: editContainer.final_destination,
        group: "",
      });
    } else {
      setParams(initialParams);
    }
  }, [editContainer]);

  useEffect(() => {
    getCollectionNames({
      responseSetter: (res) => {
        if (res.success) {
          setCollectionNames([...res.data, "OTHER"]);
        }
      },
    });
  }, []);

  const handleCreate = () => {
    setIsLoading(true);
    createContainerRecord({
      setError: (_) => {
        setIsLoading(false);
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        onCreate();
        setParams(initialParams);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Container record created.",
          status: "success",
        });
      },
      body: params,
    });
  };

  const handleEdit = () => {
    setIsLoading(true);
    updateContainerRecord({
      setError: (_) => {
        setIsLoading(false);
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        onEdit();
        setParams(initialParams);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Container record updated.",
          status: "success",
        });
      },
      body: params,
      id: editContainer.id,
    });
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteParams = (prop) => (_, value) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteValues = (prop) => (_, value) => {
    setAutocompleteValues((prev) => ({ ...prev, [prop]: value }));
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
  };

  const clearAdornment = (input) => {
    getClearAdornment(input, clearHandler, params);
  };

  return (
    <Dialog
      maxWidth="xxl"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        {`${editContainer ? "Update" : "Create"} Container Record`}
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <Grid container spacing={2} className={classes.mt10}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.po_type}
                  onChange={handleChangeAutocompleteValues("po_type")}
                  inputValue={params.po_type}
                  onInputChange={handleChangeAutocompleteParams("po_type")}
                  id="po_type"
                  options={PO_TYPES.map((t) => t.value)}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="PO Type"
                      variant="outlined"
                      fullWidth
                      LeftIcon={Style}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="po_number"
                  label={t("po_number")}
                  variant="outlined"
                  fullWidth
                  value={params.po_number}
                  onChange={handleChangeFilterInput("po_number")}
                  InputProps={clearAdornment("po_number")}
                  LeftIcon={Receipt}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="invoice_number"
                  label={t("invoice_number")}
                  variant="outlined"
                  fullWidth
                  value={params.invoice_number}
                  onChange={handleChangeFilterInput("invoice_number")}
                  InputProps={clearAdornment("invoice_number")}
                  LeftIcon={Receipt}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.vendor}
                  onChange={handleChangeAutocompleteValues("vendor")}
                  inputValue={params.vendor}
                  onInputChange={handleChangeAutocompleteParams("vendor")}
                  id="vendor"
                  options={VENDORS_MANUFACTURERS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Vendor"
                      variant="outlined"
                      fullWidth
                      LeftIcon={SnippetFolder}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.manufacturer}
                  onChange={handleChangeAutocompleteValues("manufacturer")}
                  inputValue={params.manufacturer}
                  onInputChange={handleChangeAutocompleteParams("manufacturer")}
                  id="manufacturer"
                  options={VENDORS_MANUFACTURERS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Manufacturer"
                      variant="outlined"
                      fullWidth
                      LeftIcon={SnippetFolder}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.customer}
                  onChange={handleChangeAutocompleteValues("customer")}
                  inputValue={params.customer}
                  onInputChange={handleChangeAutocompleteParams("customer")}
                  id="customer"
                  options={CUSTOMERS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Customer"
                      variant="outlined"
                      fullWidth
                      LeftIcon={FolderSpecial}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.importer}
                  onChange={handleChangeAutocompleteValues("importer")}
                  inputValue={params.importer}
                  onInputChange={handleChangeAutocompleteParams("importer")}
                  id="importer"
                  options={IMPORTERS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Importer"
                      variant="outlined"
                      fullWidth
                      LeftIcon={ClipboardCopy}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={params.receipt_uploaded}
                        disabled={!editContainer}
                        onChange={() => {
                          setParams((prev) => ({
                            ...prev,
                            receipt_uploaded: !prev.receipt_uploaded,
                          }));
                        }}
                      />
                    }
                    label="Receipt Uploaded"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <Autocomplete
                      value={autocompleteValues.group_name}
                      onChange={handleChangeAutocompleteValues("group_name")}
                      inputValue={groupName}
                      onInputChange={(_e, v) => {
                        v && setGroupName(v);
                      }}
                      id="group"
                      options={collectionNames.filter(
                        (c) =>
                          params.collections && !params.collections.includes(c),
                      )}
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          label="Collection"
                          variant="outlined"
                          fullWidth
                          LeftIcon={SnippetFolder}
                          hasBorder
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setParams((prev) => ({
                          ...prev,
                          collections: [...prev.collections, groupName],
                        }));
                        setAutocompleteValues((prev) => ({
                          ...prev,
                          group: "",
                        }));
                        setGroupName("");
                      }}
                      disabled={!autocompleteValues.group_name}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    {params.collections &&
                      params.collections.map((group, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          color="primary"
                          size="small"
                          className={`${classes.mr10} ${classes.mb10}`}
                          onClick={() => {
                            setParams((prev) => ({
                              ...prev,
                              collections: prev.collections.filter(
                                (v) => v !== group,
                              ),
                            }));
                            setGroupName("");
                            setAutocompleteValues((prev) => ({
                              ...prev,
                              group: "",
                            }));
                          }}
                        >
                          <span className={classes.mr10}>• {group}</span>
                          <Trash size={0.7} color="#fff" />
                        </Button>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="container_number"
                  label={t("container_number")}
                  variant="outlined"
                  fullWidth
                  value={params.container_number}
                  onChange={handleChangeFilterInput("container_number")}
                  InputProps={clearAdornment("container_number")}
                  LeftIcon={Receipt}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="bol_number"
                  label={t("bol_number")}
                  variant="outlined"
                  fullWidth
                  value={params.bol_number}
                  onChange={handleChangeFilterInput("bol_number")}
                  InputProps={clearAdornment("bol_number")}
                  LeftIcon={Receipt}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.line}
                  onChange={handleChangeAutocompleteValues("line")}
                  inputValue={params.line}
                  onInputChange={handleChangeAutocompleteParams("line")}
                  id="line"
                  options={LINES}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Line"
                      variant="outlined"
                      fullWidth
                      LeftIcon={MergeType}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="port_and_forwarder"
                  label="Port & Forwarder"
                  variant="outlined"
                  fullWidth
                  value={params.port_and_forwarder}
                  onChange={handleChangeFilterInput("port_and_forwarder")}
                  InputProps={clearAdornment("port_and_forwarder")}
                  LeftIcon={LocationMarker}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="vessel"
                  label="Vessel"
                  variant="outlined"
                  fullWidth
                  value={params.vessel}
                  onChange={handleChangeFilterInput("vessel")}
                  InputProps={clearAdornment("vessel")}
                  LeftIcon={AssistantPhoto}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="hc40_freight_cost"
                  label="40HC Freight Cost"
                  variant="outlined"
                  fullWidth
                  value={params.hc40_freight_cost}
                  onChange={handleChangeFilterInput("hc40_freight_cost")}
                  InputProps={clearAdornment("hc40_freight_cost")}
                  LeftIcon={ReceiptLong}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.ship_to_port}
                  onChange={handleChangeAutocompleteValues("ship_to_port")}
                  inputValue={params.ship_to_port}
                  onInputChange={handleChangeAutocompleteParams("ship_to_port")}
                  id="ship_to_port"
                  options={SHIP_TO_PORTS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Ship to Port"
                      variant="outlined"
                      fullWidth
                      LeftIcon={LocationMarker}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={autocompleteValues.final_destination}
                  onChange={handleChangeAutocompleteValues("final_destination")}
                  inputValue={params.final_destination}
                  onInputChange={handleChangeAutocompleteParams(
                    "final_destination",
                  )}
                  id="final_destination"
                  options={DESTINATIONS}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Final Destination"
                      variant="outlined"
                      fullWidth
                      LeftIcon={OfficeBuilding}
                      hasBorder
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} className={classes.mt10}>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      id="etd"
                      title="ETD"
                      value={params.etd}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          const date = new Date(e.target.value);
                          date.setMonth(date.getMonth() + 1);
                          setParams((prev) => ({
                            ...prev,
                            eta: date.toISOString().split("T")[0],
                          }));
                        } else {
                          setParams((prev) => ({ ...prev, eta: "" }));
                        }
                        handleChangeFilterInput("etd")(e);
                      }}
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      id="eta"
                      title="ETA"
                      value={params.eta}
                      onChange={handleChangeFilterInput("eta")}
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      id="scheduled_date"
                      title="Scheduled Date"
                      value={params.scheduled_date}
                      onChange={handleChangeFilterInput("scheduled_date")}
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      id="ata"
                      title="ATA"
                      value={params.ata}
                      onChange={handleChangeFilterInput("ata")}
                      hasBorder
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.notesContainer}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{
                      paddingRight: "1rem",
                      paddingTop: "1.1rem",
                    }}
                  >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="loading"
                        defaultValue="Floor_Loaded"
                        name="radio-buttons-group"
                        value={params.loading_method}
                        onChange={handleChangeFilterInput("loading_method")}
                      >
                        <FormControlLabel
                          value="Floor_Loaded"
                          control={<Radio />}
                          label="Floor Loaded"
                        />
                        <FormControlLabel
                          value="Palletized"
                          control={<Radio />}
                          label={
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>Palletized</Grid>
                              <Tooltip title="Please enter the pallet size and count in the notes.">
                                <IconButton aria-label="info" color="primary">
                                  <Info size={1} color="#0ab6ff" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={2}>
                        <div className={classes.notesLabelContainer}>
                          <ClipboardList color="rgb(10, 182, 255)" />
                          <span className={classes.notesLabel}>Notes:</span>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.notesInputContainer}
                      >
                        <TextField
                          id="notes"
                          variant="outlined"
                          fullWidth
                          value={params.notes}
                          onChange={handleChangeFilterInput("notes")}
                          InputProps={{
                            className: classes.notesInput,
                          }}
                          LeftIcon={ClipboardList}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="center"
              className={classes.mt10}
            >
              <Grid item xs={12}>
                <Button
                  onClick={editContainer ? handleEdit : handleCreate}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.actionButton}
                  disabled={
                    isLoading ||
                    !params.po_type ||
                    !params.po_number ||
                    !params.container_number ||
                    !params.invoice_number ||
                    !params.bol_number ||
                    !params.manufacturer ||
                    !params.vendor ||
                    !params.importer ||
                    !params.customer ||
                    !params.line ||
                    !params.port_and_forwarder ||
                    !params.ship_to_port ||
                    !params.final_destination ||
                    !params.vessel
                  }
                >
                  {isLoading ? (
                    <CircularProgress size={25} />
                  ) : editContainer ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
