import React, { useEffect, useState } from "react";

import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getInventories } from "api/inventory";

import { MobileResetButton } from "components/Custom";
import { InputContainer, TextInput } from "components/Form";
import PageTitle from "components/PageTitle";
import DesktopTable from "components/Table/DesktopTable";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { X as Clear, QrCode2 } from "icons";

import { initialAPIListValue } from "utils/constants";
import { doesStartLikeLocationCode } from "utils/validations";

import themes from "themes";

const SearchTypes = { None: 0, SKU: 1, Location: 2 };

export default function InventoryMobile() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();

  const [error, setError] = useState(false);
  const [inventory, setInventory] = useState(initialAPIListValue);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(SearchTypes.None);

  const cells = [{ id: "qty" }];

  if (searchType === SearchTypes.SKU) {
    cells.unshift({
      id: "location_code",
      label: "location",
      render: (r) => r.location.code,
    });
  } else if (searchType === SearchTypes.Location) {
    cells.unshift({
      id: "product_sku",
      render: (r) => r.product.sku,
    });
  }

  useEffect(() => {
    if (inventory.isFetching) {
      let params = {
        location_code_startswith:
          searchType === SearchTypes.Location ? search : "",
        product__sku: searchType === SearchTypes.SKU ? search : "",
      };
      getInventories({
        params,
        setError,
        setFunc: setInventory,
        paginated: true,
      });
    }
  }, [search, searchType, inventory.isFetching]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const resetFunction = () => {
    setInventory({
      ...initialAPIListValue,
      isFetching: false,
    });
    setSearchType(SearchTypes.None);
    setSearch("");
  };

  return (
    <>
      <PageTitle title={t("inventory")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              fullWidth
              id="sku"
              label={t("sku_or_location")}
              variant="outlined"
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && search) {
                  const isLocationCode = doesStartLikeLocationCode(search);
                  if (isLocationCode) {
                    setSearchType(SearchTypes.Location);
                  } else {
                    setSearchType(SearchTypes.SKU);
                  }
                  setSearch(e.target.value);
                  setInventory({ ...inventory, isFetching: true });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={resetFunction} edge="end">
                      {search.length > 0 && <Clear />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              LeftIcon={QrCode2}
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginBottom: themes.default.spacing(5) }}>
          <DesktopTable
            headers={cells}
            data={cells.length > 1 ? inventory : { items: [] }}
            headerVisible={cells.length > 1}
          />
        </Grid>
        <MobileResetButton onClick={resetFunction} />
      </Grid>
    </>
  );
}
