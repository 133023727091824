import * as React from "react";

const SvgTruck = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 4 .15.005A2 2 0 0 1 14 6h2.586l.176.008A2 2 0 0 1 18 6.586L16.586 8 18 6.586 21.414 10l.12.13A2 2 0 0 1 22 11.414V16l-.005.15A2 2 0 0 1 20 18h-.171A3.001 3.001 0 0 1 17 20l-.176-.005A3.002 3.002 0 0 1 14.17 18L14 18l-.15-.005a1.988 1.988 0 0 1-.85-.263c-.293.17-.635.268-1 .268H9.829A3.001 3.001 0 0 1 7 20l-.176-.005A3.002 3.002 0 0 1 4.17 18L4 18l-.15-.005A2 2 0 0 1 2 16V6l.005-.15A2 2 0 0 1 4 4h8ZM7 16a1 1 0 0 0-1 1l.007.117A1 1 0 0 0 8 17l-.007-.117A1 1 0 0 0 7 16Zm10.993.883A1 1 0 0 0 16 17l.007.117A1 1 0 0 0 18 17ZM12 6H4v10h.17A3.001 3.001 0 0 1 7 14l.176.005c1.23.071 2.26.883 2.653 1.995H12V6Zm5.176 8.005c1.23.071 2.26.883 2.653 1.995H20v-4.586L16.586 8H14v8h.17A3.001 3.001 0 0 1 17 14Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgTruck;
