import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";

import useStyles from "./styles";

async function getCSRFToken(setCSRFToken) {
  const url = `${process.env.REACT_APP_API_URL}accounts/password_reset/`;
  const options = {
    method: "GET",
    credentials: "include",
  };

  try {
    const res = await fetch(url, options);
    if (res.status === 200) {
      const textResponse = await res.text().catch(() => null);
      if (textResponse) {
        const csrfToken = textResponse.match(
          /name="csrfmiddlewaretoken" value="(.*?)"/,
        )[1];
        setCSRFToken(csrfToken);
      }
    } else {
      throw new Error("Server unavailable, admins notified.");
    }
  } catch (e) {
    console.error(e.message);
  }
}

// Sends password reset instructions to user's email
async function resetPassword(
  email,
  csrfToken,
  setIsLoading,
  setError,
  setSuccess,
) {
  const url = `${process.env.REACT_APP_API_URL}accounts/password_reset/`;
  const options = {
    method: "POST",
    body: new URLSearchParams({
      email,
    }),
    headers: {
      "X-CSRFToken": csrfToken,
    },
    credentials: "include",
  };
  setIsLoading(true);

  try {
    const res = await fetch(url, options);
    if (res.status === 200) {
      // Check if reset link was sent
      const jsonResponse = await res.json().catch(() => null);
      if (jsonResponse && jsonResponse.status === "success") {
        setSuccess(true);
        setIsLoading(false);
      } else {
        throw new Error("Error! please check the e-mail you entered.");
      }
    } else {
      throw new Error("Server unavailable, admins notified.");
    }
  } catch (e) {
    setError(e.message);
    setIsLoading(false);
  }
}

// Password reset page
function PasswordReset() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [csrfToken, setCSRFToken] = useState("");

  const send = () => {
    resetPassword(emailValue, csrfToken, setIsLoading, setError, setSuccess);
  };

  useEffect(() => {
    getCSRFToken(setCSRFToken);
  }, []);

  return (
    <Grid container className={classes.container}>
      {success ? (
        <div className={classes.messageContainer}>
          <Typography variant="h5" className={classes.message}>
            {t("password_reset_sent")}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t("password_reset_sent_p1")}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {t("password_reset_sent_p2")}
          </Typography>
          <Link to="/login" className={classes.button}>
            <Button variant="contained" color="primary" size="large">
              Home
            </Button>
          </Link>
        </div>
      ) : (
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Password Reset" classes={{ root: classes.tab }} />
            </Tabs>
            <Fragment>
              <Fade in={!!error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              <TextField
                fullWidth
                id="email"
                type="email"
                placeholder={t("email")}
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                onKeyPress={({ key }) => key === "Enter" && send()}
                margin="normal"
                disabled={isLoading}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loader} />
                ) : (
                  <Button
                    disabled={emailValue.length === 0 || isLoading}
                    onClick={send}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {isLoading ? "Loading.." : "Send Reset Link"}
                  </Button>
                )}
              </div>
            </Fragment>
          </div>
        </div>
      )}
    </Grid>
  );
}

export default withRouter(PasswordReset);
