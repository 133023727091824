import { useState, useEffect } from "react";
import classNames from "classnames";
import { useTheme } from "@material-ui/styles";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import { WMSLogo, ChevronDoubleLeft, ChevronDoubleRight } from "icons";

import useStyles from "./styles";

export default function Header() {
  const classes = useStyles();
  const [isPermanent, setPermanent] = useState(true);
  const { isSidebarOpened, isMobile } = useLayoutState();
  const theme = useTheme();
  const layoutDispatch = useLayoutDispatch();

  const logoColors = [theme.palette.colors.white, "#95b8b3", "#d9d2ae"];


  const handleWindowWidthChange = () => {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  };

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  return !isMobile ? (
    <div
      className={classNames(classes.header, {
        [classes.drawerClosedHeader]: !isSidebarOpened,
      })}
    >
      <div
        className={classNames(classes.toggle, {
          [classes.drawerClosedToggle]: !isSidebarOpened,
        })}
        onClick={() => toggleSidebar(layoutDispatch)}
      >
        {isSidebarOpened ? (
          <ChevronDoubleLeft color={theme.palette.colors.white} />
        ) : (
          <ChevronDoubleRight color={theme.palette.colors.white} />
        )}
      </div>
      <div
        className={classNames(classes.logo, {
          [classes.hide]: !isSidebarOpened,
        })}
      >
        <WMSLogo colors={logoColors} />
      </div>
    </div>
  ) : null;
}
