import * as React from "react";

const SvgGlobe = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M5 10a3 3 0 0 1 2.995 2.824L8 13v1a1 1 0 0 0 1 1 3 3 0 0 1 2.995 2.824L12 18v2.945a1 1 0 0 1-1.993.117L10 20.945V18a1 1 0 0 0-.883-.993L9 17a3 3 0 0 1-2.995-2.824L6 14v-1a1 1 0 0 0-.883-.993L5 12H3.055a1 1 0 0 1-.117-1.993L3.055 10H5Zm15.064 5a1 1 0 0 1 .117 1.993l-.117.007H17a1 1 0 0 0-.993.883L16 18v2.488a1 1 0 0 1-1.993.117L14 20.488V18a3 3 0 0 1 2.824-2.995L17 15h3.064ZM8 2.936a1 1 0 0 1 .993.883L9 3.936V5.5a1.5 1.5 0 0 0 1.356 1.493L10.5 7h.5a3 3 0 0 1 2.995 2.824L14 10a1 1 0 1 0 2 0 3 3 0 0 1 2.824-2.995L19 7h1.064a1 1 0 0 1 .117 1.993L20.064 9H19a1 1 0 0 0-.993.883L18 10a3 3 0 1 1-6 0 1 1 0 0 0-.883-.993L11 9h-.5a3.5 3.5 0 0 1-3.495-3.308L7 5.5V3.936a1 1 0 0 1 1-1Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgGlobe;
