import { Button as MuiButton } from "@material-ui/core";

import useStyles from "./styles";

export default function Button({
  children,
  color,
  disabled,
  onClick,
  value,
  variant,
  style,
  containerStyle,
  ...props
}) {
  const classes = useStyles();
  let colorClass;
  if (color === "primary") {
    colorClass = classes.buttonPrimary;
  } else if (color === "secondary") {
    colorClass = classes.buttonSecondary;
  } else if (color === "blue") {
    colorClass = classes.buttonBlue;
  } else if (color === "danger") {
    colorClass = classes.buttonDanger;
  }
  return (
    <div className={classes.buttonContainer} style={containerStyle}>
      <MuiButton
        fullWidth
        variant={variant}
        className={[classes.button, colorClass].join(" ")}
        disabled={disabled}
        onClick={onClick}
        style={style}
        {...props}
      >
        {children}
      </MuiButton>
    </div>
  );
}
