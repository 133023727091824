import * as React from "react";

const SvgColorswatch = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M7 21a4 4 0 0 1-4-4V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v12a4 4 0 0 1-4 4Z" />
      <path d="M7 21h12a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.343" />
      <path d="m11 7.343 1.657-1.657a2 2 0 0 1 2.828 0l2.829 2.829a2 2 0 0 1 0 2.828l-8.486 8.485" />
    </g>
  </svg>
);

export default SvgColorswatch;
