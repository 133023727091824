import React from "react";
import { Button } from "@material-ui/core";
import * as XLSX from "xlsx";

import { CloudDownload } from "icons";

import themes from "themes";

const fitToColumn = (csvData) => {
  // get maximum character of each column
  return Object.entries(csvData[0]).map((key) => ({
    wch: Math.max(
      ...csvData.map((row) => {
        let l = 0;
        if (row[key[0]]) {
          l = Math.min(row[key[0]].toString().length, 40);
        }
        return l;
      }),
      key[0].length,
    ),
  }));
};

const exportToExcel = (
  csvData,
  fileName,
  autoWidth,
  currentInstances,
  setExportDisabled = () => {},
) => {
  let wb = {
    Sheets: {},
    SheetNames: [],
  };
  if (currentInstances && currentInstances.length > 0) {
    const ws2 = XLSX.utils.json_to_sheet(currentInstances);
    if (autoWidth) {
      ws2["!cols"] = fitToColumn(currentInstances);
    }
    wb.Sheets.Sheet1 = ws2;
    wb.SheetNames.push("Sheet1");
  }
  if (csvData && csvData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(csvData);
    if (autoWidth) {
      ws["!cols"] = fitToColumn(csvData);
    }
    wb.Sheets.Options = ws;
    wb.SheetNames.push("Options");
  }
  setExportDisabled(false);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default function ExportExcel({
  csvData,
  fileName,
  autoWidth,
  currentInstances,
}) {
  return (
    <Button
      className="pull-left"
      variant="outlined"
      color="secondary"
      startIcon={<CloudDownload color={themes.default.palette.common.white} />}
      onClick={(e) =>
        exportToExcel(csvData, fileName, autoWidth, currentInstances)
      }
    >
      Template
    </Button>
  );
}

export { exportToExcel, ExportExcel };
