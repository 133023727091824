import React, { useState } from "react";

import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { assignShipments, unassignShipments } from "api/shipment";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { PickersWithAssignedQty } from "components/Custom";

export default function ReassignShipments({
  handleClose,
  selectedTransactions,
  product_type,
}) {
  const layoutDispatch = useLayoutDispatch();
  const [picker, setPicker] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleReassign = () => {
    const container = selectedTransactions
      .filter((transaction) => transaction.user.id !== picker.user.id)
      .map((transaction) => ({
        detail_id: transaction.detail.id,
        user_id: transaction.user.id,
        quantity: transaction.quantity,
      }));

    if (!container.length) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Please select another picker.",
      });
      return;
    }
    setIsLoading(true);
    unassignShipments({
      container,
      responseSetter: (data) => {
        const { success, message } = data;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          setIsLoading(false);
          return;
        }

        assignShipments({
          details: container.map((c) => ({ ...c, user_id: picker.user.id })),
          responseSetter: (data) => {
            const { success, message } = data;
            if (!success) {
              popupNotification({
                dispatch: layoutDispatch,
                message: `Shipment(s) unassigned but cannot be re-assigned. Error : ${message}`,
                status: "warning",
              });
              setIsLoading(false);
              return;
            }
            popupNotification({
              dispatch: layoutDispatch,
              message: `Shipment(s) unassigned and re-assigned`,
              status: "success",
            });
            setIsLoading(false);
            handleClose(true);
            return;
          },
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => handleClose(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{"Re-assign shipments"}</DialogTitle>
      <DialogContent>
        {`${selectedTransactions.length} shipment(s)`}
        <PickersWithAssignedQty
          lg={12}
          md={12}
          sm={12}
          xs={12}
          product_type={product_type}
          selectedPicker={picker}
          onChange={(e, picker) => setPicker(picker)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleReassign}
          color="primary"
          variant="contained"
          autoFocus
          disabled={!picker || isLoading}
        >
          {isLoading && <CircularProgress size={15} />}
          Re-assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
