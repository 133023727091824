import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import {
  createNationalInventoryCounter,
  getNationalCountGroups,
} from "api/inventory";
import { getProductsWithAttributes } from "api/products";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import {
  MobileButtonContainer,
  MobileResetButton,
  Spacer,
} from "components/Custom";
import { TextInput } from "components/Form";
import {
  FormattedTextField,
  TextInputWithKeyboard,
} from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { QrCode2, SnippetFolder, TextSnippet, X as Clear } from "icons";
import { focusInput } from "utils/dom";
import { separateQtyFromValue } from "utils/string";

import useStyles from "./styles";

const initialParams = {
  number: "",
  type: "Other",
  barcode: "",
  product: "",
  product_sku: "",
  count_group_id: "",
  quantity: "",
};

export default function NationalInventoryCounter() {
  const [t] = useTranslation();
  const barcodeEl = useRef(null);
  const skuEl = useRef(null);
  const numberEl = useRef(null);
  const quantityEl = useRef(null);
  const layoutDispatch = useLayoutDispatch();
  const [params, setParams] = useState(initialParams);
  const [countGroups, setCountGroups] = useState([]);
  const [barcodeNotFound, setBarcodeNotFound] = useState(false);
  const [showQtyInput, setShowQtyInput] = useState(false);
  const handleChangeFilterInput = (prop) => (e) => {
    setParams({ ...params, [prop]: e.target.value });
  };
  const classes = useStyles();

  useEffect(() => {
    getNationalCountGroups({
      params: {
        status: "Created",
      },
      responseSetter: (res) => {
        const { results } = res;
        if (results && !results.length) {
          setParams(initialParams);
          setCountGroups([]);
        }

        if (results.length === 1) {
          const { id, number, type } = results[0];
          setParams((prev) => ({ ...prev, count_group_id: id, type, number }));
        }
        setCountGroups(results);
      },
    });
  }, []);

  useEffect(() => {
    if (params.count_group_id) {
      barcodeEl.current.focus();
    }
  }, [params.count_group_id]);

  useEffect(() => {
    if (params.type) {
      numberEl.current.focus();
    }
  }, [params.type]);

  const isSubmitDisabled = () =>
    !params.count_group_id || !params.barcode || barcodeNotFound
      ? !params.product_sku
      : false || showQtyInput
      ? !params.quantity
      : false;

  const handleClickListItem = (group) => {
    const { id, number, type } = group;
    setParams({ count_group_id: id, type, number });
  };

  const handleReset = useCallback(() => {
    setParams({
      ...params,
      barcode: "",
      product: "",
      product_sku: "",
      quantity: "",
    });
    setBarcodeNotFound(false);
    barcodeEl.current.focus();
  }, [params]);

  const handleSearchBarcode = () => {
    const { value, qty } = separateQtyFromValue(params.barcode);
    setParams({ ...params, barcode: value, product_sku: "" });
    getProductsWithAttributes({
      params: {
        value_iexact: value,
        redis_cache: true,
      },
      responseSetter: (res) => {
        const { results } = res;
        if (results.length === 1) {
          setParams({
            ...params,
            barcode: value,
            product: results[0].product,
            product_sku: results[0].product_sku,
            quantity: qty || "",
          });
          setBarcodeNotFound(false);
          quantityEl.current.focus();
        } else {
          setBarcodeNotFound(true);
          popupNotification({
            dispatch: layoutDispatch,
            message: t("unknown_barcode"),
            afterCloseFunction: () => {
              skuEl.current.focus();
            },
          });
        }
      },
    });
  };

  const handleSubmitForm = useCallback(() => {
    const { count_group_id, barcode, product, product_sku, quantity } = params;
    const product_quantity = showQtyInput ? parseInt(quantity) : 1;
    if (product_quantity < 1 || product_quantity > 1000) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity must be between 1 and 1000.",
      });
    }

    createNationalInventoryCounter({
      body: {
        count_group_id,
        barcode,
        product,
        product_sku,
        quantity: product_quantity,
      },
      responseSetter: (res) => {
        if (res.success) {
          handleReset();
          popupNotification({
            dispatch: layoutDispatch,
            message: t("successful_operation"),
            status: "success",
            afterCloseFunction: () => barcodeEl.current.focus(),
          });
        } else {
          popupNotification({
            dispatch: layoutDispatch,
            message: res.message,
            status: "error",
            afterCloseFunction: () => {
              setParams({
                ...params,
                product_sku: "",
              });
            },
          });
        }
      },
    });
  }, [handleReset, layoutDispatch, params, t, showQtyInput]);

  useEffect(() => {
    if (params.product && params.product_sku && !showQtyInput) {
      handleSubmitForm();
    }
  }, [params.product, params.product_sku, showQtyInput, handleSubmitForm]);

  const handleKeyPress = ({ key }) => {
    if (key !== "Enter") {
      return;
    }
    const findGroup = countGroups.find(
      (group) => group.type === params.type && group.number === params.number,
    );
    if (findGroup) {
      handleClickListItem(findGroup);
    }
  };

  const handleQuantityChange = ({ target: { value } }) => {
    setParams({
      ...params,
      quantity: /^\d+$/.test(value) ? parseInt(value) : "",
    });
  };

  return (
    <>
      <PageTitle title={t("item_count")} />
      <Widget
        button={
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label={showQtyInput ? "Multiple" : "Single"}
              checked={showQtyInput}
              onChange={({ target: { checked } }) => {
                setShowQtyInput(checked);
                if (!params.product_sku && barcodeEl.current) {
                  barcodeEl.current.focus();
                } else {
                  focusInput(quantityEl);
                }
              }}
            />
          </FormGroup>
        }
      >
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextInput
              fullWidth
              inputRef={numberEl}
              variant={"outlined"}
              id={"number"}
              label={t("number")}
              value={params.number}
              onChange={handleChangeFilterInput("number")}
              onKeyPress={handleKeyPress}
              disabled={!!params.count_group_id}
              LeftIcon={SnippetFolder}
              hasBorder
            />
          </Grid>
          {countGroups.length && !params.count_group_id && params.type ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <List>
                {countGroups
                  .filter(
                    (group) =>
                      group.number.indexOf(params.number) !== -1 &&
                      group.type === params.type,
                  )
                  .map((group) => (
                    <ListItem
                      key={group.id}
                      onClick={() => handleClickListItem(group)}
                      className={classes.listItem}
                    >
                      <ListItemText primary={group.number} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          ) : null}
          {params.count_group_id ? (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextInputWithKeyboard
                  fullWidth
                  id={"barcode"}
                  variant={"outlined"}
                  inputRef={barcodeEl}
                  label={t("barcode")}
                  value={params.barcode}
                  onChange={handleChangeFilterInput("barcode")}
                  onKeyPress={({ key }) =>
                    key === "Enter" ? handleSearchBarcode() : null
                  }
                  disabled={!!params.product_sku || barcodeNotFound}
                  LeftIcon={QrCode2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setParams({
                              ...params,
                              barcode: "",
                              product_sku: "",
                            });
                            setBarcodeNotFound(false);
                          }}
                          edge="end"
                        >
                          {params.barcode && params.barcode.length > 0 && (
                            <Clear />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  hasBorder
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ display: !barcodeNotFound ? "none" : "block" }}
              >
                <FormattedTextField
                  fullWidth
                  inputRef={skuEl}
                  variant={"outlined"}
                  id={"product_sku"}
                  label={t("product_sku")}
                  value={params.product_sku}
                  onChange={handleChangeFilterInput("product_sku")}
                  onKeyPress={({ key }) =>
                    key === "Enter"
                      ? showQtyInput
                        ? quantityEl.current.focus()
                        : handleSubmitForm()
                      : null
                  }
                  LeftIcon={QrCode2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setParams({
                              ...params,
                              product_sku: "",
                            });
                          }}
                          edge="end"
                        >
                          {params.product_sku &&
                            params.product_sku.length > 0 && <Clear />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  isProductSKU
                  hasBorder
                />
              </Grid>
              {showQtyInput && (
                <Grid item xs={12}>
                  <TextInputWithKeyboard
                    inputRef={quantityEl}
                    type="number"
                    size="medium"
                    name="quantity"
                    inputMode="numeric"
                    label={t("quantity")}
                    variant="outlined"
                    value={params.quantity}
                    fullWidth
                    onChange={handleQuantityChange}
                    onKeyPress={({ key }) =>
                      key === "Enter" ? handleSubmitForm() : null
                    }
                    LeftIcon={TextSnippet}
                    hasBorder
                  />
                </Grid>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <MobileButtonContainer>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitForm}
                    disabled={isSubmitDisabled()}
                  >
                    {t("submit")}
                  </Button>
                </MobileButtonContainer>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Widget>
      <Spacer height="2rem" />
      <MobileResetButton
        onClick={() => {
          setParams(initialParams);
          setBarcodeNotFound(false);
        }}
      />
    </>
  );
}
