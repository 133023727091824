import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

import { getInventories } from "api/inventory";
import { getLocations } from "api/locations";
import { getProductsWithAttributes } from "api/products";
import { getReceiptLines, getReceipts, receiptTransfer } from "api/receipt";

import {
  MobileButtonContainer,
  Spacer,
  MarkLocationAsInspected,
  MobileResetButton,
} from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { QrCode2, SpaceBar, TextSnippet } from "icons";

import { initialAPIListValue } from "utils/constants";
import { separateQtyFromValue } from "utils/string";
import {
  isRug,
  locationCodeValidation,
  productAttributeValidation,
} from "utils/validations";

const initialFormData = {
  state: "product",
  location_code: "",
  dock_id: "",
  to_location_code: "",
  to_location_id: "",
  product_sku: "",
  product_id: null,
  quantity: "",
  receipt: null,
  receiptLine: null,
  searchAltLoc: true,
};

export default function ReceiptTransfer() {
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const [receiptLines, setReceiptLines] = useState(initialAPIListValue);
  const [locationList, setLocationList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState("");
  const [inspectedLocations, setInspectedLocations] = useState([]);

  const toLocationEl = useRef(null);
  const productEl = useRef(null);
  const qtyEl = useRef(null);

  useEffect(() => {
    popupNotification({
      dispatch: layoutDispatch,
      message: error,
    });
  }, [error, layoutDispatch]);

  useEffect(() => {
    if (receiptLines.items.length > 1) {
      const line = receiptLines.items[0];
      setFormData((prev) => ({
        ...prev,
        product_type: line.product_type,
        dock_id: line.location,
      }));
    }
  }, [receiptLines.items]);

  const handleLocationSubmit = (value, direction = "pick") => {
    if (direction === "shelf") {
      let location_code = locationCodeValidation(value.trim());
      if (!location_code) {
        location_code = value.trim();
      }

      if (locationList) {
        const matchLocation = locationList.find(
          (l) => l.code === location_code,
        );
        if (matchLocation) {
          setFormData((prev) => ({
            ...prev,
            to_location_code: matchLocation.code,
            to_location_id: matchLocation.id,
            state: "to_qty",
          }));
          return;
        }
      }

      getLocations({
        params: {
          code: location_code,
        },
        responseSetter: (res) => {
          const { results } = res;
          const matchLocation = results.find((r) => r.code === location_code);
          if (!results || !matchLocation) {
            toLocationEl.current.value = "";
            return popupNotification({
              dispatch: layoutDispatch,
              message: "Location not found.",
              status: "error",
            });
          }
          setFormData((prev) => ({
            ...prev,
            to_location_code: matchLocation.code,
            to_location_id: matchLocation.id,
            state: "to_qty",
          }));
          window.scroll({ top: 100, left: 0, behavior: "smooth" });
        },
      });
      return;
    }
    if (!value.toUpperCase().includes("DOCK")) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "This is not a Dock location",
        status: "error",
      });
    }
    getReceipts({
      params: {
        dock_id: value,
        for_receipt: true,
      },
      paginated: true,
      responseSetter: (res) => {
        const { results } = res;
        if (results && results.length) {
          if (results.length === 1) {
            const receipt = results[0];
            setFormData((prev) => ({
              ...prev,
              location_code: value,
              receipt: receipt,
              state: "product",
            }));
            // receipt lines will be helpful to prevent SKU validation calls so we load them as early as possible.
            getReceiptLines({
              params: {
                receipt_id: receipt.id,
                limit: 1000,
              },
              paginated: true,
              setFunc: setReceiptLines,
              setError,
            });
          } else {
            setFormData((prev) => ({
              ...prev,
              location_code: value,
              state: "product",
            }));
          }
        } else {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "We cannot find any Receipt for the scanned Dock.",
            status: "error",
          });
        }
      },
      setError,
    });

    window.scroll({ top: 100, left: 0, behavior: "smooth" });
    if (productEl && productEl.current) {
      productEl.current.focus();
    }
  };

  const handleProductSubmit = (sku) => {
    const { value, qty } = separateQtyFromValue(sku);
    const formatted_value = productAttributeValidation(value);
    const attribute = formatted_value ? formatted_value : value.split(",")[0];
    const lineItem = receiptLines.items.find(
      (rl) => rl.product_sku === attribute,
    );
    if (lineItem) {
      setFormData((prev) => ({
        ...prev,
        product_sku: value,
        product_id: lineItem.product,
        receiptLine: lineItem,
        product_type: lineItem.product_type,
        state: "to_location",
        quantity: qty || "",
      }));
      searchLocations({ sku: value });
    } else {
      getProductsWithAttributes({
        params: {
          value_iexact: attribute,
        },
        setError,
        responseSetter: ({ results }) => {
          if (results.length === 1) {
            const attribute = results[0];
            const product_type = isRug(attribute.product_sku) ? 1 : 2;
            setFormData((prev) => ({
              ...prev,
              product_sku: attribute.product_sku,
              product_id: attribute.product,
              product_type: formData.product_type
                ? prev.product_type
                : product_type,
              state: "to_location",
              quantity: qty || "",
            }));
            searchLocations({ sku: attribute.product_sku, product_type });
          } else {
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("wrong_product"),
              status: "error",
            });
          }
        },
      });
    }

    window.scroll({ top: 100, left: 0, behavior: "smooth" });
    if (qtyEl && qtyEl.current) {
      qtyEl.current.focus();
    }
  };

  const handleQuantitySubmit = ({ target: { value } }) => {
    setFormData((prev) => ({ ...prev, quantity: parseInt(value) }));
  };

  const handleSubmit = () => {
    setIsDisabled(true);
    receiptTransfer({
      body: {
        to_location_id: formData.to_location_id,
        product_id: formData.product_id,
        quantity: formData.quantity,
      },
      setError,
      responseSetter: (data) => {
        setIsDisabled(false);
        const { success, message } = data;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        } else {
          setFormData(initialFormData);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
            status: "success",
          });
        }
      },
    });
  };

  const getEmptyLocations = (sku, product_type) => {
    getLocations({
      params: {
        empty: true,
        location_type: product_type ? product_type : formData.product_type,
        product_sku: sku ? sku : formData.product_sku,
      },
      responseSetter: (data) => {
        setIsDisabled(false);
        if (!data.results.length) {
          setLocationList([]);
          setFormData((prev) => ({ ...prev, searchAltLoc: false }));
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("no_results_found"),
            status: "error",
          });
        } else {
          const locations = data.results.sort((a, b) =>
            a.code > b.code ? 1 : a.code < b.code ? -1 : 0,
          );
          setLocationList(_.uniqBy(locations, "code"));
          setFormData((prev) => ({ ...prev, searchAltLoc: true }));
        }
      },
    });
  };

  const getAlternateLocations = (sku, product_type) => {
    getInventories({
      params: {
        product_sku_iexact: sku ? sku : formData.product_sku,
        location__location_type: product_type
          ? product_type
          : formData.product_type,
        ordering: "qty",
      },
      responseSetter: (data) => {
        setIsDisabled(false);
        if (!data.results.length) {
          setLocationList([]);
          setFormData((prev) => ({ ...prev, searchAltLoc: false }));
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("no_results_found"),
            status: "error",
          });
        } else {
          const locations = data.results.map((item) => ({
            ...item.location,
          }));
          setLocationList(_.uniqBy(locations, "code"));
          setFormData((prev) => ({ ...prev, searchAltLoc: false }));
        }
      },
    });
  };

  const searchLocations = ({ sku, product_type }) => {
    if (formData.searchAltLoc) {
      getAlternateLocations(sku, product_type);
    } else {
      getEmptyLocations(sku, product_type);
    }
  };

  const afterInspectConfirm = () => {
    setInspectedLocations((prev) => [...prev, formData.to_location_id]);
    setFormData((prev) => ({
      ...prev,
      to_location_code: "",
      to_location_id: "",
      state: "to_location",
    }));
  };

  return (
    <>
      <PageTitle title="Container Transfer" />
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginTop: "2.5em" }}>
          {formData.state === "product" ? (
            <TextInputWithKeyboard
              fullWidth
              autoFocus
              inputRef={productEl}
              id="product_sku"
              variant="outlined"
              label={t("product")}
              defaultValue={formData.product_sku}
              onKeyPress={({ target, key }) => {
                if (key === "Enter") handleProductSubmit(target.value);
              }}
              LeftIcon={QrCode2}
              hasBorder
            />
          ) : (
            formData.product_sku && (
              <Typography variant="subtitle1">
                {t("product_sku")}:{" "}
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  {formData.product_sku}
                </span>
              </Typography>
            )
          )}
        </Grid>
        <Grid item xs={12}>
          {formData.state === "to_location" ? (
            <>
              <MobileButtonContainer>
                {!isDisabled && (
                  <ToggleButtonGroup
                    color="primary"
                    value={formData.searchAltLoc ? "1" : "0"}
                    onChange={searchLocations}
                    style={{ width: "100%" }}
                  >
                    <ToggleButton value="0">Alt. Loc.</ToggleButton>
                    <ToggleButton value="1">Empty Loc.</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </MobileButtonContainer>
              {locationList.length ? (
                <List dense disablePadding>
                  {locationList
                    .filter((l) => !inspectedLocations.includes(l.id))
                    .slice(0, 3)
                    .map((location) => (
                      <ListItem key={location.id}>
                        <ListItemText primary={location.code} />
                      </ListItem>
                    ))}
                </List>
              ) : null}
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                inputRef={toLocationEl}
                id="to_location_code"
                variant="outlined"
                label={t("location")}
                defaultValue=""
                onKeyPress={({ target, key }) => {
                  if (key === "Enter")
                    handleLocationSubmit(target.value, "shelf");
                }}
                LeftIcon={SpaceBar}
                hasBorder
              />
            </>
          ) : (
            formData.to_location_code && (
              <MarkLocationAsInspected
                title="Bin:"
                product_sku={formData.product_sku}
                location_code={formData.to_location_code}
                location_id={formData.to_location_id}
                afterConfirm={afterInspectConfirm}
              />
            )
          )}
        </Grid>
        <Grid item xs={12}>
          {formData.state === "to_qty" && (
            <TextInputWithKeyboard
              fullWidth
              autoFocus
              type="number"
              size="medium"
              inputRef={qtyEl}
              name="quantity"
              inputMode="numeric"
              label={t("quantity")}
              variant="outlined"
              error={
                Number.isNaN(parseInt(formData.quantity)) ||
                parseInt(formData.quantity) < 0 ||
                parseInt(formData.quantity) > 999
              }
              value={formData.quantity}
              onChange={handleQuantitySubmit}
              LeftIcon={TextSnippet}
              hasBorder
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {formData.quantity > 0 && (
            <MobileButtonContainer>
              <Button
                disabled={isDisabled}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={handleSubmit}
              >
                {t("submit")}
              </Button>
            </MobileButtonContainer>
          )}
        </Grid>
      </Grid>
      <Spacer height="320px" />
      <MobileResetButton onClick={() => setFormData(initialFormData)} />
    </>
  );
}
