import * as React from "react";

const SvgTimelapse = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.82 8.18A5.398 5.398 0 0 0 12 6.6V12l-3.82 3.82a5.397 5.397 0 0 0 7.636 0 5.406 5.406 0 0 0 .004-7.64ZM12 3c-4.973 0-9 4.027-9 9s4.027 9 9 9a9 9 0 0 0 9-9c0-4.973-4.027-9-9-9Zm0 16.2A7.198 7.198 0 0 1 4.8 12c0-3.978 3.222-7.2 7.2-7.2s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2Z"
      fill={color}
    />
  </svg>
);

export default SvgTimelapse;
