import * as React from "react";

const SvgCalculator = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M9 6a1 1 0 0 0 0 2V6ZM15 8a1 1 0 1 0 0-2v2ZM14 17a1 1 0 1 0 2 0h-2ZM16 14a1 1 0 1 0-2 0h2ZM12 16a1 1 0 1 0 0 2v-2ZM12.01 18a1 1 0 1 0 0-2v2ZM9 16a1 1 0 1 0 0 2v-2ZM9.01 18a1 1 0 1 0 0-2v2ZM9 13a1 1 0 1 0 0 2v-2ZM9.01 15a1 1 0 1 0 0-2v2ZM12 13a1 1 0 1 0 0 2v-2ZM12.01 15a1 1 0 1 0 0-2v2ZM15 10a1 1 0 1 0 0 2v-2ZM15.01 12a1 1 0 1 0 0-2v2ZM12 10a1 1 0 1 0 0 2v-2ZM12.01 12a1 1 0 1 0 0-2v2ZM9 10a1 1 0 1 0 0 2v-2ZM9.01 12a1 1 0 1 0 0-2v2ZM7 4h10V2H7zM18 5v14h2V5zM17 20H7v2h10zM6 19V5H4v14zM7 20a1 1 0 0 1-1-1H4a3 3 0 0 0 3 3v-2ZM18 19a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2ZM17 4a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM7 2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1V2ZM9 8h6V6H9zM16 17v-3h-2v3zM12 18h.01v-2H12zM9 18h.01v-2H9zM9 15h.01v-2H9zM12 15h.01v-2H12zM15 12h.01v-2H15zM12 12h.01v-2H12zM9 12h.01v-2H9z" />
    </g>
  </svg>
);

export default SvgCalculator;
