import * as React from "react";

const SvgFilterCenterFocus = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 15H3v4a2 2 0 0 0 2 2h4v-2H5v-4ZM5 5h4V3H5a2 2 0 0 0-2 2v4h2V5Zm14-2h-4v2h4v4h2V5a2 2 0 0 0-2-2Zm0 16h-4v2h4a2 2 0 0 0 2-2v-4h-2v4ZM12 9c-1.655 0-3 1.345-3 3s1.345 3 3 3 3-1.345 3-3-1.345-3-3-3Z"
      fill={color}
    />
  </svg>
);

export default SvgFilterCenterFocus;
