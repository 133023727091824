import * as React from "react";

const SvgMoreVert = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8C13.105 8 14 7.105 14 6C14 4.895 13.105 4 12 4C10.895 4 10 4.895 10 6C10 7.105 10.895 8 12 8ZM12 10C10.895 10 10 10.895 10 12C10 13.105 10.895 14 12 14C13.105 14 14 13.105 14 12C14 10.895 13.105 10 12 10ZM12 16C10.895 16 10 16.895 10 18C10 19.105 10.895 20 12 20C13.105 20 14 19.105 14 18C14 16.895 13.105 16 12 16Z"
      fill={color}
    />
  </svg>
);

export default SvgMoreVert;
