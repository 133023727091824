import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";

import useStyles from "./styles";

/**
 * Table with pagination and custom render methods
 * @param headers array of objects with header config
 * @param data object with items, count and isFetching attributes
 * @param rowsPerPage pagination parameter
 * @param page pagination paramter
 * @param handleChangePage function to assign new data for given page
 * @param handleChangeRowsPerPage function to assign rowsPerPage attribute with going back to first page
 * @param hasCheckBox boolean to determine showing checkboxes on the left
 * @param onRowChecked function to run when row is selected
 * @param selected list of selected items
 */
export default function MobileTable({
  headers,
  data,
  rowsPerPage = 10,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  hasCheckBox,
  onRowChecked,
  selected,
  selectUnselectAll,
  rowOnClick,
}) {
  const { items, count, isFetching } = data;
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      {hasCheckBox && (
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              color="default"
              checked={items.length === selected.length}
              onChange={selectUnselectAll}
            />
          }
          label={
            items.length === selected.length
              ? t("unselect_all")
              : t("select_all")
          }
        />
      )}

      {isFetching ? (
        <CircularProgress size={26} />
      ) : items.length === 0 ? (
        <span>{t("no_results_found")}</span>
      ) : (
        items.map((row) => (
          <Card
            key={row.id}
            style={{
              padding: "1rem",
              backgroundColor:
                selected && selected.map((s) => s.id).indexOf(row.id) >= 0
                  ? "lightgreen"
                  : "white",
            }}
            onClick={() => rowOnClick && rowOnClick(row)}
            className={classes.card}
          >
            <Grid container spacing={4} alignContent="center">
              {hasCheckBox && (
                <Grid item xs={1} sm={1} className={classes.checkboxGrid}>
                  <Checkbox
                    className={classes.checkbox}
                    color="default"
                    checked={selected.map((s) => s.id).indexOf(row.id) >= 0}
                    onChange={() => onRowChecked(row)}
                  />
                </Grid>
              )}
              <Grid item xs={hasCheckBox ? 11 : 12} sm={hasCheckBox ? 11 : 12}>
                <Grid container spacing={1} alignContent="center">
                  {headers.map((h) => (
                    <Fragment key={h.id}>
                      <Grid item xs={4} sm={4}>
                        <Typography
                          key={h.id}
                          variant="inherit"
                          style={{
                            fontWeight: "bold",
                          }}
                          className={classes.typography}
                        >
                          {h.label ? t(h.label) : t(h.id)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={hasCheckBox ? 7 : 8}
                        sm={hasCheckBox ? 7 : 8}
                      >
                        {h.render && h.render(row) ? (
                          h.render(row)
                        ) : row[h.id] ? (
                          <Typography
                            key={h.id}
                            variant="body2"
                            align="right"
                            className={classes.typography}
                            style={{ marginRight: "2px" }}
                          >
                            {row[h.id]}
                          </Typography>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        ))
      )}

      {handleChangePage && handleChangeRowsPerPage && (
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
