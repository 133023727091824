import * as React from "react";

const SvgAssistantPhoto = ({ color, size }) => (
  <svg
    viewBox="0 0 23 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="m14.002 12 .366 2h3.072V8h-4.791l-.366-2h-4.9v6h6.619z"
      fill={color}
    />
    <path
      d="M7.381 14h5.12l.366 2h6.4V6h-5.12l-.366-2H5.553v17H7.38v-7zm0-8h4.9l.366 2h4.791v6h-3.072L14 12H7.381V6z"
      fill={color}
    />
  </svg>
);

export default SvgAssistantPhoto;
