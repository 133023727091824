import React, { useEffect } from "react";
import * as XLSX from "xlsx";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  createContainerReceipt,
  readContainerReceipt,
  validateContainerReceipt,
} from "api/purchase";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { FormattedTextField } from "components/FormElements";
import Widget from "components/Widget";

import {
  ACUMATICA_BASE_URL,
  PURCHASE_ORDER_URL,
  RECEIPT_URL,
} from "utils/constants";
import { exportToExcel } from "utils/exportToExcel";
import { CloudDownload, X as Close, ClipboardList } from "icons";

import useStyles from "./styles";

const initialFormData = {
  items: [],
  vendor: "",
  invoiceNumber: "",
  containerNumber: "",
  receiptType: "Ecom",
};

const NOTIFICATION_DURATION = 6000;

export default function ContainerReceiptModal({ container, open, onClose }) {
  const classes = useStyles();
  const [totals, setTotals] = React.useState({ qty: 0, cost: 0 });
  const [disabled, setDisabled] = React.useState(false);
  const [formData, setFormData] = React.useState(initialFormData);
  const [result, setResult] = React.useState({
    receiptNo: null,
    fileURL: "",
    shouldFetch: false,
  });
  const layoutDispatch = useLayoutDispatch();

  const handleInputChange = (prop) => (e) => {
    setFormData({
      ...formData,
      [prop]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      let dataParse = XLSX.utils.sheet_to_json(ws);
      let totalQty = 0;
      let totalCost = 0;
      dataParse = dataParse.reduce((res, d) => {
        let quantity = d.quantity || d["Order Qty."];
        let cost = d.cost || d["Unit Cost"];
        let item = d.item || d["Invertory ID"];
        let po = d.po || d["PO#"];
        if (item && cost && quantity) {
          res.push({ item, po, cost, quantity });
          totalQty += quantity;
          totalCost += quantity * cost;
        }
        return res;
      }, []);
      setTotals({ qty: totalQty, cost: totalCost });
      setFormData({ ...formData, items: dataParse });
    };
    reader.readAsBinaryString(f);
  };

  const handleSubmitClick = async (e) => {
    setDisabled(true);
    try {
      popupNotification({
        dispatch: layoutDispatch,
        status: "info",
        message: "Your request is processing, please wait...",
      });
      createContainerReceipt({
        body: formData,
        responseSetter: ({ receipt, file, status, message }) => {
          if (receipt) {
            popupNotification({
              dispatch: layoutDispatch,
              status: "success",
              message: `Receipt created: ${receipt}`,
            });
            setResult({
              receiptNo: receipt,
              fileURL: file,
              shouldFetch: false,
            });
            setFormData(initialFormData);
          } else if (status === "Error") {
            popupNotification({
              dispatch: layoutDispatch,
              status: "error",
              message,
            });
          } else {
            popupNotification({
              dispatch: layoutDispatch,
              status: "warning",
              message:
                "Cannot create receipt, check preview screen and try it later",
            });
          }
          setDisabled(false);
        },
      });
    } catch (error) {
      popupNotification({
        dispatch: layoutDispatch,
        status: "warning",
        message: "Your request is processing, please wait",
      });
      setResult({ receiptNo: null, fileURL: "", shouldFetch: true });
    }
  };

  useEffect(() => {
    if (!result.receiptNo && result.shouldFetch) {
      const readReceipt = () => {
        try {
          readContainerReceipt({
            body: formData,
            responseSetter: ({ items }) => {
              if (items.status === "Success") {
                setDisabled(false);
                setResult({
                  receiptNo: items.message,
                  fileURL: items.file,
                  shouldFetch: false,
                });
                setFormData(initialFormData);
              } else if (items.status === "Error") {
                setDisabled(false);
                popupNotification({
                  dispatch: layoutDispatch,
                  status: "error",
                  message: items.message,
                });
              } else if (items.status === "Not Completed") {
                popupNotification({
                  dispatch: layoutDispatch,
                  status: "warning",
                  message:
                    "Please do not refresh this page until you see an error message or the receipt link",
                });
                setTimeout(readReceipt, NOTIFICATION_DURATION);
              }
            },
          });
        } catch (error) {
          setTimeout(readReceipt, NOTIFICATION_DURATION);
        }
      };
      setTimeout(readReceipt, NOTIFICATION_DURATION);
    }
  }, [result, formData, layoutDispatch]);

  useEffect(() => {
    if (formData.items.length > 0) {
      const item = formData.items[0];
      if (item.is_valid === undefined) {
        let payload = { items: formData.items };
        if (formData.vendor && formData.vendor.acumatica_id) {
          payload.vendor = formData.vendor.acumatica_id;
        }
        validateContainerReceipt({
          body: payload,
          responseSetter: (items) => {
            setFormData({ ...formData, items });
          },
        });
      }
    }
  }, [formData]);

  useEffect(() => {
    if (container) {
      setFormData({
        ...initialFormData,
        vendor: container?.vendor || "",
        invoiceNumber: container?.invoice_number || "",
        containerNumber: container?.container_number || "",
        receiptType:
          container?.po_type === "Ecommerce"
            ? "Ecom"
            : container?.po_type === "National"
            ? "Nat"
            : container?.po_type === "Furniture"
            ? "Furn"
            : "Mix",
      });
    }
  }, [container]);

  return (
    <Dialog
      maxWidth="md"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
          if (result.receiptNo) {
            window.location.reload();
          }
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Upload Container Receipt - {container?.container_number} -{" "}
        {container?.po_type}
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.receiptContainer}>
            <Grid container>
              {result.receiptNo && (
                <Grid item xs={12}>
                  <h4>
                    Receipt:{" "}
                    <Link
                      href={`${ACUMATICA_BASE_URL}${RECEIPT_URL}${result.receiptNo}`}
                      color="primary"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "green" }}
                    >
                      {result.receiptNo}
                    </Link>
                  </h4>
                </Grid>
              )}
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} lg={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        exportToExcel(
                          [
                            {
                              po: "",
                              item: "",
                              quantity: "",
                              cost: "",
                            },
                          ],
                          "container_receipt_template",
                        );
                      }}
                    >
                      <CloudDownload color="#FFF" />
                      Template
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={3}>
                    <FormattedTextField
                      id="container_number"
                      label={"Container #"}
                      variant="outlined"
                      fullWidth
                      value={formData.containerNumber}
                      onChange={handleInputChange("containerNumber")}
                      LeftIcon={ClipboardList}
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} lg={2}>
                    <label htmlFor="file-upload-receipt">
                      <input
                        id="file-upload-receipt"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFileChange}
                        hidden
                      />
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        component="span"
                        disabled={disabled}
                        fullWidth
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              {formData.items.length > 0 &&
              formData.items[0].is_valid === undefined ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <CircularProgress size={30} />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {formData.items.length > 0 &&
            formData.items[0].is_valid !== undefined ? (
              <Widget style={{ width: "100%", marginTop: "1rem" }}>
                <Grid container>
                  <Grid item sm={12}>
                    <h3>Preview</h3>
                    <Divider />
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong>InventoryID</strong>
                              </TableCell>
                              <TableCell>
                                <strong>PO Number</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Quantity</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Unit Cost ($)</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formData.items.map((d, index) => {
                              return (
                                <TableRow
                                  key={"row_" + index}
                                  style={{
                                    backgroundColor:
                                      d.is_valid === false
                                        ? "#dc354590"
                                        : "white",
                                  }}
                                >
                                  <TableCell>{d.item}</TableCell>
                                  <TableCell>
                                    <Link
                                      href={`${ACUMATICA_BASE_URL}${PURCHASE_ORDER_URL}${d.po
                                        .toString()
                                        .padStart(6, "0")}`}
                                      color="secondary"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {`${d.po}${
                                        d.without_po ? " (New Line)" : ""
                                      }`}
                                    </Link>
                                  </TableCell>
                                  <TableCell>{d.quantity}</TableCell>
                                  <TableCell>{d.cost}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                      {formData.items.length > 0 ? (
                        <Grid item xs={12} lg={4}>
                          <Typography>Total Quantity: {totals.qty}</Typography>
                        </Grid>
                      ) : null}
                      {formData.items.length > 0 ? (
                        <Grid item xs={12} lg={4}>
                          <Typography>
                            Total Cost: ${totals.cost.toFixed(2)}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12} lg={4}>
                        <Button
                          disabled={disabled}
                          variant="contained"
                          color="secondary"
                          component="div"
                          onClick={handleSubmitClick}
                          style={{ float: "right" }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Widget>
            ) : null}
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onClose();
            if (result.receiptNo) {
              window.location.reload();
            }
          }}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
