import * as React from "react";

const SvgMail = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 4a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3Zm1 5.868-6.336 4.224a3 3 0 0 1-3.147.112l-.181-.112L4 9.87V17a1 1 0 0 0 .883.993L5 18h14a1 1 0 0 0 1-1V9.868ZM19 6H5a1 1 0 0 0-1 1v.464l7.445 4.964a1 1 0 0 0 .994.067l.116-.067L20 7.464V7a1 1 0 0 0-.883-.993L19 6Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgMail;
