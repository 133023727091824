import { useCallback, useEffect, useState } from "react";

import _ from "lodash";

import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
  assignedLocationsSummary,
  assignLocationsToCounters,
  getAssignedLocations,
  getLocationAssignData,
  reassignCountLocations,
  unassignCountLocations,
} from "api/locations";
import { getUsers } from "api/user";

import { ProductTypeFilter } from "components/Custom";
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  InputContainer,
  RangeInput,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";
import PageTitle from "components/PageTitle";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { ListAlt, Person, QrCode2, Selector, SpaceBar } from "icons";

import {
  initialAPIListValue,
  LOCATION_POSITIONS,
  ProductClasses,
} from "utils/constants";
import { timezoneFormat } from "utils/date";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { toTitleCase } from "utils/string";
import { getWithExpiry } from "utils/storage";

import AssigneeDetailModal from "./AssigneeDetailModal";

export default function CycleCountAssign() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const { related_product } = parseJwt(getWithExpiry("access"));
  const [params, setParams] = useState({
    date: "",
    location_code_start: "",
    position: "",
    location_type: related_product || 1,
    sku: "",
    class_name: "",
    hide_empty: true,
    is_duplicate: false,
    is_recount: false,
    min_location: 1,
    max_location: null,
    sku_check: false,
  });
  const [selectedUser, setSelectedUser] = useState({});
  const [inventoryGroupUsers, setInventoryGroupUsers] = useState(
    initialAPIListValue,
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [assignIsLoading, setAssignIsLoading] = useState(false);
  const [results, setResults] = useState(initialAPIListValue);
  const [assignedResults, setAssignedResults] = useState(initialAPIListValue);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("last_counted_at");
  const [selectedAssigneeDetails, setSelectedAssigneeDetails] = useState(
    initialAPIListValue,
  );
  const [skuList, setSkuList] = useState({});
  const [headerCounts, setHeaderCounts] = useState({});
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [recountCount, setRecountCount] = useState(0);
  const [skuCheckCount, setSKUCheckCount] = useState(0);

  const assignedResultHeaders = [
    {
      id: "assignee",
      label: "Counter",
      sortable: true,
      render: (r) => (
        <button
          onClick={() => handleDetailOpen(r)}
          style={{
            border: "none",
            textDecoration: "underline",
            color: "blue",
            background: "none",
            cursor: "pointer",
          }}
        >
          {toTitleCase(r.assignee)}
        </button>
      ),
    },
    {
      id: "total_assigned",
      label: "Assigned",
    },
    {
      id: "total_counted",
      label: "Counted (Today)",
    },
  ];

  const resultHeaders = [
    {
      id: "code",
      label: "Location",
      exportLabel: "Location",
      sortable: true,
    },
    {
      id: "inventory__product__sku",
      label: "SKU",
      exportLabel: "Inventory ID",
      render: (r) =>
        r.inventory_set.length > 1 ? (
          <Typography
            variant="body2"
            onClick={() => setSkuList(r)}
            style={{
              border: "none",
              textDecoration: "underline",
              color: "blue",
              background: "none",
              cursor: "pointer",
            }}
          >
            Multiple SKU ({r.inventory_set.length})
          </Typography>
        ) : r.inventory_set[0] ? (
          r.inventory_set[0].sku
        ) : (
          "-"
        ),
      sortable: true,
      style: { minWidth: "140px" },
    },
    {
      id: "total_qty",
      label: "Qty.",
      render: (r) =>
        r.inventory_set ? r.inventory_set.reduce((a, b) => a + b.qty, 0) : 0,
    },
    {
      id: "last_counter",
      label: "Counter",
      sortable: true,
      render: (r) => r.last_counter || "-",
    },
    {
      id: "last_counted_at",
      label: "Date",
      render: (r) =>
        r.last_counted_at ? timezoneFormat(r.last_counted_at) : "-",
      sortable: true,
    },
  ];

  useEffect(() => {
    if (inventoryGroupUsers.isFetching) {
      getUsers({
        params: {
          group: "Inventory",
          user__is_active: true,
          is_manager: false,
          is_listable: true,
        },
        setFunc: setInventoryGroupUsers,
        paginated: true,
      });
    }
  }, [inventoryGroupUsers.isFetching]);

  useEffect(() => {
    if (results.isFetching) {
      let filters = {
        ...params,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };

      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${
          filters.min_location > 1 || filters.max_location
            ? "inventory__product__sku"
            : orderBy
        }`;
        if (orderBy !== "code") {
          filters.ordering += ",code";
        }
      }

      getLocationAssignData({
        params: filters,
        setFunc: (res) => {
          setResults({
            items: res.items,
            isFetching: res.isFetching,
            count: res.count,
          });
          setHeaderCounts(res.customCounts);
        },
      });
    }
  }, [
    orderBy,
    orderDirection,
    page,
    params,
    results.isFetching,
    rowsPerPage,
    layoutDispatch,
  ]);

  useEffect(() => {
    getLocationAssignData({
      params: {
        is_duplicate: true,
      },
      setFunc: (res) => {
        setDuplicateCount(res.customCounts.code);
      },
    });
  }, []);

  useEffect(() => {
    getLocationAssignData({
      params: {
        is_recount: true,
      },
      setFunc: (res) => {
        setRecountCount(res.customCounts.code);
      },
    });
  }, []);

  useEffect(() => {
    getLocationAssignData({
      params: {
        sku_check: true,
      },
      setFunc: (res) => {
        setSKUCheckCount(res.customCounts.code);
      },
    });
  }, []);

  useEffect(() => {
    if (assignedResults.isFetching) {
      assignedLocationsSummary({
        responseSetter: (res) => {
          setAssignedResults({
            items: res.results.sort((a, b) =>
              a.assignee.localeCompare(b.assignee),
            ),
            isFetching: false,
            count: res.results.length,
          });
        },
      });
    }
  }, [assignedResults.isFetching]);

  const handleChangeFilterInput = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setParams((prev) => ({ ...prev, [prop]: !prev[prop] }));
    debouncedSearchHandler();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0);
    setResults({ ...results, isFetching: true });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setResults({
      ...results,
      isFetching: true,
    });
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    setResults({ ...results, isFetching: true });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setResults({ ...results, isFetching: true });
    }, 500),
    [],
  );

  const handleDetailOpen = (row) => {
    getAssignedLocations({
      params: {
        assignee_id: row.id,
        is_counted: false,
        is_cancelled: false,
        limit: 1000,
      },
      setFunc: setSelectedAssigneeDetails,
    });
  };

  const handleDetailClose = () => {
    setSelectedAssigneeDetails([]);
  };

  const handleAssignment = () => {
    setAssignIsLoading(true);
    assignLocationsToCounters({
      body: {
        location_ids: selectedLocations.map((l) => l.id),
        user_id: selectedUser.user.id,
      },
      responseSetter: (res) => {
        if (!res.success) {
          setAssignIsLoading(false);
          return popupNotification({
            dispatch: layoutDispatch,
            message: res.message,
            status: "error",
          });
        }
        setTimeout(() => window.location.reload(), 1500);
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
    });
  };

  const handleReassign = (locations, user) => {
    reassignCountLocations({
      body: {
        assignment_ids: locations.map((l) => l.id),
        user_id: user.user.id,
      },
      responseSetter: (res) => {
        if (!res.success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: res.message,
            status: "error",
          });
        }
        setTimeout(() => window.location.reload(), 1500);
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
    });
  };

  const handleUnassign = (locations) => {
    unassignCountLocations({
      body: {
        assignment_ids: locations.map((l) => l.id),
      },
      responseSetter: (res) => {
        if (!res.success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: res.message,
            status: "error",
          });
        }
        setTimeout(() => window.location.reload(), 1500);
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
    });
  };

  return (
    <>
      <PageTitle title="Assign" backLink="/app/cycle-counts" />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DatePicker
              id="last_count_date"
              title="Last Count Date"
              value={params.date}
              onChange={handleChangeFilterInput("date")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              fullWidth
              value={selectedUser && selectedUser.id ? selectedUser : null}
              options={inventoryGroupUsers.items.sort((a, b) =>
                a.user.first_name.localeCompare(b.user.first_name),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.user.first_name}
              onChange={(e, user) => setSelectedUser(user || {})}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="User"
                  variant="outlined"
                  LeftIcon={Person}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="location"
              label={`${t("location")} (*)`}
              variant="outlined"
              title="Searches from beginning"
              value={params.location_code_start}
              onChange={handleChangeFilterInput("location_code_start")}
              onKeyUp={debouncedSearchHandler}
              isLocationCode={true}
              LeftIcon={SpaceBar}
              InputProps={clearAdornment("location_code_start")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="position"
              title={t("position")}
              value={params.position}
              onChange={handleChangeFilterInput("position")}
              items={LOCATION_POSITIONS}
              LeftIcon={Selector}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label="SKU"
              variant="outlined"
              value={params.sku}
              onChange={handleChangeFilterInput("sku")}
              onKeyUp={debouncedSearchHandler}
              LeftIcon={QrCode2}
              InputProps={clearAdornment("sku")}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Dropdown
              id="class_name"
              title="Size"
              value={params.class_name}
              onChange={handleChangeFilterInput("class_name")}
              items={ProductClasses}
              LeftIcon={ListAlt}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={6} sm={3}>
                <Checkbox
                  checked={params.hide_empty}
                  onChange={handleChangeCheckbox("hide_empty")}
                  color="blue"
                  label="Hide Empty"
                  title="Hides empty locations."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Checkbox
                  checked={params.is_duplicate}
                  onChange={handleChangeCheckbox("is_duplicate")}
                  color="blue"
                  label={`Duplicates (${duplicateCount})`}
                  title="Show duplicate locations."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Checkbox
                  checked={params.is_recount}
                  onChange={handleChangeCheckbox("is_recount")}
                  color="blue"
                  label={`Recount (${recountCount})`}
                  title="Show locations need to be counted again."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Checkbox
                  checked={params.sku_check}
                  onChange={handleChangeCheckbox("sku_check")}
                  color="blue"
                  label={`To Check (${skuCheckCount})`}
                  title="Show locations need SKU Check."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <RangeInput
              label="Loc"
              minKey="min_location"
              maxKey="max_location"
              onChange={handleChangeFilterInput}
              params={params}
            />
          </Grid>
          <ProductTypeFilter
            value={params.location_type}
            setValue={(data) => {
              setParams({ ...params, location_type: data });
              debouncedSearchHandler();
            }}
            onChange={handleChangeFilterInput("location_type")}
          />
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="blue"
              disabled={
                !selectedLocations.length || !selectedUser.id || assignIsLoading
              }
              onClick={handleAssignment}
            >
              {assignIsLoading ? <CircularProgress size={10} /> : null}
              Assign ({selectedLocations.length})
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={7} md={12} xs={12}>
          <DefaultTable
            headers={resultHeaders}
            headerCounts={headerCounts}
            data={results}
            hasCheckBox={true}
            selected={selectedLocations}
            setSelected={setSelectedLocations}
            uniqueIdCount={_.uniqBy(results.items, "id").length}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortActions={{
              onSort: (header) => {
                const isAsc = orderBy === header && orderDirection === "asc";
                const newDirection = isAsc ? "desc" : "asc";
                setOrderDirection(newDirection);
                setOrderBy(header);
                setPage(0);
                setResults({ ...results, isFetching: true });
              },
              orderBy,
              orderDirection,
            }}
            filters={params}
          />
        </Grid>
        <Box
          component={Grid}
          item
          lg={1}
          display={{
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
          }}
          style={{ borderRight: "none" }}
        />
        <Grid item lg={4} md={12} xs={12}>
          <DefaultTable
            headers={assignedResultHeaders}
            data={assignedResults}
            filters={params}
            footerVisible={false}
          />
        </Grid>
      </Grid>
      {selectedAssigneeDetails.items && selectedAssigneeDetails.items.length ? (
        <AssigneeDetailModal
          assignedLocations={selectedAssigneeDetails.items.sort((a, b) =>
            a.location.code.localeCompare(b.location.code),
          )}
          headers={[
            {
              id: "location.code",
              label: "Location",
              render: (r) => r.location.code,
            },
            {
              id: "product.sku",
              label: "SKU",
              render: (r) => r.products.map((p) => p.sku).join(", ") || "-",
            },
            {
              id: "total_qty",
              label: "Qty.",
            },
          ]}
          inventoryGroupUsers={inventoryGroupUsers}
          onClose={handleDetailClose}
          onReassign={handleReassign}
          onUnassign={handleUnassign}
        />
      ) : null}
      {Object.keys(skuList).length ? (
        <SKUModal row={skuList} onClose={() => setSkuList({})} />
      ) : null}
    </>
  );
}

function SKUModal({ row, onClose }) {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle> {row.code} </DialogTitle>
      <DialogContent>
        <DefaultTable
          headers={[{ id: "sku" }, { id: "qty" }]}
          data={{
            items: row.inventory_set,
            isFetching: false,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
