function compareValues(key, order = "asc") {
  const props = key.split(".");
  return function innerSort(a, b) {
    let varA = "";
    let varB = "";

    let i = 0;
    while (i < props.length) {
      varA = typeof varA === "object" ? varA[props[i]] : a[props[i]];
      varB = typeof varB === "object" ? varB[props[i]] : b[props[i]];
      i++;
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export { compareValues };
