import { makeStyles } from "@material-ui/styles";
import { blueGrey, blue, green, orange, brown } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  borderedButton: {
    border: "2px solid #fff",
  },
  drawerPaper: {
    overflowX: "hidden",
    overflowBlock: "scroll",
  },
  radioButton: {
    "& > span": {
      fontSize: "0.8125rem",
      fontFamily: "Roboto",
      color: "#484848",
    },
  },
  inputLabel: {
    fontSize: "0.8125rem",
    fontFamily: "Roboto",
    color: "#a1a1a1",
    paddingLeft: "1rem",
    paddingTop: "0.4rem",
  },
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingBottom: 20,
    width: "80vw",
  },
  receiptContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingBottom: 20,
    width: "50vw",
  },
  actionButton: {
    width: "10rem",
    float: "right",
  },
  mr10: {
    marginRight: 10,
  },
  mb10: {
    marginBottom: 10,
  },
  mt10: {
    marginTop: 10,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  ecomBadge: {
    marginLeft: "1rem",
    marginRight: "1.1rem",
    "& span": {
      minWidth: "3.5rem",
      backgroundColor: green[200],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  natBadge: {
    marginLeft: "1rem",
    marginRight: "1.1rem",
    "& span": {
      minWidth: "3.5rem",
      backgroundColor: blue[200],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  mixBadge: {
    marginLeft: "1rem",
    marginRight: "1.1rem",
    "& span": {
      minWidth: "3.5rem",
      backgroundColor: orange[200],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  furnBadge: {
    marginLeft: "1rem",
    marginRight: "1.1rem",
    "& span": {
      minWidth: "3.5rem",
      backgroundColor: brown[200],
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  },
  containerStatusLine: {
    width: "5px",
    height: "30px",
    position: "relative",
    left: "-5px",
    borderRadius: "3px",
  },
  customerContent: {
    marginLeft: "0.5rem",
  },
  subtitle: {
    fontSize: "0.6rem",
    opacity: 0.6,
  },
  mlAuto: {
    marginLeft: "auto",
  },
  notesContainer: {
    paddingLeft: "1.2rem",
  },
  notesLabelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "1.6rem",
    marginBottom: "0.5rem",
  },
  notesLabel: {
    fontSize: "0.8125rem",
    fontFamily: "Roboto",
    color: "#a1a1a1",
  },
  notesInputContainer: {
    marginTop: "1.5rem",
  },
  notesInput: {
    color: "#484848",
    fontSize: "0.8125rem",
    fontFamily: "Roboto",
    minHeight: "6rem",
    alignItems: "start",
    paddingTop: "0.5rem",
  },
}));
