import { useTheme } from "@material-ui/styles";

export default function StatusLabel({ status, text, altText }) {
  const theme = useTheme();

  const statusStyles = {
    "Unassigned, Open, Confirmed, Created, -": {
      color: theme.palette.colors.slate700,
      backgroundColor: `${theme.palette.colors.slate200}50`,
    },
    Printed: {
      color: theme.palette.colors.amber600,
      backgroundColor: `${theme.palette.colors.amber100}50`,
    },
    Picking: {
      color: `${theme.palette.colors.pastelOrange}`,
      backgroundColor: `${theme.palette.colors.pastelOrange}50`,
    },
    "Assigned, In_Progress, No Purchases, Warning, Packing, Defective, Mixed": {
      color: theme.palette.colors.amber700,
      backgroundColor: `${theme.palette.colors.amber200}50`,
    },
    Picked: {
      color: theme.palette.colors.blue700,
      backgroundColor: `${theme.palette.colors.blue200}50`,
    },
    Packed: {
      color: theme.palette.colors.pastelBlue,
      backgroundColor: `${theme.palette.colors.pastelBlue}50`,
    },
    "Shipped, Active, Ready, Good, Positive": {
      color: theme.palette.colors.emerald700,
      backgroundColor: `${theme.palette.colors.emerald200}50`,
    },
    "Invoiced, Completed, Yes, True": {
      color: theme.palette.colors.emerald700,
      backgroundColor: `${theme.palette.colors.emerald500}50`,
    },
    Default: {
      color: theme.palette.colors.rose700,
      backgroundColor: `${theme.palette.colors.rose200}50`,
    },
  };

  const getStatusStyle = (status) => {
    let statusStyle = "Default";
    Object.keys(statusStyles).forEach((key) => {
      const statuses = key.split(",").map((k) => k.trim());
      if (statuses.includes(status)) {
        statusStyle = key;
      }
    });

    return statusStyles[statusStyle];
  };

  return (
    <span
      style={{
        ...getStatusStyle(status),
        borderRadius: "1rem",
        padding: "0.25rem 1rem",
        minWidth: "6rem",
        textAlign: "center",
        display: "table-cell",
      }}
      variant="filled"
      title={altText ? altText : ""}
    >
      {text ? text : status.split("_").join(" ")}
    </span>
  );
}
