import { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { getShipments, importLTLShipment } from "api/shipment";

import { ProductTypeFilter, StatusLabel } from "components/Custom";
import { InternalLink } from "components/Custom/Links";
import PickStatus from "components/Custom/PickStatus";
import {
  Button,
  DatePickerGroup,
  Dropdown,
  InputContainer,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { Class, FolderSpecial, SnippetFolder } from "icons";

import { initialAPIListValue, ShipmentStatus } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { toTitleCase } from "utils/string";

import ActionButtons from "./ActionButtons";
import { useUserState } from "context/UserContext";

export default function LTLShipments() {
  const [t] = useTranslation();
  const claims = parseJwt(getWithExpiry("access"));
  const layoutDispatch = useLayoutDispatch();
  const { related_product } = claims;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useState({
    is_small_carrier: false, // default
    with_details: true,
    status: "",
    shipment_number: "",
    order__po_number: "",
    order__customer_id: "",
    product_type: related_product || 1,
    start_date: "",
    end_date: "",
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_date");
  const [shipments, setShipments] = useState(initialAPIListValue);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});
  const [importShipment, setImportShipment] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [shipmentNumber, setShipmentNumber] = useState("");
  const { permissions } = useUserState();
  const hasPermission = permissions.includes("shipment.add_shipment");

  const headers = [
    {
      id: "shipment_number",
      render: (r, exported) =>
        exported ? (
          r.shipment_number
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "normal",
              alignContent: "center",
            }}
          >
            <InternalLink
              to={`/app/ltl/shipments/${r.id}`}
              children={r.shipment_number}
            />
            {!r.shipped_at ? (
              <ActionButtons
                shipment={r}
                reloadShipments={() =>
                  setShipments({ ...shipments, isFetching: true })
                }
              />
            ) : null}
          </div>
        ),
      style: {
        minWidth: "150px",
      },
      sortable: true,
    },
    {
      id: "customer",
      render: (r) => toTitleCase(r.order.customer_id),
    },
    {
      id: "po_number",
      render: (r) => toTitleCase(r.order.po_number),
    },
    {
      id: "qty",
      render: (r, exported) => {
        const details = r.details;
        let picked_qty = 0;
        let total_qty = 0;
        details.forEach((detail) => {
          total_qty += detail.quantity;
          const transactions = detail.transactions;
          picked_qty += transactions
            .filter((t) => t.action_type === "Picked")
            .reduce((total, transaction) => total + transaction.quantity, 0);
        });

        if (exported) {
          return `(${picked_qty}/${total_qty})`;
        }

        return <PickStatus picked_qty={picked_qty} total_qty={total_qty} />;
      },
    },
    {
      id: "ship_via",
      render: (r) => toTitleCase(r.ship_via),
      style: {
        minWidth: "120px",
      },
    },
    {
      id: "created_date",
      label: "Created / Requested / Shipped At",
      render: (r) =>
        `${timezoneFormat(r.created_date, true)} ${
          r.estimated_date && r.created_date !== r.estimated_date
            ? ` - ${timezoneFormat(r.estimated_date, true)}`
            : r.shipped_at
            ? " - / "
            : ""
        } ${r.shipped_at ? ` - ${timezoneFormat(r.shipped_at, true)}` : ""}`,
      sortable: true,
    },
    {
      id: "status",
      render: (r, exported) =>
        exported ? (
          toTitleCase(r.status)
        ) : (
          <StatusLabel status={toTitleCase(r.status)} />
        ),
    },
  ];

  useEffect(() => {
    if (shipments.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getShipments({
        params,
        setFunc: (res) => {
          setShipments(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    rowsPerPage,
    shipments.isFetching,
    orderDirection,
    orderBy,
  ]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setShipments({ ...shipments, isFetching: true });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setShipments((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const handleChangeFilterSelect = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `LTL-Shipments-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, headers),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (shipments.items.length === shipments.count) {
      exportData(shipments.items);
    } else {
      getShipments({
        params: {
          ...search,
          limit: shipments.count,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const handleImportLTLShipment = () => {
    setImportLoading(true);
    importLTLShipment({
      shipmentNumber: shipmentNumber,
      responseSetter: ({ success, message }) => {
        setImportLoading(false);
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        setShipmentNumber("");
        setImportShipment(false);
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setShipments({ ...shipments, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("ltl_shipments")} />
      <InputContainer>
        <Grid container>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterSelect("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterSelect("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="shipment_number"
              label={t("shipment_number")}
              variant="outlined"
              fullWidth
              value={search.shipment_number}
              onChange={handleChangeFilterInput("shipment_number")}
              InputProps={clearAdornment("shipment_number")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="customer"
              label={t("customer")}
              variant="outlined"
              fullWidth
              value={search.order__customer_id}
              onChange={handleChangeFilterInput("order__customer_id")}
              InputProps={clearAdornment("order__customer_id")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="po_number"
              label="PO #"
              variant="outlined"
              fullWidth
              value={search.order__po_number}
              onChange={handleChangeFilterInput("order__po_number")}
              InputProps={clearAdornment("order__po_number")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="status"
              title={t("status")}
              value={search.status}
              onChange={handleChangeFilterSelect("status")}
              items={ShipmentStatus.map((item) => ({
                value: item,
                label: item,
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <ProductTypeFilter
            value={search.product_type}
            setValue={(product_type) => setSearch({ ...search, product_type })}
            onChange={handleChangeFilterSelect("product_type")}
          />
          {hasPermission ? (
            <Grid xs={12} lg={2} md={4} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setImportShipment(true)}
              >
                Import Shipment
              </Button>
              {importShipment ? (
                <Dialog
                  open={true}
                  onClose={() =>
                    !importLoading ? setImportShipment(false) : null
                  }
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle> Import LTL Shipment </DialogTitle>
                  <DialogContent>
                    <FormattedTextField
                      autoFocus
                      fullWidth
                      name="shipment_number"
                      label="Shipment Number"
                      variant="contained"
                      placeholder="Please type shipment number"
                      disabled={importLoading}
                      value={shipmentNumber}
                      onChange={({ target: { value } }) =>
                        setShipmentNumber(value)
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={importLoading}
                      onClick={() => setImportShipment(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={importLoading}
                      onClick={() => handleImportLTLShipment()}
                    >
                      {importLoading ? <CircularProgress size={10} /> : null}
                      Import
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={shipments}
            headers={headers}
            headerCounts={headerCounts}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
