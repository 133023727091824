import React, { useEffect, useState } from "react";

import { Button, ButtonGroup, CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { getUsers } from "api/user";
import { getPickerPerformance } from "api/shipment";

import DashboardCard from "components/Card/DashboardCard";
import { DonutChart } from "components/Charts/Donut";
import { InternalLink } from "components/Custom/Links";
import { Spacer } from "components/Custom";
import { DatePickerGroup, Dropdown, InputContainer } from "components/Form";
import DefaultTable from "components/Table";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { Person, BarChart, Toc, Timelapse, Sync } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { convertDate, formatDateList, timeDiffCalc } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";

const cells = [
  {
    id: "shipment_number",
    render: (r, exported) =>
      exported ? (
        r.shipment_number
      ) : (
        <InternalLink
          to={`/app/shipments/${r.shipment_id}`}
          children={r.shipment_number}
        />
      ),
  },
  {
    id: "product_sku",
    exportLabel: "Inventory ID",
  },
  {
    id: "location",
    render: (r) => r.location_code,
  },
  {
    id: "assigned_picked",
    label: "Assigned -> Picked",
    render: (r) =>
      formatDateList([
        r.assigned_at ? r.assigned_at : "N/A",
        r.picked_at ? r.picked_at : "N/A",
      ]),
  },
  {
    id: "Time Diffrence (Assigned to Picked)",
    render: (r) =>
      r.picked_at && r.assigned_at
        ? timeDiffCalc(new Date(r.picked_at), new Date(r.assigned_at)).text
        : "N/A",
  },
];

const date = new Date();
const end_date = convertDate(date);
date.setDate(1);
const start_date = convertDate(date);

export default function PickerPerformanceReport() {
  const [data, setData] = useState(initialAPIListValue);
  const [pickers, setPickers] = useState(initialAPIListValue);
  const [graphicalView, setGraphicalView] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [params, setParams] = useState({
    user_id: "",
    start_date,
    end_date,
  });

  useEffect(() => {
    getUsers({
      params: {
        group: "Picker",
        user__is_active: true,
        is_manager: false,
        is_listable: true,
        limit: 100,
      },
      setFunc: setPickers,
      paginated: true,
    });
  }, []);

  useEffect(() => {
    if (data.isFetching && params.user_id) {
      getPickerPerformance({
        params,
        responseSetter: (res) => {
          handleResponse(res);
        },
      });
    }
  }, [data.isFetching, params]);

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
    setData((prev) => ({ ...prev, isFetching: true }));
  };

  const handleResponse = (results) => {
    const transaction_records = results;
    const details = [];
    const unique_shipment_details = [
      ...new Map(
        results.map((item) => [
          item["detail"]["id"],
          { ...item.detail, shipment_id: item.shipment_id },
        ]),
      ).values(),
    ];

    transaction_records.forEach((item) => {
      const detail_id = item.detail.id;
      const shipment_detail = unique_shipment_details.find(
        (item) => item.id === detail_id,
      );
      if (shipment_detail) {
        shipment_detail[`${item.action_type.toLowerCase()}_at`] =
          item.transaction_time;
      }
    });
    setData({
      isFetching: false,
      items: unique_shipment_details,
      count: unique_shipment_details.length,
    });
    return details;
  };

  const handleExportData = () => {
    const { user } = pickers.items.find(
      (picker) => picker.user.id === params.user_id,
    );

    exportToExcel(
      [],
      `Picker-Performance-${start_date}-to-${end_date}-${user.username}`,
      true,
      convertDataToTableView(data.items, cells),
      setExportDisabled,
    );
  };

  return (
    <>
      <PageTitle title="Picker Performance" />
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <InputContainer>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Dropdown
                  all_option_active={false}
                  title="Picker"
                  items={pickers.items.map((picker) => ({
                    value: picker.user.id,
                    label: picker.display_name,
                  }))}
                  onChange={handleChangeFilterInput("user_id")}
                  LeftIcon={Person}
                />
              </Grid>
              <DatePickerGroup
                startDateValue={params.start_date}
                startDateOnChange={handleChangeFilterInput("start_date")}
                endDateValue={params.end_date}
                endDateOnChange={handleChangeFilterInput("end_date")}
                itemGridProps={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                  lg: 3,
                }}
              />
            </Grid>
          </InputContainer>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            button={
              <ButtonGroup disableElevation variant="contained">
                <Button onClick={() => setGraphicalView(!graphicalView)}>
                  {graphicalView ? <Toc /> : <BarChart />}
                </Button>
              </ButtonGroup>
            }
          >
            <Grid container spacing={4}>
              {!params.user_id ? (
                <Grid item lg={12}>
                  <Alert severity="warning"> Please select a picker </Alert>
                </Grid>
              ) : graphicalView ? (
                <GraphicalView
                  transactions={data.items}
                  params={params}
                  isFetching={data.isFetching}
                />
              ) : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <DefaultTable
                    data={data}
                    headers={cells}
                    handleExportData={handleExportData}
                    exportDisabled={exportDisabled}
                    setExportDisabled={setExportDisabled}
                    filters={params}
                  />
                </Grid>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

const colors = {
  pick: "#2196F3",
  assign: "#4CAF50",
  fedex: "#4D148C",
  ups: "#FFB500",
  rug: "#1565C0",
  furniture: "#F9A825",
  daily_transactions: "#FB8C00",
  average_pick: "#1E88E5",
  average_ship: "#43A047",
};

const classSizeColors = [
  "#90CAF9",
  "#81D4FA",
  "#80DEEA",
  "#80CBC4",
  "#A5D6A7",
  "#C5E1A5",
  "#E6EE9C",
  "#FFE082",
];

function GraphicalView({ transactions, isFetching }) {
  const dates = [
    ...new Set(
      transactions
        .filter((t) => t.picked_at)
        .map((t) => (t.picked_at ? t.picked_at.split("T")[0] : null)),
    ),
  ];

  const classSizesData = {
    "2x/3x": 0,
    "4x": 0,
    "5x": 0,
    "6x/7x/8x": 0,
    "9x/10x/11x/12x": 0,
    "SET3/SET4/SET5": 0,
    Other: 0,
  };

  const productTypeData = {
    Rug: 0,
    Furniture: 0,
  };

  const carrierData = {
    FEDEX: 0,
    UPS: 0,
  };

  const pickTransactions = transactions.filter((t) => t.picked_at);
  const getDaily = pickTransactions.length / dates.length;
  const assignedTransactions = transactions.filter((t) => t.assigned_at);
  const totalPickTime = pickTransactions
    .filter((t) => t.assigned_at)
    .reduce(
      (total, t) =>
        total +
        Number(
          timeDiffCalc(new Date(t.picked_at), new Date(t.assigned_at))
            .totalMinutes,
        ),
      0,
    );

  pickTransactions.forEach((transaction) => {
    const carrier = transaction.ship_via
      ? transaction.ship_via.split(" ")[0]
      : "Other";
    classSizesData[transaction.product_class] += 1;
    productTypeData[transaction.product_type_display] += 1;
    carrierData[carrier] += 1;
  });

  const averagePickTime = totalPickTime
    ? (totalPickTime / pickTransactions.length).toFixed(2)
    : "-";

  return isFetching ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={4}>
      {!transactions.length || !getDaily ? (
        <Grid item lg={12}>
          <Spacer height="20px" />
          <Alert fullWidth severity="info">
            No results found.
          </Alert>
        </Grid>
      ) : (
        <>
          <DashboardCard
            itemSize={6}
            value={`${getDaily.toFixed(2)}`}
            info={"Transactions(Daily Average)"}
            icon={<Sync color={colors.daily_transactions} />}
            bgColor={colors.daily_transactions}
          />
          <DashboardCard
            itemSize={6}
            value={`${averagePickTime} minute(s)`}
            info={"Average Pick Time"}
            icon={<Timelapse />}
            bgColor={colors.average_pick}
          />
          <Grid item lg={3}>
            <DonutChart
              series={[assignedTransactions.length, pickTransactions.length]}
              labels={["Assign", "Pick"]}
              title="Assign / Pick (All)"
              colors={[colors.assign, colors.pick]}
            />
          </Grid>
          <Grid item lg={3}>
            <DonutChart
              series={Object.entries(classSizesData).map((item) => item[1])}
              labels={Object.keys(classSizesData)}
              title="Class Sizes (All)"
              colors={classSizeColors}
            />
          </Grid>

          <Grid item lg={3}>
            <DonutChart
              series={Object.entries(carrierData).map((item) => item[1])}
              labels={Object.keys(carrierData)}
              title="Fedex/ Ups (All)"
              colors={[colors.fedex, colors.ups]}
            />
          </Grid>

          <Grid item lg={3}>
            <DonutChart
              series={Object.entries(productTypeData).map((item) => item[1])}
              labels={Object.keys(productTypeData)}
              title="Rug / Furniture (All)"
              colors={[colors.rug, colors.furniture]}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
