import React, { useEffect, useState } from "react";

import { CircularProgress, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import CarrierData from "components/Card/CarrierData";

import { Spacer } from "components/Custom";
import WidgetBox, { TooltipWidgetBox } from "components/Dashboard/WidgetBox";
import PageTitle from "components/PageTitle";

import { getSnapshots, getTransactionDetails } from "api/shipment";

import { addParamsToEndpoint } from "utils/api";
import { isExpress } from "utils/carrier";
import { initialAPIListValue } from "utils/constants";
import DefaultTable from "components/Table";

const initialExpressData = {
  printed: { fedex: 0, ups: 0 },
  assigned: { fedex: 0, ups: 0 },
  picked: { fedex: 0, ups: 0 },
  notPicked: { fedex: 0, ups: 0 },
  shipped: { fedex: 0, ups: 0 },
  notShipped: { fedex: 0, ups: 0 },
};

const initialReportData = {
  fedex: 0,
  ups: 0,
  rug: 0,
  furniture: 0,
  total: 0,
  isFetching: true,
};

const snapshotHeaders = [
  { id: "date" },
  { id: "printed" },
  { id: "assigned" },
  { id: "not_picked", label: "Not Picked" },
  { id: "picked" },
  { id: "not_shipped", label: "Not Shipped" },
  { id: "shipped" },
];

export default function ShippingDashboard() {
  const [printed, setPrinted] = useState(initialReportData);
  const [assigned, setAssigned] = useState(initialReportData);
  const [picked, setPicked] = useState(initialReportData);
  const [notPicked, setNotPicked] = useState(initialReportData);
  const [shipped, setShipped] = useState(initialReportData);
  const [notShipped, setNotShipped] = useState(initialReportData);
  const [express, setExpress] = useState(initialExpressData);
  const [isFetching, setIsFetching] = useState(true);
  const [snapshots, setSnapshots] = useState(initialAPIListValue);

  const theme = useTheme();

  useEffect(() => {
    getTransactionDetails({
      responseSetter: (res) => {
        const expressData = initialExpressData;
        const printedData = res.printed.reduce((result, detail) => {
          const { carrier, product_type_display, quantity, ship_via } = detail;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.printed[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        const assignedData = res.assigned.reduce((result, transaction) => {
          const {
            carrier,
            product_type_display,
            ship_via,
          } = transaction.detail;
          const quantity = transaction.quantity;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.assigned[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        const pickedData = res.picked.reduce((result, transaction) => {
          const {
            carrier,
            product_type_display,
            ship_via,
          } = transaction.detail;
          const quantity = transaction.quantity;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.picked[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        const notPickedData = res.not_picked.reduce((result, transaction) => {
          const {
            carrier,
            product_type_display,
            ship_via,
          } = transaction.detail;
          const quantity = transaction.quantity;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.notPicked[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        const shippedData = res.shipped.reduce((result, transaction) => {
          const {
            carrier,
            product_type_display,
            ship_via,
          } = transaction.detail;
          const quantity = transaction.quantity;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.shipped[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        const notshippedData = res.not_shipped.reduce((result, detail) => {
          const { carrier, product_type_display, quantity, ship_via } = detail;
          if (!carrier) {
            return result;
          }
          result[carrier.toLowerCase()] += quantity;
          result[product_type_display.toLowerCase()] += quantity;
          if (isExpress(ship_via)) {
            expressData.notShipped[carrier.toLowerCase()] += quantity;
          }
          return { ...initialReportData, ...result };
        }, Object.create(initialReportData));
        if (printedData && Object.keys(printedData).length) {
          setPrinted({
            ...printedData,
            total: (printedData.fedex || 0) + (printedData.ups || 0),
            isFetching: false,
          });
        } else {
          setPrinted({ ...initialReportData, isFetching: false });
        }
        if (assignedData && Object.keys(assignedData).length) {
          setAssigned({
            ...assignedData,
            total: (assignedData.fedex || 0) + (assignedData.ups || 0),
            isFetching: false,
          });
        } else {
          setAssigned({ ...initialReportData, isFetching: false });
        }
        if (pickedData && Object.keys(pickedData).length) {
          setPicked({
            ...pickedData,
            total: (pickedData.fedex || 0) + (pickedData.ups || 0),
            isFetching: false,
          });
        } else {
          setPicked({ ...initialReportData, isFetching: false });
        }
        if (notPickedData && Object.keys(notPickedData).length) {
          setNotPicked({
            ...notPickedData,
            total: (notPickedData.fedex || 0) + (notPickedData.ups || 0),
            isFetching: false,
          });
        } else {
          setNotPicked({ ...initialReportData, isFetching: false });
        }
        if (shippedData && Object.keys(shippedData).length) {
          setShipped({
            ...shippedData,
            total: (shippedData.fedex || 0) + (shippedData.ups || 0),
            isFetching: false,
          });
        } else {
          setShipped({ ...initialReportData, isFetching: false });
        }
        if (notshippedData && Object.keys(notshippedData).length) {
          setNotShipped({
            ...notshippedData,
            total: (notshippedData.fedex || 0) + (notshippedData.ups || 0),
            isFetching: false,
          });
        } else {
          setNotShipped({ ...initialReportData, isFetching: false });
        }
        setExpress({ ...expressData, isFetching: false });
        setIsFetching(false);
      },
    });
  }, []);

  useEffect(() => {
    getSnapshots({
      params: {
        limit: 10,
      },
      setFunc: setSnapshots,
    });
  }, []);

  const Loading = () => (
    <Grid
      item
      xs={12}
      lg={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        padding: 0,
        margin: "0px auto",
      }}
    >
      <CircularProgress size={50} />
    </Grid>
  );

  return (
    <>
      <PageTitle title="Shipping Dashboard" />
      <Grid container spacing={6} wrap="nowrap">
        {!isFetching ? (
          <>
            <Grid item xs={12} lg={2}>
              <WidgetBox
                id="printed"
                title={`Printed (${printed.total})`}
                bgColor={
                  printed.total
                    ? theme.palette.colors.emerald500
                    : theme.palette.colors.rose200
                }
                onClick={() =>
                  window.open(
                    addParamsToEndpoint("/#/app/transaction-report", {
                      status: "Printed",
                    }),
                    "_blank",
                  )
                }
              >
                <CarrierData
                  fedex={printed.fedex}
                  ups={printed.ups}
                  express={express.printed}
                />
              </WidgetBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <WidgetBox
                id="assigned"
                title={`Assigned (${assigned.total})`}
                bgColor={
                  assigned.total
                    ? theme.palette.colors.emerald500
                    : theme.palette.colors.rose200
                }
                onClick={() =>
                  window.open(
                    addParamsToEndpoint("/#/app/transaction-report", {
                      status: "Assigned",
                    }),
                    "_blank",
                  )
                }
              >
                <CarrierData
                  fedex={assigned.fedex}
                  ups={assigned.ups}
                  express={express.assigned}
                />
              </WidgetBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <TooltipWidgetBox text="Assigned today but not picked">
                <WidgetBox
                  id="not_picked"
                  title={`Not Picked (${notPicked.total})`}
                  bgColor={
                    notPicked.total > 100
                      ? theme.palette.colors.rose200
                      : notPicked.total > 50
                      ? theme.palette.colors.rose100
                      : notPicked.total > 10
                      ? theme.palette.colors.emerald100
                      : theme.palette.colors.emerald200
                  }
                  onClick={() =>
                    window.open(
                      addParamsToEndpoint("/#/app/transaction-report", {
                        status: "Assigned",
                      }),
                      "_blank",
                    )
                  }
                >
                  <CarrierData
                    fedex={notPicked.fedex}
                    ups={notPicked.ups}
                    express={express.notPicked}
                  />
                </WidgetBox>
              </TooltipWidgetBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <WidgetBox
                id="picked"
                title={`Picked (${picked.total})`}
                bgColor={
                  picked.total
                    ? theme.palette.colors.emerald500
                    : theme.palette.colors.rose200
                }
                onClick={() =>
                  window.open(
                    addParamsToEndpoint("/#/app/transaction-report", {
                      status: "Picked",
                    }),
                    "_blank",
                  )
                }
              >
                <CarrierData
                  fedex={picked.fedex}
                  ups={picked.ups}
                  express={express.picked}
                />
              </WidgetBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <WidgetBox
                id="shipped"
                title={`Shipped (${shipped.total})`}
                bgColor={
                  shipped.total
                    ? theme.palette.colors.emerald500
                    : theme.palette.colors.rose200
                }
                onClick={() =>
                  window.open(
                    addParamsToEndpoint("/#/app/transaction-report", {
                      status: "Shipped",
                    }),
                    "_blank",
                  )
                }
              >
                <CarrierData
                  fedex={shipped.fedex}
                  ups={shipped.ups}
                  express={express.shipped}
                />
              </WidgetBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <TooltipWidgetBox text="Waiting for shipping (all time)">
                <WidgetBox
                  id="not_shipped"
                  title={`Not Shipped (${notShipped.total})`}
                  bgColor={
                    notShipped.total > 100
                      ? theme.palette.colors.rose200
                      : notShipped.total > 50
                      ? theme.palette.colors.rose100
                      : notShipped.total > 10
                      ? theme.palette.colors.emerald100
                      : theme.palette.colors.emerald200
                  }
                  onClick={() =>
                    window.open(
                      addParamsToEndpoint("/#/app/transaction-report", {
                        status: "Picked",
                      }),
                      "_blank",
                    )
                  }
                >
                  <CarrierData
                    fedex={notShipped.fedex}
                    ups={notShipped.ups}
                    express={express.notShipped}
                  />
                </WidgetBox>
              </TooltipWidgetBox>
            </Grid>
          </>
        ) : (
          <Loading />
        )}
      </Grid>
      <Spacer height="5vh" />
      <DefaultTable headers={snapshotHeaders} data={snapshots} />
    </>
  );
}
