import React, { useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useUserDispatch, loginUser } from "context/UserContext";

import useStyles from "./styles";
import WmsJPGLogo from "components/Logos/wms";
import TayseJPGLogo from "components/Logos/tayse";

import { updateDocumentTitle } from "utils/dom";

function Login() {
  const { t } = useTranslation();
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  updateDocumentTitle("Login");
  const login = () => {
    setError(null);
    loginUser(userDispatch, loginValue, passwordValue, setIsLoading, setError);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item lg={5}></Grid>
      <Grid item lg={7} className={classes.rightContainer}>
        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.logotypeContainer}>
              <TayseJPGLogo className={classes.tayseLogo} />
              <WmsJPGLogo className={classes.wmsLogo} />
            </div>
          </Grid>
          {error && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Alert severity="error"> {error} </Alert>
            </Grid>
          )}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              label="Username"
              variant="filled"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                className: classes.textLabel,
              }}
              onChange={({ target: { value } }) => setLoginValue(value)}
              onKeyPress={({ key }) =>
                key === "Enter" &&
                passwordRef.current &&
                passwordRef.current.focus()
              }
              disabled={isLoading}
              inputRef={usernameRef}
              autoFocus
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              type="password"
              label="Password"
              variant="filled"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                className: classes.textLabel,
              }}
              onChange={({ target: { value } }) => setPasswordValue(value)}
              onKeyPress={({ key }) => key === "Enter" && login()}
              disabled={isLoading}
              inputRef={passwordRef}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              fullWidth
              className={classes.loginButton}
              onClick={login}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? "Loading.." : "Login"}
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Link className={classes.link} to="/password_reset">
              {t("password_forgot")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
