const getDaysArray = function (start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

const getDaysObject = function (start, end, defaultValue = {}) {
  let d = new Date(start);
  const end_date = new Date(end);
  let data = {};
  while (d <= end_date) {
    data[d.toLocaleDateString("en-CA")] = JSON.parse(
      JSON.stringify(defaultValue),
    );
    d.setDate(d.getDate() + 1);
  }
  return data;
};

const timezoneFormat = (
  date,
  withoutTime = false,
  timeZone = "America/New_York",
  locale = "en-US",
) => {
  if (!date) {
    return "-";
  }

  if (withoutTime) {
    return new Date(date).toLocaleString(locale, {
      timeZone,
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return new Date(date).toLocaleString(locale, {
    timeZone,
    hourCycle: "h24",
    hour: "2-digit",
    minute: "2-digit",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

const formatDateList = (
  dateList,
  timeZone = "America/New_York",
  locale = "en-US",
) => {
  let currentDate;
  return dateList
    .map((date) => {
      if (date === "N/A") {
        return "N/A";
      }
      const dateObj = new Date(date);
      if (!currentDate) {
        currentDate = dateObj;
        return timezoneFormat(date, false, timeZone, locale);
      }
      if (dateObj.getDate() !== currentDate.getDate()) {
        currentDate = dateObj;
        return dateObj.toLocaleString(locale, {
          timeZone,
          hourCycle: "h24",
          hour: "2-digit",
          minute: "2-digit",
          month: "2-digit",
          day: "2-digit",
        });
      } else {
        return dateObj.toLocaleString(locale, {
          timeZone,
          hourCycle: "h24",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    })
    .join(" -> ");
};

const convertDate = (date) => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

const convertUSDate = (date) => {
  return `${("0" + (date.getMonth() + 1)).slice(-2)}/${(
    "0" + date.getDate()
  ).slice(-2)}/${date.getFullYear()}`;
};

const toTime = (date, timeZone = "America/New_York", locale = "en-US") => {
  return new Date(date).toLocaleString(locale, {
    timeZone,
    hourCycle: "h24",
    hour: "2-digit",
    minute: "2-digit",
  });
};

function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  }

  if (hours > 0) {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;
  }

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} minute` : `${minutes} minutes`;

  return {
    minutes,
    hours,
    days,
    totalMinutes: days * 24 * 60 + hours * 60 + minutes,
    text: difference,
  };
}

const getPreviousDate = (date, years = 0, months = 0, days = 0) => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  let previousYear = currentYear - years;
  let previousMonth = currentMonth - months;
  let previousDay = currentDay - days;

  while (previousDay < 1) {
    const daysInPreviousMonth = new Date(
      previousYear,
      previousMonth,
      0,
    ).getDate();
    previousDay += daysInPreviousMonth;
    previousMonth--;

    if (previousMonth < 0) {
      previousMonth += 12;
      previousYear--;
    }
  }

  return new Date(previousYear, previousMonth, previousDay);
};

export {
  convertDate,
  convertUSDate,
  formatDateList,
  getDaysArray,
  getDaysObject,
  getPreviousDate,
  timezoneFormat,
  timeDiffCalc,
  toTime,
};
