import * as React from "react";

const SvgArrowUpward = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 16 20"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.91161 10.2466L2.82322 10.335L2.91161 10.4234L3.85191 11.3637L3.94021 11.452L4.0286 11.3638L7.54313 7.85553V15.67V15.795H7.66813H9.00187H9.12687V15.67V7.85629L12.6346 11.3703L12.723 11.4588L12.8114 11.3704L13.7584 10.4234L13.8468 10.335L13.7584 10.2466L8.42339 4.91161L8.335 4.82322L8.24661 4.91161L2.91161 10.2466Z"
      fill={color}
    />
  </svg>
);

export default SvgArrowUpward;
