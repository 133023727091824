import { Link } from "react-router-dom";

export function InternalLink({ to, children, ...props }) {
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
}

export function ExternalLink({ to, children, ...props }) {
  return (
    <a href={to} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
}
