import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ListItemIcon } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import {
  popupNotification,
  setSubMenuItems,
  useLayoutDispatch,
  useLayoutState,
} from "context/LayoutContext";

const bottomHeight = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: bottomHeight,
    backgroundColor: theme.palette.colors.dark_green_grey,
  },
  list: {
    position: "fixed",
    bottom: bottomHeight,
    backgroundColor: theme.palette.colors.dark_green_grey,
    width: "100%",
  },
  bottomNav: {
    paddingTop: "0px",
    color: "#a1a1a1",
    "&.Mui-selected": {
      color: "#f6f8fb",
    },
  },
  menu: {
    width: "100%",
    backgroundColor: theme.palette.colors.dark_green_grey,
  },
  item: { padding: 10 },
}));

export default function MobileNav({ items }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { subMenuItems } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const wrapperRef = useRef(null);

  const openSubMenu = (id) => {
    const item = items.find((item) => item.id === id);
    setSubMenuItems(
      layoutDispatch,
      item.children ? item.children.filter((child) => child.mobile) : [],
    );
  };

  const clearSubMenuItems = () => setSubMenuItems(layoutDispatch, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          clearSubMenuItems();
        }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} style={{ zIndex: "9999" }}>
      {subMenuItems && (
        <div className={classes.menu}>
          <List
            component="nav"
            className={classes.list}
            aria-label="main mailbox folders"
          >
            {subMenuItems.map((sm, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={sm.link}
                onClick={() => {
                  const {
                    clickFunction,
                    popupMessage,
                    popupCloseFunction,
                  } = sm;
                  if (clickFunction) {
                    clickFunction();
                    clearSubMenuItems();
                    if (popupMessage) {
                      popupNotification({
                        dispatch: layoutDispatch,
                        message: t(popupMessage),
                        afterCloseFunction: popupCloseFunction,
                      });
                    }
                    return;
                  }
                  clearSubMenuItems();
                }}
                className={classes.item}
              >
                <ListItemIcon>{<sm.icon color={"#dadada"} />}</ListItemIcon>
                <ListItemText
                  primary={t(sm.label)}
                  style={{ color: "#a1a1a1" }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      )}
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {items
          .filter((item) => item.mobile)
          .map((item) => (
            <BottomNavigationAction
              className={classes.bottomNav}
              component={Link}
              key={item.id}
              label={t(item.label)}
              icon={<item.icon color={"#f6f8fb"} />}
              to={item.link}
              onClick={() => openSubMenu(item.id)}
            />
          ))}
      </BottomNavigation>
    </div>
  );
}
