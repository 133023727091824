import * as React from "react";

const SvgArchive = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3a3 3 0 0 1 1 5.829V18a3 3 0 0 1-2.824 2.995L17 21H7a3 3 0 0 1-2.995-2.824L4 18V8.83a3.001 3.001 0 0 1 .824-5.825L5 3h14Zm-1 6H6v9a1 1 0 0 0 .883.993L7 19h10a1 1 0 0 0 .993-.883L18 18V9Zm-4 2a1 1 0 0 1 .117 1.993L14 13h-4a1 1 0 0 1-.117-1.993L10 11h4Zm5-6H5a1 1 0 0 0-.117 1.993L5 7h14a1 1 0 0 0 .117-1.993L19 5Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgArchive;
