import * as React from "react";

const SvgDotsvertical = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M12 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
    </g>
  </svg>
);

export default SvgDotsvertical;
