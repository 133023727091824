import tinycolor from "tinycolor2";

const primary = "#153931";
const secondary = "#0ab6ff";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      error: "#D8000C",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
      error: "#FFBABA",
    },
    colors: {
      dark_green_grey: "#153931",
      light_green_grey: "#3a5b56",
      dark_grey_blue: "#31504b",
      light_grey_blue: "#f8f8f8",
      blue_grey: "#59817b",
      light_text: "#cccedd",
      light_icon: "#dadada",
      dark_icon: "#757575",
      blue_icon: "#0ab6ff",
      blue_button: "#1a75ff",
      light_hover: "#fff6e8",
      dark_active_grey: "#484848",
      table_item_green: "#f2f5ed",
      table_header_green: "#f8f8f8",
      white: "#FFFFFF",
      slate50: "#f8fafc",
      slate60: "#f6f7fb",
      slate100: "#f1f5f9",
      slate200: "#e2e8f0",
      slate300: "#cbd5e1",
      slate400: "#94a3b8",
      slate500: "#64748b",
      slate600: "#475569",
      slate700: "#334155",
      slate800: "#1e293b",
      slate900: "#0f172a",
      blue900: "#1e3a8a",
      blue50: "#eff6ff",
      blue100: "#dbeafe",
      blue200: "#bfdbfe",
      blue300: "#93c5fd",
      blue400: "#60a5fa",
      blue500: "#3b82f6",
      blue600: "#2563eb",
      blue700: "#1d4ed8",
      blue800: "#1e40af",
      rose50: "#fff1f2",
      rose100: "#ffe4e6",
      rose200: "#fecdd3",
      rose300: "#fda4af",
      rose400: "#fb7185",
      rose500: "#f43f5e",
      rose600: "#e11d48",
      rose700: "#be123c",
      rose800: "#9f1239",
      rose900: "#881337",
      amber900: "#78350f",
      amber800: "#92400e",
      amber700: "#b45309",
      amber600: "#d97706",
      amber500: "#f59e0b",
      amber400: "#fbbf24",
      amber300: "#fcd34d",
      amber200: "#fde68a",
      amber100: "#fef3c7",
      amber50: "#fffbeb",
      emerald50: "#ecfdf5",
      emerald100: "#d1fae5",
      emerald200: "#a7f3d0",
      emerald300: "#6ee7b7",
      emerald400: "#34d399",
      emerald500: "#10b981",
      emerald600: "#059669",
      emerald700: "#047857",
      emerald800: "#065f46",
      emerald900: "#064e3b",
      pastelBlue: "#2471A3",
      pastelOrange: "#F5B041",
      basewhite: "#fff",
      baseblack: "#000",
      brown: "#A52A2A",
    },
  },
  customShadows: {
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    buttonPrimary: `0 3px 12px 2px ${primary}59, 0 1px 4px 0 ${primary}40`,
    buttonSecondary: `0 3px 12px 2px ${secondary}59, 0 1px 4px 0 ${secondary}40`,
    buttonBlue: `0 3px 12px 2px #1a75ff59, 0 1px 4px 0 #1a75ff40`,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24,
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
  },
};

Object.keys(tinycolor.names).map(
  (color) =>
    (defaultTheme.palette[color] = {
      main: color,
      light: tinycolor(color).lighten(lightenRate).toHexString(),
      dark: tinycolor(color).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    }),
);

export default defaultTheme;
