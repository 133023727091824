import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getShipments = ({
  setFunc,
  setError,
  paginated,
  params,
  responseSetter,
  id,
}) => {
  api.list({
    endpoint: id
      ? `shipments/${id}/`
      : addParamsToEndpoint(`shipments/`, params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getShipmentDetailByID = ({ id, responseSetter }) => {
  api.list({
    endpoint: `shipment-detail/${id}/`,
    responseSetter,
  });
};

const getShipmentDetails = ({ setFunc, params, paginated, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint(`shipment-detail/`, params),
    responseSetter,
    setFunc,
    paginated,
  });
};

const getSearchResults = ({ params, responseSetter, setFunc }) => {
  api.list({
    endpoint: addParamsToEndpoint(`custom/shipment-detail-search/`, params),
    responseSetter,
    setFunc,
  });
};

const getTransactions = ({ setFunc, params, responseSetter, paginated }) => {
  api.list({
    endpoint: addParamsToEndpoint(`transaction/`, params),
    setFunc,
    responseSetter,
    paginated,
  });
};

const getAssignedShipments = ({ setFunc, responseSetter }) => {
  api.list({
    endpoint: `custom/assigned-shipments/`,
    setFunc,
    responseSetter,
  });
};

const getUnassignableShipmentDetails = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint(
      `custom/unassignable-shipment-details/`,
      params,
    ),
    responseSetter,
  });
};

const getAssignedShipmentDetail = ({ responseSetter }) => {
  api.list({
    endpoint: `custom/assigned-shipment-detail/`,
    responseSetter,
  });
};

const assignShipments = ({ ids, details, responseSetter }) => {
  api.create({
    endpoint: `custom/assign-shipments/`,
    body: {
      ids,
      details,
    },
    responseSetter,
  });
};

const unassignShipments = ({ container, responseSetter }) => {
  api.create({
    endpoint: `custom/unassign-shipments/`,
    body: {
      container,
    },
    responseSetter,
  });
};

const getShipmentForReturn = ({ setError, params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/get-shipment-for-return/", params),
    setError,
    responseSetter,
  });
};

const getAssignedShipmentsCountWithGroup = ({ responseSetter }) => {
  api.list({
    endpoint: "custom/assigned-shipments-count/",
    responseSetter,
  });
};

const getPickerStatistics = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/picker-statistics/", params),
    responseSetter,
  });
};

const getPickerPerformance = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/picker-performance/", params),
    responseSetter,
  });
};

const setStageLocation = ({ body, setError, responseSetter }) => {
  api.create({
    endpoint: "custom/set-stage-location/",
    body,
    setError,
    responseSetter,
  });
};

const ecommOrderShip = ({ body, responseSetter, setError }) => {
  api.create({
    endpoint: "custom/ship-product/",
    body,
    responseSetter,
    setError,
  });
};

const buggyShip = ({ body, responseSetter, setError }) => {
  api.create({
    endpoint: "custom/ship-buggy/",
    body,
    responseSetter,
    setError,
  });
};

const shipByShipmentNumber = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/ship-by-shipment-number/", params),
    responseSetter,
  });
};

const bulkShip = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/bulk-ship-by-shipment-number/",
    body,
    responseSetter,
  });
}

const getShipmentDetailReport = ({ query, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/transaction-content/", query),
    responseSetter,
  });
};

const getTransactionDetails = ({ responseSetter }) => {
  api.list({
    endpoint: `custom/transaction-details/`,
    responseSetter,
  });
};

const getUnassignedShipmentDetail = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/unassigned-shipment-detail/", params),
    responseSetter,
  });
};

const getSnapshots = ({ params, setFunc }) => {
  api.list({
    endpoint: addParamsToEndpoint("snapshots/", params),
    paginated: true,
    setFunc,
  });
};

const cancelShipment = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/cancel-shipment/",
    body,
    responseSetter,
  });
};

const updateShipment = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `shipments/${id}/`,
    body,
    responseSetter,
  });
};

const updateShipmentDetail = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `shipment-detail/${id}/`,
    body,
    responseSetter,
  });
};

const cancelRequest = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/cancel-request/",
    body,
    responseSetter,
  });
};

const syncPrintedShipments = ({ body, responseSetter }) => {
  api.list({
    endpoint: "custom/sync-printed-shipments/",
    body,
    responseSetter,
  });
};

const dashboardTransactions = ({ body, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/dashboard-transactions/", body),
    responseSetter,
  });
};

const markWrongShipped = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `shipment-detail/${id}/`,
    body,
    responseSetter,
  });
};

const createCastlegatePDF = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-castlegate-pdf/",
    body,
    responseSetter,
  });
};

const createLTLShipmentLabels = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-ltl-labels/",
    body,
    responseSetter,
  });
};

const readyToShip = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/ready-to-ship/",
    body,
    responseSetter,
  });
};

const ltlShip = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/ltl-ship/", params),
    responseSetter,
  });
};

const getShipmentPackages = ({
  paginated,
  params,
  responseSetter,
  setFunc,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("packages/", params),
    setFunc,
    paginated,
    responseSetter,
  });
};

const createShipmentPackage = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-shipment-package/",
    body,
    responseSetter,
  });
};

const addToPackage = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/add-to-package/",
    body,
    responseSetter,
  });
};

const updatePackage = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `packages/${id}/`,
    body,
    responseSetter,
  });
};

const deletePackage = ({ id, responseSetter }) => {
  api.delete({
    endpoint: `packages/${id}`,
    responseSetter,
  });
};

const getPackageLabels = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/get-package-labels/", params),
    responseSetter,
  });
};

const getReturns = ({ params, responseSetter, setFunc, paginated }) => {
  api.list({
    endpoint: addParamsToEndpoint("returns/", params),
    responseSetter,
    setFunc,
    paginated,
  });
};

const createReturn = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-return/",
    body,
    responseSetter,
  });
};

const updateReturn = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `returns/${id}/`,
    body,
    responseSetter,
  });
};

const publishReturns = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/publish-returns/",
    body,
    responseSetter,
  });
};

const revertShipmentStatus = ({ id, responseSetter }) => {
  api.create({
    endpoint: "custom/revert-shipment-status/",
    body: {
      id,
    },
    responseSetter,
  });
};

const downloadLabels = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/download-labels/", params),
    responseSetter,
  });
};

const refreshErroredShipments = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/refresh-shipments/", params),
    responseSetter,
  });
};

const getCarrierShipmentCounts = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/carrier-shipment-counts/", params),
    responseSetter,
  });
};

const deleteShipments = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/delete-shipments/",
    body,
    responseSetter,
  });
};

const hideShipment = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/hide-shipment/",
    body,
    responseSetter,
  });
};

const importLTLShipment = ({ shipmentNumber, responseSetter }) => {
  api.list({
    endpoint: `custom/create-ltl-shipment/?shipment_number=${shipmentNumber}`,
    responseSetter,
  });
};

export {
  addToPackage,
  assignShipments,
  buggyShip,
  bulkShip,
  cancelRequest,
  cancelShipment,
  createCastlegatePDF,
  createLTLShipmentLabels,
  createReturn,
  createShipmentPackage,
  dashboardTransactions,
  deletePackage,
  deleteShipments,
  downloadLabels,
  ecommOrderShip,
  getAssignedShipmentDetail,
  getAssignedShipments,
  getAssignedShipmentsCountWithGroup,
  getCarrierShipmentCounts,
  getPackageLabels,
  getReturns,
  getShipmentDetailByID,
  getShipmentDetailReport,
  getShipmentDetails,
  getShipmentForReturn,
  getShipments,
  getShipmentPackages,
  getSnapshots,
  getTransactionDetails,
  getUnassignableShipmentDetails,
  getUnassignedShipmentDetail,
  getTransactions,
  getPickerStatistics,
  getSearchResults,
  getPickerPerformance,
  hideShipment,
  importLTLShipment,
  ltlShip,
  markWrongShipped,
  publishReturns,
  readyToShip,
  refreshErroredShipments,
  revertShipmentStatus,
  setStageLocation,
  shipByShipmentNumber,
  syncPrintedShipments,
  unassignShipments,
  updatePackage,
  updateReturn,
  updateShipment,
  updateShipmentDetail,
};
