import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import useStyles from "./styles";

export default function TextInput({
  LeftIcon,
  InputProps,
  error,
  onIconClick,
  onBlur,
  type,
  min,
  max,
  steps,
  hasBorder = false,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <div
        className={`${classes.container} ${
          hasBorder ? classes.containerBordered : ""
        }`}
      >
        {LeftIcon && (
          <div className={classes.leftIcon} onClick={onIconClick}>
            {
              <LeftIcon
                color={error ? "red" : theme.palette.colors.blue_icon}
              />
            }
          </div>
        )}
        <TextField
          {...props}
          variant="standard"
          className={classes.textField}
          InputProps={{
            disableUnderline: true,
            ...InputProps,
            ...(type === "number"
              ? { inputProps: { min, max, step: steps || "1" } }
              : {}),
          }}
          InputLabelProps={{
            className: classes.textLabel,
          }}
          type={type}
          onBlur={onBlur}
        />
      </div>
      {error && (
        <div className={classes.error}>
          <span>{`* ${error}`}</span>
        </div>
      )}
    </>
  );
}
