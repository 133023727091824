import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  NativeSelect,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";

import { Download, FilterList, Info, TwoWayArrow } from "icons";

import useStyles from "./styles";
import { toTitleCase } from "utils/string";

/**
 * Table with pagination and custom render methods
 * @param headers array of objects with header config
 * @param data object with items, count and isFetching attributes
 * @param rowsPerPage pagination parameter
 * @param page pagination paramter
 * @param handleChangePage function to assign new data for given page
 * @param handleChangeRowsPerPage function to assign rowsPerPage attribute with going back to first page
 * @param hasCheckBox boolean to determine showing checkboxes on the left
 * @param onRowChecked function to run when row is selected
 * @param selected list of selected items
 * @param disableExportData boolean value, designates whather user can export see the export data button
 * @param sortActions takes { onSort, orderBy, orderDirection }, onSort-function: is the function for sorting operation
 * @param exportDisabled true if we want to hide export button or disable it
 * @param setExportDisabled state handler to enable/disable export button
 * @param filters applied filters to show if the result is empty list
 * @param headerFilters array of objects with header filter config
 * @param onFilter function to run when header filter is applied
 * @param selectedFilters object to keep track of selected header filters
 * @param setSelectedFilters function to set selected header filters
 * @param highlightColor highlight background color
 * @param highlightFunc filter function to change background color
 * @param highlightStyle style object to override row style
 * @param headerVisible flag to show/hide table header
 * @param footerVisible flag to show/hide table footer
 * @param rowsPerPageOptions overrides default rows per page options [10, 25, 50, 100]
 * orderBy-str: field name for the soring orderDirection-str: asc or desc, Sorting is only evaluated for desktop table
 */
export default function DesktopTable({
  headers,
  headerCounts = {},
  data,
  rowsPerPage = 100,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  hasCheckBox,
  onRowChecked,
  selected,
  selectUnselectAll,
  handleExportData,
  rowOnClick,
  disableExportData,
  sortActions,
  exportDisabled = false,
  setExportDisabled = () => {},
  filters,
  headerFilters,
  onFilter,
  selectedFilters,
  setSelectedFilters,
  highlightColor,
  highlightFunc,
  highlightStyle,
  headerVisible,
  footerVisible,
  rowsPerPageOptions = [10, 25, 50, 100],
  uniqueIdCount,
  style,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [openFilters, setOpenFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const [popperActive, setPopperActive] = useState(false);
  const { items, count, isFetching } = data;
  const [t] = useTranslation();
  const { onSort, orderBy, orderDirection } = sortActions
    ? sortActions
    : { onSort: false, orderBy: false, orderDirection: false };

  const exportEnabled = !disableExportData && items.length && handleExportData;
  const footerColSpan = hasCheckBox
    ? headers.length + 1
    : headers.length;
  const paginationCount = Math.ceil(count / rowsPerPage);

  const data_ids = data.items.map((i) => i.id);

  const handleFilterClick = (filters) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilters(filters);
  };

  const getFilterCount = (filters) => {
    let count = 0;
    filters.forEach((filter) => {
      count += selectedFilters[filter.key].length;
    });
    return count ? `(${count})` : "";
  };

  // Close the filter popper on backdrop click and apply filter
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setAnchorEl(null);
        setOpenFilters(false);
        if (popperActive) {
          onFilter();
          setPopperActive(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popperActive, onFilter]);

  return (
    <Paper className={classes.paper}>
      <Popper
        open={openFilters}
        anchorEl={anchorEl}
        ref={popperRef}
        placement="bottom"
        className={classes.popper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.popperPaper}>
              {openFilters.length > 0 && (
                <div className={classes.openFilters}>
                  {openFilters.map((filter, i) => (
                    <div
                      className={
                        i !== openFilters.length - 1
                          ? classes.rightBorder
                          : null
                      }
                    >
                      <div className={classes.filterTitle}>
                        {filter.title.toUpperCase()}
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.filterContent}>
                        <FormGroup>
                          {filter.options.map((option) => (
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={selectedFilters[
                                      filter.key
                                    ].includes(option)}
                                    onChange={() => {
                                      setPopperActive(true);
                                      if (
                                        selectedFilters[filter.key].includes(
                                          option,
                                        )
                                      ) {
                                        setSelectedFilters({
                                          ...selectedFilters,
                                          [filter.key]: selectedFilters[
                                            filter.key
                                          ].filter((f) => f !== option),
                                        });
                                      } else {
                                        setSelectedFilters({
                                          ...selectedFilters,
                                          [filter.key]: [
                                            ...selectedFilters[filter.key],
                                            option,
                                          ],
                                        });
                                      }
                                    }}
                                  />
                                }
                                label={option}
                              />
                            </div>
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
      {exportEnabled ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
            height: 0,
            overflow: "visible",
            top: "5px",
            right: "5px",
            zIndex: 99999,
            cursor: "pointer",
          }}
          onClick={() => {
            setExportDisabled(true);
            handleExportData();
          }}
          title={t("export_data")}
        >
          {exportDisabled ? (
            <CircularProgress size={20} style={{ marginLeft: "15px" }} />
          ) : (
            <Download color="black" />
          )}
        </div>
      ) : null}
      <TableContainer style={style || { maxHeight: "50rem" }}>
        <Table className={classes.table} size="small" stickyHeader>
          {headerVisible && (
            <TableHead>
              <TableRow>
                {hasCheckBox && (
                  <TableCell key={"checkbox"} padding="checkbox">
                    <Checkbox
                      color="default"
                      checked={
                        (uniqueIdCount || data.items.length) ===
                        selected.filter((s) => data_ids.includes(s.id)).length
                      }
                      onChange={selectUnselectAll}
                    />
                    ({data.items.length ? selected.length : 0})
                  </TableCell>
                )}
                {headers && headers.length > 0 && (
                  <TableCell
                    align={headers[0].numeric ? "right" : "left"}
                    padding={headers[0].disablePadding ? "none" : "normal"}
                    sortDirection={
                      orderBy === headers[0].id ? headers[0].name : false
                    }
                    style={headers[0].style}
                  >
                    <div className={classes.headerContent}>
                      {headerFilters && (
                        <div className={classes.filterContainer}>
                          <div>
                            <span className={classes.filterText}>
                              {getFilterCount(headerFilters)}
                            </span>
                          </div>
                          <div>
                            <IconButton
                              title="Filter"
                              onClick={handleFilterClick(headerFilters)}
                              className={classes.filterIcon}
                            >
                              <FilterList color="black" size={0.75} />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      <div className={classes.fullWidth}>
                        {onSort && headers[0].sortable ? (
                          <TableSortLabel
                            active={orderBy === headers[0].id}
                            direction={orderDirection ? orderDirection : null}
                            onClick={() => {
                              onSort(headers[0].id);
                            }}
                            hideSortIcon={true}
                          >
                            {headers[0].label
                              ? t(headers[0].label)
                              : t(headers[0].id)}
                            {orderBy !== headers[0].id && (
                              <TwoWayArrow style={{ marginTop: "7px" }} />
                            )}
                          </TableSortLabel>
                        ) : headers[0].label ? (
                          <span className={classes.headerTitle}>
                            {t(headers[0].label)}
                          </span>
                        ) : (
                          <span className={classes.headerTitle}>
                            {t(headers[0].id)}
                          </span>
                        )}
                        {headerCounts[headers[0].id] ? (
                          <Typography
                            variant="caption"
                            style={{ marginLeft: "5px" }}
                          >
                            ({headerCounts[headers[0].id]})
                          </Typography>
                        ) : null}
                        {headers[0].info && (
                          <Tooltip title={headers[0].info} placement={"top"}>
                            <IconButton>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </TableCell>
                )}
                {headers &&
                  headers.slice(1).map((header) => (
                    <TableCell
                      key={header.id}
                      align={header.numeric ? "right" : "left"}
                      padding={header.disablePadding ? "none" : "normal"}
                      sortDirection={
                        orderBy === header.id ? header.name : false
                      }
                      style={header.style}
                    >
                      <div className={classes.headerContent}>
                        <div className={classes.fullWidth}>
                          {onSort && header.sortable ? (
                            <TableSortLabel
                              active={orderBy === header.id}
                              direction={orderDirection ? orderDirection : null}
                              onClick={() => {
                                onSort(header.id);
                              }}
                              hideSortIcon={true}
                            >
                              {header.label ? t(header.label) : t(header.id)}
                              {orderBy !== header.id && (
                                <TwoWayArrow style={{ marginTop: "7px" }} />
                              )}
                            </TableSortLabel>
                          ) : header.label ? (
                            <span className={classes.headerTitle}>
                              {t(header.label)}
                            </span>
                          ) : (
                            <span className={classes.headerTitle}>
                              {t(header.id)}
                            </span>
                          )}
                          {headerCounts[header.id] ? (
                            <Typography
                              variant="caption"
                              style={{ marginLeft: "5px" }}
                            >
                              ({headerCounts[header.id]})
                            </Typography>
                          ) : null}
                          {header.info && (
                            <Tooltip title={header.info} placement={"top"}>
                              <IconButton onClick={header.onInfoClick}>
                                <Info />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isFetching ? (
              <TableRow style={{ height: 49 * rowsPerPage }}>
                <TableCell
                  colSpan={hasCheckBox ? headers.length + 1 : headers.length}
                  className="text-center"
                >
                  <CircularProgress size={26} />
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableRow height={49}>
                <TableCell
                  colSpan={hasCheckBox ? headers.length + 1 : headers.length}
                  className="text-center"
                >
                  <span>
                    {t("no_results_found")}
                    {filters && (
                      <>
                        <br></br>
                        <span style={{ fontWeight: "bold" }}>Filters: </span>
                        {Object.entries(filters)
                          .filter(
                            ([key, value]) =>
                              typeof value === "boolean" || !!value,
                          )
                          .filter(([key, value]) => typeof value !== "object")
                          .map(([key, value]) => (
                            <React.Fragment key={key}>
                              <span
                                style={{ marginLeft: "2rem" }}
                              >{`${toTitleCase(
                                key.split("_").join(" "),
                              )}: `}</span>
                              <span style={{ fontWeight: "bold" }}>
                                {toTitleCase(value.toString())}
                              </span>
                            </React.Fragment>
                          ))}
                      </>
                    )}
                  </span>
                </TableCell>
              </TableRow>
            ) : (
              items.map((row) => (
                <TableRow
                  key={row.sku ? `${row.id}-${row.sku}` : row.id}
                  style={
                    highlightStyle
                      ? {
                          ...highlightStyle(row),
                          cursor: rowOnClick ? "pointer" : "default",
                        }
                      : {
                          backgroundColor:
                            highlightFunc && highlightFunc(row)
                              ? highlightColor || theme.palette.background.error
                              : selected &&
                                selected.map((s) => s.id).indexOf(row.id) >= 0
                              ? theme.palette.colors.slate300
                              : null,
                          fontWeight: "bold",
                          cursor: rowOnClick ? "pointer" : "default",
                        }
                  }
                  className={classes.tableRow}
                  onClick={() =>
                    rowOnClick && !hasCheckBox ? rowOnClick(row) : null
                  }
                >
                  {hasCheckBox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="default"
                        checked={selected.map((s) => s.id).indexOf(row.id) >= 0}
                        onChange={() => onRowChecked(row)}
                      />
                    </TableCell>
                  )}
                  {headers.map((p) => (
                    <TableCell
                      onClick={() =>
                        rowOnClick && hasCheckBox ? rowOnClick(row) : null
                      }
                      component="th"
                      scope="row"
                      key={p.id}
                      style={{
                        ...p.style,
                        ...(highlightStyle
                          ? highlightStyle(row)
                          : {
                              color: "black",
                              fontWeight: "normal",
                            }),
                        backgroundColor: p.bgColor || null,
                      }}
                    >
                      {p.render ? p.render(row) : row[p.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
          {footerVisible && (
            <TableFooter>
              <TableRow>
                  <TableCell colSpan={footerColSpan}>
                    {handleChangePage ? (
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "1.2rem" }}
                        >
                          Rows per page:
                        </Typography>
                        <NativeSelect
                          style={{
                            marginRight: "1.2rem",
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                          }}
                          disableUnderline
                          value={rowsPerPage}
                          onChange={handleChangeRowsPerPage}
                        >
                          {rowsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </NativeSelect>
                        <Pagination
                          color="secondary"
                          count={paginationCount}
                          disabled={isFetching || paginationCount === 1}
                          page={page + 1}
                          onChange={(event, newPage) => {
                            handleChangePage(event, newPage - 1);
                          }}
                        />
                      </div>
                    ) : null}
                  </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}
