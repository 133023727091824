import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getAdjustments = ({
  setFunc,
  setError,
  paginated,
  params,
  responseSetter,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/adjustments/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getAdjustmentCycleCountsById = ({ id, responseSetter }) => {
  api.list({
    endpoint: `custom/adjustment-cycle-counts/?adjustment_id=${id}`,
    responseSetter,
  });
};

const adjustmentPublish = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/adjustment-publish/",
    body,
    responseSetter,
  });
};

const cancelAdjustment = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/cancel-adjustment/",
    body,
    responseSetter,
  });
};

export {
  adjustmentPublish,
  cancelAdjustment,
  getAdjustments,
  getAdjustmentCycleCountsById,
};
