import DatePicker from "./DatePicker";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

export default function DatePickerGroup({
  startDateValue,
  startDateOnChange,
  endDateValue,
  endDateOnChange,
  itemGridProps = { xs: 12, sm: 6, md: 4, lg: 2, xl: 2 },
  ...props
}) {
  const [t] = useTranslation();
  return (
    <>
      <Grid item {...itemGridProps}>
        <DatePicker
          id="start_date"
          title={t("start_date")}
          value={startDateValue}
          onChange={startDateOnChange}
          max={endDateValue}
          {...props}
        />
      </Grid>
      <Grid item {...itemGridProps}>
        <DatePicker
          id="end_date"
          title={t("end_date")}
          value={endDateValue}
          onChange={endDateOnChange}
          min={startDateValue}
          {...props}
        />
      </Grid>
    </>
  );
}
