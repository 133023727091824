import * as React from "react";

const SvgCalendarToday = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3.5h-1v-2h-2v2H7v-2H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm0 2v3H4v-3h16zm-16 16v-11h16v11H4z"
      fill={color}
    />
    <path opacity={0.3} d="M4 5.51h16V8.5H4V5.51z" fill={color} />
  </svg>
);

export default SvgCalendarToday;
