import { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  getProductAttributeRequests,
  updateProductAttributeRequests,
} from "api/products";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { ConfirmDialog } from "components/Custom";
import { Button, Dropdown, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useUserState } from "context/UserContext";

import { Qrcode, QrCode2, Style } from "icons";

import {
  initialAPIListValue,
  ProductAttributeTypes,
  PRODUCT_REQ_ATTRIBUTE_STATUSES,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";

const ProductAttributeReqStatuses = [
  {
    id: 1,
    label: "Open",
  },
  {
    id: 2,
    label: "Canceled",
  },
  {
    id: 3,
    label: "Approved",
  },
];

const headers = [
  {
    id: "product__sku",
    label: "sku",
    exportLabel: "Invenyory Id",
    render: (r) => r.product_sku,
    sortable: true,
  },
  {
    id: "value",
    sortable: true,
  },
  {
    id: "code_type",
    label: "type",
    render: (r) =>
      r.code_type
        ? ProductAttributeTypes.find((pat) => pat.value === r.code_type).label
        : "-",
    sortable: true,
  },
  {
    id: "scan_count",
    sortable: true,
  },
];

export default function ValidateCrossReferences() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [productAttributeReqs, setProductAttributeReqs] = useState(
    initialAPIListValue,
  );
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("scan_count");
  const [search, setSearch] = useState({
    product_sku: "",
    value: "",
    code_type: "",
    status: ProductAttributeReqStatuses[0].id,
  });
  const [headerCounts, setHeaderCounts] = useState({});
  const [selected, setSelected] = useState([]);
  const [dialogStatus, setDialogStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { permissions } = useUserState();
  const hasUpdatePermission = permissions.includes(
    "product.change_productattributerequest",
  );

  useEffect(() => {
    if (productAttributeReqs.isFetching) {
      let filters = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };

      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }

      getProductAttributeRequests({
        params: filters,
        setFunc: (res) => {
          setProductAttributeReqs(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    productAttributeReqs,
    rowsPerPage,
    page,
    search,
    orderBy,
    orderDirection,
  ]);

  const handleChangeInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setProductAttributeReqs({
        ...productAttributeReqs,
        isFetching: true,
      });
    }, 500),
    [],
  );

  const handleChangeDropdown = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setProductAttributeReqs({ ...productAttributeReqs, isFetching: true });
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `XRefs-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, headers),
    );
  };

  const handleExportData = () => {
    if (productAttributeReqs.items.length === productAttributeReqs.count) {
      exportData(productAttributeReqs.items);
    } else {
      getProductAttributeRequests({
        params: {
          limit: productAttributeReqs.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const handleSubmit = (status) => {
    setIsLoading(true);
    updateProductAttributeRequests({
      body: {
        ids: selected.map((s) => s.id),
        status,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        handleCloseDialog();
        if (message) {
          if (success) {
            setProductAttributeReqs((prev) => ({ ...prev, isFetching: true }));
            return popupNotification({
              dispatch: layoutDispatch,
              message,
              status: "warning",
            });
          }
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        setProductAttributeReqs((prev) => ({ ...prev, isFetching: true }));
        popupNotification({
          dispatch: layoutDispatch,
          message: "Done!",
          status: "success",
        });
      },
    });
  };

  const handleCloseDialog = () => {
    setDialogStatus("");
    setIsLoading(false);
    setSelected([]);
  };

  return (
    <>
      <PageTitle title="Validate Cross References" />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product_sku}
              onChange={handleChangeInput("product_sku")}
              isProductSKU={true}
              InputProps={clearAdornment("product_sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="value"
              label={t("value")}
              variant="outlined"
              value={search.value}
              onChange={handleChangeInput("value")}
              InputProps={clearAdornment("value")}
              LeftIcon={Qrcode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="code_type"
              title={t("type")}
              value={search.code_type}
              onChange={handleChangeDropdown("code_type")}
              items={ProductAttributeTypes}
              LeftIcon={Style}
            />
          </Grid>
          {hasUpdatePermission && (
            <Grid item lg={6} md={12} sm={6} xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item lg={3} md={2} sm={6} xs={6}>
                  <Button
                    color={"danger"}
                    disabled={!selected.length}
                    onClick={() =>
                      setDialogStatus(PRODUCT_REQ_ATTRIBUTE_STATUSES.Reject)
                    }
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item lg={3} md={2} sm={6} xs={6}>
                  <Button
                    color={"blue"}
                    disabled={!selected.length}
                    onClick={() =>
                      setDialogStatus(PRODUCT_REQ_ATTRIBUTE_STATUSES.Approve)
                    }
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </InputContainer>
      <Grid container>
        <Grid item xs={12}>
          <DefaultTable
            headers={headers}
            headerCounts={headerCounts}
            data={productAttributeReqs}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            filters={search}
            hasCheckBox={true}
            selected={selected}
            setSelected={setSelected}
            sortActions={{
              onSort: (header) => {
                const isAsc = orderBy === header && orderDirection === "asc";
                const newDirection = isAsc ? "desc" : "asc";
                setOrderDirection(newDirection);
                setOrderBy(header);
                setPage(0);
                setProductAttributeReqs((prev) => ({
                  ...prev,
                  isFetching: true,
                }));
              },
              orderBy,
              orderDirection,
            }}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={!!dialogStatus}
        title={`${selected.length} record(s) will be update?`}
        onClose={handleCloseDialog}
        onConfirm={() => handleSubmit(dialogStatus)}
        isLoading={isLoading}
      >
        Are you sure ?
      </ConfirmDialog>
    </>
  );
}
