import React from "react";

import { Card, CardContent, Grid, Tooltip } from "@material-ui/core";

import Style from "./styles";

export default function DashboardCard({
  icon,
  bgColor,
  info,
  value,
  itemSize,
  onClick,
  altText,
}) {
  const classes = Style({ bgColor })();

  return (
    <Grid item xs={itemSize} onClick={onClick}>
      <Tooltip
        disableFocusListener
        title={altText || ""}
        arrow
        disableHoverListener={altText ? false : true}
      >
        <Card className={classes.cardContainer}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <div>
              <div className={classes.cardIcon}>{icon}</div>
              <div>
                <b className={classes.cardValue}>{value}</b>
                <span className={classes.cardInfo}>{info}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  );
}
