import * as React from "react";

const SvgLooks = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 11.273A5.734 5.734 0 0 0 6.273 17h1.636A4.098 4.098 0 0 1 12 12.91 4.098 4.098 0 0 1 16.09 17h1.637A5.734 5.734 0 0 0 12 11.273ZM12 8c-4.962 0-9 4.038-9 9h1.636c0-4.058 3.306-7.364 7.364-7.364s7.364 3.306 7.364 7.364H21c0-4.962-4.038-9-9-9Z"
      fill={color}
    />
  </svg>
);

export default SvgLooks;
