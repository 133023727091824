import { useState } from "react";
import { Card, CardMedia, CircularProgress, Grid } from "@material-ui/core";

import { getProductImage } from "api/products";

import { InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { QrCode2 } from "icons";

const initialParams = {
  value: "",
  imageUrl: "",
  isFetching: false,
  rotate: 0,
};

export default function ProductImage() {
  const layoutDispatch = useLayoutDispatch();
  const [params, setParams] = useState(initialParams);

  const handleProductImage = ({ key }) => {
    if (key !== "Enter") {
      return;
    }
    setParams((prev) => ({ ...prev, isFetching: true }));
    getProductImage({
      params: {
        value:
          params.value.trim().split(" ").length > 1
            ? params.value
            : params.value.trim() + " 5x8",
      },
      responseSetter: (res) => {
        const { success, message, image_url, sku, width, height } = res;
        if (!success) {
          setParams(initialParams);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        setParams({
          value: sku,
          imageUrl: image_url,
          isFetching: false,
          rotate: width >= height ? 90 : 0,
        });
      },
    });
  };

  return (
    <>
      <PageTitle title="Product Image" />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              autoFocus
              id="sku"
              label="SKU"
              variant="outlined"
              value={params.value}
              onChange={({ target: { value } }) =>
                setParams((prev) => ({ ...prev, value }))
              }
              onKeyPress={handleProductImage}
              isProductSKU={true}
              LeftIcon={QrCode2}
            />
          </Grid>
        </Grid>
      </InputContainer>

      <Grid container>
        <Grid item xs={12}>
          {params.isFetching ? (
            <CircularProgress />
          ) : params.imageUrl ? (
            <Card>
              <CardMedia
                component="img"
                height="auto"
                image={params.imageUrl}
                style={{ transform: `rotate(${params.rotate}deg)` }}
                alt={params.value}
              />
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
