import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

import {
  getCollectionRecommendations,
  getClassifications,
  getGroups,
  getGroupMatrix,
  classificationExport,
} from "api/stock";

import { Button, InputContainer, TextInput } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import {
  getSalesForEachYear,
  getStockoutForEachYear,
  getStockOutSum,
} from "utils/stock";
import { getWithExpiry } from "utils/storage";
import { formatCurrency } from "utils/string";

import { ExpandMore, QrCode2, SnippetFolder } from "icons";

import CreateGroupModal from "./CreateGroupModal";
import ClassificationConfigModal from "./ClassificationConfigModal";
import StockItemModal from "./StockItemModal";
import CollectionModal from "./CollectionModal";

import useStyles from "./styles";

const MATRIX_ORDER = ["AL", "AM", "AH", "BL", "BM", "BH", "CL", "CM", "CH"];
const CALHOUN_WAREHOUSE_NAME = "CALHOUN";

const MatrixItem = ({ item, value, onClick }) => {
  const classes = useStyles();

  const getStyle = (item) => {
    if (["AH", "AM", "BH"].includes(item)) {
      return classes.greenMatrixItem;
    } else if (["BM", "BL", "CM"].includes(item)) {
      return classes.purpleMatrixItem;
    } else if (["AL", "CH"].includes(item)) {
      return classes.brownMatrixItem;
    }
    return classes.redMatrixItem;
  };

  return (
    <Grid
      container
      className={`${classes.matrixItem} ${getStyle(item)}`}
      onClick={onClick}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center">
          <span className={classes.matrixItemTitle}>{item}</span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center">
          <strong className={classes.matrixItemValue}>{value}</strong>
        </Grid>
      </Grid>
    </Grid>
  );
};

let exportHeaders = [
  { id: "collection", render: (r) => r.product.collection },
  { id: "product", render: (r) => r.product.sku },
  {
    id: "order_recommend",
    exportLabel: "Qty to Order",
  },
  {
    id: "in_stock_avg_qty",
    exportLabel: "Monthly Avrg Sales",
    render: (r) =>
      getStockoutForEachYear(r.product)[2] >= 365
        ? 0
        : parseInt(
            (
              (30 * getSalesForEachYear(r.product)[2]) /
              (365 - getStockoutForEachYear(r.product)[2])
            ).toFixed(0),
          ),
  },
  {
    id: "oos_days",
    exportLabel: "OOS Days",
    render: (r) => getStockOutSum(r.product),
  },
  { id: "12m_sales_qty", render: (r) => r.qty },
  {
    id: "classification",
    label: "Class",
    render: (r) => r.abc_value + r.hml_value,
  },
  { id: "qty_available", render: (r) => r.stock.qty_available },
  { id: "qty_on_dock", render: (r) => r.stock.qty_on_dock },
  { id: "qty_on_ocean", render: (r) => r.stock.qty_on_ocean },
  { id: "qty_on_po", render: (r) => r.stock.qty_on_po },
  { id: "return_qty", render: (r) => r.product.qty_return },
  {
    id: "order_m²",
    exportLabel: "Order m²",
    render: (r) => Math.ceil(r.order_recommend_sqrmtr),
  },
  {
    id: "Order $",
    render: (r) => parseFloat(r.product.last_cost * r.order_recommend),
  },
  {
    id: "sales_qty_y1",
    exportLabel: "Sales Qty Y-1",
    render: (r) => getSalesForEachYear(r.product)[2],
  },
  {
    id: "sales_qty_y2",
    exportLabel: "Sales Qty Y-2",
    render: (r) => getSalesForEachYear(r.product)[1],
  },
  {
    id: "sales_qty_y3",
    exportLabel: "Sales Qty Y-3",
    render: (r) => getSalesForEachYear(r.product)[0],
  },
  {
    id: "oos_days_y1",
    exportLabel: "OOS Days Y-1",
    render: (r) => getStockoutForEachYear(r.product)[2],
  },
  {
    id: "oos_days_y2",
    exportLabel: "OOS Days Y-2",
    render: (r) => getStockoutForEachYear(r.product)[1],
  },
  {
    id: "oos_days_y3",
    exportLabel: "OOS Days Y-3",
    render: (r) => getStockoutForEachYear(r.product)[0],
  },
  { id: "unit_cost", render: (r) => parseFloat(r.product.last_cost) },
  { id: "area_sqrmtr", render: (r) => parseFloat(r.product.area_sqrmtr) },
];

export default function Stock(props) {
  const { t } = useTranslation();
  const { is_manager } = parseJwt(getWithExpiry("access"));
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [stock, setStock] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product_sku: "",
    group_name: "DEFAULT",
  });
  const [groups, setGroups] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [collectionModal, setCollectionModal] = useState(false);
  const [groupMatrix, setGroupMatrix] = useState({});
  const [autocompleteValues, setAutocompleteValues] = useState({
    group_name: "DEFAULT",
  });
  const [expanded, setExpanded] = useState(false);
  const [product, setProduct] = useState(null);
  const [headerCounts, setHeaderCounts] = useState({});
  const [exportDisabled, setExportDisabled] = useState(false);
  const [recommendations, setRecommendations] = useState({});

  const getClassification = (hmlValue, abcValue) => {
    const value = abcValue + hmlValue;
    if (["AH", "AM", "BH"].includes(value)) {
      return classes.greenBadge;
    } else if (["BM", "BL", "CM"].includes(value)) {
      return classes.purpleBadge;
    } else if (["AL", "CH"].includes(value)) {
      return classes.brownBadge;
    }
    return classes.redBadge;
  };

  const cells = [
    {
      id: "product_sku",
      label: "product",
      render: (r) => (
        <Grid container alignItems="center">
          <Grid item style={{ marginLeft: "20px", marginRight: "30px" }}>
            <Badge
              color="error"
              badgeContent={r.abc_value + r.hml_value}
              className={getClassification(r.hml_value, r.abc_value)}
            />
          </Grid>
          <Grid item>
            <span>{r.product.sku}</span>
            <Typography variant="subtitle2" className={classes.subtitle}>
              <span title="Area SqrMtr">{`${
                r.product.area_sqrmtr ? r.product.area_sqrmtr + "m²" : "-"
              }`}</span>
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "qty_available",
      label: "Qty Available / PO / Ocean",
      render: (r) => (
        <Grid container alignItems="center">
          <Grid item>
            <span>
              {r.stock.qty_available + r.stock.qty_on_dock} /{" "}
              {r.stock.qty_on_po} / {r.stock.qty_on_ocean}
            </span>
            <Typography variant="subtitle2" className={classes.subtitle}>
              <span title="Area SqrMtr">
                {`${Math.max(
                  (
                    r.product.area_sqrmtr *
                    (r.stock.qty_available + r.stock.qty_on_dock)
                  ).toFixed(0),
                  0,
                )} m² / ${(r.product.area_sqrmtr * r.stock.qty_on_po).toFixed(
                  0,
                )} m² / ${(
                  r.product.area_sqrmtr * r.stock.qty_on_ocean
                ).toFixed(0)} m²`}
              </span>
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "qty_sold",
      label: "qty sold",
      render: (r) => (
        <>
          <span>{r.qty}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Qty Rate">{r.qty_rate}%</span>
          </Typography>
        </>
      ),
    },
    {
      id: "amount",
      label: "amount",
      render: (r) => (
        <>
          <span>{formatCurrency(r.amount)}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Amount Rate">{r.amount_rate}%</span>
          </Typography>
        </>
      ),
    },
    {
      id: "order_qty",
      label: "Order Qty / M²",
      render: (r) => (
        <>
          <span>{r.order_recommend}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Amount Rate">{r.order_recommend_sqrmtr} m²</span>
          </Typography>
        </>
      ),
      info: "Click for collection details",
      onInfoClick: () => {
        setCollectionModal(true);
      },
    },
  ];

  useEffect(() => {
    if (stock.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      getClassifications({
        params,
        setError,
        setFunc: (res) => {
          // remove stock out history for other warehouses
          res.items.forEach((item) => {
            item.product.stock_out_history = item.product.stock_out_history.filter(
              (history) => history.warehouse === CALHOUN_WAREHOUSE_NAME,
            );
          });
          setStock(res);
          setHeaderCounts({
            ...res.customCounts,
            order_qty: Math.ceil(res.customCounts.order_qty),
          });
        },
        paginated: true,
      });
    }
  }, [rowsPerPage, page, search, stock.isFetching, is_manager]);

  useEffect(() => {
    getGroups({
      setFunc: (res) => {
        if (res.items) {
          setGroups(res.items.results.map((group) => group.name));
        }
      },
    });
  }, []);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  useEffect(() => {
    getGroupMatrix({
      params: { group_name: search.group_name },
      setFunc: (res) => {
        if (res.items) {
          setGroupMatrix(res.items.data);
        }
      },
    });
    getCollectionRecommendations({
      params: { group_name: search.group_name },
      setFunc: (res) => {
        if (res.items) {
          setRecommendations(res.items.data);
        }
      },
    });
  }, [search.group_name]);

  const handleChangeAutocompleteParams = (prop) => (_, value) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteValues = (prop) => (_, value) => {
    setAutocompleteValues((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setStock({ ...stock, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch({ ...search, [prop]: value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStock({
      items: stock.items,
      isFetching: true,
      count: stock.count,
    });
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setStock((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Stock-${today
        .toISOString()
        .replace("T", "-")
        .replaceAll(":", "-")
        .slice(0, 19)}`,
      true,
      convertDataToTableView(data, exportHeaders),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (search.group_name === "DEFAULT") {
      classificationExport({
        setFunc: (res) => {
          setExportDisabled(false);
          if (res.success) {
            popupNotification({
              dispatch: layoutDispatch,
              message: "The file will be sent to your email within 15 minutes",
              status: "success",
              title: "Success",
            });
          } else {
            popupNotification({
              dispatch: layoutDispatch,
              message: res.message,
              status: "error",
              title: "Error",
            });
          }
        },
        setError: () => {
          setExportDisabled(false);
        },
        body: {
          group_name: search.group_name,
        },
      });
    } else {
      getClassifications({
        params: {
          limit: stock.count,
          ordering: "-order_recommend_year",
          ...search,
        },
        setFunc: ({ items: { results } }) => {
          // remove stock out history for other warehouses
          results.forEach((item) => {
            item.product.stock_out_history = item.product.stock_out_history.filter(
              (history) => history.warehouse === CALHOUN_WAREHOUSE_NAME,
            );
          });
          exportData(results);
        },
      });
    }
  };

  return (
    <>
      <PageTitle title={t("Stock")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product_sku}
              onChange={handleChangeFilterInput("product_sku")}
              InputProps={clearAdornment("product_sku")}
              LeftIcon={QrCode2}
              isProductSKU={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              value={autocompleteValues.group_name}
              onChange={handleChangeAutocompleteValues("group_name")}
              inputValue={search.group_name}
              onInputChange={handleChangeAutocompleteParams("group_name")}
              id="group"
              options={groups}
              disableClearable={true}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Group"
                  variant="outlined"
                  fullWidth
                  LeftIcon={SnippetFolder}
                />
              )}
            />
          </Grid>
          <Grid item lg={4} style={{ borderRight: "none" }} />
          <Grid item lg={2} style={{ borderRight: "none" }}>
            <Button
              color="secondary"
              onClick={() => setCreateModal(true)}
              fullWidth
            >
              Create Group
            </Button>
          </Grid>
          <Grid item lg={2} style={{ borderRight: "none" }}>
            <Button
              color="primary"
              onClick={() => setConfigModal(true)}
              fullWidth
            >
              Classification
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={2}>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            className={classes.accordion}
            style={{ marginBottom: 10, backgroundColor: "#f0f0f0" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.heading}>
                    Matrix Report
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Paper className={classes.matrixContainer}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  {MATRIX_ORDER.map((item) => (
                    <Grid item key={item} xs={4}>
                      <MatrixItem
                        item={item}
                        value={groupMatrix ? groupMatrix[item] : 0}
                        onClick={() => {}}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            data={stock}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            filters={search}
            highlightStyle={(_r) => ({
              backgroundColor: "white",
              borderBottom: "1px solid #dddddd90",
              height: "4rem",
            })}
            rowOnClick={(r) => {
              setProduct(r);
            }}
            headerCounts={headerCounts}
            handleExportData={handleExportData}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
          />
        </Grid>
      </Grid>
      <CreateGroupModal
        open={createModal}
        onClose={() => {
          setCreateModal(false);
        }}
        onCreate={() => setStock({ ...stock, isFetching: true })}
        groups={groups}
        fetchGroups={() => {
          getGroups({
            setFunc: (res) => {
              if (res.items) {
                setGroups(res.items.results.map((group) => group.name));
              }
            },
          });
        }}
        t={t}
      />
      <ClassificationConfigModal
        open={configModal}
        onClose={() => {
          setConfigModal(false);
        }}
        onUpdate={() => setStock({ ...stock, isFetching: true })}
        t={t}
      />
      <StockItemModal
        open={product}
        product={product}
        onClose={() => {
          setProduct(null);
        }}
      />
      <CollectionModal
        open={collectionModal}
        onClose={() => {
          setCollectionModal(false);
        }}
        data={recommendations}
      />
    </>
  );
}
