import * as React from "react";

const SvgFolderSpecial = ({ color, size }) => (
  <svg
    viewBox="0 0 23 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="m10.405 8.5-1.863-2H3.727v12H18.63v-10h-8.226zm3.568 1 1.109 2.79 2.822.26-2.142 1.99.642 2.96-2.43-1.53-2.432 1.53.643-2.96-2.143-1.99 2.823-.26 1.108-2.79z"
      fill={color}
    />
    <path
      d="M18.63 6.5h-7.452l-1.863-2H3.726c-1.024 0-1.863.9-1.863 2v12c0 1.1.839 2 1.863 2H18.63c1.025 0 1.863-.9 1.863-2v-10c0-1.1-.838-2-1.863-2zm0 12H3.726v-12h4.816l1.863 2h8.225v10zm-6.446-3.96-.642 2.96 2.43-1.53 2.432 1.53-.643-2.96 2.143-1.99-2.823-.26-1.108-2.79-1.109 2.79-2.822.26 2.142 1.99z"
      fill={color}
    />
  </svg>
);

export default SvgFolderSpecial;
