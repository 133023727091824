import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  fedex: {
    backgroundColor: "#4D148C",
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#4D148C",
      },
    },
    "& .MuiListItem-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiButton-root:nth-child(1)": {
      backgroundColor: "#FF9900",
      color: "white",
    },
    "& .MuiButton-root:nth-child(2)": {
      backgroundColor: "#FF990090",
      color: "white",
    },
    "& .Mui-disabled": {
      backgroundColor: "#9B9A9A",
    },
  },
  ups: {
    backgroundColor: "#351C15",
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#351C15",
      },
    },
    "& .MuiListItem-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiButton-root:nth-child(2)": {
      backgroundColor: "#FFD500",
      color: "white",
    },
    "& .MuiButton-root:nth-child(1)": {
      backgroundColor: "#FFD50090",
      color: "white",
    },
    "& .Mui-disabled": {
      backgroundColor: "#9B9A9A",
    },
  },
}));
