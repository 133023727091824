import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  getNationalCountGroups,
  getNationalInventoryCounts,
} from "api/inventory";

import { DatePickerGroup, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { FolderSpecial, Person, QrCode2 } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";

const mergeQuantities = (items) => {
  const barcodes = {};
  const mergedItems = [];

  // create initial object for each barcode
  items.forEach((item) => {
    barcodes[item.barcode] = { quantity: 0, product_sku: item.product_sku };
  });

  // merge quantities
  items.forEach((item) => (barcodes[item.barcode].quantity += item.quantity));
  Object.entries(barcodes).forEach(([barcode, data]) => {
    mergedItems.push({ ...data, barcode });
  });
  return mergedItems;
};

const commonHeaders = [
  {
    id: "barcode",
  },
  {
    id: "product_sku",
    exportLabel: "Inventory Id",
  },
  {
    id: "quantity",
  },
];

const headers = commonHeaders.concat([
  {
    id: "created_by",
    render: (r) => toTitleCase(r.created_by.username),
  },
  {
    id: "created_at",
    render: (r) => timezoneFormat(r.created_at),
  },
]);

export default function NationalInventoryCouuntGroupDetail(props) {
  const { id } = useParams();
  const [data, setData] = useState(initialAPIListValue);
  const [params, setParams] = useState({
    count_group_id: id,
    barcode: "",
    product_sku: "",
    created_by__username: "",
    start_date: "",
    end_date: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [pageTitle, setPageTitle] = useState({
    title: "",
    helpText: "",
  });
  const [exportDisabled, setExportDisabled] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    getNationalCountGroups({
      params: {
        id,
      },
      responseSetter: (res) => {
        const data = res.results;
        if (data.length) {
          setPageTitle({
            title: `${data[0].type} ${data[0].number}`,
            helpText: `${data[0].note}`,
          });
        }
      },
    });
  }, [id]);

  useEffect(() => {
    if (data.isFetching) {
      getNationalInventoryCounts({
        params: { ...params, limit: rowsPerPage, offset: rowsPerPage * page },
        setFunc: setData,
        paginated: true,
      });
    }
  }, [id, data.isFetching, params, page, rowsPerPage]);

  const handleChangeFilterInput = (props) => (e) => {
    setParams({ ...params, [props]: e.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setData({ ...data, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setData({ ...data, isFetching: true });
  };

  const handleExportData = () => {
    const items = mergeQuantities(data.items);
    const today = new Date();
    exportToExcel(
      [],
      `${pageTitle.title}-Item-Count-Detail-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(items, commonHeaders),
      setExportDisabled,
    );
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setData((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  return !pageTitle.title ? (
    <CircularProgress />
  ) : (
    <>
      <PageTitle
        title={pageTitle.title}
        helpText={pageTitle.helpText}
        backLink="/app/item-counts-group"
      />
      <InputContainer>
        <Grid container>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              variant="outlined"
              label={t("barcode")}
              value={params.barcode}
              onChange={handleChangeFilterInput("barcode")}
              InputProps={clearAdornment("barcode")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              variant="outlined"
              label={t("product_sku")}
              value={params.product_sku}
              onChange={handleChangeFilterInput("product_sku")}
              isProductSKU={true}
              InputProps={clearAdornment("product_sku")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              variant="outlined"
              label={t("created_by")}
              value={params.created_by__username}
              onChange={handleChangeFilterInput("created_by__username")}
              InputProps={clearAdornment("created_by__username")}
              LeftIcon={Person}
            />
          </Grid>
          <DatePickerGroup
            startDateValue={params.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={params.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
          />
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={headers}
            data={data}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleExportData={handleExportData}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={params}
          />
        </Grid>
      </Grid>
    </>
  );
}
