import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  profile: {
    left: 0,
    bottom: 0,
    position: "fixed",
    width: 230,
    height: "8vh",
    backgroundColor: theme.palette.colors.dark_grey_blue,
    color: theme.palette.colors.white,
    paddingTop: theme.spacing(1),
    borderTop: "1px solid",
    borderColor: theme.palette.colors.blue_grey,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClosedProfile: {
    width: 64,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  photo: {
    float: "left",
    width: "50px",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "right",
    margin: "0.5rem auto",
  },
  detail: {
    textAlign: "left",
    float: "left",
    width: "140px",
    paddingLeft: "20px",
  },
  name: {
    marginTop: "3px",
  },
  link: {
    marginTop: -theme.spacing(1.7),
    "& > a": {
      color: theme.palette.colors.light_hover,
    },
    "& > a:hover": {
      color: theme.palette.colors.blue_grey,
    },
  },
  logout: {
    float: "right",
    paddingTop: "13px",
    paddingLeft: "2px",
    width: "40px",
    cursor: "pointer",
  },
}));
