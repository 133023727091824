import { express_services } from "./constants";

export function isFedex(r) {
  return r.ship_via && r.ship_via.slice(0, 5).toLowerCase() === "fedex";
}

export function isUps(r) {
  return r.ship_via && r.ship_via.slice(0, 3).toLowerCase() === "ups";
}

export function isExpress(r) {
  return express_services.includes(r.toUpperCase());
}

export function formatTrackingNumber(tracking_number) {
  return tracking_number.startsWith("1Z")
    ? tracking_number
    : tracking_number.length >= 12
    ? tracking_number.includes("61299999")
      ? tracking_number.substring(tracking_number.length - 20)
      : tracking_number.substring(tracking_number.length - 12)
    : tracking_number;
}

export const tracking_re = new RegExp(/(1Z(V|C)[0-9]{12,})|([0-9]{12,})/);
