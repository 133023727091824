import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getTransfers = ({
  setFunc,
  setError,
  paginated,
  params,
  responseSetter,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/transfers/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getTransferCycleCountsById = ({ id, responseSetter }) => {
  api.list({
    endpoint: `custom/transfer-cycle-counts/?transfer_id=${id}`,
    responseSetter,
  });
};

const createTransfer = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-transfer/",
    body,
    responseSetter,
  });
};

const transferPublish = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/transfer-publish/",
    body,
    responseSetter,
  });
};

const cancelTransfer = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/cancel-transfer/",
    body,
    responseSetter,
  });
};

export {
  createTransfer,
  cancelTransfer,
  getTransfers,
  getTransferCycleCountsById,
  transferPublish,
};
