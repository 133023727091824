import { IconButton, InputAdornment } from "@material-ui/core";
import { X as Clear } from "icons";

export default function getClearAdornment(input, onClickHandler, container) {
  return {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          size="small"
          onClick={() => onClickHandler(input)}
          edge="end"
        >
          {container[input] && container[input].length > 0 && <Clear />}
        </IconButton>
      </InputAdornment>
    ),
  };
}
