import { useState } from "react";

import { Grid } from "@material-ui/core";

import { createCastlegatePDF } from "api/shipment";

import { Button, Dropdown, InputContainer } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { toBase64Handler } from "utils/base64";
import { CASTLEGATE_LOCATIONS } from "utils/constants";

const headers = [
  {
    id: "name",
    label: "File Name",
  },
];

export default function CastlegatePDFGenerator() {
  const layoutDispatch = useLayoutDispatch();
  const [transferLocation, setTransferLocation] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = !uploadedFiles.length || isLoading;

  const handlePDFFileChange = ({ target: { files } }) => {
    const uploaded = [...uploadedFiles];
    const filesArray = [...files];
    filesArray
      .filter(
        (file) => uploadedFiles.findIndex((f) => f.name === file.name) === -1,
      )
      .map((file) => {
        uploaded.push(file);
        return file;
      });
    setUploadedFiles(filesArray);
  };

  const handleSubmit = async () => {
    const files = await toBase64Handler(uploadedFiles);
    setIsLoading(true);
    createCastlegatePDF({
      body: {
        files,
        transfer_location: transferLocation,
      },
      responseSetter: (res) => {
        setIsLoading(false);
        const { success, message, email } = res;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return;
        }

        popupNotification({
          dispatch: layoutDispatch,
          message: `We'll send to email within 5 minutes.Please check your email.(${email})`,
          status: "success",
        });
      },
    });
  };
  return (
    <>
      <PageTitle title="Castlegate PDF Generator" />
      <InputContainer>
        <Grid container>
          <Grid item lg={2}>
            <label htmlFor="pdf-upload">
              <input
                id="pdf-upload"
                type="file"
                accept="application/pdf"
                onChange={handlePDFFileChange}
                multiple
                hidden
              />
              <Button
                variant="contained"
                size="medium"
                color="primary"
                component="span"
                disabled={isLoading}
              >
                Upload PDF Files ({uploadedFiles.length})
              </Button>
            </label>
          </Grid>
          <Grid item lg={1}>
            <Button
              variant="contained"
              color="danger"
              onClick={() => setUploadedFiles([])}
              disabled={isDisabled}
            >
              Clear
            </Button>
          </Grid>

          <Grid item lg={5}></Grid>
          <Grid item lg={2}>
            <Dropdown
              id="transfer_location"
              title="Transfer Location"
              value={transferLocation}
              onChange={({ target: { value } }) => setTransferLocation(value)}
              items={CASTLEGATE_LOCATIONS.map((location_code) => ({
                value: location_code,
                label: location_code,
              }))}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item lg={2}>
            <Button
              variant="contained"
              color="blue"
              disabled={isDisabled || !transferLocation}
              onClick={handleSubmit}
            >
              Create Castlegate PDF
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12}>
          <DefaultTable
            headers={headers}
            data={{
              items: [...uploadedFiles],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
