import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    boxShadow: "none",
    overflow: "hidden",
  },
  table: {
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: theme.palette.colors.slate100,
      textTransform: "uppercase",
    },
    "& thead > tr": {
      "& > th": {
        backgroundColor: `${theme.palette.colors.table_header_green} !important`,
        color: theme.palette.colors.dark_active_grey,
        fontSize: theme.typography.pxToRem(11),
        fontFamily: "Roboto",
        fontWeight: "bold",
        letterSpacing: theme.typography.pxToRem(1.1),
        borderBottom: `2px solid rgba(159, 162, 191, 0.18)`,
      },
      "th:first-child": {
        borderTopLeftRadius: theme.typography.pxToRem(6),
      },
      "th:last-child": {
        borderTopRightRadius: theme.typography.pxToRem(6),
      },
    },
    "& tbody > :nth-of-type(odd)": {
      backgroundColor: `${theme.palette.colors.table_item_green}`,
    },
    "& tbody > :nth-of-type(even)": {
      backgroundColor: `${theme.palette.colors.white}`,
    },
    "& tbody > tr": {
      "& > th, td": {
        border: "none",
        fontFamily: "Roboto",
        fontSize: theme.typography.pxToRem(13),
        textAlign: "left",
        color: "#223354",
        "& > a": {
          color: theme.palette.colors.blue_button,
        },
      },
      "& > th:first-child, td:first-child": {
        borderRadius: `${theme.typography.pxToRem(
          6,
        )} 0 0 ${theme.typography.pxToRem(6)}`,
      },
      "& > th:last-child, td:last-child": {
        borderRadius: `0 ${theme.typography.pxToRem(
          6,
        )} ${theme.typography.pxToRem(6)} 0`,
      },
    },
    "& tbody > tr:first-child": {
      "& > th:first-child, td:first-child": {
        borderTopLeftRadius: "0px !important",
      },
      "& > th:last-child, td:last-child": {
        borderTopRightRadius: "0px !important",
      },
    },
    "& tbody > tr:last-child": {
      "& > th, td": {
        borderBottom: `1px solid rgba(159, 162, 191, 0.18)`,
      },
      "& > th:first-child, td:first-child": {
        borderBottomLeftRadius: "0px !important",
      },
      "& > th:last-child, td:last-child": {
        borderBottomRightRadius: "0px !important",
      },
    },
    "& tfoot > tr": {
      backgroundColor: `${theme.palette.colors.table_header_green}`,
      "& > td": {
        border: "none",
      },
      "& > td:first-child": {
        borderBottomLeftRadius: theme.typography.pxToRem(6),
      },
      "& > td:last-child": {
        borderBottomRightRadius: theme.typography.pxToRem(6),
      },
      "& > td > div > div": {
        "& > p": {
          fontFamily: "Roboto",
          fontSize: theme.typography.pxToRem(14),
          textAlign: "left",
          color: "rgba(34, 51, 84, 0.5)",
        },
        "& > div > .MuiTablePagination-select": {
          flexGrow: "0",
          fontFamily: "Roboto",
          fontSize: theme.typography.pxToRem(14),
          color: "black",
        },
      },
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: `${theme.palette.colors.slate200} !important`,
      "& > th": {
        color: "black !important",
        fontWeight: "normal !important",
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkboxGrid: { margin: "auto", marginLeft: "0px" },
  checkbox: { padding: "0px" },
  formControl: { marginLeft: "-18px" },
  card: { marginBottom: "5px" },
  typography: { fontSize: 14 },
  popper: { zIndex: 1500, marginTop: "0.5rem" },
  popperPaper: { minWidth: "12rem", maxHeight: "50rem", overflowY: "auto" },
  openFilters: { display: "flex", flexDirection: "row" },
  rightBorder: { borderRight: "1px solid #eee" },
  filterTitle: { padding: "1rem", fontWeight: "bold" },
  divider: { marginBottom: "0.5rem" },
  filterContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem 0.5rem 1rem",
    maxHeight: "40rem",
    overflowY: "auto",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "0.5rem",
  },
  filterText: {
    color: "red",
    position: "absolute",
    bottom: "0.9rem",
    right: "3.25rem",
  },
  filterIcon: {
    padding: "0.4rem",
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
  },
  headerTitle: {
    opacity: "0.75",
  },
  fullWidth: { width: "100%" },
}));
