import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { TextInput } from "components/Form";
import DefaultTable from "components/Table";

import { Person } from "icons";

import { toTitleCase } from "utils/string";

export default function AssigneeDetailModal({
  assignedLocations,
  headers,
  inventoryGroupUsers,
  onClose,
  onReassign,
  onUnassign,
}) {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);

  const unassignDisabled = selectedLocations.length === 0;

  const reassignDisabled =
    unassignDisabled ||
    !selectedUser.id ||
    selectedUser.id === assignedLocations[0].assignee.id;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="assigned-locations"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="assigned-locations">
        Assigned Locations (
        {toTitleCase(assignedLocations[0].assignee.username)})
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item lg={12}>
            <DefaultTable
              data={{
                items: assignedLocations,
                count: assignedLocations.length,
                isFetching: false,
              }}
              headers={headers}
              footerVisible={false}
              hasCheckBox={true}
              selected={selectedLocations}
              setSelected={setSelectedLocations}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Autocomplete
          value={selectedUser && selectedUser.id ? selectedUser : null}
          options={inventoryGroupUsers.items.sort((a, b) =>
            a.user.first_name.localeCompare(b.user.first_name),
          )}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.user.first_name}
          onChange={(e, user) => setSelectedUser(user || {})}
          renderInput={(params) => (
            <TextInput
              {...params}
              label="User"
              variant="outlined"
              LeftIcon={Person}
            />
          )}
        />
        <Button
          variant="contained"
          onClick={() => {
            onReassign(selectedLocations, selectedUser);
            onClose();
          }}
          style={{
            backgroundColor: reassignDisabled
              ? theme.palette.common.main
              : theme.palette.secondary.main,
            color: "white",
            marginRight: theme.spacing(2),
          }}
          disabled={reassignDisabled}
        >
          Re-assign
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onUnassign(selectedLocations);
            onClose();
          }}
          style={{
            backgroundColor: unassignDisabled
              ? theme.palette.common.main
              : theme.palette.error.main,
            color: "white",
            marginRight: "auto",
          }}
          disabled={unassignDisabled}
        >
          Unassign
        </Button>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
