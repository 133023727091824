import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import { getShipments } from "api/shipment";

import PageTitle from "components/PageTitle";

import { initialAPIListValue } from "utils/constants";

import ShipmentPreview from "./ShipmentPreview";

export default function LTLShipmentDetail() {
  const { shipment_id } = useParams();
  const [shipment, setShipment] = useState(initialAPIListValue);

  useEffect(() => {
    getShipments({
      id: shipment_id,
      responseSetter: (data) => setShipment({ isFetching: false, ...data }),
    });
  }, [shipment_id]);

  return shipment.isFetching ? (
    <CircularProgress />
  ) : (
    <>
      <PageTitle
        title={shipment.shipment_number}
        backLink="/app/ltl/shipments"
      />
      <ShipmentPreview shipment={shipment} />
    </>
  );
}
