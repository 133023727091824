import * as React from "react";

const SvgQrCode = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3 6.5H5.7a2.66 2.66 0 0 0-1.91.816A2.832 2.832 0 0 0 3 9.286v7.428c0 .74.284 1.448.79 1.97a2.66 2.66 0 0 0 1.91.816h12.6a2.66 2.66 0 0 0 1.91-.816c.505-.522.79-1.23.79-1.97V9.286c0-.739-.285-1.447-.79-1.97A2.66 2.66 0 0 0 18.3 6.5zM6.6 16.714h-.9V9.286h.9v7.428zm2.7 0H7.5V9.286h1.8v7.428zm1.8 0h-.9V9.286h.9v7.428zm3.6 0H12V9.286h2.7v7.428zm1.8 0h-.9V9.286h.9v7.428zm1.8 0h-.9V9.286h.9v7.428z"
      fill={color}
    />
  </svg>
);

export default SvgQrCode;
