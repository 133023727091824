import {
  deepOrange,
  orange,
  deepPurple,
  blueGrey,
  green,
  blue,
  brown,
} from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    width: "25rem",
  },
  header: {
    width: "100%",
    wordBreak: "break-all",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  calendarIcon: { paddingTop: "3px", paddingRight: "3px" },
  title: {
    backgroundColor: blueGrey[900],
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  detailFrame: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
    "& .MuiListItem-dense.MuiListItem-gutters": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
  },
  paper: {
    backgroundColor: blue[100],
    width: "auto",
    margin: "0",
    borderRadius: "0",
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    borderRadius: 0,

    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepPurple[800]),
      backgroundColor: deepPurple[800],
    },
  },
  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
    borderRadius: 0,

    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(brown[800]),
      backgroundColor: brown[800],
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    borderRadius: 0,

    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepOrange[800]),
      backgroundColor: deepOrange[800],
    },
  },
  ecomBadge: {
    position: "absolute",
    left: 30,
    "& span": {
      backgroundColor: green[500],
      fontWeight: "bold",
    },
  },
  natBadge: {
    position: "absolute",
    left: 30,
    "& span": {
      backgroundColor: blue[500],
      fontWeight: "bold",
    },
  },
  mixBadge: {
    position: "absolute",
    left: 30,
    "& span": {
      backgroundColor: orange[600],
      fontWeight: "bold",
    },
  },
  furnBadge: {
    position: "absolute",
    left: 30,
    "& span": {
      backgroundColor: brown[500],
      fontWeight: "bold",
    },
  },
  lineItemTitle: { fontSize: 13, whiteSpace: "nowrap" },
  lineItemValue: { fontWeight: "bold", wordBreak: "break-word", fontSize: 13 },
  floatRight: { float: "right" },
  accordion: { backgroundColor: "#fafafa" },
  accordionContent: {
    backgroundColor: "#fff",
    border: "1px dashed #0002",
    borderRadius: "5px",
  },
  notesTitle: { fontWeight: "bold" },
  notesContainer: {
    backgroundColor: "#fff",
    padding: "10px",
    wordBreak: "break-all",
    border: "1px dashed #0005",
  },
  fileContainer: {
    backgroundColor: "#fff",
    border: "1px dashed #0002",
    padding: "10px !important",
    borderRadius: "10px",
    margin: "0.15rem 0",
  },
}));
