import ReactApexChart from "react-apexcharts";

import { useTheme } from "@material-ui/styles";

import { toTitleCase } from "utils/string";

export function Circle({
  number,
  title,
  color,
  size,
  series,
  isSelected,
  tooltip = false,
  tooltipDataFormatter,
  tooltipTitleFormatter,
  onClick,
}) {
  const ratio = ((series[1] / series[0]) * 100).toFixed(0);
  const theme = useTheme();
  let options = {
    chart: {
      type: "radialBar",
      height: size,
      toolbar: {
        show: false,
      },
    },
    colors: [color],
    labels: [toTitleCase(title)],
    legend: {
      show: true,
      position: "bottom",
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          background: isSelected ? `${color}25` : theme.palette.colors.white,
          position: "front",
          size: "70%",
        },
        track: {
          background: theme.palette.colors.slate200,
          strokeWidth: "100%",
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "4rem",
            fontWeight: "bold",
            color: color,
            offsetY: 20,
            formatter: () => number,
          },
          total: { show: false },
        },
      },
    },
    redrawOnWindowResize: true,
    series: [ratio],
    stroke: {
      lineCap: "round",
    },
    tooltip: {
      enabled: tooltip,
      y: {
        formatter: () =>
          tooltipDataFormatter
            ? tooltipDataFormatter(number, ratio)
            : `%${ratio}`,
        title: {
          formatter: (seriesName) =>
            tooltipTitleFormatter
              ? tooltipTitleFormatter(seriesName)
              : `${seriesName}:`,
        },
      },
    },
  };

  return (
    <ReactApexChart
      onClick={onClick ? onClick() : null}
      options={options}
      series={[ratio]}
      type="radialBar"
      height={size}
      width="100%"
    />
  );
}
