import * as React from "react";

const SvgClose = ({ color, size }) => (
  <svg
    viewBox="0 0 25 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.744 6.41 18.334 5l-5.59 5.59L7.154 5l-1.41 1.41 5.59 5.59-5.59 5.59L7.154 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
      fill={color}
    />
  </svg>
);

export default SvgClose;
