/**
 * @param {Object} mergedFilters
 *
 * example:
 *  mergedFilters = {
 *    po_type: ["Ecommerce", "National"],
 *    customer: [],
 *    importer: ["Tayse"],
 *   });
 *
 * expected result: "&po_type=Ecommerce&po_type=National&importer=Tayse"
 */
export const convertMergedFiltersToQueryString = (mergedFilters) => {
  let queryString = "";
  Object.entries(mergedFilters).forEach(([key, value]) => {
    if (value.length) {
      value.forEach((v) => {
        queryString += `&${key}=${v}`;
      });
    }
  });
  return queryString;
};
