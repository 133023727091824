import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

import { getDuplicateItems } from "api/inventory";
import { getAisles, getLevels } from "api/locations";

import { ProductTypeFilter } from "components/Custom";
import { InputContainer, TextInput } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { FormatListNumbered, QrCode2, SpaceBar, TextSnippet } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

const cells = [
  {
    id: "location__code",
    label: "location",
    render: (r) => r.location.code,
    sortable: true,
  },
  {
    id: "product__sku",
    label: "product_sku",
    exportLabel: "Inventory Id",
    render: (r) => r.product.sku,
    sortable: true,
  },
  { id: "qty", exportLabel: "Quantity", sortable: true },
  { id: "size", render: (r) => r.product.short_size },
  { id: "product_type", render: (r) => r.product.product_type_display },
];

export default function DuplicateItems(props) {
  const { t } = useTranslation();
  const { is_manager, related_product } = parseJwt(getWithExpiry("access"));
  const layoutDispatch = useLayoutDispatch();

  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [inventory, setInventory] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product__product_type: related_product || 1,
    product__sku: "",
    location__code: "",
    location__aisle: "",
    location__level: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("location__code");

  const [aisles, setAisles] = useState(initialAPIListValue);
  const [levels, setLevels] = useState(initialAPIListValue);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  useEffect(() => {
    getAisles({ setFunc: setAisles });
    getLevels({ setFunc: setLevels });
  }, []);

  useEffect(() => {
    if (inventory.isFetching && (is_manager || search.product__product_type)) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getDuplicateItems({
        params,
        setError,
        setFunc: (res) => {
          setInventory(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    inventory.isFetching,
    is_manager,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setInventory({ ...inventory, isFetching: true });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setInventory({ ...inventory, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeAutoCompleteFilterInput = (prop) => (e, item) => {
    setSearch({ ...search, [prop]: item ? item.value : null });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setInventory({
      items: inventory.items,
      isFetching: true,
      count: inventory.count,
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Duplicate-Items-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (inventory.items.length === inventory.count) {
      exportData(inventory.items);
    } else {
      getDuplicateItems({
        params: {
          limit: inventory.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    setPage(0);
    setInventory({
      ...inventory,
      isFetching: true,
    });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setInventory((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("duplicate_items")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product__sku}
              onChange={handleChangeFilterInput("product__sku")}
              onKeyUp={debouncedSearchHandler}
              LeftIcon={QrCode2}
              InputProps={clearAdornment("product__sku")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="location"
              label={t("location")}
              variant="outlined"
              value={search.location__code}
              onChange={handleChangeFilterInput("location__code")}
              onKeyUp={debouncedSearchHandler}
              isLocationCode={true}
              LeftIcon={SpaceBar}
              InputProps={clearAdornment("location__code")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="location__aisle"
              onChange={handleChangeAutoCompleteFilterInput("location__aisle")}
              options={
                aisles.items.length
                  ? aisles.items.map((item) => ({
                      value: item.aisle,
                      label: item.aisle,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.aisle === value.aisle
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("aisle")}
                  variant="outlined"
                  LeftIcon={FormatListNumbered}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              id="location__level"
              onChange={handleChangeAutoCompleteFilterInput("location__level")}
              options={
                levels.items.length
                  ? levels.items.map((item) => ({
                      value: item.level,
                      label: item.level,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              fullWidth
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("level")}
                  variant="outlined"
                  LeftIcon={TextSnippet}
                />
              )}
            />
          </Grid>
          <ProductTypeFilter
            value={search.product__product_type}
            setValue={(data) => {
              setSearch({ ...search, product__product_type: data });
              debouncedSearchHandler();
            }}
            onChange={handleChangeFilterInput("product__product_type")}
          />
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={inventory}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
