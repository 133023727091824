import React from "react";

import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

import classNames from "classnames";

// styles
import useStyles from "./styles";

export default function WidgetBox({
  id,
  title,
  text,
  onClick,
  isFetching,
  children,
  isActive = false,
  bgColor = null,
}) {
  var classes = useStyles();

  return (
    <Card
      id={id}
      className={classNames(classes.widgetCard, {
        [classes.widgetCardActive]: isActive,
      })}
      onClick={() => (!isActive ? (onClick ? onClick() : null) : null)}
      style={{ backgroundColor: bgColor }}
    >
      <CardContent>
        <Typography
          variant={`h${title.length / 2 > 6 ? 6 : parseInt(title.length / 2)}`}
          style={{ whiteSpace: "nowrap" }}
        >
          {title}
        </Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            {text && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant={`h${
                      text.length / 3 > 6 ? 6 : parseInt(text.length / 3)
                    }`}
                  >
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {children}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export function TooltipWidgetBox({ text, children }) {
  var classes = useStyles();

  return (
    <Tooltip title={text} arrow>
      <div className={classes.tooltip}>{children}</div>
    </Tooltip>
  );
}
