import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const createGroup = ({ setFunc, setError, body }) => {
  api.create({
    endpoint: "stock/product-group/",
    setError,
    setFunc,
    body,
  });
};

const getClassificationConfig = ({ setFunc, setError, responseSetter }) => {
  api.list({
    endpoint: "stock/classification-config/",
    setError,
    setFunc,
    responseSetter,
  });
};

const getClassifications = ({
  setFunc,
  setError,
  responseSetter,
  paginated,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("stock/classification-history/", params),
    setError,
    setFunc,
    responseSetter,
    paginated,
  });
};

const getCollectionRecommendations = ({
  setFunc,
  setError,
  responseSetter,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("stock/collection-recommendations/", params),
    setError,
    setFunc,
    responseSetter,
  });
};

const getGroups = ({ setFunc, setError, responseSetter }) => {
  api.list({
    endpoint: "stock/product-group/?limit=9999",
    setError,
    setFunc,
    responseSetter,
  });
};

const getGroupMatrix = ({ setFunc, setError, responseSetter, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("stock/group-matrix/", params),
    setError,
    setFunc,
    responseSetter,
  });
};

const getProductPolicy = ({ productId, setFunc, setError, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("stock/product-policy/", { product_id: productId }),
    setError,
    setFunc,
    responseSetter,
  });
};

const updateClassificationConfig = ({ setFunc, setError, body }) => {
  api.create({
    endpoint: "stock/classification-config/",
    setError,
    setFunc,
    body,
  });
};

const updateForecast = ({ setFunc, setError, body }) => {
  api.create({
    endpoint: "stock/update-forecast/",
    setError,
    setFunc,
    body,
  });
};

const updateProductPolicy = ({ setFunc, setError, body }) => {
  api.create({
    endpoint: "stock/update-product-policy/",
    setError,
    setFunc,
    body,
  });
};

const classificationExport = ({ setFunc, setError, body }) => {
  api.create({
    endpoint: "stock/classification-export/",
    setError,
    setFunc,
    body,
  });
};

export {
  classificationExport,
  createGroup,
  getClassificationConfig,
  getClassifications,
  getCollectionRecommendations,
  getGroups,
  getGroupMatrix,
  getProductPolicy,
  updateClassificationConfig,
  updateForecast,
  updateProductPolicy,
};
