import React, { useCallback, useEffect, useState } from "react";

import { debounce, pickBy, sum } from "lodash";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  CircularProgress,
  Grid,
  Button as MUIButton,
} from "@material-ui/core";

import {
  getCarrierShipmentCounts,
  getShipments,
  downloadLabels,
  refreshErroredShipments,
} from "api/shipment";
import { QuantityResult } from "components/Custom";
import {
  Button,
  DatePickerGroup,
  Dropdown,
  InputContainer,
  SwitchButtonGroup,
  SwitchButton,
} from "components/Form";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import {
  Calculator,
  ExternalLink as ExternalLinkIcon,
  SnippetFolder,
  FolderSpecial,
  Style,
  Clock,
  Warning,
  Printer,
  Check,
  Truck,
  Download,
  Refresh2,
  Close,
  QrCode2,
} from "icons";

import { getCarrierTrackingLink } from "./utils";
import { downloadPDF } from "utils/base64";
import { convertDate, getPreviousDate, timezoneFormat } from "utils/date";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";
import {
  ACUMATICA_BASE_URL,
  BatchTypes,
  initialAPIListValue,
  ORDER_URL,
  SHIPMENT_URL,
} from "utils/constants";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import EndOfDayModal from "./EndOfDayModal";
import MorningBatchModal from "./MorningBatchModal";
import RatesModal from "./RatesModal";

import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";
import useStyles from "./styles";
import ShipmentDrawer from "./ShipmentDrawer";

let intervalID;

const shipmentStatuses = [
  { label: "OPEN", value: "Open", icon: Clock, color: "red" },
  { label: "ERROR", value: "Errored", icon: Warning, color: "red" },
  { label: "PRINTED", value: "Printed", icon: Printer, color: "#00aa00" },
  { label: "WARNING", value: "Warning", icon: Warning, color: "orange" },
  { label: "IN TRANSIT", value: "In_Transit", icon: Truck, color: "#00aadd" },
  { label: "CANCELS", value: "Cancelled", icon: Close, color: "red" },
  { label: "DELIVERED", value: "Delivered", icon: Check, color: "green" },
];

export default function ShipmentsToPrint() {
  const [t] = useTranslation();
  const classes = useStyles();

  const initialCells = [
    {
      id: "shipment_number",
      render: (r, exported) =>
        exported ? (
          r.shipment_number
        ) : (
          <Grid container spacing={1}>
            <Grid item>
              <InternalLink
                to={`/app/shipments/${r.id}`}
                children={r.shipment_number}
              />
            </Grid>
            <Grid item>
              <ExternalLink
                to={`${ACUMATICA_BASE_URL}${SHIPMENT_URL}${r.shipment_number}`}
                children={<ExternalLinkIcon color="blue" size={0.7} />}
              />
            </Grid>
          </Grid>
        ),
      style: {
        minWidth: "150px",
      },
      sortable: true,
    },
    {
      id: "customer",
      render: (r) => r.order.customer_id,
    },
    {
      id: "order_number",
      render: (r) => (
        <Grid container spacing={1}>
          <Grid item>{r.order.number}</Grid>
          <Grid item>
            <ExternalLink
              to={`${ACUMATICA_BASE_URL}${ORDER_URL}${r.order.number}`}
              children={<ExternalLinkIcon color="blue" size={0.7} />}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      id: "po_number",
      render: (r) => r.order.po_number,
      style: {
        minWidth: "150px",
      },
    },
    {
      id: "ship_via",
      render: (r) => (
        <Grid container alignItems="center">
          <Grid item>
            {r.ship_via.split(" ")[0] === "FEDEX" ? (
              <Avatar src={FedexLogo} className={classes.fedexLogoSmall} />
            ) : (
              <Avatar src={UpsLogo} className={classes.upsLogoSmall} />
            )}
          </Grid>
          <Grid item>
            {toTitleCase(
              r.ship_via
                .split(" ")
                .filter((r) => !["FEDEX", "UPS"].includes(r))
                .join(" "),
            )}
          </Grid>
        </Grid>
      ),
      style: {
        minWidth: "120px",
      },
    },
    {
      id: "tracking_number",
      render: (r) =>
        r.tracking_number ? (
          <Grid container spacing={1}>
            <Grid item>{r.tracking_number}</Grid>
            <Grid item>
              <ExternalLink
                to={getCarrierTrackingLink(r.ship_via, r.tracking_number)}
                children={<ExternalLinkIcon color="blue" size={0.7} />}
              />
            </Grid>
          </Grid>
        ) : (
          <span className={classes.errorMessage}>{r.error_message}</span>
        ),
    },
    {
      id: "created_date",
      label: "created_at",
      render: (r) => timezoneFormat(r.created_date),
      style: {
        minWidth: "150px",
      },
      sortable: true,
    },
  ];

  const [cells, setCells] = useState(initialCells);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useState({
    is_small_carrier: true, // default
    shipflow_status: "Open",
    batch_type: "",
    shipment_number: "",
    ship_via: "",
    order__number: "",
    order__po_number: "",
    order__customer_id: "",
    start_date: convertDate(getPreviousDate(new Date(), 0, 1)), // one month before
    end_date: "",
    sku: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [shipments, setShipments] = useState(initialAPIListValue);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [headerCounts, setHeaderCounts] = useState({});
  const [shipmentCounts, setShipmentCounts] = useState({
    isFetching: true,
    data: {
      fedex_open: 0,
      fedex_errored: 0,
      fedex_printed: 0,
      fedex_warning: 0,
      fedex_in_transit: 0,
      fedex_cancelled: 0,
      fedex_delivered: 0,
      ups_open: 0,
      ups_errored: 0,
      ups_printed: 0,
      ups_warning: 0,
      ups_in_transit: 0,
      ups_cancelled: 0,
      ups_delivered: 0,
    },
  });
  const [endOfDayModal, setEndOfDayModal] = useState(false);
  const [morningBatchModal, setMorningBatchModal] = useState(false);
  const [ratesModal, setRatesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shipment, setShipment] = useState({});
  const layoutDispatch = useLayoutDispatch();

  useEffect(() => {
    if (shipments.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getShipments({
        params,
        setFunc: (res) => {
          setShipments(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    rowsPerPage,
    shipments.isFetching,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    setSelectedShipments([]);
  }, [search.ship_via, search.shipflow_status]);

  useEffect(() => {
    if (shipmentCounts.isFetching) {
      getCarrierShipmentCounts({
        params: { start_date: search.start_date, end_date: search.end_date },
        responseSetter: (res) => {
          setShipmentCounts({ isFetching: false, data: res });
        },
      });
    }
  }, [search.start_date, search.end_date, shipmentCounts.isFetching]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setShipments({ ...shipments, isFetching: true });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setShipments((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const handleChangeFilterSelect = (prop) => ({ target: { value } }) => {
    if (["start_date", "end_date"].includes(prop)) {
      setShipmentCounts({ ...shipmentCounts, isFetching: true });
    }
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setShipments({ ...shipments, isFetching: true });
    }, 500),
    [],
  );

  const handleShipmentAction = (shipflowStatus, shipmentNumbers = []) => {
    popupNotification({
      dispatch: layoutDispatch,
      status: "info",
      message: "PLEASE WAIT UNTIL THE PROCESS IS COMPLETED...",
    });
    setIsLoading(true);
    const shipmentIds = shipmentNumbers.length
      ? shipmentNumbers
      : selectedShipments.map((s) => s.shipment_number);
    let params = {
      shipment_numbers: shipmentIds.join(","),
      reprint: !["Open", "Errored"].includes(shipflowStatus),
    };
    if (shipflowStatus === "Errored") {
      refreshErroredShipments({
        params,
        responseSetter: (res) => {
          if (res.success) {
            intervalID = setInterval(() => {
              refreshErroredShipments({
                params: {},
                responseSetter: (res) => {
                  if (res.success) {
                    setIsLoading(false);
                    setSelectedShipments([]);
                    setShipments({ ...shipments, isFetching: true });
                    setShipmentCounts({ ...shipmentCounts, isFetching: true });
                    clearInterval(intervalID);
                  }
                },
              });
            }, 5000);
          } else {
            if (res.message) {
              popupNotification({
                dispatch: layoutDispatch,
                status: "error",
                message: res.message,
              });
            }
            setIsLoading(false);
          }
        },
      });
    } else {
      downloadLabels({
        params,
        responseSetter: (res) => {
          if (res.process_key) {
            intervalID = setInterval(() => {
              downloadLabels({
                params: {
                  process_key: res.process_key,
                },
                responseSetter: (res) => {
                  if (res.success) {
                    downloadPDF(
                      res.data,
                      `${
                        search.ship_via
                          ? search.ship_via.toUpperCase() + "-"
                          : ""
                      }${shipflowStatus}-${
                        search.batch_type ? search.batch_type + "-" : ""
                      }${new Date().toTimeString().slice(0, 8)}`,
                    );
                    setIsLoading(false);
                    setSelectedShipments([]);
                    setShipments({ ...shipments, isFetching: true });
                    setShipmentCounts({ ...shipmentCounts, isFetching: true });
                    clearInterval(intervalID);
                  }
                },
              });
            }, 5000);
          }
        },
      });
    }
  };

  const handleDrawerClose = (status) => {
    if (status) {
      setShipments((prev) => ({ ...prev, isFetching: true }));
    }
    setShipment({});
  };

  return (
    <>
      <PageTitle
        backLink={"/app/shipments"}
        title="Shipments to Print"
        button={
          <Grid container spacing={1}>
            <Grid item>
              <MUIButton
                variant="contained"
                size="medium"
                color="primary"
                className={classes.borderedButton}
                onClick={() => setRatesModal(true)}
              >
                <Calculator color="#fff" size={0.8} /> Rates
              </MUIButton>
            </Grid>
            <Grid item>
              <MUIButton
                variant="contained"
                size="medium"
                color="secondary"
                className={classes.borderedButton}
                onClick={() => setMorningBatchModal(true)}
              >
                Morning Batch
              </MUIButton>
            </Grid>
            <Grid item>
              <MUIButton
                variant="contained"
                size="medium"
                color="primary"
                className={classes.borderedButton}
                onClick={() => setEndOfDayModal(true)}
              >
                End Of Day Report
              </MUIButton>
            </Grid>
          </Grid>
        }
        multipleButtons={true}
      />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="batch_type"
              title="Batch Type"
              value={search.batch_type}
              onChange={handleChangeFilterSelect("batch_type")}
              items={BatchTypes}
              LeftIcon={Style}
            />
          </Grid>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterSelect("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterSelect("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="shipment_number"
              label={t("shipment_number")}
              variant="outlined"
              fullWidth
              value={search.shipment_number}
              onChange={handleChangeFilterInput("shipment_number")}
              InputProps={clearAdornment("shipment_number")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="order__number"
              label={t("order_number")}
              variant="outlined"
              fullWidth
              value={search.order__number}
              onChange={handleChangeFilterInput("order__number")}
              InputProps={clearAdornment("order__number")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="customer"
              label={t("customer")}
              variant="outlined"
              fullWidth
              value={search.order__customer_id}
              onChange={handleChangeFilterInput("order__customer_id")}
              InputProps={clearAdornment("order__customer_id")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="po_number"
              label="PO #"
              variant="outlined"
              fullWidth
              value={search.order__po_number}
              onChange={handleChangeFilterInput("order__po_number")}
              InputProps={clearAdornment("order__po_number")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="sku"
              label="SKU"
              variant="outlined"
              fullWidth
              value={search.sku}
              onChange={handleChangeFilterInput("sku")}
              InputProps={clearAdornment("sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid
            item
            md={0}
            lg={6}
            display={{
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            }}
            style={{ borderRight: "none" }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button
              color="blue"
              disabled={selectedShipments.length === 0 || isLoading}
              onClick={() => {
                handleShipmentAction(search.shipflow_status);
              }}
            >
              {isLoading ? (
                <CircularProgress size={16} />
              ) : search.shipflow_status === "Open" ? (
                <>
                  <Download color="white" size={0.8} />
                  <span style={{ marginLeft: "0.2rem" }}>Download Labels</span>
                </>
              ) : search.shipflow_status === "Errored" ? (
                <>
                  <Refresh2 color="white" size={0.8} />
                  <span style={{ marginLeft: "0.2rem" }}>Retry</span>
                </>
              ) : (
                <>
                  <Printer color="white" size={0.8} />
                  <span style={{ marginLeft: "0.2rem" }}>Reprint</span>
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
      <SwitchButtonGroup
        defaultValue={search.ship_via}
        xs={12}
        onChange={(value) => {
          handleChangeFilterSelect("ship_via")({ target: { value } });
        }}
        style={{ marginBottom: 5 }}
      >
        <SwitchButton
          value=""
          selectedValue={search.ship_via}
          xs={4}
          selectedBg="rgba(69, 142, 106, 0.3)"
          color="default"
        >
          <QuantityResult
            value={
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Avatar src={FedexLogo} className={classes.fedexLogo} />
                </Grid>
                <Grid item>{" - "}</Grid>
                <Grid item>
                  <Avatar src={UpsLogo} className={classes.upsLogo} />
                </Grid>
                <Grid item className={classes.carrierCounts}>
                  {`[${sum(
                    Object.values(
                      pickBy(shipmentCounts.data, (_, key) =>
                        ["open", "errored"].includes(key.split("_")[1]),
                      ),
                    ),
                  )}]`}
                </Grid>
              </Grid>
            }
          />
        </SwitchButton>
        <SwitchButton
          value="fedex"
          selectedValue={search.ship_via}
          xs={4}
          selectedBg="rgba(41, 0, 124, 0.2)"
        >
          <QuantityResult
            value={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Avatar src={FedexLogo} className={classes.fedexLogo} />
                </Grid>
                <Grid item className={classes.carrierCounts}>
                  [
                  {shipmentCounts.data["fedex_open"] +
                    shipmentCounts.data["fedex_errored"]}
                  ]
                </Grid>
              </Grid>
            }
          />
        </SwitchButton>
        <SwitchButton
          value="ups"
          selectedValue={search.ship_via}
          xs={4}
          selectedBg="rgba(255, 180, 6, 0.2)"
        >
          <QuantityResult
            value={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Avatar src={UpsLogo} className={classes.upsLogo} />
                </Grid>
                <Grid item className={classes.carrierCounts}>
                  [
                  {shipmentCounts.data["ups_open"] +
                    shipmentCounts.data["ups_errored"]}
                  ]
                </Grid>
              </Grid>
            }
          />
        </SwitchButton>
      </SwitchButtonGroup>
      <SwitchButtonGroup
        xs={12}
        style={{ marginBottom: 20 }}
        defaultValue={search.shipflow_status}
        onChange={(value) => {
          if (value === "Errored") {
            cells[5].id = "error";
          } else {
            cells[5].id = "tracking_number";
          }
          setCells(cells);
          setSelectedShipments([]);
          handleChangeFilterSelect("shipflow_status")({ target: { value } });
        }}
      >
        {shipmentStatuses.map((status) => (
          <SwitchButton
            key={status.value}
            value={status.value}
            selectedValue={search.shipflow_status}
            selectedBg="rgba(180, 180, 180, 0.2)"
            color="default"
            itemSize={shipmentStatuses.length}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ padding: "5px" }}
            >
              <Grid container item justifyContent="center">
                <status.icon color={status.color} size={0.8} />
              </Grid>
              <Grid item>
                <b style={{ userSelect: "none" }}>{status.label}</b>
              </Grid>
              <Grid item className={classes.statusCounts}>{`[${
                search.ship_via
                  ? shipmentCounts.data[
                      `${search.ship_via}_${status.value.toLowerCase()}`
                    ]
                  : shipmentCounts.data[`fedex_${status.value.toLowerCase()}`] +
                    shipmentCounts.data[`ups_${status.value.toLowerCase()}`]
              }]`}</Grid>
            </Grid>
          </SwitchButton>
        ))}
      </SwitchButtonGroup>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={shipments}
            headers={cells}
            headerCounts={headerCounts}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortActions={{ onSort, orderBy, orderDirection }}
            filters={search}
            hasCheckBox={search.ship_via !== ""}
            selected={selectedShipments}
            setSelected={setSelectedShipments}
            rowOnClick={(r) => setShipment(r)}
          />
          {Object.keys(shipment).length ? (
            <ShipmentDrawer
              onClose={handleDrawerClose}
              open={true}
              shipment={shipment}
              onAction={handleShipmentAction}
              fetchCounts={() => {
                setShipmentCounts({ ...shipmentCounts, isFetching: true });
              }}
              isLoading={isLoading}
            />
          ) : null}
        </Grid>
      </Grid>
      <RatesModal open={ratesModal} onClose={() => setRatesModal(false)} />
      <EndOfDayModal
        open={endOfDayModal}
        onClose={() => setEndOfDayModal(false)}
      />
      <MorningBatchModal
        open={morningBatchModal}
        onClose={() => setMorningBatchModal(false)}
      />
    </>
  );
}
