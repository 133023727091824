import * as React from "react";

const SvgArrowBack = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 11.5H7.83l5.59-5.59L12 4.5l-8 8 8 8 1.41-1.41-5.58-5.59H20v-2z"
      fill={color}
    />
  </svg>
);

export default SvgArrowBack;
