import * as React from "react";

const SvgLeft = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.293 4.293a1 1 0 0 1 1.497 1.32l-.083.094L10.415 12l6.292 6.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083-7-7a1 1 0 0 1-.083-1.32l.083-.094 7-7Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgLeft;
