import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { getAssignedShipments } from "api/shipment";
import { getUsers } from "api/user";

import { TextInput } from "components/Form";

import { Person } from "icons";

import { initialAPIListValue } from "utils/constants";

export default function PickersWithAssignedQty({
  lg = 2,
  md = 4,
  sm = 4,
  xs = 6,
  product_type,
  selectedPicker,
  onChange,
  reload,
  ...props
}) {
  const [pickerData, setPickerData] = useState([]);
  const [pickers, setPickers] = useState(initialAPIListValue);

  useEffect(() => {
    if (pickers.isFetching || reload) {
      getUsers({
        params: {
          group: "Picker",
          user__is_active: true,
          related_product: product_type,
          is_manager: false,
          is_listable: true,
        },
        setFunc: setPickers,
        paginated: true,
      });

      getAssignedShipments({
        responseSetter: (data) => {
          setPickerData(data);
        },
      });
    }
  }, [pickers.isFetching, product_type, reload]);

  const getPickerAssignedQty = (picker_id) => {
    const getPickerData = pickerData.find(
      (picker) => picker.user_id === picker_id,
    );
    return getPickerData ? getPickerData.total_quantity : 0;
  };

  const getAvailablePickers = (results) => {
    const pickersWithLabels = results.filter(
      (picker) => getPickerAssignedQty(picker.user.id) > 0,
    );
    const pickersWithoutLabels = results.filter(
      (picker) => getPickerAssignedQty(picker.user.id) === 0,
    );
    return [
      ...pickersWithLabels.sort((a, b) =>
        a.user.username > b.user.username ? 1 : -1,
      ),
      ...pickersWithoutLabels.sort((a, b) =>
        a.user.username > b.user.username ? 1 : -1,
      ),
    ];
  };

  return (
    <Grid item lg={lg} md={md} sm={sm} xs={xs}>
      <Autocomplete
        fullWidth
        value={selectedPicker && selectedPicker.id ? selectedPicker : null}
        options={getAvailablePickers(pickers.items)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          option.display_name
            ? `${option.display_name} - (${getPickerAssignedQty(
                option.user.id,
              )})`
            : "Select a picker"
        }
        onChange={onChange}
        renderInput={(params) => (
          <TextInput
            {...params}
            label={"Picker"}
            variant="outlined"
            LeftIcon={Person}
          />
        )}
        {...props}
      />
    </Grid>
  );
}
