import { useCallback, useEffect, useState } from "react";

import { debounce, groupBy } from "lodash";

import { CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useTranslation } from "react-i18next";

import { getMovements } from "api/movement";
import { receivingReport } from "api/receipt";

import { DatePickerGroup, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { QrCode2, SpaceBar } from "icons";

import { initialAPIListValue, LocationTypes } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { convertDate } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";

const cells = [
  {
    id: "product__sku",
    label: "product_sku",
    exportLabel: "Inventory Id",
    render: (r) => r.product.sku,
    sortable: true,
  },
  { id: "location", render: (r) => r.location.code },
  { id: "qty", exportLabel: "Quantity", sortable: true },
  {
    id: "created_at",
    render: (r) =>
      new Date(r.created_at).toLocaleString("en-US", {
        timeZone: "America/New_York",
      }),
    sortable: true,
  },
];

export default function ReceivingReport() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [receivingSummary, setReceivingSummary] = useState(initialAPIListValue);
  const [userBasedSummary, setUserBasedSummary] = useState({});
  const [movements, setMovements] = useState(initialAPIListValue);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const today = convertDate(new Date());
  const [search, setSearch] = useState({
    operation_type: 1, // Pick
    product__sku: "",
    location__code: "",
    location__location_type: LocationTypes[2].value, // DOCK
    end_date: today,
    start_date: today,
    created_by__username: "",
  });

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  const { permissions } = useUserState();

  const userHeaders = [
    {
      id: "user",
      render: (r) => (
        <span
          style={{ color: "blue", fontWeight: "bold", textDecoration: "none" }}
        >
          {toTitleCase(r.username)}
        </span>
      ),
    },
    {
      id: "qty",
      render: (r) => r.total_qty,
    },
  ];

  useEffect(() => {
    if (search.start_date && search.end_date) {
      receivingReport({
        params: {
          start_date: search.start_date,
          end_date: search.end_date,
        },
        responseSetter: (res) => {
          const { data } = res;
          setReceivingSummary({
            isFetching: false,
            items: data,
            count: data.length,
          });
          setUserBasedSummary(groupBy(data, "created_by__username"));
        },
      });
    }
  }, [search.start_date, search.end_date]);

  useEffect(() => {
    if (movements.isFetching && search.created_by__username) {
      let params = {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...search,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getMovements({
        params,
        setFunc: (res) => {
          setMovements(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    movements.isFetching,
    rowsPerPage,
    orderDirection,
    orderBy,
  ]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    if (prop === "start_date" || prop === "end_date") {
      setReceivingSummary((prev) => ({ ...prev, isFetching: true }));
    }
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `${toTitleCase(
        search.created_by__username,
      )}-Receiving-History-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (movements.count > 1000) {
      setExportDisabled(false);
      return popupNotification({
        dispatch: layoutDispatch,
        message: "The count of records to export must be less than 1000.",
        status: "warning",
      });
    }

    if (movements.items.length === movements.count) {
      exportData(movements.items);
    } else {
      getMovements({
        params: {
          limit: movements.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setMovements((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("receiving_report")} />
      <InputContainer>
        <Grid container>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 6, lg: 3 }}
          />
        </Grid>
      </InputContainer>
      {receivingSummary.isFetching ? (
        <CircularProgress />
      ) : !receivingSummary.items.length ? (
        <Alert severity="warning">No results found.</Alert>
      ) : (
        <Grid container spacing={4}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <DefaultTable
              headers={userHeaders}
              data={{
                items: Object.entries(userBasedSummary).map(([key, rows]) => ({
                  username: key,
                  total_qty: rows.reduce(
                    (total, row) => total + row.total_qty,
                    0,
                  ),
                })),
              }}
              highlightFunc={(r) => r.username === search.created_by__username}
              rowOnClick={(r) => {
                setSearch((prev) => ({
                  ...prev,
                  created_by__username: r.username,
                }));
                setMovements((prev) => ({ ...prev, isFetching: true }));
              }}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Widget>
              {!search.created_by__username ? (
                <Alert severity="info">
                  Select a user from the list to see the details.
                </Alert>
              ) : (
                <>
                  <Grid item lg={12}>
                    <InputContainer>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormattedTextField
                            fullWidth
                            id="sku"
                            label={t("product_sku")}
                            variant="outlined"
                            value={search.product__sku}
                            onChange={handleChangeFilterInput("product__sku")}
                            isProductSKU={true}
                            InputProps={clearAdornment("product__sku")}
                            LeftIcon={QrCode2}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormattedTextField
                            fullWidth
                            id="location"
                            label={t("location")}
                            variant="outlined"
                            value={search.location__code}
                            onChange={handleChangeFilterInput("location__code")}
                            isLocationCode={true}
                            InputProps={clearAdornment("location__code")}
                            LeftIcon={SpaceBar}
                          />
                        </Grid>
                      </Grid>
                    </InputContainer>
                  </Grid>
                  <Grid item lg={12}>
                    <DefaultTable
                      headers={cells}
                      headerCounts={headerCounts}
                      data={movements}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleExportData={handleExportData}
                      disableExportData={
                        !permissions.includes("warehouse.export_data.movement")
                      }
                      sortActions={{ onSort, orderBy, orderDirection }}
                      exportDisabled={exportDisabled}
                      setExportDisabled={setExportDisabled}
                      filters={search}
                    />
                  </Grid>
                </>
              )}
            </Widget>
          </Grid>
        </Grid>
      )}
    </>
  );
}
