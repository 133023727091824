import * as React from "react";

const SvgOfficebuilding = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M19 21V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v16M19 21h2M19 21h-5M5 21H3M5 21h5M9 7h1M9 11h1M14 7h1M14 11h1" />
      <path d="M10 21v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5M10 21h4" />
    </g>
  </svg>
);

export default SvgOfficebuilding;
