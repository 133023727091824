import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { debounce } from "lodash";

import { CircularProgress, Grid } from "@material-ui/core";

import themes from "themes";

import { getAdjustments } from "api/adjustment";
import { getCycleCounts, getInventoryDashboardSummary } from "api/inventory";
import { getTransfers } from "api/transfer";
import { getReceipts } from "api/receipt";

import { Circle } from "components/Charts/Circle";
import {
  StatusLabel,
  InspectionWidget,
  ValidateXRefWidget,
} from "components/Custom";

import { InputContainer } from "components/Form";
import Spacer from "components/Custom/Spacer";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { Check, X } from "icons";

import AdjustmentFilter from "pages/adjustment/AdjustmentFilter";
import CycleCountFilter from "pages/count/CycleCountFilter";
import { cells as count_headers } from "pages/count/CycleCounts";
import TransferFilter from "pages/transfer/TransferFilter";
import ReceiptFilter from "pages/receipt/ReceiptFilter";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { convertDate, timezoneFormat } from "utils/date";
import { updateDocumentTitle } from "utils/dom";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { toTitleCase } from "utils/string";

import useStyles from "./styles";

const initialValues = {
  cyclecount: {
    params: {
      start_date: "",
      end_date: "",
      product_sku: "",
      location_code_start: "",
      location_position: "",
      status: "Open",
      show_only_different: "True",
      is_published: "False",
      created_by_username: "",
      product_type: "",
      should_recount: false,
    },
    headers: count_headers,
    exportHeaders: [
      {
        exportLabel: "Location",
        render: (r) => r.location.code,
      },
      {
        exportLabel: "Inventory ID",
        render: (r) => r.product.sku,
      },
      {
        exportLabel: "Before",
        render: (r) =>
          r.old_counts
            ? `${r.old_count} | ${r.old_counts.join(", ")}`
            : r.old_count,
      },
      {
        exportLabel: "Counted",
        render: (r) =>
          r.counts ? `${r.count} | ${r.counts.join(", ")}` : r.count,
      },
      {
        exportLabel: "Difference",
        render: (r) =>
          r.differences
            ? `${r.difference} | ${r.differences.join(", ")}`
            : r.difference,
      },
      {
        exportLabel: "Created By",
        render: (r) =>
          r.usernames
            ? `${toTitleCase(r.created_by.username)} | ${r.usernames
                .map(toTitleCase)
                .join(", ")}`
            : toTitleCase(r.created_by.username),
      },
      {
        exportLabel: "Created At",
        render: (r) => {
          if (r.times) {
            return `${timezoneFormat(r.created_at)} | ${r.times.join(", ")}`;
          }
          return timezoneFormat(r.created_at);
        },
        sortable: true,
      },
      {
        exportLabel: "Inactive",
        render: (r) => (r.product.status === "Inactive" ? "Yes" : "No"),
      },
    ],
    fetchAPI: getCycleCounts,
    hasCheckBox: false,
  },
  transfer: {
    params: {
      from_location__code: "",
      is_published: false,
      is_cancelled: false,
      product__sku: "",
      product__product_type: "",
      to_location__code: "",
      start_date: "",
      end_date: "",
      type: "",
    },
    headers: [
      {
        id: "product__sku",
        label: "product_sku",
        exportLabel: "Inventory Id",
        render: (r) => r.product.sku,
        sortable: true,
      },
      {
        id: "from_location__code",
        label: "from_location",
        render: (r) => r.from_location.code,
        sortable: true,
      },
      {
        id: "to_location__code",
        label: "to_location",
        render: (r) => r.to_location.code,
        sortable: true,
      },
      {
        id: "qty",
        exportLabel: "Quantity",
        sortable: true,
        render: (r) => r.qty,
      },
      {
        id: "created_at",
        sortable: true,
        render: (r) => timezoneFormat(r.created_at),
      },
      {
        id: "is_published",
        sortable: true,
        render: (r, exported) =>
          exported ? (
            r.is_published ? (
              "Published"
            ) : (
              "Not Published"
            )
          ) : r.is_published ? (
            <Check color="green" />
          ) : (
            <X color="red" />
          ),
      },
    ],
    fetchAPI: getTransfers,
    hasCheckBox: true,
  },
  adjustment: {
    params: {
      location__code: "",
      is_published: false,
      is_cancelled: false,
      product__sku: "",
      product__product_type: "",
      start_date: "",
      end_date: "",
      type: "",
    },
    headers: [
      {
        id: "product__sku",
        label: "product_sku",
        exportLabel: "Inventory Id",
        render: (r) => r.product.sku,
        sortable: true,
      },
      {
        id: "location__code",
        label: "location",
        render: (r) => r.location.code,
        sortable: true,
      },

      {
        id: "qty",
        exportLabel: "Quantity",
        sortable: true,
        render: (r) => r.qty,
      },
      {
        id: "created_at",
        sortable: true,
        render: (r) => timezoneFormat(r.created_at),
      },
      {
        id: "is_published",
        sortable: true,
        render: (r, exported) =>
          exported ? (
            r.is_published ? (
              "Published"
            ) : (
              "Not Published"
            )
          ) : r.is_published ? (
            <Check color="green" />
          ) : (
            <X color="red" />
          ),
      },
    ],
    fetchAPI: getAdjustments,
    hasCheckBox: true,
  },
  receipt: {
    params: {
      number: "",
      container: "",
      invoice: "",
      status: "",
      start_date: "",
      end_date: "",
    },
    headers: [
      {
        id: "number",
        render: (row, exported) =>
          exported ? (
            row.number
          ) : (
            <Link
              to={{
                pathname: `/app/receipt/${row.id}`,
                state: {
                  status: row.status,
                  receiptNumber: row.number,
                  dockId: row.dock_id,
                  container: row.container,
                },
              }}
            >
              {row.number}
            </Link>
          ),
        sortable: true,
      },
      {
        id: "status",
        render: (row, exported) =>
          exported ? row.status : <StatusLabel status={row.status} />,
        sortable: true,
      },
      {
        id: "percentage",
        render: (row, exported) =>
          exported ? (
            `${row.transferred_quantity} / ${row.total_qty} (%${parseInt(
              (row.transferred_quantity / row.total_qty) * 100,
            )})`
          ) : (
            <StatusLabel
              status={
                row.status !== "Completed"
                  ? "Open"
                  : row.total_qty === row.transferred_quantity
                  ? "Active"
                  : row.total_qty > row.transferred_quantity
                  ? "Cancelled"
                  : "Picked"
              }
              text={`${row.transferred_quantity} / ${
                row.total_qty
              } (%${parseInt(
                (row.transferred_quantity / row.total_qty) * 100,
              )})`}
            />
          ),
      },
      { id: "container", sortable: true },
      { id: "dock_id", label: "dock", sortable: true },
      { id: "container_type", label: "content", sortable: true },
      { id: "date", sortable: true },
    ],
    fetchAPI: getReceipts,
    hasCheckBox: false,
  },
};

export default function InventoryDashboard() {
  const classes = useStyles();
  const { related_product } = parseJwt(getWithExpiry("access"));
  const [rows, setRows] = useState(initialAPIListValue);
  const [inventorySummary, setInventorySummary] = useState({
    counts: 0,
    receipts: 0,
    adjustments: 0,
    published_adjustments: 0,
    transfers: 0,
    published_transfers: 0,
    isFetching: true,
  });
  const [params, setParams] = useState({
    ...initialValues.cyclecount.params,
    product_type: related_product || 1,
    start_date: convertDate(new Date()),
  });
  const [headers, setHeaders] = useState(initialValues.cyclecount.headers);
  const [selected, setSelected] = useState("cyclecount");
  const [selectedRows, setSelectedRows] = useState([]);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  updateDocumentTitle("Inventory DB");

  useEffect(() => {
    if (rows.isFetching) {
      const filters = {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...params,
      };

      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }

      initialValues[selected].fetchAPI({
        params: filters,
        setFunc: setRows,
        paginated: true,
      });
    }
  }, [
    rows.isFetching,
    orderBy,
    orderDirection,
    params,
    page,
    rowsPerPage,
    selected,
  ]);

  useEffect(() => {
    getInventoryDashboardSummary({
      responseSetter: (res) => {
        setInventorySummary({ ...res, isFetching: false });
      },
    });
  }, []);

  const handleSelectedFilter = (filter) => (e) => {
    const initialValue = initialValues[filter];
    setSelected(filter);
    setParams({
      ...initialValue.params,
      [filter === "cyclecount" ? "product_type" : "product__product_type"]:
        related_product || 1,
      start_date: convertDate(new Date()),
    });
    setRows(initialAPIListValue);
    setHeaders(initialValue.headers);
    setSelectedRows([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setRows({ ...rows, isFetching: true });
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setRows({ ...rows, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    if (
      (selected === "transfer" || selected === "adjustment") &&
      prop === "type"
    ) {
      setSelectedRows([]);
    }
    setParams({ ...params, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    setPage(0);
    setRows({
      ...rows,
      isFetching: true,
    });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setRows((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setRows({
      ...rows,
      isFetching: true,
    });
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.checked || null });
    debouncedSearchHandler();
  };

  const afterSuccessPublish = () => {
    handleSelectedFilter(selected);
    setRows((prev) => ({ ...prev, isFetching: true }));
    setSelectedRows([]);
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `${toTitleCase(selected)}-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(
        data,
        initialValues[selected].exportHeaders
          ? initialValues[selected].exportHeaders
          : initialValues[selected].headers,
      ),
    );
  };

  const handleExportData = () => {
    if (rows.items.length === rows.count) {
      exportData(rows.items);
    } else {
      initialValues[selected].fetchAPI({
        params: {
          limit: rows.count,
          ...params,
        },
        setFunc: ({ items }) => exportData(items),
        paginated: true,
      });
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={8} xs={12}>
          <Widget>
            {inventorySummary.isFetching ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                spacing={0}
                className={classes.containerCenter}
                style={{ cursor: "pointer" }}
              >
                <Grid item xs={6} lg={3}>
                  <Circle
                    title="Receipts"
                    number={inventorySummary.receipts}
                    color={themes.default.palette.colors.amber500}
                    size={450}
                    series={[inventorySummary.receipts]}
                    isSelected={selected === "receipt"}
                    onClick={() => handleSelectedFilter("receipt")}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Circle
                    title="Cycle Counts"
                    number={inventorySummary.counts}
                    color={themes.default.palette.colors.rose500}
                    isSelected={selected === "cyclecount"}
                    onClick={() => handleSelectedFilter("cyclecount")}
                    size={450}
                    series={[inventorySummary.counts]}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Circle
                    title="Transfers"
                    number={
                      inventorySummary.transfers -
                      inventorySummary.published_transfers
                    }
                    color={themes.default.palette.colors.slate500}
                    isSelected={selected === "transfer"}
                    size={450}
                    series={[
                      inventorySummary.transfers,
                      inventorySummary.published_transfers,
                    ]}
                    tooltip={true}
                    onClick={() => handleSelectedFilter("transfer")}
                    tooltipTitleFormatter={() => "Published Transfers:"}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Circle
                    title="Adjustments"
                    number={
                      inventorySummary.adjustments -
                      inventorySummary.published_adjustments
                    }
                    color={themes.default.palette.colors.blue500}
                    isSelected={selected === "adjustment"}
                    onClick={() => handleSelectedFilter("adjustment")}
                    size={450}
                    series={[
                      inventorySummary.adjustments,
                      inventorySummary.published_adjustments,
                    ]}
                    tooltip={true}
                    tooltipTitleFormatter={() => "Published Adjustments:"}
                  />
                </Grid>
              </Grid>
            )}
          </Widget>
          <Spacer height="1rem" />
          <Widget>
            <InputContainer>
              {selected === "cyclecount" ? (
                <CycleCountFilter
                  clearAdornment={clearAdornment}
                  handleChangeFilterInput={handleChangeFilterInput}
                  params={params}
                  setParams={setParams}
                  debouncedSearchHandler={debouncedSearchHandler}
                />
              ) : selected === "transfer" ? (
                <TransferFilter
                  clearAdornment={clearAdornment}
                  handleChangeFilterInput={handleChangeFilterInput}
                  params={params}
                  setParams={setParams}
                  debouncedSearchHandler={debouncedSearchHandler}
                  selectedTransfers={selectedRows}
                  afterSuccessPublish={afterSuccessPublish}
                  headers={initialValues[selected].headers}
                />
              ) : selected === "adjustment" ? (
                <AdjustmentFilter
                  clearAdornment={clearAdornment}
                  handleChangeFilterInput={handleChangeFilterInput}
                  params={params}
                  setParams={setParams}
                  debouncedSearchHandler={debouncedSearchHandler}
                  selectedAdjustments={selectedRows}
                  afterSuccessPublish={afterSuccessPublish}
                  headers={initialValues[selected].headers}
                />
              ) : selected === "receipt" ? (
                <ReceiptFilter
                  clearAdornment={clearAdornment}
                  handleChangeFilterInput={handleChangeFilterInput}
                  handleChangeCheckbox={handleChangeCheckbox}
                  params={params}
                />
              ) : null}
            </InputContainer>
            <DefaultTable
              headers={headers}
              data={rows}
              filters={params}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              sortActions={{ onSort, orderBy, orderDirection }}
              handleExportData={handleExportData}
              hasCheckBox={initialValues[selected].hasCheckBox}
              selected={selectedRows}
              setSelected={setSelectedRows}
            />
          </Widget>
        </Grid>
        <Grid item lg={4} xs={12}>
          <InspectionWidget />
          <Spacer height="1rem" />
          <ValidateXRefWidget />
        </Grid>
      </Grid>
    </>
  );
}
