import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { debounce } from "lodash";
import * as XLSX from "xlsx";

import { createPromotions, getPromotions } from "api/promotion";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { ExternalLink } from "components/Custom/Links";
import {
  Button,
  DatePickerGroup,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import {
  Class,
  CloudUpload,
  Download,
  Edit,
  FolderSpecial,
  QrCode2,
  Tag,
} from "icons";

import { initialAPIListValue, ProductTypes } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";

import PromotionUpdateModal from "./PromotionUpdateModal";

const exportHeaders = [
  {
    id: "customer_name",
  },
  {
    id: "title",
  },
  {
    id: "inventory_id",
  },
  {
    id: "discount",
    render: (r) => Number(r.discount),
  },
  {
    id: "ecom_price",
  },
  {
    id: "promo_price",
    render: (r) => Number(r.promo_price),
  },
  {
    id: "daily_avg",
    exportLabel: "Promotion Daily Sales Avg.",
    render: (r) => Number(r.daily_avg),
  },
  {
    id: "sales_qty",
    exportLabel: "Promotion Sales",
  },
  {
    id: "three_months_sales",
    exportLabel: "3 Months Sales Avg (Quantity)",
    render: (r) =>
      `${Number(r.three_months_sales_qty / 90).toFixed(2)} (${Number(
        r.three_months_sales_qty,
      )})`,
  },
  {
    id: "six_months_sales",
    exportLabel: "6 Months Sales Avg (Quantity)",
    render: (r) =>
      `${Number(r.six_months_sales_qty / 180).toFixed(2)} (${Number(
        r.six_months_sales_qty,
      )})`,
  },
  {
    id: "start_date",
  },
  {
    id: "end_date",
  },
];

export default function Promotion() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [promotions, setPromotions] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    customer_name: "",
    product_type: "",
    inventory_id: "",
    start_date: "",
    end_date: "",
    title: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [uploading, setUploading] = useState(false);
  const hasUpdatePermission = permissions.includes("order.change_promotion");
  const hasAddPermission = permissions.includes("order.add_promotion");

  useEffect(() => {
    if (promotions.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getPromotions({
        params,
        setError,
        setFunc: (res) => {
          setPromotions(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    promotions.isFetching,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setPromotions({ ...promotions, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPromotions({ ...promotions, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeFilterSelect = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setPromotions({
      items: promotions.items,
      isFetching: true,
      count: promotions.count,
    });
  };

  const exportData = (_data) => {
    getPromotions({
      params: {
        ...search,
        limit: 10000,
        offset: 0,
      },
      setError: setError,
      setFunc: (res) => {
        const today = new Date();
        exportToExcel(
          [],
          `Promotions-${today.toLocaleDateString()}`,
          true,
          convertDataToTableView(res.items, exportHeaders),
          setExportDisabled,
        );
      },
      paginated: true,
    });
  };

  const handleExportData = () => {
    if (promotions.items.length === promotions.count) {
      exportData(promotions.items);
    } else {
      getPromotions({
        params: {
          ...search,
          limit: promotions.count,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setPromotions((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const validatePromotions = (parsedData) => {
    let errors = [];
    const payload = parsedData.reduce((result, row) => {
      let startDate = row["Start Date"];
      let endDate = row["End Date"];
      let discount = parseFloat(row["Discount"]).toFixed(2);
      let promoPrice = parseFloat(row["Promo Price"]).toFixed(2);
      if (promoPrice === 0 || promoPrice === "NaN") {
        errors.push({
          row: row,
          message: "Promo Price should contain positive float values",
        });
        return result;
      }
      if (startDate > endDate) {
        errors.push({
          row: row,
          message: "Start date cannot be after end date",
        });
        return result;
      }
      if (discount >= 1 || discount <= -1) {
        errors.push({
          row: row,
          message: "Discount values should be between -1 and 1",
        });
        return result;
      }
      result.push({
        customer: row.Customer,
        title: row["Title"],
        item: row["Inventory ID"],
        discount: parseFloat(discount),
        promo_price: parseFloat(promoPrice),
        start_date: startDate,
        end_date: endDate,
      });
      return result;
    }, []);
    return {
      payload: payload,
      errors: errors,
    };
  };

  const handleFileChange = (e) => {
    setUploading(true);
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const parsed = XLSX.utils.sheet_to_json(ws, {
        raw: false,
      });
      const { payload, errors } = validatePromotions(parsed);
      if (errors.length > 0) {
        setUploading(false);
        return popupNotification({
          dispatch: layoutDispatch,
          message: errors
            .map((e) => `Row ${data.indexOf(e.row) + 2}: ${e.message}`)
            .join("\r\n"),
        });
      }
      createPromotions({
        body: payload,
        responseSetter: (res) => {
          setUploading(false);
          if (res.success) {
            popupNotification({
              dispatch: layoutDispatch,
              message: `Records (${payload.length}) are created`,
              status: "success",
            });
            setPromotions((prev) => ({ ...prev, isFetching: true }));
          } else {
            Object.keys(res.data).forEach((key) => {
              popupNotification({
                dispatch: layoutDispatch,
                message: `${key} for the row -> ${res.data[key]}`,
              });
            });
          }
        },
      });
    };
    reader.readAsBinaryString(f);
    e.target.value = "";
  };

  const handleSelectedRow = (promotion) => {
    setSelectedRow(promotion);
  };

  const handleUpdateModalClose = (status) => {
    if (status) {
      setPromotions((prev) => ({ ...prev, isFetching: true }));
    }
    setSelectedRow({});
  };

  const cells = [
    {
      id: "customer_name",
      label: "Customer",
      render: (row, exported) =>
        exported ? (
          row.customer_name
        ) : (
          <>
            <ExternalLink
              href={`https://tayse.acumatica.com/(W(2))/Main?CompanyID=Tayse&ScreenId=AR303000&AcctCD=${row.customer_name}`}
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              {row.customer_name}
            </ExternalLink>
            <Typography
              variant="subtitle2"
              style={{ fontSize: "0.6rem", opacity: 0.6 }}
            >
              {row.title}
            </Typography>
          </>
        ),
    },
    {
      id: "inventory_id",
      label: "Inventory ID",
      render: (row) => row.inventory_id || "-",
    },
    {
      id: "discount",
      label: "Ecomm / Promo",
      render: (row) => (
        <>
          ${row.ecom_price} / ${row.promo_price}
          <Typography
            variant="subtitle2"
            style={{ fontSize: "0.6rem", opacity: 0.6 }}
          >
            %{(row.discount * 100).toFixed(0)}
          </Typography>
        </>
      ),
      sortable: true,
    },
    {
      id: "daily_avg",
      label: "Daily Avg. Promotion/3M/6M",
      sortable: true,
      render: (row) => (
        <>
          <span title={`${row.sales_qty} item(s) sold.`}>{row.daily_avg}</span>
          <Typography
            variant="subtitle2"
            style={{ fontSize: "0.6rem", opacity: 0.6 }}
          >
            {`${(row.three_months_sales_qty / 90).toFixed(2)}`} /{" "}
            {`${(row.six_months_sales_qty / 180).toFixed(2)}`}
          </Typography>
        </>
      ),
    },
    {
      id: "date",
      label: "Start - End Date",
      render: (row) => {
        const start_date = new Date(row.start_date);
        const end_date = new Date(row.end_date);
        return `${start_date.toLocaleDateString()} - ${end_date.toLocaleDateString()}`;
      },
    },
  ];

  if (hasUpdatePermission) {
    cells.push({
      id: "actions",
      label: "Actions",
      render: (row) => {
        return (
          <IconButton
            color="primary"
            aria-label="Edit"
            size="small"
            onClick={() => handleSelectedRow(row)}
          >
            <Edit color="#5383ff" />
          </IconButton>
        );
      },
    });
  }

  return (
    <>
      <PageTitle title={t("promotions")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={1}>
            <TextInput
              fullWidth
              id="customer_name"
              label={t("customer")}
              variant="outlined"
              value={search.customer_name}
              onChange={handleChangeFilterInput("customer_name")}
              InputProps={clearAdornment("customer_name")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1}>
            <TextInput
              fullWidth
              id="title"
              label={t("title")}
              variant="outlined"
              value={search.title}
              onChange={handleChangeFilterInput("title")}
              InputProps={clearAdornment("title")}
              LeftIcon={Tag}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1}>
            <Dropdown
              id="product_type"
              title={t("product_type")}
              value={search.product_type}
              onChange={handleChangeFilterInput("product_type")}
              items={ProductTypes}
              LeftIcon={Class}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1}>
            <TextInput
              fullWidth
              id="inventory_id"
              label={"Inventory ID"}
              variant="outlined"
              value={search.inventory_id}
              onChange={handleChangeFilterInput("inventory_id")}
              InputProps={clearAdornment("inventory_id")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterSelect("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterSelect("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                exportToExcel(
                  [
                    {
                      Customer: "",
                      Title: "",
                      "Inventory ID": "",
                      Discount: "",
                      "Promo Price": "",
                      "Start Date": "",
                      "End Date": "",
                    },
                  ],
                  "promotion_template",
                )
              }
            >
              <span style={{ paddingRight: "2px" }}>
                <Download color="#fff" size={0.8} />
              </span>
              Template
            </Button>
          </Grid>
          {hasAddPermission ? (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <label htmlFor="file_picker">
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: "none" }}
                  id="file_picker"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  component="span"
                  disabled={uploading}
                >
                  <span style={{ paddingRight: "2px" }}>
                    <CloudUpload color="#fff" size={0.8} />
                  </span>
                  {uploading ? "Uploading.." : "Upload"}
                </Button>
              </label>
            </Grid>
          ) : null}
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={promotions}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
          {Object.keys(selectedRow).length ? (
            <PromotionUpdateModal
              onClose={handleUpdateModalClose}
              promotion={selectedRow}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
