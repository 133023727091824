import React from "react";
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";

import useStyles from "./styles";
import { X as Close } from "icons";

const CollectionModal = ({ open, onClose, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="xl"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Order Recommendation By Collection
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <List dense={true}>
              <Grid container>
                {Object.keys(
                  _.fromPairs(_.sortBy(_.toPairs(data), (pair) => -pair[1])),
                ).map((collection, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      style={
                        index % 3 !== 2
                          ? { borderRight: "1px solid #ccc" }
                          : null
                      }
                    >
                      <ListItem style={{ height: "2.5rem" }}>
                        <ListItemText
                          primary={collection}
                          style={{ width: "80%" }}
                        />
                        <ListItemText
                          className={classes.detailsTitle}
                          secondary={data[collection] + " m²"}
                          style={{ marginRight: "1rem" }}
                        />
                      </ListItem>
                      <Divider />
                    </Grid>
                  );
                })}
              </Grid>
            </List>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={onClose}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollectionModal;
