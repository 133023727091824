import * as React from "react";

const SvgStyle = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M15.22 5.25 7.87 8.29l4.96 11.96 7.35-3.05-4.96-11.95zM11 10.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      fill={color}
    />
    <path
      d="m3.87 11.68-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61l1.34.56v-9.03zm18.16 4.77L17.07 4.48a2.013 2.013 0 0 0-1.81-1.23c-.26 0-.53.04-.79.15L7.1 6.45a1.999 1.999 0 0 0-1.08 2.6l4.96 11.97a1.998 1.998 0 0 0 2.6 1.08l7.36-3.05a1.994 1.994 0 0 0 1.09-2.6zm-9.2 3.8L7.87 8.29l7.35-3.04h.01l4.95 11.95-7.35 3.05z"
      fill={color}
    />
    <path
      d="M11 10.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM9.33 22.25l-3.45-8.34v6.34c0 1.1.9 2 2 2h1.45z"
      fill={color}
    />
  </svg>
);

export default SvgStyle;
