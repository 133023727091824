const initialAPIListValue = {
  items: [],
  isFetching: true,
  count: 0,
};

const ProductStatus = ["Active", "Inactive", "No Purchases"];

const OrderStatus = [
  "OPEN",
  "SHIPPING",
  "ON_HOLD",
  "PENDING_APPROVAL",
  "PENDING_PROCESSING",
  "REJECTED",
  "AWAITING_PAYMENT",
  "CREDIT_HOLD",
  "CANCELLED",
  "COMPLETED",
  "BACK_ORDER",
  "INVOICED",
  "IS_EMPTY",
];

const ShipmentStatus = [
  "Open",
  "Confirmed",
  "Printed",
  "Assigned",
  "Picking",
  "Picked",
  "Packed",
  "Ready",
  "Shipped",
  "Invoiced",
  "Pending_Cancellation",
  "Cancelled",
  "Errored",
];

const ShipmentDetailStatus = [
  "Unassigned",
  "Assigned",
  "Picked",
  "Packed",
  "Shipped",
];

const SHIPMENT_STATUS_ORDER = [
  "Open",
  "Printed",
  "Assigned",
  "Picked",
  "Shipped",
  "Invoiced",
];

const EcommerceShipmentDetailStatus = [
  "Unassigned",
  "Assigned",
  "Picked",
  "Shipped",
  "Pending_Cancellation",
  "Cancelled",
];

const BatchTypes = [
  {
    value: "Re-Roll",
    label: "Re-Roll",
  },
  {
    value: "Furniture",
    label: "Furniture",
  },
  {
    value: "Rugs",
    label: "Rugs",
  },
  {
    value: "SinglePiece",
    label: "SinglePiece",
  },
  {
    value: "MultiPiece",
    label: "MultiPiece",
  },
];

const OrderTypes = [
  {
    value: false,
    label: "LTL / Retail / National",
  },
  {
    value: true,
    label: "FEDEX / UPS",
  },
];

const ProductTypes = [
  { label: "Rug", value: 1 },
  { label: "Furniture", value: 2 },
];

const LocationTypes = [
  { label: "Rug", value: 1 },
  { label: "Furniture", value: 2 },
  { label: "Dock", value: 3 },
  { label: "Buggy", value: 4 },
  { label: "Others", value: 5 },
];

const ProductAttributeTypes = [
  { label: "Upc", value: 1 },
  { label: "Sku", value: 2 },
  { label: "Serial Number", value: 3 },
  { label: "Global", value: 5 },
  { label: "Customer Part Number", value: 6 },
  { label: "Vendor Part Number", value: 7 },
  { label: "Others", value: 4 },
];

const ProductClasses = [
  {
    value: "2x/3x",
    label: "2x/3x - (Runner)",
    product_type: 1,
  },
  {
    value: "4x",
    label: "4x - (Small)",
    product_type: 1,
  },
  {
    value: "5x",
    label: "5x - (Normal)",
    product_type: 1,
  },
  {
    value: "6x/7x/8x",
    label: "6x/7x/8x - (Medium)",
    product_type: 1,
  },
  {
    value: "9x/10x/11x/12x",
    label: "9x/10x/11x/12x - (Large)",
    product_type: 1,
  },
  {
    value: "SET3/SET4/SET5",
    label: "SET3/SET4/SET5",
    product_type: 1,
  },
  {
    value: "Other",
    label: "Other",
    product_type: 2,
  },
];
const CountStatus = ["Created", "Started", "Cancelled", "Completed"];

const SizeAislePairs = {
  "6RND": "102,103,104,107,110,112,113",
  "4x6": "102,103,104,107,110,112,113",
  "5x8": "102,103,104,107,110,112,113",
  "5x8OVL": "102,103,104,107,110,112,113",
  "5x8OCT": "102,103,104,107,110,112,113",
  "8RND": "105,106,111,114",
  "7x10": "105,106,111,114",
  "8x10": "105,106,111,114",
  "8OCT": "105,106,111,114",
  "8OVL": "111,114",
  "2x3": "108,109,114,115,116",
  "2x8": "108,109,114,115,116",
  "3x8": "108,109,114,115,116",
  "3x10": "108,109,114,115,116",
};

const ShipmentServiceTypes = [
  {
    value: "ground",
    label: "Ground",
  },
  {
    value: "express",
    label: "Express",
  },
];

const colors = {
  success: "#1bc943",
  danger: "#e91e63",
  fedexOrange: "#FF9900",
  fedexPurple: "#4D148C",
  upsBrown: "#351C15",
  upsGold: "#FFB500",
};

const carriers = ["FEDEX", "UPS"];

const CONDITIONS = ["Good", "Damaged", "Defective", "Mixed"];

const express_services = [
  "FEDEX 2 DAY",
  "FEDEX EXP SVR",
  "FEDEX FRST OVRN",
  "FEDEX PRIO OVRN",
  "FEDEX STND OVRN",
  "UPS NXD AIR",
  "UPS NXD AIR SVR",
];

const BUGGY_USE_TYPES = {
  EcommercePick: 1,
  Transfer: 2,
  Receiving: 3,
};

const PRODUCT_REQ_ATTRIBUTE_STATUSES = {
  Open: 1,
  Reject: 2,
  Approve: 3,
};

const LOCATION_POSITIONS = [
  {
    value: 1,
    label: "Low",
  },
  {
    value: 2,
    label: "High",
  },
  {
    value: 3,
    label: "Top",
  },
];

const PUBLISH_TYPES = [
  {
    value: "count",
    label: "Cycle Count",
  },
  {
    value: "receipt",
    label: "Receipt",
  },
  {
    value: "regular",
    label: "Regular",
  },
  {
    value: "snapshot",
    label: "Snapshot",
  },
];

const CASTLEGATE_LOCATIONS = ["CA", "IL", "NJ", "TX", "WAYFAIRCG"];

const INSPECTION_REASONS = {
  Damaged: "Damaged",
  Defective: "Defective",
  Wrong_Item: "Wrong Item",
  Mis_Labeled: "Mis-labeled",
  Quantity_Problem: "Quantity Problem",
  Empty_Location: "Empty Location",
};

const PACKING_TYPES = ["Loose", "Box", "Pallet"];

const NationalCountGroupTypes = ["PO", "SO", "Container", "Other"];
const NationalCountStatuses = ["Created", "Cancelled", "Completed"];

const ACUMATICA_BASE_URL = "https://tayse.acumatica.com/";
const COMPANY = process.env.NODE_ENV === "development" ? "Test" : "Tayse";
const INVOICE_URL = `(W(61))/Main?CompanyID=${COMPANY}&ScreenId=SO303000&DocType=INV&RefNbr=`;
const ORDER_URL = `(W(60))/Main?CompanyID=${COMPANY}&ScreenId=SO301000&OrderType=SO&OrderNbr=`;
const PURCHASE_ORDER_URL = `(W(2))/Main?CompanyID=${COMPANY}&ScreenId=PO301000&OrderType=RO&OrderNbr=`;
const RECEIPT_URL = `(W(3))/Main?CompanyID=${COMPANY}&ScreenId=PO302000&ReceiptType=RT&ReceiptNbr=`;
const RETURN_URL = `(W(60))/Main?CompanyID=${COMPANY}&ScreenId=SO301000&OrderType=RC&OrderNbr=`;
const SHIPMENT_URL = `(W(59))/Main?CompanyID=${COMPANY}&ScreenId=SO302000&ShipmentNbr=`;

export {
  ACUMATICA_BASE_URL,
  BUGGY_USE_TYPES,
  carriers,
  CASTLEGATE_LOCATIONS,
  colors,
  CONDITIONS,
  CountStatus,
  EcommerceShipmentDetailStatus,
  express_services,
  initialAPIListValue,
  INSPECTION_REASONS,
  INVOICE_URL,
  LOCATION_POSITIONS,
  LocationTypes,
  NationalCountGroupTypes,
  NationalCountStatuses,
  BatchTypes,
  OrderStatus,
  OrderTypes,
  ORDER_URL,
  PACKING_TYPES,
  ProductAttributeTypes,
  PRODUCT_REQ_ATTRIBUTE_STATUSES,
  ProductClasses,
  ProductStatus,
  ProductTypes,
  PUBLISH_TYPES,
  PURCHASE_ORDER_URL,
  RECEIPT_URL,
  RETURN_URL,
  ShipmentDetailStatus,
  ShipmentServiceTypes,
  ShipmentStatus,
  SHIPMENT_STATUS_ORDER,
  SHIPMENT_URL,
  SizeAislePairs,
};
