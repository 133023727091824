import * as React from "react";

const SvgAutorenew = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 7.619h.01a5.154 5.154 0 0 1 2.525 1.234 4.421 4.421 0 0 1 1.363 2.29 4.158 4.158 0 0 1 0 1.824 4.395 4.395 0 0 1-1.364 2.289l1.414 1.28c.968-.877 1.631-1.991 1.908-3.205a5.736 5.736 0 0 0 0-2.553c-.273-1.215-.937-2.33-1.907-3.204a7.05 7.05 0 0 0-2.223-1.359 7.457 7.457 0 0 0-1.315-.37c-.136-.024-.274-.038-.412-.056V3L9 6.622l4 3.622V7.619ZM5.55 14.52c.174.373.392.736.646 1.078.249.335.537.65.854.936a7.118 7.118 0 0 0 2.224 1.36c.425.162.868.286 1.315.369.167.03.338.05.508.07V21l4-3.622-4-3.622V16.5c-.035-.006-.072-.003-.107-.01a5.153 5.153 0 0 1-2.526-1.233 4.522 4.522 0 0 1-1.086-1.469 4.155 4.155 0 0 1-.38-1.733c0-.595.13-1.184.381-1.733.252-.55.62-1.048 1.086-1.468L7.05 7.573a6.353 6.353 0 0 0-1.5 2.013 5.804 5.804 0 0 0-.001 4.935Z"
      fill={color}
    />
  </svg>
);

export default SvgAutorenew;
