import * as React from "react";

const SvgCalendar = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2a1 1 0 0 1 .993.883L17 3v1h2a3 3 0 0 1 2.995 2.824L22 7v12a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V3a1 1 0 0 1 1.993-.117L9 3v1h6V3a1 1 0 0 1 1-1Zm3 4h-2v1a1 1 0 0 1-1.993.117L15 7V6H9v1a1 1 0 0 1-1.993.117L7 7V6H5a1 1 0 0 0-.993.883L4 7v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm-2 4a1 1 0 0 1 .117 1.993L17 12H7a1 1 0 0 1-.117-1.993L7 10h10Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgCalendar;
