import * as React from "react";

const SvgClass = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="m13 13.5-3-2.25-3 2.25v-9H6v16h12v-16h-5v9z"
      fill={color}
    />
    <path
      d="M18 2.5H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-9 2h2v5l-1-.75-1 .75v-5zm9 16H6v-16h1v9l3-2.25 3 2.25v-9h5v16z"
      fill={color}
    />
  </svg>
);

export default SvgClass;
