import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  signatureVersion: "v4",
  region: "us-east-2",
  correctClockSkew: true,
});

export default s3;
