import defaultTheme from "./default";
import { createTheme } from "@material-ui/core";
import "./global.css";

const overrides = {
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    h1: {
      fontFamily: "Nunito Sans",
      fontSize: "32px !important",
      fontWeight: "bold !important",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "18px !important",
      color: "#1d4ed8",
      fontWeight: "bold !important",
    },
  },
};

const themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
