import * as React from "react";

const SvgHistory = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.9}
      d="M13.281 4c-4.264 0-7.71 3.582-7.71 8H3l3.339 3.462.06.13L9.857 12H7.286c0-3.436 2.687-6.222 6-6.222s6 2.786 6 6.222c0 3.436-2.687 6.222-6 6.222-1.659 0-3.155-.702-4.239-1.826l-1.213 1.257C9.227 19.103 11.151 20 13.281 20 17.546 20 21 16.418 21 12s-3.454-8-7.719-8Zm-.852 4.444v4.445l3.668 2.258.617-1.08-3-1.845V8.444H12.43Z"
      fill={color}
    />
  </svg>
);

export default SvgHistory;
