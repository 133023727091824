import { useTheme } from "@material-ui/styles";

export default function PickStatus({ picked_qty, total_qty, title }) {
  const theme = useTheme();

  const statusStyles = {
    Picked: {
      color: theme.palette.colors.emerald700,
      backgroundColor: `${theme.palette.colors.emerald200}50`,
    },
    Picking: {
      color: theme.palette.colors.amber700,
      backgroundColor: `${theme.palette.colors.amber200}50`,
    },
    NotStarting: {
      color: theme.palette.colors.rose700,
      backgroundColor: `${theme.palette.colors.rose200}50`,
    },
  };

  const getStatusStyle = () => {
    let statusStyle = "NotStarting";
    if (total_qty === picked_qty) {
      statusStyle = "Picked";
    } else if (picked_qty > 0) {
      statusStyle = "Picking";
    }
    return statusStyles[statusStyle];
  };

  return (
    <span
      style={{
        ...getStatusStyle(),
        borderRadius: "1rem",
        padding: "0.25rem 1rem",
        minWidth: "6rem",
        textAlign: "center",
        display: "table-cell",
      }}
      variant="filled"
      title={title}
    >
      {picked_qty} / {total_qty}
    </span>
  );
}
