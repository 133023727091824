import * as React from "react";

const SvgArrowDropDown = ({ color, size }) => (
  <svg
    viewBox="0 0 16 16"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.667 6.667 8 10l3.333-3.333H4.667z"
      fill={color}
      fillOpacity={0.5}
    />
  </svg>
);

export default SvgArrowDropDown;
