import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { getCollectionNames } from "api/products";
import { createGroup } from "api/stock";
import { TextInput } from "components/Form";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import getClearAdornment from "utils/form";

import useStyles from "./styles";
import {
  X as Close,
  Collection,
  Duplicate,
  FolderSpecial,
  Refresh,
  SnippetFolder,
  Trash,
  Truck,
  ViewBoards,
} from "icons";

const initialAutocomplete = {
  group: "",
};

const initialParams = {
  name: "",
  group_name: "",
  lead_time: "125",
  safety_stock: "120",
  replenishment_cycle: "120",
  max_order_days: "360",
  minimum_order_m2: "10000",
  groups: [],
  products: [],
};

const CreateGroupModal = ({ open, onClose, onCreate, fetchGroups }) => {
  const classes = useStyles();
  const [params, setParams] = useState(initialParams);
  const [autocompleteValues, setAutocompleteValues] = useState(
    initialAutocomplete,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [collectionNames, setCollectionNames] = useState([]);
  const layoutDispatch = useLayoutDispatch();

  const handleCreate = () => {
    setIsLoading(true);
    createGroup({
      setError: (_) => {
        setIsLoading(false);
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        onCreate();
        setParams(initialParams);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Group created.",
          status: "success",
        });
        fetchGroups();
      },
      body: params,
    });
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteParams = (prop) => (_, value) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteValues = (prop) => (_, value) => {
    setAutocompleteValues((prev) => ({ ...prev, [prop]: value }));
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
  };

  const clearAdornment = (input) => {
    getClearAdornment(input, clearHandler, params);
  };

  const handleOnBlur = (prop, min, max) => () => {
    if (params[prop] < min) {
      setParams((prev) => ({ ...prev, [prop]: min }));
    } else if (params[prop] > max) {
      setParams((prev) => ({ ...prev, [prop]: max }));
    } else if (params[prop] === "") {
      setParams((prev) => ({ ...prev, [prop]: initialParams[prop] }));
    }
  };

  useEffect(() => {
    getCollectionNames({
      responseSetter: (res) => {
        if (res.success) {
          setCollectionNames(res.data);
        }
      },
    });
  }, []);

  return (
    <Dialog
      maxWidth="xl"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Create Group
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.mt10}>
              <strong>Group and Product Policy:</strong>
            </Typography>
            <Grid container spacing={2} className={classes.mt10}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextInput
                  id="name"
                  label="Group Name"
                  variant="outlined"
                  fullWidth
                  value={params.name}
                  onChange={handleChangeFilterInput("name")}
                  InputProps={clearAdornment("name")}
                  LeftIcon={FolderSpecial}
                  hasBorder
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextInput
                  id="lead_time"
                  label="Lead time"
                  variant="outlined"
                  fullWidth
                  value={params.lead_time}
                  onChange={handleChangeFilterInput("lead_time")}
                  InputProps={clearAdornment("lead_time")}
                  onBlur={handleOnBlur("lead_time", 30, 1000)}
                  type="number"
                  min={30}
                  max={1000}
                  LeftIcon={Truck}
                  hasBorder
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextInput
                  id="safety_stock"
                  label="Safety Stock"
                  variant="outlined"
                  fullWidth
                  value={params.safety_stock}
                  onChange={handleChangeFilterInput("safety_stock")}
                  InputProps={clearAdornment("safety_stock")}
                  onBlur={handleOnBlur("safety_stock", 30, 1000)}
                  type="number"
                  min={30}
                  max={1000}
                  LeftIcon={ViewBoards}
                  hasBorder
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextInput
                  id="replenishment_cycle"
                  label="Replenishment Cycle"
                  variant="outlined"
                  fullWidth
                  value={params.replenishment_cycle}
                  onChange={handleChangeFilterInput("replenishment_cycle")}
                  InputProps={clearAdornment("replenishment_cycle")}
                  onBlur={handleOnBlur("replenishment_cycle", 30, 1000)}
                  type="number"
                  min={30}
                  max={1000}
                  LeftIcon={Refresh}
                  hasBorder
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextInput
                  id="minimum_order_m2"
                  label="Minimum Order (m²)"
                  variant="outlined"
                  fullWidth
                  value={params.minimum_order_m2}
                  onChange={handleChangeFilterInput("minimum_order_m2")}
                  InputProps={clearAdornment("minimum_order_m2")}
                  onBlur={handleOnBlur("minimum_order_m2", 0, 100000)}
                  type="number"
                  min={0}
                  max={100000}
                  LeftIcon={Collection}
                  hasBorder
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextInput
                  id="max_order_days"
                  label="Max Order (Days)"
                  variant="outlined"
                  fullWidth
                  value={params.max_order_days}
                  onChange={handleChangeFilterInput("max_order_days")}
                  InputProps={clearAdornment("max_order_days")}
                  type="number"
                  LeftIcon={Duplicate}
                  hasBorder
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography variant="subtitle2">
              <strong>Add Collections to Group:</strong>
            </Typography>
            <Grid
              container
              spacing={2}
              className={classes.mt10}
              alignItems="center"
            >
              <Grid item xs={3}>
                <Autocomplete
                  value={autocompleteValues.group_name}
                  onChange={handleChangeAutocompleteValues("group_name")}
                  inputValue={params.group_name}
                  onInputChange={handleChangeAutocompleteParams("group_name")}
                  id="group"
                  options={collectionNames}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label="Collection"
                      variant="outlined"
                      fullWidth
                      LeftIcon={SnippetFolder}
                      hasBorder
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setParams((prev) => ({
                      ...prev,
                      groups: [...prev.groups, params.group_name],
                    }));
                    setAutocompleteValues(initialAutocomplete);
                    setParams((prev) => ({
                      ...prev,
                      group_name: "",
                    }));
                  }}
                  disabled={!autocompleteValues.group_name}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={6}>
                {params.groups.map((group, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={`${classes.mr10} ${classes.mb10}`}
                    onClick={() => {
                      setParams((prev) => ({
                        ...prev,
                        groups: prev.groups.filter((v) => v !== group),
                        group_name: "",
                      }));
                      setAutocompleteValues(initialAutocomplete);
                    }}
                  >
                    <span className={classes.mr10}>• {group}</span>
                    <Trash size={0.7} color="#fff" />
                  </Button>
                ))}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              alignItems="flex-end"
              justifyContent="center"
              className={classes.mt10}
            >
              <Grid item xs={12}>
                <Button
                  onClick={handleCreate}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.actionButton}
                  disabled={
                    isLoading ||
                    !params.name ||
                    !params.lead_time ||
                    !params.safety_stock ||
                    !params.replenishment_cycle ||
                    !params.minimum_order_m2 ||
                    !params.max_order_days ||
                    (params.groups.length === 0 &&
                      params.products.length === 0) ||
                    params.groups.length < 2
                  }
                >
                  {isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Create / Classify"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupModal;
