import * as React from "react";

const SvgLockopen = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M8 11V7a4 4 0 1 1 8 0M12 15v2" />
      <path d="M6 21h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Z" />
    </g>
  </svg>
);

export default SvgLockopen;
