import React, { useEffect, createElement } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error/Error";
import Login from "../pages/login/Login";
import PasswordReset from "../pages/password/PasswordReset";
import PasswordConfirm from "../pages/password/PasswordConfirm";

// context
import { useUserState, useUserDispatch, validToken } from "context/UserContext";

import { getWithExpiry } from "../utils/storage";

export default function App() {
  // global
  const { isAuthenticated } = useUserState();
  const userDispatch = useUserDispatch();
  const access = getWithExpiry("access");

  useEffect(() => {
    if (access) {
      validToken(userDispatch, access);
    }
  }, [access, userDispatch]);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/my-work" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/my-work" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/password_reset" component={PasswordReset} />
        <PublicRoute path="/password_confirm" component={PasswordConfirm} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app/my-work",
              }}
            />
          ) : (
            createElement(component, props)
          )
        }
      />
    );
  }
}
