import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Divider,
  Button,
  CircularProgress,
  InputBase,
  Card,
  Dialog,
} from "@material-ui/core";

import { getShipments } from "api/shipment";

import { SelectBox } from "components/FormElements";

import { useLayoutState } from "context/LayoutContext";

import { Search2 } from "icons";

import ShipmentPreview from "pages/shipments/ShipmentPreview";

import { formatTrackingNumber } from "utils/carrier";

import useStyles from "./styles";

const SearchTypes = [
  {
    value: "order__po_number",
    label: "PO Number",
  },
  {
    value: "tracking_number",
    label: "Tracking Number",
  },
  {
    value: "shipment_number",
    label: "Shipment Number",
  },
];

function HeaderSearch({ isSidebarOpened }) {
  const classes = useStyles();
  const [searchType, setSearchType] = useState("shipment_number");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useLayoutState();
  const [shipment, setShipment] = useState({});
  const [open, setOpen] = useState(false);
  const searchEl = useRef(null);

  useEffect(() => {
    if (open && searchEl.current) {
      searchEl.current.focus();
    }

    return () => {
      setSearchValue("");
    };
  }, [open]);

  const submitSearch = (type) => {
    setIsLoading(true);
    let formated_value = searchValue;

    if (type === "tracking_number" || searchType === "tracking_number") {
      formated_value = formatTrackingNumber(searchValue);
    }
    setShipment({});
    getShipments({
      params: {
        [type || searchType]: formated_value,
      },
      responseSetter: (res) => {
        setShipment(res.results[0]);
        setIsLoading(false);
      },
      setError: (err) => {
        setIsLoading(false);
      },
    });
  };

  const handleSearchChange = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSearchType = ({ target: { value } }) => {
    setSearchType(value);
    if (searchValue) {
      submitSearch(value);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClickOpen}
        startIcon={
          <Search2
            style={{ marginLeft: 10 }}
            color={isMobile ? "#FFF" : "#000"}
          />
        }
        className={classes.searchButton}
      >
        {isSidebarOpened && "Search"}
      </Button>

      <Dialog
        open={open}
        keepMounted
        maxWidth="lg"
        fullWidth
        onClose={handleClose}
      >
        <Box>
          <form onSubmit={submitSearch}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center">
                <Search2 className={classes.searchIcon} />
                <InputBase
                  value={searchValue}
                  onChange={handleSearchChange}
                  inputRef={searchEl}
                  placeholder={"Search tracking, shipment or po number"}
                  fullWidth
                  style={{
                    padding: 10,
                  }}
                  onKeyPress={({ key }) =>
                    key === "Enter" && searchValue && submitSearch()
                  }
                />
              </Box>
              <Card className={classes.selectBox}>
                <SelectBox
                  all_option_active={false}
                  items={SearchTypes}
                  onChange={handleChangeSearchType}
                  value={searchType}
                />
              </Card>
            </Box>
          </form>
        </Box>
        <Divider />
        {isLoading ? (
          <Box className={classes.circularProgress}>
            <CircularProgress />
          </Box>
        ) : shipment && shipment.id ? (
          <ShipmentPreview shipment={shipment} />
        ) : null}
      </Dialog>
    </>
  );
}

export default HeaderSearch;
