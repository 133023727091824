import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { X as Close, CloudDownload } from "icons";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

const useStyles = makeStyles(() => ({
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    marginTop: 20,
  },
}));

const EndOfDayModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date().toLocaleDateString("en-CA"));
  const layoutDispatch = useLayoutDispatch();

  const handleDownload = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/custom/fedex-end-of-day?close_date=${date}`,
      {
        method: "GET",
      },
    )
      .then(async (res) => {
        if (res.status === 200) {
          const blob = await res.blob();
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = url;
          link.download = `end_of_day_${
            date || new Date().toLocaleDateString("en-CA")
          }.txt`;
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          const jsonRes = await res.json();
          popupNotification({
            dispatch: layoutDispatch,
            message: jsonRes.message,
          });
        }
      })
      .catch(() => {
        onClose();
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Download End of Day Report
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <TextField
              id="date"
              label="Close Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: "2023-04-25",
                  max: new Date().toLocaleDateString("en-CA"),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              onClick={handleDownload}
              variant="contained"
              color="secondary"
              size="small"
              endIcon={<CloudDownload color="white" />}
              className={classes.button}
            >
              Download
            </Button>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={onClose}
          className={classes.button}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndOfDayModal;
