import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { debounce } from "lodash";

import { getTransfers } from "api/transfer";

import { StatusLabel } from "components/Custom";
import { InputContainer } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { Check, X } from "icons";

import themes from "themes";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import TransferCountsModal from "./TransferCountsModal";
import TransferFilter from "./TransferFilter";

export default function Transfers() {
  const query = new URLSearchParams(useLocation().search);
  const [transferId, setTransferId] = useState("");
  const headers = [
    {
      id: "product__sku",
      label: "product_sku",
      exportLabel: "Inventory Id",
      render: (r, exported) =>
        exported ? (
          r.product.sku
        ) : (
          <span
            style={{
              color: themes.default.palette.colors.blue_button,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setTransferId(r.id)}
          >
            {r.product.sku}
          </span>
        ),
      sortable: true,
    },
    {
      id: "from_location__code",
      label: "from_location",
      render: (r, exported) => {
        if (exported) {
          return r.from_location.code;
        }
        const isDeleted = r.from_location.is_deleted === true;
        return (
          <StatusLabel
            status={isDeleted ? "Cancelled" : "Open"}
            text={r.from_location.code}
            altText={isDeleted ? "Inactive" : ""}
          />
        );
      },
      sortable: true,
    },
    {
      id: "to_location__code",
      label: "to_location",
      render: (r, exported) => {
        if (exported) {
          return r.to_location.code;
        }
        const isDeleted = r.to_location.is_deleted === true;
        return (
          <StatusLabel
            status={isDeleted ? "Cancelled" : "Open"}
            text={r.to_location.code}
            altText={isDeleted ? "Inactive" : ""}
          />
        );
      },
      sortable: true,
    },
    {
      id: "qty",
      exportLabel: "Quantity",
      sortable: true,
      render: (r) => r.qty,
    },
    {
      id: "created_at",
      sortable: true,
      render: (r) => timezoneFormat(r.created_at),
    },
    {
      id: "is_published",
      sortable: true,
      render: (r, exported) =>
        exported ? (
          r.is_published ? (
            "Published"
          ) : (
            "Not Published"
          )
        ) : r.is_published ? (
          <Check color="green" />
        ) : (
          <X color="red" />
        ),
    },
  ];
  const claims = parseJwt(getWithExpiry("access"));
  const { related_product } = claims;

  const { t } = useTranslation();
  const [transfers, setTransfers] = useState(initialAPIListValue);
  const [selectedTransfers, setSelectedTransfers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [params, setParams] = useState({
    from_location__code: "",
    is_published: false,
    product__sku: "",
    product__product_type: related_product || 1,
    to_location__code: "",
    start_date: "",
    end_date: "",
    type: query.get("type"),
  });

  useEffect(() => {
    if (transfers.isFetching) {
      let filters = {
        ...params,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getTransfers({
        params: filters,
        setFunc: setTransfers,
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    params,
    transfers.isFetching,
    orderDirection,
    orderBy,
  ]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTransfers((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTransfers(initialAPIListValue);
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setTransfers((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    if (prop === "type") {
      setSelectedTransfers([]);
    }
    setParams({ ...params, [prop]: value });
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setParams((prev) => ({ ...prev, [input]: "" }));
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setTransfers((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleClosePublishModal = (isSuccess) => {
    if (isSuccess) {
      setTransfers((prev) => ({ ...prev, isFetching: true }));
    }
    setSelectedTransfers([]);
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Transfers-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, [
        {
          id: "id",
        },
        ...headers,
      ]),
    );
  };

  const handleExportData = () => {
    if (transfers.items.length === transfers.count) {
      exportData(transfers.items);
    } else {
      getTransfers({
        params: {
          limit: transfers.count,
          ...params,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  return (
    <>
      <PageTitle title={t("transfers")} helpText="Cycle Count records" />
      <InputContainer>
        <TransferFilter
          clearAdornment={clearAdornment}
          handleChangeFilterInput={handleChangeFilterInput}
          params={params}
          setParams={setParams}
          debouncedSearchHandler={debouncedSearchHandler}
          headers={headers}
          selectedTransfers={selectedTransfers}
          afterSuccessPublish={handleClosePublishModal}
        />
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={transfers}
            headers={headers}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortActions={{ onSort, orderBy, orderDirection }}
            hasCheckBox={true}
            selected={selectedTransfers}
            setSelected={setSelectedTransfers}
            handleExportData={handleExportData}
          />
          {transferId && (
            <TransferCountsModal
              onClose={() => setTransferId("")}
              transferId={transferId}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
