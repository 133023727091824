import * as React from "react";

const SvgScale = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="m3 6 3 1M6 7l-3 9a5.002 5.002 0 0 0 6.001 0M6 7l3 9M6 7l6-2M18 7l3-1M18 7l-3 9a5.002 5.002 0 0 0 6.001 0M18 7l3 9M18 7l-6-2M12 3v2M12 21V5M12 21H9M12 21h3" />
    </g>
  </svg>
);

export default SvgScale;
