import React, { useEffect, useState } from "react";

import _ from "lodash";

import { CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";

import { getPickerStatistics } from "api/shipment";

import { DatePickerGroup, InputContainer } from "components/Form";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import { ProductTypeFilter } from "components/Custom";
import DashboardCard from "components/Card/DashboardCard";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { ChevronDoubleRight, Qrcode, ShoppingCart, UserGroup } from "icons";

import { getCarrierTrackingLink } from "pages/shipments/utils";
import { initialAPIListValue, ProductClasses } from "utils/constants";
import { convertDate, formatDateList } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import { convertDataToTableView } from "utils/convert";
import { toTitleCase } from "utils/string";
import { getWithExpiry } from "utils/storage";
import parseJwt from "utils/jwt";

const headers = [
  {
    id: "tracking_number",
    render: (r) => (
      <ExternalLink
        to={getCarrierTrackingLink(r.detail.ship_via, r.detail.tracking_number)}
      >
        {r.detail.tracking_number}
      </ExternalLink>
    ),
  },
  {
    id: "location",
    render: (r) => r.detail.location_code,
  },
  {
    id: "product",
    exportLabel: "Inventory Id",
    render: (r) => r.detail.product_sku,
  },
  {
    id: "shipment_number",
    render: (r) => (
      <InternalLink to={`/app/shipments/${r.shipment_id}`}>
        {r.detail.shipment_number}
      </InternalLink>
    ),
  },
  {
    id: "assigned_picked",
    label: "Assigned -> Picked",
    render: (r) =>
      formatDateList([
        r.assigned_at ? r.assigned_at : "N/A",
        r.picked_at ? r.picked_at : "N/A",
      ]),
  },
];

const today = convertDate(new Date());

export default function PickerStatistics() {
  const theme = useTheme();
  const layoutDispatch = useLayoutDispatch();
  const { related_product } = parseJwt(getWithExpiry("access"));

  const [data, setData] = useState(initialAPIListValue);
  const [assignedShipments, setAssignedShipments] = useState(
    initialAPIListValue,
  );
  const [selectedPicker, setSelectedPicker] = useState(null);
  const [params, setParams] = useState({
    is_small_carrier: true,
    start_date: today,
    end_date: today,
    product_type: related_product || 1,
  });
  const [exportDisabled, setExportDisabled] = useState(false);

  useEffect(() => {
    if (data.isFetching && params.product_type) {
      getPickerStatistics({
        params,
        responseSetter: (results) => {
          if (!results) {
            return popupNotification({
              dispatch: layoutDispatch,
              message: "Server Error",
            });
          }

          const data = {};
          const groupedData = _.groupBy(
            _.orderBy(results, "transaction_time", "desc"),
            (i) => i.action_type,
          );
          const unique_detail_action_type = _.uniqWith(
            results,
            (a, b) =>
              a.detail.id === b.detail.id && a.action_type === b.action_type,
          );

          const groupByDetail = (data) => _.groupBy(data, (i) => i.detail.id);
          groupedData.Assigned = groupByDetail(groupedData.Assigned);
          groupedData.Picked = groupByDetail(groupedData.Picked);

          unique_detail_action_type.forEach((item) => {
            const display_name = item.user.username;
            const class_name = item.detail.product_class;
            if (data[display_name]) {
              data[display_name][class_name] =
                (data[display_name][class_name] || 0) + 1;
            } else {
              data[display_name] = {
                [class_name]: 1,
                id: item.user.id || null,
                total_assigned: 0,
                total_picked: 0,
              };
            }
          });

          results.forEach((item, index) => {
            const detail_id = item.detail.id;
            const display_name = item.user.username;
            const status = item.action_type;

            const assigned_transactions = groupedData.Assigned[detail_id];
            const picked_transactions = groupedData.Picked[detail_id];

            results[index].assigned_at =
              assigned_transactions &&
              assigned_transactions[0].transaction_time;
            results[index].picked_at =
              picked_transactions && picked_transactions[0].transaction_time;

            if (!data[display_name]) {
              data[display_name] = { total_picked: 0, total_assigned: 0 };
            }
            data[display_name][
              status === "Picked" ? "total_picked" : "total_assigned"
            ] =
              (data[display_name][
                status === "Picked" ? "total_picked" : "total_assigned"
              ] || 0) + 1;
          });
          setAssignedShipments({ items: results, isFetching: false });
          setData({
            items: data,
            isFetching: false,
          });
          if (results && results.length) {
            const user = results[0].user;
            const filteredEvents = _.uniqBy(
              Object.values(results).filter((as) => as.user.id === user.id),
              (e) => e.detail.id,
            );
            setSelectedPicker({
              id: user.id,
              key: user.username,
              items:filteredEvents,
            });
          }
        },
      });
    }
  }, [params, layoutDispatch, data.isFetching]);

  const calculatePercentage = (value, total) => {
    return value ? ((100 * value) / total).toFixed(0) : 0;
  };

  const handleChangeFilterInput = (prop) => (event) => {
    event.persist();
    setSelectedPicker(null);
    setParams((prev) => ({ ...prev, [prop]: event.target.value }));
    setData({ ...data, isFetching: true });
  };

  const totalAssigned = Object.entries(data.items).reduce(
    (total, [_, item]) => total + item["total_assigned"],
    0,
  );

  const totalPicked = Object.entries(data.items).reduce(
    (total, [_, item]) => total + (item["total_picked"] || 0),
    0,
  );

  const totalPickers = Object.keys(data.items).length;

  const ClassesList = ProductClasses.filter(
    (class_name) => params.product_type === class_name.product_type,
  );

  const percentageColor = (value, total) => {
    const percentage = calculatePercentage(value, total);

    if (percentage > 70) {
      return theme.palette.colors.emerald500;
    } else if (percentage > 40) {
      return theme.palette.colors.amber500;
    }

    return theme.palette.colors.rose500;
  };

  const handleExportData = () => {
    const today = new Date();
    exportToExcel(
      [],
      `AssignedLabels-${selectedPicker.key}-${params.start_date}-${
        params.end_date
      }-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(selectedPicker.items, headers),
      setExportDisabled,
    );
  };

  const userTableHeaders = [
    {
      id: "username",
      render: (r) => (
        <span
          style={{ color: theme.palette.colors.blue600, fontWeight: "bold" }}
        >
          {toTitleCase(r.username)}
        </span>
      ),
    },
    ...ClassesList.map((item) => ({
      id: item.value,
      label: item.label,
      render: (r) => <span>{r[item.value] || 0}</span>,
    })),
    {
      id: "total_assigned",
      label: "Assigned",
      render: (r) => (
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {r.total_assigned || 0}
        </span>
      ),
    },
    {
      id: "total_picked",
      label: "Picked",
      render: (r) => (
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {r.total_picked || 0}
        </span>
      ),
    },
    {
      id: "percentage",
      label: "(%)",
      render: (r) => (
        <span>
          {r["total_assigned"] === 0
            ? "100"
            : calculatePercentage(r["total_picked"], r["total_assigned"])}
          %
        </span>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Picker Statistics" />
      <Grid container spacing={4}>
        <DashboardCard
          info="Pickers"
          icon={<UserGroup color={theme.palette.colors.blue400} />}
          value={totalPickers}
          bgColor={theme.palette.colors.blue400}
          itemSize={3}
        />
        <DashboardCard
          info="Assigned"
          icon={<Qrcode color={theme.palette.colors.blue600} />}
          value={totalAssigned}
          bgColor={theme.palette.colors.blue600}
          itemSize={3}
        />
        <DashboardCard
          info="Picked"
          icon={<ShoppingCart color={theme.palette.colors.blue800} />}
          value={totalPicked}
          itemSize={3}
          bgColor={theme.palette.colors.blue800}
        />
        <DashboardCard
          info="Picked / Assigned (%)"
          icon={
            <ChevronDoubleRight
              color={percentageColor(totalPicked, totalAssigned)}
            />
          }
          value={`${calculatePercentage(totalPicked, totalAssigned)} %`}
          itemSize={3}
          bgColor={percentageColor(totalPicked, totalAssigned)}
        />
      </Grid>
      <InputContainer style={{ marginTop: 15 }}>
        <Grid container>
          <DatePickerGroup
            startDateValue={params.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={params.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
          />
          <ProductTypeFilter
            value={params.product_type}
            setValue={(value) => setParams({ ...params, product_type: value })}
            onChange={handleChangeFilterInput("product_type")}
            xs={12}
            sm={6}
            md={4}
            lg={2}
          />
        </Grid>
      </InputContainer>

      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            {!params.product_type ? (
              <Alert severity="info">Please select product type!</Alert>
            ) : data.isFetching ? (
              <CircularProgress />
            ) : !Object.keys(data.items).length ? (
              <Alert severity="info">No results found!</Alert>
            ) : (
              <DefaultTable
                headers={userTableHeaders}
                data={{
                  items: Object.entries(data.items).map(([key, item]) => ({
                    username: key,
                    ...item,
                  })),
                  isFetching: false,
                }}
                rowOnClick={(r) => {
                  setSelectedPicker({
                    id: r.id,
                    key: r.username,
                    items: _.uniqBy(
                      assignedShipments.items.filter(
                        (as) => as.user.id === r.id,
                      ),
                      (e) => e.detail.id,
                    ),
                  });
                }}
              />
            )}
          </Widget>
          {selectedPicker && selectedPicker.items && (
            <Widget style={{ marginTop: theme.spacing(2) }}>
              {selectedPicker && selectedPicker.key && (
                <strong style={{ float: "left", fontSize: "2em" }}>
                  {toTitleCase(selectedPicker.key)}
                </strong>
              )}
              <strong style={{ float: "right", fontSize: "2em" }}>
                Total: {selectedPicker.items.length}
              </strong>
              <DefaultTable
                headers={headers}
                data={{
                  items: selectedPicker.items,
                  isFetching: false,
                }}
                handleExportData={handleExportData}
                exportDisabled={exportDisabled}
                setExportDisabled={setExportDisabled}
              />
            </Widget>
          )}
        </Grid>
      </Grid>
    </>
  );
}
