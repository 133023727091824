import * as React from "react";

const SvgReceipt = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.333 16.5H6.667v-1.8h10.666v1.8Zm0-3.6H6.667v-1.8h10.666v1.8Zm0-3.6H6.667V7.5h10.666v1.8ZM4 21l1.333-1.35L6.667 21 8 19.65 9.333 21l1.334-1.35L12 21l1.333-1.35L14.667 21 16 19.65 17.333 21l1.334-1.35L20 21V3l-1.333 1.35L17.333 3 16 4.35 14.667 3l-1.334 1.35L12 3l-1.333 1.35L9.333 3 8 4.35 6.667 3 5.333 4.35 4 3v18Z"
      fill={color}
    />
  </svg>
);

export default SvgReceipt;
