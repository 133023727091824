import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  label: {
    fontSize: "0.675rem",
    margin: "1rem 0 0 0",
    color: "#a1a1a1",
  },
  link: {
    fontSize: "0.875rem",
    marginTop: "1rem",
  },
  fedexLogo: {
    height: "42px",
    width: "100% !important",
    "& > img": {
      height: "58px !important",
      width: "65px !important",
      objectFit: "contain",
    },
  },
  fedexLogoSmall: {
    height: "42px",
    width: "100% !important",
    marginRight: "0.5rem",
    "& > img": {
      height: "38px !important",
      width: "100% !important",
      objectFit: "contain",
    },
  },
  upsLogo: {
    height: "42px",
    width: "100% !important",
    "& > img": {
      height: "32px !important",
      width: "100% !important",
      objectFit: "contain",
    },
  },
  upsLogoSmall: {
    height: "42px",
    marginRight: "0.5rem",
    width: "100% !important",
    "& > img": {
      height: "24px !important",
      width: "24px !important",
      objectFit: "contain",
    },
  },
  carrierCounts: {
    fontSize: "1.2rem",
    marginLeft: "0.2rem",
    color: "blue",
    fontWeight: "normal",
  },
  statusCounts: {
    marginLeft: "0.2rem",
    color: "blue",
    fontWeight: "bold",
  },
  borderedButton: {
    border: "2px solid #fff",
  },
  drawerPaper: {
    overflowX: "hidden",
    overflowBlock: "scroll",
  },
  errorMessage: {
    color: "red",
  },
}));
