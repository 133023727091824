import * as React from "react";

const SvgArchive = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M5 19.5h14v-11H5v11zm5.55-6v-3h2.91v3H16l-4 4-4-4h2.55z"
      fill={color}
    />
    <path
      d="M16 13.5h-2.55v-3h-2.9v3H8l4 4 4-4zm4.54-7.77-1.39-1.68c-.27-.34-.68-.55-1.15-.55H6c-.47 0-.88.21-1.16.55L3.46 5.73C3.17 6.07 3 6.52 3 7v12.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-.48-.17-.93-.46-1.27zM6.24 5.5h11.52l.81.97H5.44l.8-.97zM19 19.5H5v-11h14v11z"
      fill={color}
    />
  </svg>
);

export default SvgArchive;
