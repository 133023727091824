import { makeStyles } from "@material-ui/styles";
import { generateAWSURL, getSignedMediaURL } from "utils/image";

const url = generateAWSURL("static/login.jpg");
const bg_image = getSignedMediaURL(url, "Login");

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundImage: `url("${bg_image}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    top: 0,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      background: "none",
    },
  },
  rightContainer: {
    background: `rgba(49, 80, 75, 0.9)`,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    width: "60%",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  logotypeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  tayseLogo: {
    margin: "20px",
    width: "250px",
    height: "98px",
    [theme.breakpoints.down("xs")]: {
      width: "50% !important",
      height: "30% !important",
    },
  },
  wmsLogo: {
    marginTop: "27px",
    width: "201px",
    height: "98px",
    [theme.breakpoints.down("xs")]: {
      width: "50% !important",
      height: "30% !important",
    },
  },
  textField: {
    height: "56px",
    borderRadius: "4px",
    border: "solid 1px rgba(34, 51, 84, 0.5)",
    backgroundColor: "#fff",
  },
  textLabel: {
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(13),
    flexGrow: "0",
    opacity: "0.5",
    lineHeight: "1.71",
    color: "#223354",
  },
  loginButton: {
    flexGrow: 0,
    height: "38px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "22px",
    borderRadius: "6px",
    backgroundColor: "#1a75ff !important",
    color: "#FFF !important",
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    color: "#f6f8fb",
    marginTop: theme.spacing(4),
    flexGrow: 0,
    opacity: 0.7,
    fontFamily: "Roboto",
    fontSize: "17px",
  },
}));
