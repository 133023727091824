import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  fedex: {
    padding: 15,
    backgroundColor: "#4D148C",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#FF6600",
    },
  },
  ups: {
    padding: 15,
    backgroundColor: "#351C15",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#FFB500",
    },
  },
}));
