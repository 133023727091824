import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import themes from "themes";

import {
  createOrUpdateProductAttributeRequest,
  getProductAttributeRequests,
} from "api/products";

import { Circle } from "components/Charts/Circle";
import Spacer from "components/Custom/Spacer";
import { Button as CustomButton } from "components/Form";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { ExternalLink } from "icons";

import useStyles from "pages/dashboard/styles";

import {
  initialAPIListValue,
  PRODUCT_REQ_ATTRIBUTE_STATUSES,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";

const headers = [
  {
    id: "product_sku",
  },
  {
    id: "value",
  },
  {
    id: "scan_count",
  },
];

export default function ValidateXRefWidget() {
  const classes = useStyles();
  const { permissions } = useUserState();
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [attributeRequests, setAttributeRequests] = useState(
    initialAPIListValue,
  );
  const [modal, setModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const hasPermission = permissions.includes(
    "product.view_productattributerequest",
  );
  useEffect(() => {
    if (attributeRequests.isFetching) {
      getProductAttributeRequests({
        params: {
          status: PRODUCT_REQ_ATTRIBUTE_STATUSES["Open"],
        },
        setFunc: setAttributeRequests,
        paginated: true,
      });
    }
  }, [attributeRequests.isFetching]);

  const handleOpen = (attribute) => {
    setSelectedAttribute(attribute);
    setModal(true);
  };

  const handleClose = (refreshData) => {
    if (refreshData) {
      setAttributeRequests((prev) => ({ ...prev, isFetching: true }));
    }
    setSelectedAttribute({});
    setModal(false);
    setIsLoading(false);
  };

  const errorHandler = (message) => {
    setIsLoading(false);
    return popupNotification({
      dispatch: layoutDispatch,
      message,
    });
  };

  const handleSubmit = (status) => {
    setIsLoading(true);
    const { id, code_type } = selectedAttribute;

    createOrUpdateProductAttributeRequest({
      body: {
        id,
        code_type,
        status: PRODUCT_REQ_ATTRIBUTE_STATUSES[status],
      },
      setError: (message) => errorHandler(message),
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return errorHandler(message);
        }
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        handleClose(true);
      },
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Attribute-Requests-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, [
        {
          id: "value",
        },
        {
          exportLabel: "Inventory ID",
          render: (r) => r.product_sku,
        },
        {
          id: "scan_count",
        },
      ]),
    );
  };

  const handleExportData = () => {
    if (attributeRequests.items.length === attributeRequests.count) {
      exportData(attributeRequests.items);
    } else {
      getProductAttributeRequests({
        params: {
          status: PRODUCT_REQ_ATTRIBUTE_STATUSES["Open"],
          limit: attributeRequests.count,
        },
        setFunc: ({ items }) => exportData(items),
        paginated: true,
      });
    }
  };

  return (
    <Widget>
      {attributeRequests.isFetching ? (
        <CircularProgress />
      ) : (
        <>
          {modal && hasPermission && (
            <Dialog
              open={true}
              onClose={() => handleClose()}
              aria-labelledby="xref-dialog"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle id="xref-dialog">
                Confirmation XRef for {selectedAttribute.product_sku}
              </DialogTitle>
              <DialogContent>
                <DefaultTable
                  headers={headers}
                  data={{
                    count: 1,
                    items: [selectedAttribute],
                    isFetching: false,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => handleSubmit("Reject")}
                          disabled={isLoading}
                          style={{
                            backgroundColor:
                              themes.default.palette.colors.rose600,
                            color: themes.default.palette.colors.white,
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          onClick={() => handleSubmit("Approve")}
                          disabled={isLoading}
                        >
                          Approve
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
          <Grid
            container
            spacing={0}
            className={classes.containerCenter}
            style={{ cursor: "pointer" }}
          >
            <Grid item xs={6} lg={4}>
              <Circle
                title="Validate XRefs"
                number={attributeRequests.count}
                color={themes.default.palette.colors.rose500}
                isSelected={false}
                size={450}
                series={[attributeRequests.count]}
              />
            </Grid>
          </Grid>
          <Spacer height="2rem" />
          <Grid
            container
            spacing={0}
            className={[classes.container, classes.tableContainer].join(" ")}
            style={{ minHeight: "1rem" }}
          >
            <Grid item xs={12} lg={12}>
              {attributeRequests.items.length > 0
                ? attributeRequests.items.map((request, index) => (
                    <Grid
                      container
                      key={request.id}
                      className={[
                        classes.tableRow,
                        index % 2 === 0 ? classes.rowEven : classes.rowOdd,
                      ].join(" ")}
                    >
                      <Grid item lg={4} style={{ paddingLeft: "10px" }}>
                        <span
                          className={classes.selectorClass}
                          onClick={() => handleOpen(request)}
                        >
                          {request.value}
                          <ExternalLink
                            color={themes.default.palette.colors.blue_button}
                            size={0.8}
                          />
                        </span>
                      </Grid>
                      <Grid item lg={4}>
                        {request.product_sku}
                      </Grid>
                      <Grid item lg={4}>
                        {request.scan_count}
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
          {hasPermission && (
            <CustomButton
              variant="contained"
              color="danger"
              onClick={() => handleExportData()}
            >
              Export Data
            </CustomButton>
          )}
        </>
      )}
    </Widget>
  );
}
