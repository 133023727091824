import { useState } from "react";
import classNames from "classnames";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { getProductsWithAttributes } from "api/products";
import {
  addToPackage,
  createShipmentPackage,
  getShipmentPackages,
  getShipments,
  updatePackage,
} from "api/shipment";

import { Archive2, LocalShipping, QrCode2, Trash } from "icons";

import { ConfirmDialog } from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { useUserState } from "context/UserContext";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import useStyles from "./styles";

const initialParams = {
  shipment_number: "",
  shipment_id: "",
  package_name: "",
  package_id: "",
  product_id: "",
  product_sku: "",
  quantity: 1,
};
export default function Packaging() {
  const classes = useStyles();
  const { permissions } = useUserState();
  const layoutDispatch = useLayoutDispatch();
  const [params, setParams] = useState(initialParams);
  const [isFetching, setIsFetching] = useState(false);
  const [completePackageDialog, setCompletePackage] = useState(false);
  const [shipment, setShipment] = useState({});
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [createModal, setCreateModal] = useState(false);

  const hasCreatePackagePermission = permissions.includes(
    "contenttypes.ltl.create_package",
  );

  const hasCompletePackagePermission = permissions.includes(
    "contenttypes.ltl.complete_package",
  );

  const hasPackagingPermission = permissions.includes(
    "contenttypes.ltl.packaging",
  );

  const handleCloseCreateModal = (status) => {
    if (status) {
      handleShipmentPackages();
    }
    setCreateModal(false);
  };

  const handleChangeFilter = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const handleShipment = () => {
    setIsFetching(true);
    setPackages([]);
    getShipments({
      params: {
        shipment_number: params.shipment_number,
        with_details: true,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { results } = res;
        if (!results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Shipment Not Found!",
          });
        }

        if (results[0].is_small_carrier) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "You can pack only LTL Shipments!",
          });
        }

        const shipmentRecord = results[0];
        if (shipmentRecord.status === "Packed") {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Shipment already packed!",
          });
        }
        if (!["Picking", "Picked"].includes(shipmentRecord.status)) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Shipment status must be Picking or Picked.",
          });
        }

        setShipment(shipmentRecord);
        setParams((prev) => ({ ...prev, shipment_id: shipmentRecord.id }));
        handleShipmentPackages();
      },
    });
  };

  const handleShipmentPackages = () => {
    setIsFetching(true);
    getShipmentPackages({
      params,
      responseSetter: (res) => {
        setIsFetching(false);
        const { results } = res;
        if (!results.length) {
          setCreateModal(true);
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Package not found, please create a package!",
            status: "info",
          });
        }

        const openPackages = results.filter((r) => r.status !== "Completed");
        if (!openPackages.length) {
          setCreateModal(true);
        } else if (openPackages.length === 1) {
          setSelectedPackage(openPackages[0]);
          setParams((prev) => ({
            ...prev,
            package_id: openPackages[0].id,
            package_name: openPackages[0].name,
          }));
        }

        setPackages(results);
      },
    });
  };

  const handleSelectBox = () => {
    const findPackage = packages.find((p) => p.name === params.package_name);
    if (!findPackage) {
      setParams((prev) => ({ ...prev, package_name: "", package_id: "" }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Package not found!",
      });
    }

    if (findPackage.status === "Completed") {
      setParams((prev) => ({ ...prev, package_name: "", package_id: "" }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Package completed!",
      });
    }

    setParams((prev) => ({ ...prev, package_id: findPackage.id }));
    setSelectedPackage(findPackage);
  };

  const clearShipment = () => {
    setShipment({});
    setParams(initialParams);
    setSelectedPackage({});
    setPackages([]);
  };

  const clearPackage = () => {
    setParams((prev) => ({
      ...prev,
      package_id: "",
      package_name: "",
      product_id: "",
      product_sku: "",
      quantity: "",
    }));
    setSelectedPackage({});
  };

  const handleSearchProduct = () => {
    setIsFetching(true);
    getProductsWithAttributes({
      params: {
        value_iexact: params.product_sku,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { results } = res;
        if (!results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Product not found!",
          });
        }

        const productAttribute = results[0];
        const shipmentItems = shipment.details.map((d) => d.product.sku);

        if (!shipmentItems.includes(productAttribute.product_sku)) {
          setParams((prev) => ({ ...prev, product_sku: "" }));
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Product not found in shipment!",
          });
        }

        const itemPickedQty = getItemPickedQty(productAttribute.product_sku);
        if (!itemPickedQty) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Product has not been picked!",
          });
        }

        const itemPackedQty = getItemPackedQty(productAttribute.product_sku);
        if (itemPackedQty === itemPickedQty) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "All picked products are packed!",
          });
        }

        setParams((prev) => ({
          ...prev,
          product_id: productAttribute.product,
          product_sku: productAttribute.product_sku,
        }));
      },
    });
  };

  const clearProduct = () => {
    setParams((prev) => ({
      ...prev,
      product_id: "",
      product_sku: "",
      quantity: "",
    }));
  };

  const getItemPickedQty = (product_sku = params.product_sku) => {
    if (!shipment.details) {
      return 0;
    }
    const shipmentDetails = shipment.details.filter(
      (d) => d.product.sku === product_sku,
    );
    let totalPicked = 0;
    shipmentDetails.forEach((d) => {
      totalPicked += d.transactions
        .filter((t) => t.action_type === "Picked")
        .reduce(
          (total, transaction) => total + Number(transaction.quantity),
          0,
        );
    });

    return totalPicked;
  };

  const getItemPackedQty = (product_sku = params.product_sku) => {
    let totalPackedItem = 0;
    packages.forEach((p) => {
      totalPackedItem += p.details
        .filter((d) => d.product.sku === product_sku)
        .reduce((total, detail) => total + Number(detail.quantity), 0);
    });

    return totalPackedItem;
  };

  const getTotalPackedQtyInPackage = () => {
    if (!selectedPackage.details) {
      return 0;
    }
    const totalPacked = selectedPackage.details.reduce(
      (total, detail) => total + Number(detail.quantity),
      0,
    );
    return totalPacked;
  };

  const handleSubmit = () => {
    if (isNaN(params.quantity) || Number(params.quantity) < 1) {
      setParams((prev) => ({ ...prev, quantity: "" }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity must be positive",
      });
    }

    const availablePackQty = getItemPickedQty() - getItemPackedQty();
    if (availablePackQty < 1) {
      setParams((prev) => ({ ...prev, quantity: "" }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: "There are no items you can pack.",
      });
    }

    if (Number(params.quantity) > availablePackQty) {
      setParams((prev) => ({ ...prev, quantity: 1 }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: `You can pack up to ${availablePackQty} items.`,
      });
    }
    setIsFetching(true);
    addToPackage({
      body: {
        package_id: params.package_id,
        product_id: params.product_id,
        quantity: params.quantity,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        console.log(res);
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        if (message === "Packed") {
          clearShipment();
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Shipment Packed",
            status: "success",
          });
        }

        setSelectedPackage((prev) => ({
          ...prev,
          details: [
            ...prev.details,
            {
              product: { sku: params.product_sku },
              quantity: params.quantity,
            },
          ],
        }));

        setPackages(
          packages.map((p) => {
            if (p.id === selectedPackage.id) {
              p.details.push({
                product: { sku: params.product_sku },
                quantity: params.quantity,
              });
            }
            return p;
          }),
        );

        if (Number(availablePackQty) === Number(params.quantity)) {
          clearProduct();
        } else {
          setParams((prev) => ({
            ...prev,
            quantity: 1,
          }));
        }

        return popupNotification({
          dispatch: layoutDispatch,
          message: "Item packed!",
          status: "success",
        });
      },
    });
  };

  const handleCompletePackage = () => {
    if (getTotalPackedQtyInPackage() < 1) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "You cannot complete this package. It doesn't have any item!",
      });
    }
    setIsFetching(true);
    updatePackage({
      id: params.package_id,
      body: {
        status: "Completed",
      },
      responseSetter: (res) => {
        setCompletePackage(false);
        setIsFetching(false);
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        setPackages((prev) =>
          prev.map((p) => {
            if (p.id === params.package_id) {
              p.status = "Completed";
            }
            return p;
          }),
        );
        setSelectedPackage({});
        setParams((prev) => ({ ...prev, package_id: "", package_name: "" }));
        return popupNotification({
          dispatch: layoutDispatch,
          message: "Package completed!",
          status: "success",
        });
      },
    });
  };

  return (
    <>
      <PageTitle title="Packaging" />
      <Widget>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!params.shipment_id ? (
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                inputMode="numeric"
                id="shipment_number"
                label="Shipment Number"
                variant="outlined"
                value={params.shipment_number}
                LeftIcon={LocalShipping}
                onChange={handleChangeFilter("shipment_number")}
                onKeyPress={({ key }) => {
                  if (key === "Enter") {
                    handleShipment();
                  }
                }}
              />
            ) : (
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h6" style={{ color: "#000" }}>
                    <span className={classes.rowTitle}>Shipment:</span>{" "}
                    {shipment.shipment_number}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={clearShipment}
                    disabled={isFetching}
                  >
                    <Trash color="#000" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          {packages.length ? (
            <Grid item xs={12}>
              {!selectedPackage.id ? (
                <TextInputWithKeyboard
                  fullWidth
                  autoFocus
                  id="package_name"
                  variant="outlined"
                  label="Package"
                  value={params.package_name}
                  LeftIcon={Archive2}
                  onChange={handleChangeFilter("package_name")}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      handleSelectBox();
                    }
                  }}
                  disabled={isFetching}
                />
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="h6" style={{ color: "#000" }}>
                      <span className={classes.rowTitle}>Package:</span>{" "}
                      {selectedPackage.name} ({getTotalPackedQtyInPackage()})
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={clearPackage}
                      disabled={isFetching}
                    >
                      <Trash color="#000" />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : null}
          {params.package_id ? (
            <Grid item xs={12}>
              {!params.product_id ? (
                <TextInputWithKeyboard
                  fullWidth
                  autoFocus
                  id="product_sku"
                  variant="outlined"
                  label="SKU"
                  value={params.product_sku}
                  LeftIcon={QrCode2}
                  onChange={handleChangeFilter("product_sku")}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      handleSearchProduct();
                    }
                  }}
                  disabled={isFetching}
                />
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="h6" style={{ color: "#000" }}>
                      <span className={classes.rowTitle}>SKU:</span>{" "}
                      {params.product_sku} (
                      {`${getItemPackedQty()}/${getItemPickedQty()}`})
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={clearProduct}
                      disabled={isFetching}
                    >
                      <Trash color="#000" />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : null}
          {params.product_id ? (
            <Grid>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                inputMode="numeric"
                id="quantity"
                variant="outlined"
                label="Quantity"
                value={params.quantity}
                LeftIcon={QrCode2}
                onChange={handleChangeFilter("quantity")}
                onKeyPress={({ key }) => {
                  if (key === "Enter") {
                    handleSubmit();
                  }
                }}
                disabled={isFetching}
              />
            </Grid>
          ) : null}
          {shipment.id ? (
            <>
              {!params.package_id && hasCreatePackagePermission ? (
                <>
                  <CreatePackage
                    open={createModal}
                    handleClose={handleCloseCreateModal}
                    shipment_id={shipment?.id}
                  />
                  <Button
                    className={classNames(classes.packagingButton, classes.red)}
                    variant="contained"
                    onClick={() => setCreateModal(true)}
                    disabled={isFetching}
                  >
                    Create Package
                  </Button>
                </>
              ) : null}
              {params.package_id &&
              !params.product_id &&
              hasCompletePackagePermission ? (
                <>
                  <ConfirmDialog
                    title="Complete Package"
                    open={completePackageDialog}
                    onClose={() => setCompletePackage(false)}
                    onConfirm={handleCompletePackage}
                  />
                  <Button
                    className={classNames(
                      classes.packagingButton,
                      classes.yellow,
                    )}
                    variant="contained"
                    onClick={() => setCompletePackage(true)}
                    disabled={isFetching}
                  >
                    Complete Package
                  </Button>
                </>
              ) : null}
              {params.product_id && hasPackagingPermission ? (
                <Button
                  className={classNames(classes.packagingButton, classes.blue)}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isFetching}
                >
                  Add To Package
                </Button>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Widget>
    </>
  );
}

function CreatePackage({ open, shipment_id, handleClose }) {
  const layoutDispatch = useLayoutDispatch();
  const [packageType, setPackageType] = useState("");

  const handleCreatePackage = () => {
    createShipmentPackage({
      body: {
        shipment_id,
        package_type: packageType,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
        handleClose(true);
      },
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle> Create Package</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="package_type"
            name="package_type"
            value={packageType}
            onChange={({ target: { value } }) => setPackageType(value)}
          >
            <FormControlLabel value="Box" control={<Radio />} label="Box" />
            <FormControlLabel
              value="Pallet"
              control={<Radio />}
              label="Pallet"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreatePackage}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
