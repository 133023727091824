import React, { useCallback, useEffect, useState } from "react";

import _ from "lodash";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getReceipts, printReceiptPDF, updateReceipt } from "api/receipt";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { ConfirmDialog, StatusLabel } from "components/Custom";
import { InputContainer } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { Printer, Timer } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import ReceiptFilter from "./ReceiptFilter";

export default function Receipt(props) {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [receipts, setReceipts] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    number: "",
    container: "",
    invoice: "",
    status: "",
    start_date: "",
    end_date: "",
    hide_completed: true,
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("number");
  const [receiptPDF, setReceiptPDF] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});
  const { permissions } = useUserState();

  const cells = [
    {
      id: "number",
      render: (row, exported) =>
        exported ? (
          row.number
        ) : (
          <Link
            to={{
              pathname: `/app/receipt/${row.id}`,
              state: {
                status: row.status,
                receiptNumber: row.number,
                dockId: row.dock_id,
                container: row.container,
              },
            }}
          >
            {row.number}
          </Link>
        ),
      sortable: true,
    },
    {
      id: "status",
      render: (row, exported) =>
        exported ? row.status : <StatusLabel status={row.status} />,
      sortable: true,
    },
    {
      id: "percentage",
      render: (row, exported) =>
        exported ? (
          `${row.transferred_quantity} / ${row.total_qty} (%${parseInt(
            (row.transferred_quantity / row.total_qty) * 100,
          )})`
        ) : (
          <StatusLabel
            status={
              row.status !== "Completed"
                ? "Open"
                : row.total_qty === row.transferred_quantity
                ? "Active"
                : row.total_qty > row.transferred_quantity
                ? "Cancelled"
                : "Picked"
            }
            text={`${row.transferred_quantity} / ${row.total_qty} (%${parseInt(
              (row.transferred_quantity / row.total_qty) * 100,
            )})`}
          />
        ),
    },
    { id: "container", sortable: true },
    { id: "dock_id", label: "dock", sortable: true },
    { id: "container_type", label: "content", sortable: true },
    { id: "date", sortable: true },
    {
      id: "actions",
      export: false,
      render: (row) => (
        <>
          {row.status !== "Completed" && (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                printReceiptPDF({
                  params: { receipt_id: row.id },
                  responseSetter: (res) => setReceiptPDF(res.pdf),
                  setError,
                })
              }
              startIcon={<Printer color="black" />}
            >
              Print
            </Button>
          )}
          {row.status === "In_Progress" &&
          permissions.includes("receipt.complete") ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleCompleteModalOpen(row)}
              startIcon={<Timer color="black" />}
            >
              Complete
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (receipts.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getReceipts({
        params,
        setError,
        setFunc: (res) => {
          setReceipts(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [rowsPerPage, page, search, receipts.isFetching, orderBy, orderDirection]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setReceipts({ ...receipts, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    debouncedSearchHandler();
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.checked || null });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setReceipts({ ...receipts, isFetching: true });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Receipts-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (receipts.items.length === receipts.count) {
      exportData(receipts.items);
    } else {
      getReceipts({
        params: {
          limit: receipts.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const handleCompleteModalOpen = (row) => {
    setCompleteModalOpen(true);
    setSelectedRow(row);
  };

  const handleComplete = () => {
    updateReceipt({
      id: selectedRow.id,
      body: { status: "Completed" },
      setError,
      responseSetter: (res) => {
        if (res.id) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Receipt status changed.",
            status: "success",
          });
        } else {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Something went wrong.",
            status: "error",
          });
        }
        setReceipts({ ...receipts, isFetching: true });
        setCompleteModalOpen(false);
        setSelectedRow(null);
      },
    });
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    _.debounce(() => {
      setPage(0);
      setReceipts({ ...receipts, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("receipt")} />
      <InputContainer>
        <ReceiptFilter
          handleChangeCheckbox={handleChangeCheckbox}
          handleChangeFilterInput={handleChangeFilterInput}
          params={search}
          clearAdornment={clearAdornment}
        />
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ConfirmDialog
            title="Complete Receiving"
            open={completeModalOpen}
            onClose={() => setCompleteModalOpen(false)}
            onConfirm={handleComplete}
            children={
              <div>
                You are trying to complete container receiving process. This
                action cannot be reverted. Are you sure?
              </div>
            }
          />
          {receiptPDF && (
            <Dialog
              open={!!receiptPDF}
              aria-labelledby="dialog-label"
              onClose={() => setReceiptPDF("")}
            >
              <DialogTitle id="dialog-label">
                Preview of the Dock label
              </DialogTitle>
              <DialogContent>
                <iframe
                  title="Preview"
                  src={`data:application/pdf;base64,${receiptPDF}`}
                  width="100%"
                  height="300px"
                />
              </DialogContent>
            </Dialog>
          )}
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={receipts}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            disableExportData={
              !permissions.includes("receipt.export_data.receipt")
            }
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={{
              ...search,
              is_assigned:
                search.is_assigned === ""
                  ? null
                  : search.is_assigned
                  ? "True"
                  : "False",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
