import * as React from "react";

const SvgSwitchhorizontal = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M8 7h12M20 7l-4-4M20 7l-4 4M16 17H4M4 17l4 4M4 17l4-4" />
    </g>
  </svg>
);

export default SvgSwitchhorizontal;
