import React from "react";

import { Button } from "components/Form";
import { useTranslation } from "react-i18next";

const MobileResetButton = ({ onClick }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    fullWidth
    style={{
      position: "fixed",
      bottom: 65,
      left: "25%",
      zIndex: 999,
      width: "50%",
      height: 30,
    }}
  >
    {useTranslation()[0]("reset")}
  </Button>
);

export default MobileResetButton;
