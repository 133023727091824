import * as React from "react";

const SvgDashboard = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M5 5.5h4v6H5v-6zm10 8h4v6h-4v-6zm-10 4h4v2H5v-2zm10-12h4v2h-4v-2z"
      fill={color}
    />
    <path
      d="M3 13.5h8v-10H3v10zm2-8h4v6H5v-6zm8 16h8v-10h-8v10zm2-8h4v6h-4v-6zm-2-10v6h8v-6h-8zm6 4h-4v-2h4v2zm-16 14h8v-6H3v6zm2-4h4v2H5v-2z"
      fill={color}
    />
  </svg>
);

export default SvgDashboard;
