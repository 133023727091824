import * as React from "react";

const SvgInfo = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3c-4.973 0-9 4.027-9 9s4.027 9 9 9 9-4.027 9-9-4.027-9-9-9Zm.9 13.5h-1.8v-5.4h1.8v5.4Zm0-7.2h-1.8V7.5h1.8v1.8Z"
      fill={color}
    />
  </svg>
);

export default SvgInfo;
