import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  ListAlt,
  LocalShipping,
  QrCode2,
  SnippetFolder,
  SpaceBar,
  Style,
  TextSnippet,
} from "icons";

import {
  assignShipments,
  getShipmentDetails,
  syncPrintedShipments,
} from "api/shipment";

import PageTitle from "components/PageTitle";
import { PickersWithAssignedQty, ProductTypeFilter } from "components/Custom";
import {
  Button as AppButton,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import {
  CarrierRadioGroupInput,
  FormattedTextField,
} from "components/FormElements";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { getCarrierTrackingLink } from "pages/shipments/utils";
import { initialAPIListValue, ProductClasses } from "utils/constants";
import { convertUSDate } from "utils/date";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

const headers = [
  {
    id: "shipment.shipment_number",
    label: "Shipment Number",
    render: (r) => (
      <InternalLink to={`/app/shipments/${r.shipment.id}`}>
        {r.shipment.shipment_number}
      </InternalLink>
    ),
    sortable: true,
  },

  {
    id: "customer",
    sortable: true,
  },
  {
    id: "tracking_number",
    render: (r) => (
      <ExternalLink
        to={getCarrierTrackingLink(r.shipment.ship_via, r.tracking_number)}
      >
        {r.tracking_number}
      </ExternalLink>
    ),
    sortable: true,
  },
  {
    id: "location.code",
    label: "Location",
    render: (r) => r.location.code,
    sortable: true,
  },
  {
    id: "product.sku",
    label: "Product",
    render: (r) => r.product.sku,
    sortable: true,
  },
  {
    id: "quantity",
    sortable: true,
  },
  {
    id: "shipment.created_date",
    label: "Date",
    render: (r) =>
      convertUSDate(new Date(r.shipment.created_date.split(" ")[0])),
    sortable: true,
  },
];

const initialParams = {
  tracking_number: "",
  location_code: "",
  product_sku: "",
  shipment_number: "",
  customer: "",
  batch_type: "",
  class_name: "",
};

export default function EcommerceAssign() {
  const layoutDispatch = useLayoutDispatch();
  const { is_manager, related_product } = parseJwt(getWithExpiry("access"));
  const [shipmentDetails, setShipmentDetails] = useState(initialAPIListValue);
  const [carrierData, setCarrierData] = useState({});
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [selectedPicker, setSelectedPicker] = useState({});
  const [reloadPickers, setReloadPickers] = useState(false);
  const [records, setRecords] = useState([]);
  const [params, setParams] = useState({
    carrier: "FEDEX",
    product_type: related_product || 1, // 1 -> rug
    ...initialParams,
  });
  const [aisles, setAisles] = useState([]);
  const [assignIsLoading, setAssignIsLoading] = useState(false);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("location.code");
  const [syncDisabled, setSyncDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  const groupByAisle = (items) => {
    const groupped = items.reduce(
      (result, item) => ({
        ...result,
        [item["location"]["aisle"]]: [
          ...(result[item["location"]["aisle"]] || []),
          item,
        ],
      }),
      {},
    );

    return groupped;
  };

  const searchFilter = useCallback(
    (row) => {
      if (
        aisles.length &&
        row.location &&
        row.location.aisle &&
        !aisles.includes(row.location.aisle)
      ) {
        return false;
      }

      if (Number(params.product_type) !== Number(row.product_type)) {
        return false;
      }

      if (row.shipment.ship_via.indexOf(params.carrier) === -1) {
        return false;
      }

      if (params.tracking_number) {
        if (row.tracking_number.indexOf(params.tracking_number) === -1) {
          return false;
        }
      }

      if (params.location_code) {
        if (
          row.location.code
            .toLowerCase()
            .indexOf(params.location_code.toLocaleLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (params.product_sku) {
        if (row.product.sku.indexOf(params.product_sku) === -1) {
          return false;
        }
      }

      if (params.shipment_number) {
        if (
          row.shipment.shipment_number.indexOf(params.shipment_number) === -1
        ) {
          return false;
        }
      }

      if (params.customer) {
        if (
          !row.customer ||
          row.customer.toLowerCase().indexOf(params.customer.toLowerCase()) ===
            -1
        ) {
          return false;
        }
      }

      if (params.batch_type) {
        if (params.batch_type === "Rugs") {
          return row.product_type === 1;
        } else if (params.batch_type === "Furniture") {
          return row.product_type === 2;
        } else if (params.batch_type === "Re-Roll") {
          return row.is_packed_goods;
        } else {
          if (row.batch_type !== params.batch_type) {
            return false;
          }
        }
      }

      if (params.class_name) {
        if (
          !row.product ||
          !row.product.class_name ||
          params.class_name !== row.product.class_name
        ) {
          return false;
        }
      }

      return true;
    },
    [params, aisles],
  );

  useEffect(() => {
    if (shipmentDetails.isFetching && params.product_type) {
      getShipmentDetails({
        params: {
          product_type: params.product_type,
          limit: 10000,
          status: "Unassigned",
          shipment__status: "Printed",
          shipment__is_small_carrier: true,
          tracking_number_isnull: false,
        },
        setFunc: ({ items, isFetching, count }) => {
          setShipmentDetails({
            items: _.orderBy(items, "location.code", "asc"),
            isFetching,
            count,
          });
        },
        paginated: true,
      });
    }
  }, [shipmentDetails, params.product_type]);

  useEffect(() => {
    if (shipmentDetails.items.length) {
      const carrierStatistics = {
        FEDEX: 0,
        UPS: 0,
      };

      shipmentDetails.items.forEach((detail) => {
        const carrier =
          detail.shipment && detail.shipment.ship_via
            ? detail.shipment.ship_via.split(" ")[0]
            : null;
        if (carrier) {
          carrierStatistics[carrier] += detail.quantity;
        }
      });
      setCarrierData(carrierStatistics);
      setRecords(groupByAisle(shipmentDetails.items));
    } else {
      setCarrierData({});
    }
  }, [shipmentDetails.items]);

  useEffect(() => {
    setAisles([]);
    setSelectedShipments([]);
    setParams((prev) => ({ ...prev, ...initialParams }));
    setSelectedPicker({});
  }, [params.carrier, params.product_type]);

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams({ ...params, [prop]: value });
    if (prop === "product_type") {
      setShipmentDetails({ ...shipmentDetails, isFetching: true });
    }
  };

  const handleSelectAisle = (key) => {
    if (aisles.includes(key)) {
      setAisles(aisles.filter((aisle) => aisle !== key));
    } else {
      setAisles([...aisles, key]);
    }
  };

  const handleSelectPicker = (e, picker) => {
    if (picker) {
      setSelectedPicker(picker);
    } else {
      setSelectedPicker({});
    }
  };

  const handleAssignShipments = () => {
    const ids = selectedShipments.map((s) => s.id);
    const details = selectedShipments.map((s) => ({
      detail_id: s.id,
      user_id: selectedPicker.user.id,
      quantity: s.quantity,
    }));
    setAssignIsLoading(true);
    assignShipments({
      details,
      responseSetter: (data) => {
        const { success, message } = data;
        setAssignIsLoading(false);
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          return;
        }

        const shipments = shipmentDetails.items.filter(
          (shipment) => !ids.includes(shipment.id),
        );

        setSelectedShipments([]);
        setShipmentDetails({ ...shipmentDetails, items: shipments });

        setSelectedPicker({});
        setReloadPickers(true);
        setParams({ ...params, ...initialParams });

        popupNotification({
          dispatch: layoutDispatch,
          message: "Shipment(s) assigned.",
          status: "success",
        });
      },
    });
  };

  const handleChangeParams = (prop) => ({ target: { value } }) => {
    setParams({ ...params, [prop]: value });
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
  };

  const clearAdornment = (input) => {
    getClearAdornment(input, clearHandler, params);
  };

  useEffect(() => {
    const items = shipmentDetails.items.filter(searchFilter);
    const headerCounts = {
      customer: _.size(_.groupBy(items, "customer")),
      "product.sku": _.size(_.groupBy(items, "product.sku")),
      "location.code": _.size(_.groupBy(items, "location.code")),
      quantity: _.sumBy(items, "quantity"),
    };
    setHeaderCounts(headerCounts);
  }, [searchFilter, shipmentDetails.items]);

  return (
    <>
      <PageTitle
        title="Assign"
        helpText="Ecommerce"
        button={
          is_manager && (
            <Button
              variant="contained"
              color="primary"
              disabled={syncDisabled}
              onClick={() => {
                setSyncDisabled(true);
                syncPrintedShipments({
                  responseSetter: async (response) => {
                    // to prevent multiple syncs
                    setTimeout(() => setSyncDisabled(false), 3000);
                    if (response.success) {
                      popupNotification({
                        dispatch: layoutDispatch,
                        message: "Shipments synced. Page will be refreshed.",
                        status: "success",
                      });
                      setTimeout(() => window.location.reload(), 2000);
                    } else {
                      popupNotification({
                        dispatch: layoutDispatch,
                        message: "Error syncing shipments.",
                        status: "error",
                      });
                    }
                  },
                });
              }}
            >
              Sync
            </Button>
          )
        }
      />
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item lg={4} md={8} sm={12} xs={12}>
          <CarrierRadioGroupInput
            value={params.carrier}
            onChange={(carrier) => setParams({ ...params, carrier })}
            fedexLabel={`FEDEX - (${carrierData["FEDEX"] || 0})`}
            upsLabel={`UPS - (${carrierData["UPS"] || 0})`}
          />
        </Grid>
        <ProductTypeFilter
          lg={2}
          md={4}
          sm={12}
          xs={12}
          value={params.product_type}
          setValue={(value) => setParams({ ...params, product_type: value })}
          onChange={handleChangeFilterInput("product_type")}
        />
      </Grid>

      {params.carrier && params.product_type ? (
        shipmentDetails.isFetching ? (
          <CircularProgress />
        ) : (
          <>
            <InputContainer style={{ marginTop: 20 }}>
              <Grid container>
                <PickersWithAssignedQty
                  xs={12}
                  sm={6}
                  selectedPicker={selectedPicker}
                  onChange={handleSelectPicker}
                  product_type={params.product_type}
                  reload={reloadPickers}
                  style={{
                    border: !selectedPicker.id
                      ? "1px solid #ff5555"
                      : "inherit",
                  }}
                />
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <FormattedTextField
                    fullWidth
                    label="Customer"
                    variant="outlined"
                    value={params.customer}
                    onChange={handleChangeParams("customer")}
                    InputProps={clearAdornment("customer")}
                    isUpperCase={true}
                    LeftIcon={TextSnippet}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInput
                    fullWidth
                    label="Shipment Number"
                    variant="outlined"
                    value={params.shipment_number}
                    onChange={handleChangeParams("shipment_number")}
                    InputProps={clearAdornment("shipment_number")}
                    LeftIcon={SnippetFolder}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <Dropdown
                    id="batch_type"
                    title="Batch Type"
                    value={params.batch_type}
                    onChange={handleChangeParams("batch_type")}
                    items={[
                      "Re-Roll",
                      "Furniture",
                      "Rugs",
                      "SinglePiece",
                      "MultiPiece",
                    ].map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    LeftIcon={Style}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <FormattedTextField
                    fullWidth
                    label="Tracking Number"
                    variant="outlined"
                    value={params.tracking_number}
                    onChange={handleChangeParams("tracking_number")}
                    InputProps={clearAdornment("tracking_number")}
                    isUpperCase={true}
                    LeftIcon={LocalShipping}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <FormattedTextField
                    fullWidth
                    label="Location"
                    variant="outlined"
                    value={params.location_code}
                    onChange={handleChangeParams("location_code")}
                    InputProps={clearAdornment("location_code")}
                    isLocationCode={true}
                    LeftIcon={SpaceBar}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <FormattedTextField
                    fullWidth
                    label="Product SKU"
                    variant="outlined"
                    value={params.product_sku}
                    onChange={handleChangeParams("product_sku")}
                    InputProps={clearAdornment("product_sku")}
                    isProductSKU={true}
                    LeftIcon={QrCode2}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <Dropdown
                    id="class_name"
                    title="Size"
                    value={params.class_name}
                    onChange={handleChangeParams("class_name")}
                    items={ProductClasses}
                    LeftIcon={ListAlt}
                  />
                </Grid>
                <Box
                  component={Grid}
                  item
                  lg={6}
                  md={8}
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  }}
                  style={{ borderRight: "none" }}
                />
                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={6}
                  xs={6}
                  style={{ display: "flex" }}
                >
                  <AppButton
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={
                      !selectedShipments.length ||
                      !selectedPicker.id ||
                      assignIsLoading
                    }
                    onClick={handleAssignShipments}
                  >
                    {assignIsLoading ? <CircularProgress size={10} /> : null}
                    Assign ({selectedShipments.length})
                  </AppButton>
                </Grid>
              </Grid>
            </InputContainer>
            <Grid container spacing={4}>
              <Grid item lg={2} md={3} sm={4} xs={12}>
                <Card>
                  <CardContent>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          {`Aisles (${Object.keys(records).reduce(
                            (total, aisle) =>
                              total +
                              records[aisle]
                                .filter(
                                  (item) =>
                                    item.shipment.ship_via
                                      .toLowerCase()
                                      .indexOf(params.carrier.toLowerCase()) >
                                    -1,
                                )
                                .reduce((t, r) => t + r.quantity, 0),
                            0,
                          )})`}
                        </ListSubheader>
                      }
                    >
                      {Object.keys(records)
                        .filter(
                          (key) =>
                            records[key]
                              .filter(
                                (item) =>
                                  item.shipment.ship_via
                                    .toLowerCase()
                                    .indexOf(params.carrier.toLowerCase()) > -1,
                              )
                              .reduce((t, r) => t + r.quantity, 0) > 0,
                        )
                        .map((key) => (
                          <ListItem
                            key={key}
                            onClick={() => handleSelectAisle(key)}
                            selected={aisles.includes(key)}
                          >
                            <ListItemText primary={key} />
                            <ListItemSecondaryAction>
                              (
                              {records[key]
                                .filter(
                                  (item) =>
                                    item.shipment.ship_via
                                      .toLowerCase()
                                      .indexOf(params.carrier.toLowerCase()) >
                                    -1,
                                )
                                .reduce((t, r) => t + r.quantity, 0)}
                              )
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={10} md={9} sm={8}>
                <DefaultTable
                  headers={headers}
                  headerCounts={headerCounts}
                  data={{
                    items: shipmentDetails.items.filter(searchFilter),
                  }}
                  hasCheckBox={true}
                  selected={selectedShipments}
                  setSelected={setSelectedShipments}
                  sortActions={{
                    onSort: (header) => {
                      const isAsc =
                        orderBy === header && orderDirection === "asc";
                      const newDirection = isAsc ? "desc" : "asc";
                      setOrderDirection(newDirection);
                      setOrderBy(header);
                      setShipmentDetails({
                        ...shipmentDetails,
                        items: _.orderBy(
                          shipmentDetails.items,
                          [header],
                          [newDirection],
                        ),
                      });
                    },
                    orderBy,
                    orderDirection,
                  }}
                  filters={params}
                />
              </Grid>
            </Grid>
          </>
        )
      ) : (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alert severity="info">
              Please select product type and carrier!
            </Alert>
          </Grid>
        </Grid>
      )}
    </>
  );
}
