import React, { useEffect, useState } from "react";

import {
  Avatar,
  Drawer,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";

import {
  Close,
  ListAlt,
  Printer,
  ExpandMore,
  X,
  Refresh2,
  Trash,
  EyeOff,
} from "icons";
import useStyles from "./drawerStyle";
import useLogoStyles from "./styles";
import { Spacer, StatusLabel } from "components/Custom";
import { timezoneFormat } from "utils/date";
import { initialAPIListValue } from "utils/constants";
import { getShipmentDetails } from "api/shipment";
import { ExternalLink } from "components/Custom/Links";
import { getCarrierTrackingLink } from "./utils";
import {
  DeleteShipmentDialog,
  HideShipmentsDialog,
  RequestCancellationDialog,
  ShipmentCancelModal,
} from "./ShipmentActions";
import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";

export default function ShipmentDrawer({
  open,
  onClose,
  shipment,
  onAction,
  fetchCounts,
  isLoading,
}) {
  const classes = useStyles();
  const [cancelId, setCancelId] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [hideId, setHideId] = useState(false);
  const isDelete = shipment.status === "Cancelled";
  const isPendingCancellation =
    shipment.status === "Pending_Cancellation" || shipment.status === "Errored";
  const showHideButton =
    shipment.track_status === "Warning" &&
    !["Cancelled", "Pending_Cancellation"].includes(shipment.status);

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="persistent"
      PaperProps={{
        className: classes.drawerPaper, // React-admin passes the classes to the <Paper>
      }}
    >
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Order Detail</Typography>
          <IconButton
            disableRipple={true}
            onClick={() => onClose(false)}
            className={classes.orange}
          >
            <Close color="#FFF" />
          </IconButton>
        </div>
        <div className={classes.detailFrame}>
          <List key={`detailFrameList-${shipment.id}`}>
            <ListItem key={`detailFrameListItem-${shipment.id}`}>
              <ListItemAvatar key="detailFrameAvatar">
                <Avatar className={classes.purple}>
                  <ListAlt color="#FFF" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={shipment.order.number}
                secondary={shipment.order.customer_id}
              />
              {[
                "Open",
                "Confirmed",
                "Assigned",
                "Printed",
                "Picked",
                "Shipped",
                "Invoiced",
                "Pending_Cancellation",
                "Cancelled",
                "Errored",
              ].includes(shipment.status) ? (
                <>
                  {deleteId && shipment.status === "Cancelled" ? (
                    <DeleteShipmentDialog
                      shipment_number={deleteId}
                      close={() => setDeleteId(false)}
                      afterDeleted={() => {
                        onClose(true);
                        fetchCounts();
                      }}
                    />
                  ) : cancelId ? (
                    !isPendingCancellation ? (
                      <RequestCancellationDialog
                        shipment_id={cancelId}
                        close={() => setCancelId(false)}
                        afterCancelled={() => {
                          onClose(true);
                          fetchCounts();
                        }}
                      />
                    ) : (
                      <ShipmentCancelModal
                        shipment_id={cancelId}
                        close={() => setCancelId(false)}
                        afterCancelled={() => {
                          onClose(true);
                          fetchCounts();
                        }}
                      />
                    )
                  ) : null}
                  {showHideButton && hideId ? (
                    <HideShipmentsDialog
                      shipment_id={hideId}
                      close={() => setHideId(false)}
                      afterHidden={() => {
                        onClose(true);
                        fetchCounts();
                      }}
                    />
                  ) : null}
                  <ListItemSecondaryAction>
                    {!["Shipped", "Invoiced"].includes(shipment.status) ? (
                      <div className={classes.secondaryActButtonWrapper}>
                        <Tooltip
                          title={
                            isDelete
                              ? "Delete"
                              : isPendingCancellation
                              ? "Cancel"
                              : "Pending Cancellation"
                          }
                          aria-label="cancel-button"
                        >
                          <IconButton
                            className={classes.secondaryActPrintButton}
                            style={{
                              backgroundColor: isLoading
                                ? "white"
                                : isPendingCancellation || isDelete
                                ? "red"
                                : "orange",
                            }}
                            aria-label="cancel"
                            onClick={() =>
                              isDelete
                                ? setDeleteId(shipment.shipment_number)
                                : setCancelId(shipment.id)
                            }
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <CircularProgress size={20} />
                            ) : isDelete ? (
                              <Trash color="#FFF" />
                            ) : (
                              <X color="#FFF" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null}
                    {shipment.track_status === "Warning" && showHideButton ? (
                      <div className={classes.secondaryActButtonWrapper}>
                        <Tooltip
                          title="Hide Shipment"
                          aria-label="hide-shipment-button"
                        >
                          <IconButton
                            className={classes.secondaryActPrintButton}
                            aria-label="hide-label"
                            onClick={() => setHideId(shipment.id)}
                            disabled={isLoading}
                            style={{
                              backgroundColor: isLoading ? "white" : "#ff5722",
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <EyeOff color="#FFF" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null}
                    {shipment.status !== "Errored" ? (
                      <div className={classes.secondaryActButtonWrapper}>
                        <Tooltip
                          title="Print Labels"
                          aria-label="print-labels-button"
                        >
                          <IconButton
                            className={classes.secondaryActPrintButton}
                            aria-label="print-label"
                            onClick={() =>
                              onAction(shipment.status, [
                                shipment.shipment_number,
                              ])
                            }
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Printer color="#FFF" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={classes.secondaryActButtonWrapper}>
                        <Tooltip title="Retry" aria-label="retry-button">
                          <IconButton
                            className={classes.secondaryActPrintButton}
                            aria-label="retry-label"
                            onClick={() =>
                              onAction(shipment.status, [
                                shipment.shipment_number,
                              ])
                            }
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Refresh2 color="#FFF" size={0.9} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </ListItemSecondaryAction>
                </>
              ) : null}
            </ListItem>
          </List>
          {shipment.status === "Errored" ? (
            <Grid container spacing={1} className={classes.paper}>
              <LineItem title="Error Source" value={shipment.error_source} />
              <LineItem title="Error Message" value={shipment.error_message} />
            </Grid>
          ) : null}
          <Divider variant="middle" />
        </div>

        <ShipmentAccordion shipment={shipment} />
        <ItemsAccordion shipmentId={shipment.id} />
        <CustomerAccordion shipment={shipment} />
      </div>
    </Drawer>
  );
}

const LineItem = ({ title, value, complexItem = false }) => (
  <>
    <Grid item xs={7}>
      <Typography variant="subtitle2" color="textPrimary">
        {title}
      </Typography>
    </Grid>
    <Grid item xs={5}>
      {complexItem ? (
        <div style={{ float: "right" }}>{value}</div>
      ) : (
        <Typography
          variant="body2"
          align="right"
          color="textSecondary"
          style={{ fontWeight: "bold" }}
        >
          {value}
        </Typography>
      )}
    </Grid>
  </>
);

function ShipmentAccordion({ shipment }) {
  const classes = useStyles();
  const logoClasses = useLogoStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.heading}>
              Shipment - {shipment.shipment_number}
            </Typography>
          </Grid>
          <Grid item>
            <StatusLabel status={shipment.status} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <LineItem
            title="Date"
            value={timezoneFormat(shipment.created_date)}
          />
          <LineItem
            title="Ship Via"
            value={
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                  {shipment.ship_via.split(" ")[0] === "FEDEX" ? (
                    <Avatar
                      src={FedexLogo}
                      className={logoClasses.fedexLogoSmall}
                    />
                  ) : (
                    <Avatar
                      src={UpsLogo}
                      className={logoClasses.upsLogoSmall}
                    />
                  )}
                </Grid>
                <Grid item>
                  {shipment.ship_via
                    .split(" ")
                    .filter((r) => !["FEDEX", "UPS"].includes(r))
                    .join(" ")}
                </Grid>
              </Grid>
            }
            complexItem
          />
          <LineItem
            title="Tracking"
            value={
              <TrackingNumber
                shipVia={shipment.ship_via}
                trackingNumber={shipment.tracking_number}
              />
            }
          />
          <LineItem title="Cost" value={`$${shipment.shipment_cost}`} />
          <LineItem
            title="Address"
            value={
              <>
                {shipment.address.line_1} {shipment.address.line_2} <br />
                {shipment.address.city} {shipment.address.zip_code},{" "}
                {shipment.address.state} {shipment.address.country}
              </>
            }
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const Row = ({ children, justifyContent = "space-between" }) => {
  return (
    <Grid item lg={12}>
      <div
        style={{
          display: "flex",
          justifyContent,
        }}
      >
        {children}
      </div>
    </Grid>
  );
};

const TrackingNumber = ({ shipVia, trackingNumber }) => {
  return (
    <ExternalLink
      to={getCarrierTrackingLink(shipVia, trackingNumber)}
      style={{ float: "right" }}
    >
      {trackingNumber}
    </ExternalLink>
  );
};

function ItemCard({ item }) {
  const locations = item.group.map((group) => group.location);
  const locationsWithQty = locations.reduce((data, location) => {
    if (data[location]) {
      data[location] += 1;
    } else {
      data[location] = 1;
    }
    return data;
  }, {});
  const box = item.group[0];
  const boxWidth = box.BoxWidth.value;
  const boxLength = box.BoxLength.value;
  const boxHeight = box.BoxHeight.value;
  const boxWeight = box.Weight.value;

  let differentItems = false;
  for (let i = 0; i < item.group.length; i++) {
    if (item.group[i].item_id !== item.product.sku) {
      differentItems = true;
      break;
    }
  }

  return (
    <div key={item.id}>
      <Grid container spacing={1}>
        <Row>
          <Typography variant="h4">{item.product.sku}</Typography>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {item.group.length}
          </Typography>
        </Row>
        <Row>
          <Typography variant="body2" align="right" color="textSecondary">
            Tracking
          </Typography>
          <Grid container>
            {item.labels.map((label) => (
              <Grid item key={label.tracking_number} xs={12}>
                <TrackingNumber
                  shipVia={item.shipment.ship_via}
                  trackingNumber={label.tracking_number}
                />
              </Grid>
            ))}
          </Grid>
        </Row>
        <Row>
          <Typography variant="body2" align="right" color="textSecondary">
            Location
          </Typography>

          <Grid item>
            {Object.entries(locationsWithQty).map(([location, qty], index) => (
              <div key={index}>
                {differentItems
                  ? item.group.filter((i) => i.location === location)[0]
                      .item_id + " > "
                  : ""}
                <strong>{qty}</strong>@{location}
              </div>
            ))}
          </Grid>
        </Row>
        <Row>
          <Typography variant="body2" align="right" color="textSecondary">
            Dims (L x W x H)
          </Typography>
          <div style={{ fontWeight: "bold" }}>
            {boxLength}" x {boxWidth}" x {boxHeight}"
          </div>
        </Row>
        <Row>
          <Typography variant="body2" align="right" color="textSecondary">
            Weight
          </Typography>
          <div style={{ fontWeight: "bold" }}>{boxWeight.toFixed(2)} (lbs)</div>
        </Row>
      </Grid>
      <Spacer height="1rem" />
    </div>
  );
}

function ItemsAccordion({ shipmentId }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [details, setDetails] = useState(initialAPIListValue);

  useEffect(() => {
    if (shipmentId) {
      setDetails(initialAPIListValue);
      getShipmentDetails({
        params: {
          shipment_id: shipmentId,
        },
        setFunc: setDetails,
        paginated: true,
      });
    }
  }, [shipmentId]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.heading}> Items </Typography>
          </Grid>
          {details.items.length ? (
            <Grid item>
              <Typography className={classes.heading}>
                {details.items.filter((item) => item.group).length} /{" "}
                {details.items.reduce(
                  (total, detail) => total + detail.quantity,
                  0,
                )}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {details.isFetching ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            {details.items.map((row) =>
              row.group ? <ItemCard key={row.id} item={row} /> : null,
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

function CustomerAccordion({ shipment }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const order = shipment.order;
  const customer_name = shipment.address.customer;
  const phone = shipment.address.phone;

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.heading}>Customer</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <LineItem title="Order Number" value={order.po_number} />
          <LineItem title="Reference Number" value={order.reference_number} />
          <LineItem title="Name" value={customer_name} />
          <LineItem title="Phone" value={phone} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
