import * as React from "react";

const SvgQrcode = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M13 4a1 1 0 1 0-2 0h2ZM11 5a1 1 0 1 0 2 0h-2ZM18 15a1 1 0 1 0 0 2v-2ZM20 17a1 1 0 1 0 0-2v2ZM12 16v-1a1 1 0 0 0-1 1h1ZM14 17a1 1 0 1 0 0-2v2ZM11 20a1 1 0 1 0 2 0h-2ZM13 9a1 1 0 1 0-2 0h2ZM12 12h-1a1 1 0 0 0 1 1v-1ZM16 19a1 1 0 1 0 0 2v-2ZM20 21a1 1 0 1 0 0-2v2ZM4 11a1 1 0 1 0 0 2v-2ZM8 13a1 1 0 1 0 0-2v2ZM12.01 13a1 1 0 1 0 0-2v2ZM16.01 13a1 1 0 1 0 0-2v2ZM20 11a1 1 0 1 0 0 2v-2ZM20.01 13a1 1 0 1 0 0-2v2ZM5 5h2V3H5zM7 5v2h2V5zM7 7H5v2h2zM5 7V5H3v2zM5 7H3a2 2 0 0 0 2 2V7ZM7 7v2a2 2 0 0 0 2-2H7ZM7 5h2a2 2 0 0 0-2-2v2ZM5 3a2 2 0 0 0-2 2h2V3ZM17 5h2V3h-2zM19 5v2h2V5zM19 7h-2v2h2zM17 7V5h-2v2zM17 7h-2a2 2 0 0 0 2 2V7ZM19 7v2a2 2 0 0 0 2-2h-2ZM19 5h2a2 2 0 0 0-2-2v2ZM17 3a2 2 0 0 0-2 2h2V3ZM5 17h2v-2H5zM7 17v2h2v-2zM7 19H5v2h2zM5 19v-2H3v2zM5 19H3a2 2 0 0 0 2 2v-2ZM7 19v2a2 2 0 0 0 2-2H7ZM7 17h2a2 2 0 0 0-2-2v2ZM5 15a2 2 0 0 0-2 2h2v-2ZM11 4v1h2V4zM18 17h2v-2h-2zM12 17h2v-2h-2z" />
      <path d="M11 16v4h2v-4zM11 9v3h2V9zM16 21h4v-2h-4zM4 13h4v-2H4z" />
      <path d="M12 13h.01v-2H12zM20 13h.01v-2H20z" />
      <path d="M12 13h4.01v-2H12z" />
    </g>
  </svg>
);

export default SvgQrcode;
