import s3 from "utils/aws";

const bucketName = process.env.REACT_APP_BUCKET_NAME || "tayse-wms-dev";

const generateAWSURL = (key) => {
  return `https://${bucketName}.s3.amazonaws.com/${key}`;
};

const getSignedMediaURL = (imageURL, initials) => {
  let avatarURL;
  if (process.env.REACT_APP_AWS_KEY && process.env.REACT_APP_AWS_SECRET) {
    const key = imageURL.split("amazonaws.com/")[1];
    const signedUrlExpireSeconds = 60 * 5;
    avatarURL = s3.getSignedUrl("getObject", {
      Bucket: bucketName,
      Key: key,
      Expires: signedUrlExpireSeconds,
    });
  }
  if (!avatarURL) {
    avatarURL = `https://via.placeholder.com/150x150.png?text=${initials}`;
  }
  return avatarURL;
};

export { getSignedMediaURL, generateAWSURL };
