import { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

import _ from "lodash";

import { getProductsWithAttributes } from "api/products";
import { createReturn, getReturns } from "api/shipment";

import { ConfirmDialog } from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { Camera, Edit, SpaceBar } from "icons";

import { CONDITIONS } from "utils/constants";
import { toBase64Handler } from "utils/base64";

import useStyles from "./styles";

const initialParams = {
  customer: "",
  po_number: "",
  tracking_number: "",
  product_id: "",
  product_sku: "",
  condition: "",
  images: [],
  step: "",
  note: "",
};

const steps = ["", "po", "customer", "product", "condition", "note", "images"];
let lastStep = "";

export default function Return() {
  const classes = useStyles();
  const layoutDispatch = useLayoutDispatch();
  const [params, setParams] = useState(initialParams);
  const [isFetching, setIsFetching] = useState(false);
  const [modal, setModal] = useState(false);

  const handleTrackingNumber = (value) => {
    if (!value) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Tracking number cannot be null!",
      });
    }

    getReturns({
      params: {
        tracking_number_iexact: value,
      },
      responseSetter: (res) => {
        const { results } = res;

        if (!results.length) {
          return setParams((prev) => ({
            ...prev,
            tracking_number: value,
            step: lastStep,
          }));
        }

        const row = results[0];
        if (row.is_published) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "The return has already been published.",
          });
        }

        lastStep = "product";
        return setParams((prev) => ({
          ...prev,
          tracking_number: results[0].tracking_number,
          po_number: results[0].po_number,
          customer: results[0].customer,
          note: results[0].note || " ",
          step: "product",
        }));
      },
    });
  };

  const handleChangeProduct = () => {
    setParams((prev) => ({
      ...prev,
      product_id: "",
      product_sku: "",
      condition: "",
      step: "product",
    }));
  };

  const handleChangePoNumber = (value) => {
    if (!value) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "PO Number field cannot be null!",
      });
    }
    if (steps.indexOf("customer") >= steps.indexOf(lastStep)) {
      lastStep = "customer";
    }
    setParams((prev) => ({ ...prev, po_number: value, step: lastStep }));
  };

  const handleProductSubmit = (value) => {
    if (!value) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Value cannot be null!",
      });
    }
    value = value.split(",")[0].trim();
    getProductsWithAttributes({
      params: {
        value_iexact: value,
      },
      responseSetter: (res) => {
        const { results } = res;
        if (!results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Product not found!",
          });
        }

        if (results.length > 1) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "More than one product found!",
          });
        }

        setParams((prev) => ({
          ...prev,
          product_sku: value,
          product_id: results[0].product,
          step: "condition",
        }));
      },
    });
  };

  const handleChangeCustomer = (value) => {
    if (!value) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Customer field cannot be null!",
      });
    }
    if (steps.indexOf("product") >= steps.indexOf(lastStep)) {
      lastStep = "product";
    }
    setParams((prev) => ({ ...prev, customer: value, step: lastStep }));
  };

  const handleCapture = ({ target }) => {
    if (target.files) {
      let files = Array.from(target.files);
      const images = files.map((file) => {
        return {
          name: file.name,
          url: URL.createObjectURL(file),
          file: file,
        };
      });

      const uniqueImages = _.uniqBy(
        [...params.images, ...images],
        (image) => image.name,
      );
      setParams((prev) => ({ ...prev, images: uniqueImages }));
    }
  };

  const handleRemoveFile = (file) => {
    setParams((prev) => ({
      ...prev,
      images: prev.images.filter((i) => i.name !== file.name),
    }));
  };

  const handleSubmit = async () => {
    const {
      tracking_number,
      customer,
      po_number,
      product_id,
      condition,
      note,
    } = params;
    setIsFetching(true);
    const images = await toBase64Handler(params.images.map((i) => i.file));
    createReturn({
      body: {
        tracking_number,
        customer,
        po_number,
        product_id,
        condition,
        images,
        note,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { message, success } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        lastStep = "";
        setParams(initialParams);
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  return (
    <>
      <PageTitle title="Return" />
      <Widget>
        <Grid container spacing={1}>
          {!params.tracking_number ? (
            <Grid item xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                id="tracking_number"
                variant="outlined"
                label="Tracking Number"
                LeftIcon={SpaceBar}
                onKeyPress={({ target, key }) => {
                  if (key === "Enter") {
                    if (steps.indexOf("po") >= steps.indexOf(lastStep)) {
                      lastStep = "po";
                    }
                    handleTrackingNumber(target.value);
                  }
                }}
                hasBorder
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    Tracking :{" "}
                    <strong style={{ fontSize: "15px" }}>
                      {params.tracking_number}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setParams({ ...params, tracking_number: "", step: "" })
                    }
                    disabled={isFetching}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          {params.step === "po" ? (
            <Grid item xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                id="po_number"
                variant="outlined"
                label="PO Number"
                LeftIcon={SpaceBar}
                onKeyPress={({ target, key }) =>
                  key === "Enter" ? handleChangePoNumber(target.value) : null
                }
                hasBorder
              />
            </Grid>
          ) : params.po_number ? (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    PO Number : <strong>{params.po_number}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setParams({ ...params, step: "po" })}
                    disabled={isFetching}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {params.step === "customer" ? (
            <Grid item xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                id="customer"
                variant="outlined"
                label="Customer"
                LeftIcon={SpaceBar}
                onKeyPress={({ target, key }) =>
                  key === "Enter" ? handleChangeCustomer(target.value) : null
                }
                hasBorder
              />
            </Grid>
          ) : params.customer ? (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    Customer : <strong>{params.customer}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setParams({ ...params, step: "customer" })}
                    disabled={isFetching}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {params.step === "product" ? (
            <Grid item xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                id="product_id"
                variant="outlined"
                label="SKU"
                placeholder={params.product}
                LeftIcon={SpaceBar}
                onKeyPress={({ target, key }) => {
                  if (key === "Enter") {
                    if (steps.indexOf("condition") >= steps.indexOf(lastStep)) {
                      lastStep = "condition";
                    }
                    handleProductSubmit(target.value);
                  }
                }}
                hasBorder
              />
            </Grid>
          ) : params.product_sku ? (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography>
                  SKU : <strong>{params.product_sku}</strong>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={handleChangeProduct}
                  disabled={isFetching}
                >
                  <Edit />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}

          {params.step === "condition" ? (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="condition"
                  name="condition"
                  onChange={({ target: { value } }) => {
                    setParams((prev) => ({ ...prev, condition: value }));
                  }}
                >
                  {CONDITIONS.map((condition) => (
                    <FormControlLabel
                      key={condition}
                      value={condition}
                      control={<Radio />}
                      label={condition}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : params.condition ? (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    Condition : <strong>{params.condition}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setParams({ ...params, step: "condition" })}
                    disabled={isFetching}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {params.step === "note" ? (
            <Grid item xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                id="note"
                variant="outlined"
                label="Note"
                LeftIcon={SpaceBar}
                onKeyPress={({ target, key }) => {
                  if (key === "Enter") {
                    if (steps.indexOf("images") >= steps.indexOf(lastStep)) {
                      lastStep = "images";
                    }
                    setParams({
                      ...params,
                      note: target.value,
                      step: lastStep,
                    });
                  }
                }}
                hasBorder
              />
            </Grid>
          ) : params.note ||
            steps.indexOf(params.step) > steps.indexOf("note") ? (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    Note : <strong>{params.note}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setParams({ ...params, step: "note" })}
                    disabled={isFetching}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {!["", "condition"].includes(params.step) ? (
            <Grid item xs={12}>
              {params.images.length ? (
                <div className={classes.root}>
                  <ImageList
                    rowHeight={100}
                    className={classes.imageList}
                    cols={2}
                  >
                    {params.images.map((file) => (
                      <ImageListItem key={file.name}>
                        <img src={file.url} alt={file.title} />
                        <ImageListItemBar
                          className={classes.itemBar}
                          title={
                            <Button
                              fullWidth
                              style={{
                                color: "#FFF",
                              }}
                              onClick={() => handleRemoveFile(file)}
                              disabled={isFetching}
                            >
                              Remove
                            </Button>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              ) : null}

              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                capture="environment"
                style={{
                  display: "none",
                }}
                onChange={handleCapture}
                multiple
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container spacing={2} className={classes.bottomButtonsGrid}>
          {["condition", "note"].includes(params.step) ? (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  const lastStepTest =
                    params.step === "condition" ? "images" : "product";
                  if (steps.indexOf(lastStepTest) >= steps.indexOf(lastStep)) {
                    lastStep = lastStepTest;
                  }
                  setParams((prev) => ({
                    ...prev,
                    step:
                      params.step === "condition"
                        ? params.note
                          ? "images"
                          : "note"
                        : params.condition
                        ? "images"
                        : "condition",
                  }));
                }}
                disabled={
                  params.step === "condition" ? !params.condition : false
                }
              >
                Next{" "}
                {params.images.length && params.step === "condition"
                  ? `(${params.images.length} images)`
                  : ""}
              </Button>
            </Grid>
          ) : null}

          {params.step === "product" ? (
            <Button
              variant="contained"
              className={classes.notOursButton}
              onClick={() => {
                setParams((prev) => ({ ...prev, step: "images" }));
              }}
            >
              Not Ours
            </Button>
          ) : null}
          {!["", "condition"].includes(params.step) ? (
            <>
              {isFetching ? (
                <CircularProgress />
              ) : (
                <>
                  <Grid item>
                    <label htmlFor="icon-button-file">
                      <Button
                        color="secondary"
                        variant="contained"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Camera fontSize="large" color="#FFF" />
                        Add Image
                      </Button>
                    </label>
                  </Grid>
                  {params.step === "images" && (
                    <>
                      <ConfirmDialog
                        open={modal}
                        title="Create Return"
                        confirmText="Save"
                        children={
                          params.condition !== "Good" &&
                          !params.images.length &&
                          params.product_id
                            ? "You didn't add any photos. Do you still want to confirm it?"
                            : null
                        }
                        isLoading={isFetching}
                        onConfirm={handleSubmit}
                        onClose={() => setModal(false)}
                      />
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setModal(true)}
                        >
                          Save
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          ) : null}
        </Grid>
      </Widget>
    </>
  );
}
