import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

import { updateLocation } from "api/locations";

import { Checkbox } from "components/Form";
import { SelectBox } from "components/FormElements";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { LocationTypes } from "utils/constants";

export default function LocationUpdateModal({ onClose, location }) {
  const layoutDispatch = useLayoutDispatch();
  const [data, setData] = useState({
    ...location,
    location_type: LocationTypes.find(
      (l) => l.label === location.location_type_display,
    ).value,
  });
  const [isFetching, setIsFetching] = useState(false);

  const handleChangeFilter = (prop) => ({ target: { value } }) => {
    setData((prev) => ({ ...prev, [prop]: value }));
  };

  const handleCheckBox = (prop) => () => {
    setData((prev) => ({ ...prev, [prop]: !data[prop] }));
  };

  const handleSubmit = () => {
    const { code, aisle, bin, level, location_type } = data;
    if (!code || !aisle || !bin || !level) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Code, aisle, bin, or level cannot be null!",
      });
    }
    setIsFetching(true);
    updateLocation({
      id: data.id,
      body: {
        code,
        aisle,
        bin,
        level,
        location_type,
        is_sales_allowed: data.is_sales_allowed,
        is_multiple_sku_allowed: data.is_multiple_sku_allowed,
        countable: data.countable,
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        onClose(true);
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => !isFetching && onClose(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{location.code}</DialogTitle>
      <DialogContent>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <TextField
                label="Location Code"
                variant="outlined"
                fullWidth
                value={data.code}
                onChange={handleChangeFilter("code")}
              />
            </Grid>
            <Grid item lg={6}>
              <SelectBox
                title="Location Type"
                items={LocationTypes}
                value={data.location_type}
                onChange={handleChangeFilter("location_type")}
                all_option_active={false}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                label="Aisle"
                variant="outlined"
                fullWidth
                value={data.aisle}
                onChange={handleChangeFilter("aisle")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                label="Bin"
                variant="outlined"
                fullWidth
                value={data.bin}
                onChange={handleChangeFilter("bin")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                label="Level"
                variant="outlined"
                fullWidth
                value={data.level}
                onChange={handleChangeFilter("level")}
              />
            </Grid>
            <Grid item lg={4}>
              <Checkbox
                label="Countable"
                checked={data.countable}
                onChange={handleCheckBox("countable")}
              />
            </Grid>
            <Grid item lg={4}>
              <Checkbox
                label="Multiple SKU Allowed"
                checked={data.is_multiple_sku_allowed}
                onChange={handleCheckBox("is_multiple_sku_allowed")}
              />
            </Grid>
            <Grid item lg={4}>
              <Checkbox
                label="Sales Allowed"
                checked={data.is_sales_allowed}
                onChange={handleCheckBox("is_sales_allowed")}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onClose(false)}
          disabled={isFetching}
        >
          Close
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isFetching}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
