import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import {
  Calculator,
  X as Close,
  MarkUnreadMailbox,
  Scale,
  MergeType,
  Truck,
} from "icons";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { Dropdown, TextInput } from "components/Form";

const useStyles = makeStyles(() => ({
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingBottom: 20,
  },
  actionButton: {
    width: "100%",
  },
  result: {
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  mt10: {
    marginTop: 10,
  },
}));

const ServiceTypes = [
  {
    value: "true",
    label: "FEDEX HOME DELIVERY",
  },
  {
    value: "false",
    label: "FEDEX GROUND",
  },
];

const RatesModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [search, setSearch] = useState({
    residential: "true",
    from: "30701", // Calhoun
    to: "80202", // Denver
    weight: "",
    length: "",
    width: "",
    height: "",
  });
  const [rateResult, setRateResult] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const layoutDispatch = useLayoutDispatch();

  const handleGet = () => {
    setIsLoading(true);
    const queryparams = `?residential=${search.residential}&from=${search.from}&to=${search.to}&weight=${search.weight}&length=${search.length}&width=${search.width}&height=${search.height}`;
    fetch(`${process.env.REACT_APP_API_URL}/api/custom/rates${queryparams}`, {
      method: "GET",
    })
      .then(async (res) => {
        setIsLoading(false);
        const jsonRes = await res.json();
        if (res.status === 200) {
          setRateResult(jsonRes.data);
        } else {
          popupNotification({
            dispatch: layoutDispatch,
            message: jsonRes.message,
          });
        }
      })
      .catch(() => {
        onClose();
      });
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeFilterSelect = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
  };

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Fedex Rates / Quotes
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <Dropdown
              all_option_active={false}
              id="service_type"
              title="Service Type"
              value={search.residential}
              onChange={handleChangeFilterSelect("residential")}
              items={ServiceTypes}
              LeftIcon={Truck}
            />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="from"
                  label="From Zip"
                  value={search.from}
                  onChange={handleChangeFilterInput("from")}
                  LeftIcon={MarkUnreadMailbox}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="to"
                  label="To Zip"
                  value={search.to}
                  onChange={handleChangeFilterInput("to")}
                  LeftIcon={MarkUnreadMailbox}
                  hasBorder
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.mt10}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="weight"
                  label="Weight (LB)"
                  value={search.weight}
                  onChange={handleChangeFilterInput("weight")}
                  LeftIcon={Scale}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="length"
                  label="Length (IN)"
                  value={search.length}
                  onChange={handleChangeFilterInput("length")}
                  LeftIcon={MergeType}
                  hasBorder
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.mt10}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="width"
                  label="Width (IN)"
                  value={search.width}
                  onChange={handleChangeFilterInput("width")}
                  LeftIcon={MergeType}
                  hasBorder
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="height"
                  label="Height (IN)"
                  value={search.height}
                  onChange={handleChangeFilterInput("height")}
                  LeftIcon={MergeType}
                  hasBorder
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
              className={classes.mt10}
            >
              <Grid item xs={10}>
                <Button
                  onClick={handleGet}
                  variant="contained"
                  color="secondary"
                  size="small"
                  endIcon={isLoading ? null : <Calculator color="white" />}
                  className={classes.actionButton}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={25} /> : "Get The Rate"}
                </Button>
              </Grid>
              <Grid item xs={2} className={classes.result}>
                <span>${parseFloat(rateResult).toFixed(2)}</span>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={onClose}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatesModal;
