import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";

import { updatePromotion } from "api/promotion";

import { DatePicker } from "components/Form";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

export default function PromotionUpdateModal({ onClose, promotion }) {
  const layoutDispatch = useLayoutDispatch();
  const [data, setData] = useState({
    ...promotion,
  });
  const [isFetching, setIsFetching] = useState(false);

  const handleChangeFilter = (prop) => ({ target: { value } }) => {
    setData((prev) => ({ ...prev, [prop]: value }));
  };

  const handleSubmit = () => {
    const { title, start_date, end_date, promo_price, discount } = data;
    if (!title || !start_date || !end_date || !discount || !promo_price) {
      return popupNotification({
        dispatch: layoutDispatch,
        message:
          "Title, Start Date, End Date, Promotion Price, Discount cannot be empty!",
      });
    }
    setIsFetching(true);
    updatePromotion({
      id: data.id,
      body: {
        title,
        start_date,
        end_date,
        promo_price,
        discount,
      },
      responseSetter: (res) => {
        if (!res.id) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Update error!",
          });
        }

        onClose(true);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Success!",
          status: "success",
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => !isFetching && onClose(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{promotion.title}</DialogTitle>
      <DialogContent>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                value={data.title}
                onChange={handleChangeFilter("title")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                id="start_date"
                title="Start Date"
                value={data.start_date}
                onChange={handleChangeFilter("start_date")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                id="end_date"
                title="End Date"
                value={data.end_date}
                onChange={handleChangeFilter("end_date")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="promo_price">Promotion Price</InputLabel>
                <OutlinedInput
                  id="promo_price"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={data.promo_price}
                  onChange={handleChangeFilter("promo_price")}
                  labelWidth={120}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="discount">Discount</InputLabel>
                <OutlinedInput
                  id="discount"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={data.discount}
                  onChange={handleChangeFilter("discount")}
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onClose(false)}
          disabled={isFetching}
        >
          Close
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            isFetching ||
            (data.title === promotion.title &&
              data.start_date === promotion.start_date &&
              data.end_date === promotion.end_date &&
              data.promo_price === promotion.promo_price &&
              data.discount === promotion.discount)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
