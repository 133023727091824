import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";

import { getAssignedShipmentsCountWithGroup } from "api/shipment";

import DefaultTable from "components/Table";

import { useLayoutState } from "context/LayoutContext";

import { initialAPIListValue } from "utils/constants";
import { compareValues } from "utils/sort";

import useStyles from "./styles";

const headers = [
  {
    id: "tracking_number",
    render: (r) => (
      <Link
        to={{
          pathname: "/app/ecommerce/pick",
          state: { tracking_number: r[1][0].detail__tracking_number },
        }}
      >
        {r[1][0].detail__tracking_number}
      </Link>
    ),
  },
  {
    id: "location",
    render: (r) => r[0],
  },
  {
    id: "product_sku",
    render: (r) => r[1][0].detail__product__sku,
  },
];

export default function PickList() {
  const classes = useStyles();
  const { isMobile } = useLayoutState();
  const [assignedShipments, setAssignedShipments] = useState(
    initialAPIListValue,
  );

  useEffect(() => {
    if (assignedShipments.isFetching) {
      getAssignedShipmentsCountWithGroup({
        responseSetter: (data) => {
          const sorted_data = data.sort(
            compareValues("detail__location__code"),
          );
          const grouped_by_location_code = sorted_data.reduce(
            (result, item) => ({
              ...result,
              [item["detail__location__code"]]: [
                ...(result[item["detail__location__code"]] || []),
                item,
              ],
            }),
            {},
          );

          setAssignedShipments({
            items: Object.entries(grouped_by_location_code),
            isFetching: false,
          });
        },
      });
    }
  }, [assignedShipments.isFetching]);

  return (
    <Card>
      <CardContent
        className={
          isMobile && assignedShipments.items.length > 8
            ? classes.mobileCardContent
            : ""
        }
      >
        <Typography variant="h3">Pick List (Ecomm)</Typography>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DefaultTable
              data={{
                ...assignedShipments,
                items: assignedShipments.items.slice(0, 3).map((a, i) => ({
                  ...a,
                  id: `${a[1].detail__location__code}-${a[1].detail__tracking_number}-${i}`,
                })),
              }}
              headers={headers}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActionArea>
        <Button
          fullWidth
          component={Link}
          disabled={
            assignedShipments.isFetching || !assignedShipments.items.length
          }
          to={{
            pathname: `/app/ecommerce/pick`,
          }}
          variant="contained"
          color="primary"
        >
          Pick
        </Button>
      </CardActionArea>
    </Card>
  );
}
