import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { createContainerFile, deleteContainerFile } from "api/purchase";
import { ConfirmDialog, Spacer } from "components/Custom";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { toBase64Handler } from "utils/base64";
import { timezoneFormat } from "utils/date";
import { sliceText, toTitleCase } from "utils/string";

import {
  Calendar,
  Close,
  ExpandMore,
  ExternalLink,
  Pencil,
  Receipt,
  Trash,
} from "icons";

import useStyles from "./drawerStyle";

let intervalID;

const DeleteFileModal = ({ file_id, onClose, onDelete }) => {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConfirmDialog
      open={isLoading || !!file_id}
      title="Delete File"
      children="Container file will be deleted?"
      confirmText="Delete"
      onConfirm={() => {
        setIsLoading(true);
        deleteContainerFile({
          id: file_id,
          responseSetter: (res) => {
            setIsLoading(false);
            if (res.success) {
              onClose(true);
              onDelete();
              popupNotification({
                dispatch: layoutDispatch,
                status: "success",
                message: `Container file deleted.`,
              });
            } else {
              if (res.message) {
                popupNotification({
                  dispatch: layoutDispatch,
                  status: "error",
                  message: res.message,
                });
              }
            }
          },
        });
      }}
      isLoading={isLoading}
      onClose={() => onClose(false)}
    />
  );
};

export default function ContainerDrawer({
  open,
  onClose,
  container,
  onEdit,
  onContainerReceipt,
  onFileDelete,
}) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="persistent"
      PaperProps={{
        className: classes.drawerPaper,
      }}
    >
      <div className={classes.root}>
        <div className={classes.title}>
          <Badge
            color="error"
            badgeContent={
              container.po_type === "Ecommerce"
                ? "ECOM"
                : container.po_type === "National"
                ? "NAT"
                : container.po_type === "Furniture"
                ? "FURN"
                : "MIX"
            }
            className={
              container.po_type === "Ecommerce"
                ? classes.ecomBadge
                : container.po_type === "National"
                ? classes.natBadge
                : container.po_type === "Furniture"
                ? classes.furnBadge
                : classes.mixBadge
            }
          />
          <div
            className={classes.header}
            style={{
              paddingLeft:
                container.po_type === "Ecommerce" ? "3.7rem" : "3.5rem",
            }}
          >
            <Typography variant="span">{`${container.container_number}`}</Typography>
            {container.scheduled_date && (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item className={classes.calendarIcon}>
                  <Calendar size={0.7} color="white" />
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="white">
                    {container.scheduled_date}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
          <Tooltip title={"Edit"} aria-label="edit-button">
            <IconButton
              disableRipple={true}
              onClick={() => onEdit(container)}
              className={classes.purple}
              style={{ marginLeft: "auto" }}
            >
              <Pencil color="#FFF" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Container Receipt Upload"} aria-label="edit-button">
            <IconButton
              disableRipple={true}
              onClick={() => onContainerReceipt(container)}
              className={classes.brown}
              style={{ marginLeft: "auto" }}
            >
              <Receipt color="#FFF" />
            </IconButton>
          </Tooltip>
          <IconButton
            disableRipple={true}
            onClick={() => onClose(false)}
            className={classes.orange}
          >
            <Close color="#FFF" />
          </IconButton>
        </div>
        <FilesAccordion
          container={container}
          onClose={onClose}
          onFileDelete={onFileDelete}
        />
        <ContainerAccordion container={container} />
      </div>
    </Drawer>
  );
}

const LineItem = ({ title, value, classes }) => (
  <Grid item xs={6}>
    <Grid container spacing={1}>
      <Grid item>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.lineItemTitle}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.lineItemValue}
        >
          {value || "-"}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

function ContainerAccordion({ container }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography className={classes.heading}>Details</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "0.3rem" }}>
            <Typography className={classes.lineItemTitle}>
              - {timezoneFormat(container.created_at).split(",")[0]}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container spacing={2} className={classes.accordionContent}>
            <LineItem
              title="Scheduled:"
              value={container.scheduled_date}
              classes={classes}
            />
            <LineItem title="ETD:" value={container.etd} classes={classes} />
            <LineItem title="ETA:" value={container.eta} classes={classes} />
            <LineItem title="ATA:" value={container.ata} classes={classes} />
          </Grid>
          <Spacer height="2rem" />
          <Grid container spacing={2} className={classes.accordionContent}>
            <LineItem
              title="PO #:"
              value={container.po_number}
              classes={classes}
            />
            <LineItem
              title="Invoice #:"
              value={container.invoice_number}
              classes={classes}
            />
            <LineItem
              title="Manufacturer:"
              value={toTitleCase(container.manufacturer)}
              classes={classes}
            />
            <LineItem
              title="Vendor:"
              value={toTitleCase(container.vendor)}
              classes={classes}
            />
            <LineItem
              title="Customer:"
              value={toTitleCase(container.customer)}
              classes={classes}
            />
            <LineItem
              title="Importer:"
              value={toTitleCase(container.importer)}
              classes={classes}
            />
            <LineItem
              title="Collections:"
              value={
                container.collections
                  ? JSON.parse(container.collections).join(", ")
                  : ""
              }
              classes={classes}
            />
          </Grid>
          <Spacer height="2rem" />
          <Grid container spacing={2} className={classes.accordionContent}>
            <LineItem
              title="BOL #:"
              value={container.bol_number}
              classes={classes}
            />
            <LineItem title="Line:" value={container.line} classes={classes} />
            <LineItem
              title="Vessel:"
              value={container.vessel}
              classes={classes}
            />
            <LineItem
              title="Port D:"
              value={container.port_and_forwarder.split("/")[0]}
              classes={classes}
            />
            <LineItem
              title="Forwarder:"
              value={container.port_and_forwarder.split("/")[1]}
              classes={classes}
            />
            <LineItem
              title="Port A:"
              value={container.ship_to_port}
              classes={classes}
            />
            <LineItem
              title="Destination:"
              value={container.final_destination}
              classes={classes}
            />
            <LineItem
              title="40HC Freight Cost:"
              value={container.hc40_freight_cost}
              classes={classes}
            />
            <LineItem
              title="Loading Method:"
              value={container.loading_method?.split("_").join(" ")}
              classes={classes}
            />
          </Grid>
          <Spacer height="2rem" />
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                className={classes.notesTitle}
              >
                Notes:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.notesContainer}>
              <Typography variant="body2" color="textPrimary">
                {container.notes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function FilesAccordion({ container, onClose, onFileDelete }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const [deleteId, setDeleteId] = useState(null);

  const handleFileChange = ({ target: { files } }) => {
    const uploaded = [...uploadedFiles];
    const filesArray = [...files];
    filesArray
      .filter(
        (file) => uploadedFiles.findIndex((f) => f.name === file.name) === -1,
      )
      .map((file) => {
        uploaded.push(file);
        return file;
      });
    setUploadedFiles(filesArray);
  };

  const handleSubmit = async () => {
    const files = await toBase64Handler(uploadedFiles);
    const fileNames = uploadedFiles.map((file) => file.name);
    const fileTypes = [];
    fileNames.forEach((name) => {
      if (name.toLowerCase().includes(".pdf")) {
        fileTypes.push("PDF");
      } else {
        fileTypes.push("EXCEL");
      }
    });
    setIsLoading(true);
    createContainerFile({
      body: {
        files,
        fileNames,
        fileTypes,
        containerId: container.id,
      },
      responseSetter: (res) => {
        if (res.success) {
          intervalID = setInterval(() => {
            createContainerFile({
              body: {
                key: res.key,
              },
              responseSetter: (res) => {
                if (!res.success && res.message) {
                  setIsLoading(false);
                  popupNotification({
                    dispatch: layoutDispatch,
                    status: "error",
                    message: res.message,
                  });
                  return clearInterval(intervalID);
                } else if (res.success) {
                  setIsLoading(false);
                  onClose(true);
                  setUploadedFiles([]);
                  popupNotification({
                    dispatch: layoutDispatch,
                    status: "success",
                    message: `Container file uploaded.`,
                  });
                  return clearInterval(intervalID);
                }
              },
            });
          }, 5000);
        } else {
          if (res.message) {
            popupNotification({
              dispatch: layoutDispatch,
              status: "error",
              message: res.message,
            });
          }
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.heading}>Attachments</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {container.files.map((file) => (
            <Grid item xs={12} className={classes.fileContainer}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Tooltip
                    title={`${file.file_name} - ${timezoneFormat(
                      file.created_at,
                    ).slice(0, 8)}`}
                    aria-label="hello"
                  >
                    <Typography variant="body2" color="textPrimary">
                      {file.file_name.slice(0, 26)}
                      {file.file_name.length > 26
                        ? `...${file.file_name.split(".")[1].toUpperCase()}`
                        : ""}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setDeleteId(file.id);
                        }}
                      >
                        <Trash color="red" size={0.8} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          window.open(file.signed_url, "_blank");
                        }}
                      >
                        <ExternalLink size={0.8} color="green" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {container.files.length > 0 && <Spacer height="1rem" />}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <label htmlFor="file-upload">
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/pdf, .xlsx, .xls"
                    onChange={handleFileChange}
                    multiple
                    hidden
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component="span"
                    disabled={isLoading}
                  >
                    {uploadedFiles.length
                      ? uploadedFiles.length > 1
                        ? uploadedFiles.length + " Files"
                        : sliceText(uploadedFiles[0].name, 25)
                      : "Select File"}
                  </Button>
                </label>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={uploadedFiles.length === 0 || isLoading}
                  onClick={handleSubmit}
                >
                  {isLoading ? <CircularProgress size={20} /> : "Upload"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <DeleteFileModal
        file_id={deleteId}
        onClose={() => {
          setDeleteId(null);
        }}
        onDelete={onFileDelete}
      />
    </Accordion>
  );
}
