import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  listItem: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: `${theme.palette.primary.main}20`,
    cursor: "pointer",
    padding: "0px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginBottom: "0.5rem",
  },
}));
