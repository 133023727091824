import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

import { getProductConsolidations } from "api/inventory";
import { getAisles } from "api/locations";

import { ProductTypeFilter } from "components/Custom";
import { InputContainer, TextInput } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { FormatListNumbered, QrCode2, SpaceBar } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import useStyles from "./styles";

const cells = [
  {
    id: "product_sku",
    exportLabel: "Inventory Id",
    render: (r) => r.product.sku,
  },
  { id: "code", render: (r) => r.location.code },
  { id: "qty", exportLabel: "Quantity" },
];

export default function ProductConsolidations(props) {
  const { t } = useTranslation();
  const { related_product } = parseJwt(getWithExpiry("access"));
  const classes = useStyles();
  const layoutDispatch = useLayoutDispatch();
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [productConsolidations, setProductConsolidations] = useState(
    initialAPIListValue,
  );
  const [aisles, setAisles] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product__product_type: related_product || 1,
    product__sku: "",
    location__code: "",
    selectedAisles: [],
  });
  const [exportDisabled, setExportDisabled] = useState(false);

  useEffect(() => {
    getAisles({ setFunc: setAisles });
  }, []);

  useEffect(() => {
    if (
      productConsolidations.isFetching &&
      (search.product__sku || search.selectedAisles.length)
    ) {
      const params = { ...search, selectedAisles: "" };
      getProductConsolidations({
        params: {
          ...params,
          aisles: search.selectedAisles.map((aisle) => aisle.aisle).toString(),
          page,
          page_size: rowsPerPage,
        },
        setError,
        setFunc: (res) =>
          setProductConsolidations({
            ...res,
            items: res.items.map((i) => ({
              ...i,
              id: `${i.product.id}-${i.location.id}`,
            })),
          }),
        paginated: true,
      });
    }
  }, [page, rowsPerPage, search, productConsolidations.isFetching]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setProductConsolidations({
      items: productConsolidations.items,
      isFetching: true,
      count: productConsolidations.count,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    debouncedSearchHandler();
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Product-Consolidations-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (productConsolidations.items.length === productConsolidations.count) {
      exportData(productConsolidations.items);
    } else {
      getProductConsolidations({
        params: {
          limit: productConsolidations.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setProductConsolidations({ ...productConsolidations, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("consolidations")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product__sku}
              onChange={handleChangeFilterInput("product__sku")}
              isProductSKU={true}
              InputProps={clearAdornment("product__sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="location"
              label={t("location")}
              variant="outlined"
              value={search.location__code}
              onChange={handleChangeFilterInput("location__code")}
              isLocationCode={true}
              InputProps={clearAdornment("location__code")}
              LeftIcon={SpaceBar}
            />
          </Grid>
          <ProductTypeFilter
            id="product__product_type"
            title={t("product_type")}
            value={search.product__product_type}
            setValue={(data) =>
              setSearch({ ...search, product__product_type: data })
            }
            onChange={handleChangeFilterInput("product__product_type")}
          />
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Autocomplete
              fullWidth
              multiple
              options={aisles.items}
              getOptionLabel={(option) => option.aisle}
              getOptionSelected={(option, value) =>
                option.aisle === value.aisle
              }
              value={search.selectedAisles}
              onChange={(e, items) => {
                if (items.length > 10) {
                  return popupNotification({
                    dispatch: layoutDispatch,
                    message: "You can select up to 10 aisles.",
                    status: "warning",
                  });
                }
                setSearch((prev) => ({
                  ...prev,
                  selectedAisles: items,
                }));
                setPage(0);
                setProductConsolidations((prev) => ({
                  ...prev,
                  isFetching: true,
                }));
              }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={t("aisles")}
                  variant="outlined"
                  LeftIcon={FormatListNumbered}
                />
              )}
            />
          </Grid>
          <Grid item md={2} lg={1} style={{ borderRight: "none" }} />
        </Grid>
      </InputContainer>
      <Grid container spacing={4} className={classes.marginBottom}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!search.product__sku && search.selectedAisles.length === 0 ? (
            "To view records, you must enter the SKU or select a aisle."
          ) : (
            <DefaultTable
              headers={cells}
              data={productConsolidations}
              rowsPerPage={rowsPerPage}
              page={page}
              handleExportData={handleExportData}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              exportDisabled={exportDisabled}
              setExportDisabled={setExportDisabled}
              filters={{
                ...search,
                selectedAisles: null,
                selected_aisles:
                  search.selectedAisles &&
                  search.selectedAisles.map((aisle) => aisle.aisle).join(", "),
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
