import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { ChartSquareBar } from "icons";

import useStyles from "./styles";

export default function RangeInput({
  InputProps,
  label,
  minKey,
  maxKey,
  params,
  onChange,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();

  const onValueChange = (key) => (e) => {
    let value = e.target.value;
    value = /^\d+$/.test(value) ? parseInt(value) : "";
    onChange(key)({ target: { value } });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.leftIcon}>
          <ChartSquareBar color={theme.palette.colors.blue_icon} />
        </div>
        <TextField
          {...props}
          inputMode="numeric"
          value={params[minKey]}
          onChange={onValueChange(minKey)}
          label={`Min-${label}`}
          variant="standard"
          className={classes.textField}
          InputProps={{
            disableUnderline: true,
            ...InputProps,
          }}
          InputLabelProps={{
            className: classes.textLabel,
          }}
        />
        <div className={classes.leftIcon}>
          <ChartSquareBar color={theme.palette.colors.blue_icon} />
        </div>
        <TextField
          {...props}
          inputMode="numeric"
          value={params[maxKey]}
          onChange={onValueChange(maxKey)}
          label={`Max-${label}`}
          variant="standard"
          className={classes.textField}
          InputProps={{
            disableUnderline: true,
            ...InputProps,
          }}
          InputLabelProps={{
            className: classes.textLabel,
          }}
        />
      </div>
    </>
  );
}
