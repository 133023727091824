import * as React from "react";

const SvgShare = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342M8.684 13.342a3 3 0 1 1 0-2.684M8.684 13.342l6.632 3.316M8.684 10.658l6.632-3.316M15.316 7.342a3 3 0 1 0 5.367-2.684 3 3 0 0 0-5.367 2.684ZM15.316 16.658a3 3 0 1 0 5.368 2.684 3 3 0 0 0-5.368-2.684Z" />
    </g>
  </svg>
);

export default SvgShare;
