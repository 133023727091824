import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { CalendarToday } from "icons";
import useStyles from "./styles";

export default function DatePicker({
  id,
  title,
  value,
  onChange,
  min,
  max,
  hasBorder,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      className={`${classes.container} ${
        hasBorder ? classes.containerBordered : ""
      }`}
    >
      <div className={classes.leftIcon}>
        {<CalendarToday color={theme.palette.colors.blue_icon} />}
      </div>
      <TextField
        id={id}
        label={title}
        type="date"
        variant="standard"
        value={value}
        onChange={onChange}
        className={classes.dateInput}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{ min, max }}
        InputLabelProps={{
          shrink: true,
          className: [classes.textLabel, classes.datePickerLabel].join(" "),
        }}
        {...props}
      />
    </div>
  );
}
