import React from "react";
import { Drawer, List } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "components/Header";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Search from "components/Search";
import Profile from "./components/Profile";

// context
import { useLayoutState } from "context/LayoutContext";

function DesktopNav({ location, items }) {
  var classes = useStyles();
  var { isSidebarOpened } = useLayoutState();

  return (
    <Drawer
      variant={"permanent"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <Header />
      <div className={classes.toolbar} />
      <Search isSidebarOpened={isSidebarOpened} />
      <List className={classes.sidebarList}>
        {items
          .filter((item) => item.desktop)
          .map((item) => (
            <SidebarLink
              key={item.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              label={item.label}
              icon={item.icon}
              link={item.link}
              children={item.children.filter((child) => child.desktop)}
            />
          ))}
      </List>
      <Profile />
    </Drawer>
  );
}

export default withRouter(DesktopNav);
