import * as React from "react";

const SvgChevrondoubledown = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5.613 12.21.094.083L12 18.585l6.293-6.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-7 7a1 1 0 0 1-1.32.083l-.094-.083-7-7a1 1 0 0 1 1.32-1.497Zm0-8 .094.083L12 10.585l6.293-6.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-7 7a1 1 0 0 1-1.32.083l-.094-.083-7-7a1 1 0 0 1 1.32-1.497Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgChevrondoubledown;
