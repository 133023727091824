import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const createPromotions = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/promotions/create/",
    body,
    responseSetter,
  });
};

const getPromotions = ({
  setFunc,
  setError,
  paginated,
  params,
  responseSetter,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("promotions/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const updatePromotion = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `promotions/${id}/`,
    body,
    responseSetter,
  });
};

export { createPromotions, getPromotions, updatePromotion };
