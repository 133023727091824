import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  header: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  toggle: {
    float: "left",
    width: "20px",
    marginLeft: "3px",
  },
  drawerClosedToggle: {
    position: "fixed",
  },
  logo: {
    float: "left",
    paddingLeft: theme.spacing(2),
    width: "80px",
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
}));
