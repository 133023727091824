import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getInventories = ({
  setFunc,
  setError,
  responseSetter,
  paginated,
  params,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/inventory/", params),
    setError,
    setFunc,
    responseSetter,
    paginated,
  });
};

const getDuplicateItems = ({ setFunc, setError, paginated, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/duplicate-items/", params),
    setError,
    setFunc,
    paginated,
  });
};

const getProductConsolidations = ({ setFunc, setError, paginated, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/product-consolidation/", params),
    setError,
    setFunc,
    paginated,
  });
};

const approveCycleCounts = ({ body, setError, setFunc }) => {
  api.create({
    endpoint: "custom/approve-cycle-count/",
    body,
    setError,
    setFunc,
  });
};

const cancelCycleCounts = ({ body, setError, setFunc }) => {
  api.create({
    endpoint: "custom/cancel-cycle-count/",
    body,
    setError,
    setFunc,
  });
};

const createCycleCount = ({ setError, body, setFunc }) => {
  api.create({
    endpoint: "warehouse/count/",
    body,
    setError,
    setFunc,
  });
};

const getCycleCounts = ({ setFunc, setError, paginated, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/count/", params),
    paginated,
    setError,
    setFunc,
  });
};

const getCycleCountSummary = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint("custom/cycle-count-summary/", params),
    responseSetter,
  });
};

const createLocationSKUValidation = ({ setError, body, setFunc }) => {
  api.create({
    endpoint: addParamsToEndpoint("warehouse/location-sku-report/"),
    body,
    setError,
    setFunc,
  });
};

const getLocationSKUReport = ({ params, setError, paginated, setFunc }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/location-sku-report/", params),
    setError,
    setFunc,
    paginated,
  });
};

const getNationalCountGroups = ({
  params,
  setError,
  setFunc,
  responseSetter,
  paginated,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/national-count-group/", params),
    paginated,
    setError,
    setFunc,
    responseSetter,
  });
};

const updateNationalCountGroupById = ({ id, body, setFunc, setError }) => {
  api.update({
    endpoint: `warehouse/national-count-group/${id}/`,
    body,
    setError,
    setFunc,
  });
};

const createNationalCountGroup = ({ body, setFunc, setError }) => {
  api.create({
    endpoint: `warehouse/national-count-group/`,
    body,
    setError,
    setFunc,
  });
};

const getNationalInventoryCounts = ({
  params,
  setFunc,
  setError,
  paginated,
}) => {
  api.list({
    endpoint: addParamsToEndpoint(
      "warehouse/national-inventory-counter/",
      params,
    ),
    params,
    setFunc,
    setError,
    paginated,
  });
};

const createNationalInventoryCounter = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-national-count/",
    body,
    responseSetter,
  });
};

const getInventoryDashboardSummary = ({ params, responseSetter }) => {
  api.list({
    endpoint: addParamsToEndpoint(
      "custom/inventory-dashboard-summary/",
      params,
    ),
    responseSetter,
  });
};

const locationBulkUpdate = ({ body, responseSetter }) => {
  api.update({
    endpoint: "custom/location-bulk-update/",
    body,
    responseSetter,
  });
};

const locationSKUBulkUpdate = ({ body, responseSetter }) => {
  api.update({
    endpoint: "custom/location-sku-update/",
    body,
    responseSetter,
  });
};

const getInventorySnapshots = ({
  params,
  paginated,
  setFunc,
  responseSetter,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/inventory-snapshots/", params),
    paginated,
    setFunc,
    responseSetter,
  });
};

const createInventorySnapshot = ({ responseSetter }) => {
  api.list({
    endpoint: "custom/create-inventory-snapshot/",
    responseSetter,
  });
};

const cycleCountByFile = ({ responseSetter, body }) => {
  api.create({
    endpoint: "custom/cycle-count-by-file/",
    body,
    responseSetter,
  });
};

export {
  approveCycleCounts,
  cancelCycleCounts,
  createCycleCount,
  createInventorySnapshot,
  cycleCountByFile,
  getCycleCounts,
  getCycleCountSummary,
  getDuplicateItems,
  getInventories,
  getInventoryDashboardSummary,
  getInventorySnapshots,
  createLocationSKUValidation,
  getLocationSKUReport,
  getProductConsolidations,
  getNationalCountGroups,
  locationBulkUpdate,
  locationSKUBulkUpdate,
  updateNationalCountGroupById,
  createNationalCountGroup,
  getNationalInventoryCounts,
  createNationalInventoryCounter,
};
