import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: "100%",
    height: "100%",
  },
  tableImageList: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  itemBar: {
    height: "1.4rem",
    display: "flex",
    alignItems: "center",
  },
  bottomButtonsGrid: {
    color: "#fff",
    height: "3rem",
    position: "absolute",
    marginLeft: "-45%",
    left: "50%",
    width: "90%",
    bottom: "5rem",
    justifyContent: "center",
  },
  notOursButton: {
    color: "#FFF",
    background: theme.palette.colors.rose600,
  },
}));
