import * as React from "react";

const SvgClipboardcheck = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M9.707 13.293a1 1 0 0 0-1.414 1.414l1.414-1.414ZM11 16l-.707.707a1 1 0 0 0 1.414 0L11 16ZM15.707 12.707a1 1 0 0 0-1.414-1.414l1.414 1.414ZM18 7v12h2V7zM17 20H7v2h10zM6 19V7H4v12zM7 6h2V4H7zM15 6h2V4h-2zM7 20a1 1 0 0 1-1-1H4a3 3 0 0 0 3 3v-2ZM18 19a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2ZM20 7a3 3 0 0 0-3-3v2a1 1 0 0 1 1 1h2ZM6 7a1 1 0 0 1 1-1V4a3 3 0 0 0-3 3h2Z" />
      <path d="m8.293 14.707 2 2 1.414-1.414-2-2z" />
      <path d="m11.707 16.707 4-4-1.414-1.414-4 4zM11 4h2V2h-2zM13 6h-2v2h2zM11 6a1 1 0 0 1-1-1H8a3 3 0 0 0 3 3V6ZM14 5a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2ZM13 4a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM11 2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1V2Z" />
    </g>
  </svg>
);

export default SvgClipboardcheck;
