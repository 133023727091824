import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SwitchButton from "./SwitchButton";
import useStyles from "./styles";

export default function SwitchButtonGroup({
  children,
  defaultValue,
  onChange,
  clearEnabled,
  xs,
  sm,
  md,
  lg,
  ...props
}) {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} {...props}>
      <Grid container spacing={0} className={classes.switchButtonGroup}>
        {children.map ? (
          children.map((child, index) => (
            <SwitchButton
              {...child.props}
              key={index}
              selectedValue={value}
              onClick={(value) => {
                setValue(value);
                onChange(value);
              }}
              onClear={
                clearEnabled
                  ? () => {
                      onChange(defaultValue);
                      setValue(defaultValue);
                    }
                  : null
              }
            />
          ))
        ) : (
          <SwitchButton
            {...children.props}
            onClick={(value) => {
              setValue(value);
              onChange(value);
            }}
            selectedValue={value}
          />
        )}
      </Grid>
    </Grid>
  );
}
