import React from "react";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { Button } from "components/Form";

const ApproveModal = ({ open, onClose, isLoading, onApprove }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="approve-cycle-count-dialog"
    >
      <DialogTitle id="approve-cycle-count-dialog">
        Approve Cycle Counts
      </DialogTitle>
      <DialogContent>
        Transfers and adjustments will be created and published.
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          color="blue"
          variant="contained"
          onClick={onApprove}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress color="white" size={20} />}
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CancelModal = ({ open, onClose, isLoading, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="approve-cycle-count-dialog"
    >
      <DialogTitle id="approve-cycle-count-dialog">
        Cancel Cycle Counts
      </DialogTitle>
      <DialogContent>Cycle counts will be cancelled.</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          color="danger"
          variant="contained"
          onClick={onCancel}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress color="white" size={20} />}
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ApproveModal, CancelModal };
