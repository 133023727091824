import { useState } from "react";
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Popover,
  Typography,
} from "@material-ui/core";

import useStyles from "./styles";

export default function Checkbox({
  children,
  color,
  disabled,
  onChange,
  checked,
  label,
  popup,
  horizontal = "left",
  anchorVertical = "bottom",
  transformVertical = "top",
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={[classes.switch, color === "blue" && classes.switchBlue].join(
        " ",
      )}
    >
      <FormControlLabel
        onMouseEnter={popup ? handlePopoverOpen : null}
        onMouseLeave={popup ? handlePopoverClose : null}
        control={
          <MuiCheckbox
            checked={!!checked}
            onChange={onChange}
            iconstyle={{ fill: color }}
          />
        }
        label={label}
        {...props}
      />
      {popup && (
        <Popover
          open={open}
          style={{ pointerEvents: "none" }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: anchorVertical,
            horizontal,
          }}
          transformOrigin={{
            vertical: transformVertical,
            horizontal,
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            variant="body2"
            style={{
              padding: "0 20px",
              lineHeight: "35px",
            }}
          >
            {popup}
          </Typography>
        </Popover>
      )}
    </div>
  );
}
