import * as React from "react";

const SvgEdit = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 16.875V20h3.125l9.223-9.222-3.126-3.126L4 16.875Zm14.756-8.506a.835.835 0 0 0 0-1.18L16.81 5.245a.835.835 0 0 0-1.18 0l-1.524 1.525 3.125 3.125 1.525-1.525Z"
      fill={color}
    />
  </svg>
);

export default SvgEdit;
