function clearInput(inputRef) {
  if (inputRef.current) {
    inputRef.current.value = "";
  } else {
    setTimeout(() => clearInput(inputRef), 100);
  }
}

function focusInput(inputRef) {
  if (inputRef.current) {
    inputRef.current.focus();
  } else {
    setTimeout(() => focusInput(inputRef), 100);
  }
}

function updateDocumentTitle(title) {
  const env = process.env.NODE_ENV;
  const documentTitle = `${title} | WMS`;
  if (env !== "production") {
    document.title = `[DEV] ${documentTitle}`;
    return;
  }
  document.title = documentTitle;
}

export { clearInput, focusInput, updateDocumentTitle };
