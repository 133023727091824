import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { getInventories } from "api/inventory";
import { getLocations } from "api/locations";
import { movement } from "api/movement";
import { getProductsWithAttributes } from "api/products";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { MobileResetButton, SetBuggyToLocal, Spacer } from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { QrCode2, SpaceBar } from "icons";

import { initialAPIListValue } from "utils/constants";
import { clearInput, focusInput } from "utils/dom";
import { getWithExpiry } from "utils/storage";
import { locationCodeValidation } from "utils/validations";

export default function Shelf() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const skuEl = useRef(null);
  const locationEl = useRef(null);
  const [buggy, setBuggy] = useState(parseInt(getWithExpiry("buggyId")));
  const initialParams = {
    product: "",
    product_sku: "",
    product_type: "",
    from_location: buggy,
    to_location_code: "",
  };
  const [params, setParams] = useState(initialParams);
  const [totalShelvedCount, setTotalShelvedCount] = useState(0);
  const [buggyInventory, setBuggyInventory] = useState([]);
  const [locations, setLocations] = useState({
    ...initialAPIListValue,
    isFetching: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [productIsFetching, setProductIsFetching] = useState(false);

  useEffect(() => {
    if (buggy) {
      getInventories({
        params: {
          location_id: buggy,
          limit: 1000,
        },
        responseSetter: ({ results }) => {
          setBuggyInventory(results);
          focusInput(skuEl);
        },
      });
    }
  }, [buggy]);

  const findProductInBuggyInventory = (sku) => {
    const skuLower = sku.toLowerCase();
    const productBuggyMatch = buggyInventory.find(
      (bi) =>
        bi.product.sku.toLowerCase() === skuLower ||
        bi.product.sku.toLowerCase() === skuLower.split(",")[0],
    );
    return productBuggyMatch ? productBuggyMatch.product : false;
  };

  const handleProductScan = (value) => {
    const product = findProductInBuggyInventory(value);

    if (product) {
      setParams({
        ...params,
        product: product.id,
        product_sku: product.sku,
        product_type: product.product_type,
      });
      return;
    }
    setProductIsFetching(true);
    getProductsWithAttributes({
      params: {
        value,
      },
      responseSetter: (data) => {
        const { results } = data;
        setProductIsFetching(false);
        if (!results.length) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Product not found!",
          });
          clearInput(skuEl);
          return;
        } else if (results.length === 1) {
          const { product, product_sku } = results[0];
          const matchProduct = findProductInBuggyInventory(product_sku);
          if (!matchProduct) {
            popupNotification({
              dispatch: layoutDispatch,
              message: "Product not found in the buggy!",
            });
            clearInput(skuEl);
            return;
          }
          setParams({
            ...params,
            product,
            product_sku,
            product_type: matchProduct.product_type,
          });
          focusInput(locationEl);
          return;
        }
        const products = results.filter((r) =>
          findProductInBuggyInventory(r.product_sku),
        );

        if (products.length === 1) {
          const { product, product_sku, product_type } = products[0];
          setParams({
            ...params,
            product,
            product_sku,
            product_type,
          });
          return;
        }
        popupNotification({
          dispatch: layoutDispatch,
          message: t("duplicate_items"),
        });
      },
    });
  };

  const listLocations = (type) => {
    setLocations({ ...initialAPIListValue, isFetching: true });
    if (type === "empty") {
      getLocations({
        params: {
          limit: 1000,
          empty: true,
          location_type: params.product_type,
        },
        setFunc: setLocations,
        paginated: true,
      });
    } else {
      getInventories({
        params: {
          product_id: params.product,
          location__location_type: params.product_type,
        },
        responseSetter: (res) => {
          const { results } = res;
          if (!results.length) {
            popupNotification({
              dispatch: layoutDispatch,
              message:
                "Product not found any inventory location. You can try empty locations",
            });
            setLocations({ ...locations, isFetching: false });
            return;
          }

          const items = results.map((r) => ({ ...r.location, qty: r.qty }));
          setLocations({
            items,
            count: items.length,
            isFetching: false,
          });
        },
      });
    }
    focusInput(locationEl);
  };

  const handleLocationScan = (value) => {
    let to_location_code = locationCodeValidation(value);
    if (!to_location_code) {
      to_location_code = value;
    }
    setLocations({ ...initialAPIListValue, isFetching: false });
    setParams({
      ...params,
      to_location_code,
    });

    handleSubmit(to_location_code);
  };

  const handleSubmit = (to_location_code) => {
    setIsLoading(true);
    if (locationEl && locationEl.current) {
      locationEl.current.value = "";
    }
    const { from_location, product_type, product } = params;
    movement({
      body: {
        from_location,
        to_location_code,
        product,
        product_type,
      },
      setFunc: () => {
        setParams(initialParams);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Product shelved!",
          status: "success",
        });
        const matchBI = buggyInventory.find((bi) => bi.product.id === product);
        if (matchBI) {
          const { qty, id } = matchBI;
          if (qty === 1) {
            setBuggyInventory(buggyInventory.filter((bi) => bi.id !== id));
          } else {
            setBuggyInventory(
              buggyInventory.map((bi) => ({
                ...bi,
                qty: bi.id === id ? bi.qty - 1 : bi.qty,
              })),
            );
          }
        }
        setTotalShelvedCount(totalShelvedCount + 1);
        setIsLoading(false);
      },
      setError: (e) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: e.message,
        });
        focusInput(locationEl);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <PageTitle title={t("shelf_products")} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Grid style={{ marginBottom: "0.5em" }}>
              <SetBuggyToLocal
                hasClearButton={true}
                onAssign={(buggy) => setBuggy(buggy.id)}
                updateInventory={totalShelvedCount}
              />
            </Grid>
            {!buggy ? (
              <Alert severity="warning"> Please select a buggy </Alert>
            ) : !buggyInventory.length ? (
              <Alert severity="warning">Buggy doesn't have any item.</Alert>
            ) : (
              <>
                {params.product && params.product_sku ? (
                  <>
                    <Spacer height="20px" />
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "0.5em" }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {t("product_sku")}:{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                            }}
                          >
                            {params.product_sku}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => listLocations("empty")}
                          disabled={locations.isFetching || isLoading}
                        >
                          {locations.isFetching ? (
                            <CircularProgress size={14} />
                          ) : null}
                          Empty Locations
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => listLocations()}
                          disabled={locations.isFetching || isLoading}
                        >
                          {locations.isFetching ? (
                            <CircularProgress size={14} />
                          ) : null}
                          Alternate Locations
                        </Button>
                      </Grid>
                    </Grid>
                    <TextInputWithKeyboard
                      fullWidth
                      autoFocus
                      inputRef={locationEl}
                      id="location_code"
                      variant="outlined"
                      label={t("location")}
                      disabled={isLoading}
                      onKeyPress={({ target, key }) => {
                        if (key === "Enter") handleLocationScan(target.value);
                      }}
                      LeftIcon={SpaceBar}
                      hasBorder
                    />
                    {locations.items.length ? (
                      <List>
                        {locations.items.slice(0, 5).map((l) => (
                          <ListItem
                            key={l.id}
                            onClick={() => handleLocationScan(l.code)}
                          >
                            <ListItemText>{l.code}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    ) : null}
                    {isLoading && (
                      <Alert severity="info">
                        <CircularProgress size={14} />
                        Your operation is in progress, please wait
                      </Alert>
                    )}
                  </>
                ) : (
                  <TextInputWithKeyboard
                    fullWidth
                    autoFocus
                    inputRef={skuEl}
                    id="product_sku"
                    variant="outlined"
                    label={t("product_sku")}
                    onKeyPress={({ target, key }) => {
                      if (key === "Enter") handleProductScan(target.value);
                    }}
                    LeftIcon={productIsFetching ? CircularProgress : QrCode2}
                    disabled={productIsFetching}
                    hasBorder
                  />
                )}
              </>
            )}
          </Widget>
        </Grid>
      </Grid>
      <MobileResetButton onClick={() => setParams(initialParams)} />
    </>
  );
}
