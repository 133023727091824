import * as React from "react";

const SvgSettingsRemote = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#SettingsRemote_svg__a)">
      <path
        opacity={0.3}
        d="M10 21.5h4v-10h-4v10zm2-9c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z"
        fill={color}
      />
      <path
        d="M15 9.5H9c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-12c0-.55-.45-1-1-1zm-1 12h-4v-10h4v10z"
        fill={color}
      />
      <path
        d="M12 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM7.05 6.55l1.41 1.41a5.022 5.022 0 0 1 7.08 0l1.41-1.41A6.976 6.976 0 0 0 12 4.5c-1.93 0-3.68.78-4.95 2.05zM12 .5C8.96.5 6.21 1.73 4.22 3.72l1.41 1.41A9.011 9.011 0 0 1 12 2.5c2.49 0 4.74 1.01 6.36 2.64l1.41-1.41C17.79 1.73 15.04.5 12 .5z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="SettingsRemote_svg__a">
        <path fill={color} transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSettingsRemote;
