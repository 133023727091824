import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  error: {
    color: theme.palette.text.error,
    backgroundColor: theme.palette.background.error,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    marginTop: 5,
  },
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
  button: {
    minWidth: 175,
  }
}));
