import * as React from "react";

const SvgTextSnippet = ({ color, size }) => (
  <svg
    viewBox="0 0 24 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M14.17 5.5 19 10.33v9.17H5v-14h9.17zM7 15.5h10v2H7v-2zm0-4h10v2H7v-2zm0-4h7v2H7v-2z"
      fill={color}
    />
    <path
      d="M14.17 5.5 19 10.33v9.17H5v-14h9.17zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9.17c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59zM7 15.5h10v2H7v-2zm0-4h10v2H7v-2zm0-4h7v2H7v-2z"
      fill={color}
    />
  </svg>
);

export default SvgTextSnippet;
