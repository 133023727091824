import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { getClassificationConfig, updateClassificationConfig } from "api/stock";
import { TextInput } from "components/Form";
import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import getClearAdornment from "utils/form";

import useStyles from "./styles";
import { ChartSquareBar, X as Close } from "icons";

const initialParams = {
  a_value: "80.0",
  b_value: "16.0",
  h_value: "80.0",
  m_value: "16.0",
};

const ClassificationConfigModal = ({ open, onClose, onUpdate, t }) => {
  const classes = useStyles();
  const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState(false);
  const layoutDispatch = useLayoutDispatch();

  useEffect(() => {
    getClassificationConfig({
      setFunc: (res) => {
        const result = res.items && res.items.results[0];
        if (!result) return;
        const a_value = parseFloat(result.abc.a);
        const b_value = parseFloat(result.abc.b);
        const h_value = parseFloat(result.hml.h);
        const m_value = parseFloat(result.hml.m);
        setParams({
          a_value: isNaN(a_value) ? initialParams.a_value : a_value.toFixed(1),
          b_value: isNaN(b_value) ? initialParams.b_value : b_value.toFixed(1),
          h_value: isNaN(h_value) ? initialParams.h_value : h_value.toFixed(1),
          m_value: isNaN(m_value) ? initialParams.m_value : m_value.toFixed(1),
        });
      },
      setError: (_) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
    });
  }, [layoutDispatch]);

  const handleUpdate = () => {
    setIsLoading(true);
    updateClassificationConfig({
      setError: (_) => {
        setIsLoading(false);
        popupNotification({
          dispatch: layoutDispatch,
          message: "An error occurred!",
        });
      },
      setFunc: () => {
        setIsLoading(false);
        onClose();
        // classification is not instant
        setTimeout(() => {
          onUpdate();
        }, 3000);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Successfully updated.",
          status: "success",
        });
      },
      body: params,
    });
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setParams((prev) => ({ ...prev, [prop]: value }));
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
  };

  const clearAdornment = (input) => {
    getClearAdornment(input, clearHandler, params);
  };

  const handleOnBlur = (prop, min, max) => () => {
    if (params[prop] < min) {
      setParams((prev) => ({ ...prev, [prop]: min }));
    } else if (params[prop] > max) {
      setParams((prev) => ({ ...prev, [prop]: max }));
    } else if (params[prop] === "") {
      setParams((prev) => ({ ...prev, [prop]: initialParams[prop] }));
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      scroll="paper"
      open={open}
      keepMounted
      aria-labelledby="item-label-dialog-title"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        Classification Settings
      </DialogTitle>
      <DialogContent className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" className={classes.mb10}>
                  <strong>ABC Classification:</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      id="a_value"
                      label="A Classification Rate (%)"
                      variant="outlined"
                      fullWidth
                      value={params.a_value}
                      onChange={handleChangeFilterInput("a_value")}
                      InputProps={clearAdornment("a_value")}
                      onBlur={handleOnBlur("a_value", 1, 100)}
                      type="number"
                      min={1}
                      max={100}
                      LeftIcon={ChartSquareBar}
                      steps=".1"
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      id="b_value"
                      label="B Classification Rate(%)"
                      variant="outlined"
                      fullWidth
                      value={params.b_value}
                      onChange={handleChangeFilterInput("b_value")}
                      InputProps={clearAdornment("b_value")}
                      onBlur={handleOnBlur("b_value", 1, 99)}
                      type="number"
                      min={1}
                      max={99}
                      LeftIcon={ChartSquareBar}
                      steps=".1"
                      hasBorder
                    />
                  </Grid>
                  <Typography variant="subtitle" className={classes.hint}>
                    The remaining{" "}
                    {(
                      100.0 -
                      (parseFloat(params.a_value) + parseFloat(params.b_value))
                    ).toFixed(2)}
                    % will be classified as C
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" className={classes.mb10}>
                  <strong>HML Classification:</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      id="h_value"
                      label="H Classification Rate (%)"
                      variant="outlined"
                      fullWidth
                      value={params.h_value}
                      onChange={handleChangeFilterInput("h_value")}
                      InputProps={clearAdornment("h_value")}
                      onBlur={handleOnBlur("h_value", 1, 100)}
                      type="number"
                      min={1}
                      max={100}
                      LeftIcon={ChartSquareBar}
                      steps=".1"
                      hasBorder
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      id="m_value"
                      label="M Classification Rate (%)"
                      variant="outlined"
                      fullWidth
                      value={params.m_value}
                      onChange={handleChangeFilterInput("m_value")}
                      InputProps={clearAdornment("m_value")}
                      onBlur={handleOnBlur("m_value", 1, 99)}
                      type="number"
                      min={1}
                      max={99}
                      LeftIcon={ChartSquareBar}
                      steps=".1"
                      hasBorder
                    />
                  </Grid>
                  <Typography variant="subtitle" className={classes.hint}>
                    The remaining{" "}
                    {(
                      100.0 -
                      (parseFloat(params.h_value) + parseFloat(params.m_value))
                    ).toFixed(2)}
                    % will be classified as L
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              alignItems="flex-end"
              justifyContent="center"
              className={classes.mt10}
            >
              <Grid item xs={12}>
                <Button
                  onClick={handleUpdate}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.actionButton}
                  disabled={
                    isLoading ||
                    !params.a_value ||
                    !params.b_value ||
                    !params.h_value ||
                    !params.m_value
                  }
                >
                  {isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Update / Reclassify"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <Close color="white" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassificationConfigModal;
