import * as React from "react";

const SvgExternallink = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M9.545 5.727H5.182A2.182 2.182 0 0 0 3 7.91v10.91C3 20.022 3.977 21 5.182 21H16.09a2.182 2.182 0 0 0 2.182-2.182v-4.363M13.909 3.545h6.546M20.455 3.545v6.546M20.455 3.545l-10.91 10.91" />
    </g>
  </svg>
);

export default SvgExternallink;
