import * as React from "react";

const SvgPresentationchartline = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M4 4V3H3v1zM20 4h1V3h-1zM6.293 11.293a1 1 0 1 0 1.414 1.414l-1.414-1.414ZM10 9l.707-.707a1 1 0 0 0-1.414 0L10 9ZM13 12l-.707.707a1 1 0 0 0 1.414 0L13 12ZM17.707 8.707a1 1 0 0 0-1.414-1.414l1.414 1.414ZM7.293 20.293a1 1 0 1 0 1.414 1.414l-1.414-1.414ZM12 17l.707-.707a1 1 0 0 0-1.414 0L12 17ZM15.293 21.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM3 3a1 1 0 0 0 0 2V3ZM21 5a1 1 0 1 0 0-2v2ZM4 5h16V3H4z" />
      <path d="M19 4v12h2V4zM19 16H5v2h14zM5 16V4H3v12zM5 16H3a2 2 0 0 0 2 2v-2ZM19 16v2a2 2 0 0 0 2-2h-2ZM7.707 12.707l3-3-1.414-1.414-3 3z" />
      <path d="m9.293 9.707 3 3 1.414-1.414-3-3z" />
      <path d="m13.707 12.707 4-4-1.414-1.414-4 4zM8.707 21.707l4-4-1.414-1.414-4 4z" />
      <path d="m11.293 17.707 4 4 1.414-1.414-4-4zM3 5h18V3H3z" />
    </g>
  </svg>
);

export default SvgPresentationchartline;
