import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  FormLabel,
  Grid,
  Tabs,
  Tab,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import _ from "lodash";

import {
  deletePackage,
  getPackageLabels,
  getShipmentDetails,
  getShipmentPackages,
  updatePackage,
} from "api/shipment";

import { ConfirmDialog, StatusLabel } from "components/Custom";
import { ExternalLink } from "components/Custom/Links";
import { Button } from "components/Form";
import PickStatus from "components/Custom/PickStatus";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import {
  popupNotification,
  useLayoutDispatch,
  useLayoutState,
} from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { Check, Edit, LockOpen, Trash } from "icons";

import DetailTimeline from "pages/shipments/ShipmentDetailTimeline";

import { downloadPDF } from "utils/base64";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import {
  ACUMATICA_BASE_URL,
  initialAPIListValue,
  INVOICE_URL,
  ORDER_URL,
  SHIPMENT_URL,
} from "utils/constants";
import { timezoneFormat } from "utils/date";

import LTLLabelsModal from "./LabelModal";
import EditNote from "./EditNote";

import useStyles from "./styles";

const packageDetailHeaders = [
  {
    id: "sku",
    render: (r) => r.product.sku,
  },
  {
    id: "quantity",
  },
];

export default function ShipmentPreview({ shipment }) {
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const { permissions } = useUserState();
  const [t] = useTranslation();
  const { isMobile } = useLayoutState();
  const [activeTab, setActiveTab] = useState(0);
  const [edit, setEdit] = useState(false);
  const [deletePack, setDeletePack] = useState(false);
  const [packageStatus, setPackageStatus] = useState("");
  const [details, setDetails] = useState(initialAPIListValue);
  const [packages, setPackages] = useState(initialAPIListValue);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [timelineRow, setTimelineRow] = useState({});
  const [downloadLabels, setDownloadLabels] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [totalPicked, setTotalPicked] = useState(0);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("product.sku");
  const [reSort, setReSort] = useState(true);
  const [editNote, setEditNote] = useState({});
  const [exportDisabled, setExportDisabled] = useState(false);

  const popoverContainer = useRef(null);

  const hasDownloadPackageLabelsPermission = permissions.includes(
    "contenttypes.ltl.download_package_labels",
  );

  const hasGetLabelsPermission = permissions.includes(
    "contenttypes.ltl.get_labels",
  );

  const hasEditPackagePermission = permissions.includes(
    "contenttypes.ltl.edit_package",
  );

  const hasCompletePackagePermission = permissions.includes(
    "contenttypes.ltl.complete_package",
  );
  const hasDeletePackagePermission = permissions.includes(
    "contenttypes.ltl.delete_package",
  );
  const hasReopenPackagePermission = permissions.includes(
    "contenttypes.ltl.reopen_package",
  );

  const previewHeaders = [
    {
      id: "product.sku",
      label: "SKU",
      render: (item) => item.product.sku,
      sortable: true,
    },
    {
      id: "quantity",
      label: "qty",
      render: (item) => (
        <PickStatus
          total_qty={item.quantity}
          picked_qty={item.transactions
            .filter((t) => t.action_type === "Picked")
            .reduce((total, t) => total + t.quantity, 0)}
        />
      ),
      sortable: true,
    },
    {
      id: "location.code",
      label: "location",
      render: (item) => item.location.code,
      sortable: true,
    },
    {
      id: "status",
      render: (item) => <StatusLabel status={item.status} />,
      sortable: true,
    },
    {
      id: "note",
      render: (item) => {
        return (
          <Grid container alignItems="center" spacing={2}>
            {item.note ? (
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Alert severity="warning" variant="filled">
                  {item.note}
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditNote(item)}
              >
                {item.note ? "Edit" : "Add"}
              </Button>
            </Grid>
          </Grid>
        );
      },
      sortable: true,
    },
  ];

  const exportHeaders = [
    {
      id: "product.sku",
      label: "SKU",
      render: (item) => item.product.sku,
    },
    {
      id: "quantity",
      label: "qty",
      render: (item) => {
        var itemQty = item.transactions
          .filter((t) => t.action_type === "Picked")
          .reduce((total, t) => total + t.quantity, 0);
        return itemQty === item.quantity
          ? item.quantity
          : `${itemQty} / ${item.quantity}`;
      },
    },
    { id: "status" },
    { id: "note" },
  ];

  const packageHeaders = [
    {
      id: "name",
      render: (r) =>
        `${r.name} (${r.details.reduce(
          (total, detail) => total + detail.quantity,
          0,
        )})`,
    },
    {
      id: "note",
      render: (r) => r.note || "-",
    },
    {
      id: "status",
      render: (r) => <StatusLabel status={r.status} />,
    },
    {
      id: "",
      render: (r) => (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={6} lg={5}>
            {hasEditPackagePermission ? (
              <Button
                variant="contained"
                size="small"
                startIcon={<Edit />}
                onClick={() => setEdit(true)}
              >
                Edit
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            {r.status === "Completed" && hasReopenPackagePermission ? (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<LockOpen color="#fff" />}
                onClick={() => setPackageStatus("Reopen")}
              >
                Reopen
              </Button>
            ) : r.status === "Packing" && hasCompletePackagePermission ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Check color="#fff" />}
                onClick={() => setPackageStatus("Complete")}
              >
                Complete
              </Button>
            ) : r.status === "Created" && hasDeletePackagePermission ? (
              <Button
                variant="contained"
                color="danger"
                size="small"
                startIcon={<Trash color="#fff" />}
                onClick={() => setDeletePack(true)}
              >
                Delete
              </Button>
            ) : null}
          </Grid>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    if (details.isFetching) {
      getShipmentDetails({
        params: {
          shipment_id: shipment.id,
          limit: 100,
        },
        responseSetter: (res) => {
          const { results } = res;
          setDetails({
            items: _.orderBy(results, [orderBy], [orderDirection]),
            isFetching: false,
          });
          if (!isMobile && results.length > 0) {
            setTimelineRow(results[0]);
            let totalPicked = 0;
            let totalQuantity = 0;
            results.forEach((detail) => {
              totalQuantity += detail.quantity;
              totalPicked += detail.transactions
                .filter((t) => t.action_type === "Picked")
                .reduce((total, t) => total + t.quantity, 0);
            });
            setTotalPicked(totalPicked);
            setItemCount(totalQuantity);
          }
        },
      });
    }
  }, [shipment.id, details.isFetching, isMobile, orderBy, orderDirection]);

  useEffect(() => {
    if (reSort) {
      setDetails((prev) => ({
        ...prev,
        items: _.orderBy(prev.items, [orderBy], [orderDirection]),
      }));
      setReSort(false);
    }
  }, [reSort, orderBy, orderDirection]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setReSort(true);
  };

  const handleChangeTab = (event, key) => {
    setActiveTab(key);
    if (key === 0) {
      setDetails((prev) => ({
        ...prev,
        isFetching: true,
      }));
    } else {
      setPackages(initialAPIListValue);
      getShipmentPackages({
        params: {
          shipment_number: shipment.shipment_number,
        },
        setFunc: setPackages,
        paginated: true,
      });
    }
  };

  const handleSelectPackage = (row) => {
    setSelectedPackage(row);
  };

  const handleEditClose = (status) => {
    if (status) {
      handleChangeTab(false, 1);
    }
    setEdit(false);
  };

  const handleUpdatePackageStatus = (status) => {
    updatePackage({
      id: selectedPackage.id,
      body: {
        status,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        handleChangeTab(false, 1);
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleDeletePackage = () => {
    deletePackage({
      id: selectedPackage.id,
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        setDeletePack(false);
        handleChangeTab(false, 1);
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleGetPackageLabels = () => {
    getPackageLabels({
      params: {
        shipment_number: shipment.shipment_number,
      },
      responseSetter: (res) => {
        const { success, message, base64 } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        downloadPDF(base64, `${shipment.shipment_number} - Package Labels`);
      },
    });
  };

  const handleCloseNote = (status) => {
    if (status) {
      setDetails((prev) => ({ ...prev, isFetching: true }));
    }

    setEditNote({});
  };

  const exportData = (data) => {
    exportToExcel(
      [],
      `LTL-Shipment-${shipment.shipment_number}`,
      true,
      convertDataToTableView(data, exportHeaders),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    getShipmentDetails({
      params: {
        shipment_id: shipment.id,
        limit: 9999,
      },
      setFunc: ({ items: { results } }) =>
        exportData(_.orderBy(results, [orderBy], [orderDirection])),
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Widget title={t("shipment")} style={{ minHeight: "12rem" }}>
            <Grid container>
              <Grid item lg={3} xs={12}>
                <div>
                  <FormLabel component="h6" className={classes.label}>
                    {t("shipment_number")}
                  </FormLabel>
                  {shipment.shipment_number ? (
                    <ExternalLink
                      className={classes.link}
                      to={`${ACUMATICA_BASE_URL}${SHIPMENT_URL}${shipment.shipment_number}`}
                      children={shipment.shipment_number}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div>
                  <FormLabel component="h6" className={classes.label}>
                    {t("order_number")}
                  </FormLabel>
                  {shipment.order && shipment.order.number ? (
                    <ExternalLink
                      className={classes.link}
                      to={`${ACUMATICA_BASE_URL}${ORDER_URL}${shipment.order.number}`}
                      children={shipment.order.number}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div>
                  <FormLabel component="h6" className={classes.label}>
                    {t("invoice_number")}
                  </FormLabel>
                  {shipment.invoice_number ? (
                    <ExternalLink
                      className={classes.link}
                      to={`${ACUMATICA_BASE_URL}${INVOICE_URL}${shipment.invoice_number}`}
                      children={shipment.invoice_number}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div>
                  <FormLabel component="h6" className={classes.label}>
                    {t("customer")}
                  </FormLabel>
                  <span>
                    {shipment.order ? shipment.order.customer_id : "-"}
                  </span>
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("status")}
                </FormLabel>
                <StatusLabel status={shipment.status} />
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("invoiced_at")}
                </FormLabel>
                <span>
                  {shipment.invoiced_at
                    ? timezoneFormat(shipment.invoiced_at)
                    : "-"}
                </span>
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("created_at")}
                </FormLabel>
                <span>{timezoneFormat(shipment.created_date)}</span>
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("ship_via")}
                </FormLabel>
                <span>{shipment.ship_via || "-"}</span>
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("total_weight")}
                </FormLabel>
                <span>{shipment.total_weight || "-"}</span>
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormLabel component="h6" className={classes.label}>
                  {t("total_sqryards")}
                </FormLabel>
                <span>{shipment.total_sqryards || "-"}</span>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Widget>
            <Tabs value={activeTab} onChange={handleChangeTab}>
              <Tab label="Items" id={0} />
              <Tab label="Packages" id={1} />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <Grid container>
                <Grid item lg={8} xs={12}>
                  {Object.keys(editNote).length ? (
                    <EditNote item={editNote} handleClose={handleCloseNote} />
                  ) : null}

                  <Widget>
                    {details.isFetching ? (
                      <CircularProgress />
                    ) : !details.items.length ? (
                      t("no_results_found")
                    ) : (
                      <Grid container>
                        <Grid item lg={12}>
                          {downloadLabels && (
                            <LTLLabelsModal
                              details={details.items}
                              shipment_number={shipment.shipment_number}
                              onClose={() => setDownloadLabels(false)}
                            />
                          )}
                          {hasGetLabelsPermission ? (
                            <Grid container justifyContent="space-between">
                              <Grid item lg={2}>
                                <Typography>{`(${totalPicked}/${itemCount})`}</Typography>
                              </Grid>
                              <Grid item lg={2}>
                                <Button
                                  color={"blue"}
                                  onClick={() => setDownloadLabels(true)}
                                >
                                  Download Labels
                                </Button>
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid item lg={12}>
                          <DefaultTable
                            data={details}
                            headers={previewHeaders}
                            selected={[timelineRow]}
                            rowOnClick={(r) => setTimelineRow(r)}
                            sortActions={{ onSort, orderBy, orderDirection }}
                            handleExportData={handleExportData}
                            exportDisabled={exportDisabled}
                            setExportDisabled={setExportDisabled}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Widget>
                </Grid>
                <Grid item lg={4} xs={12} ref={popoverContainer}>
                  {timelineRow && timelineRow.id ? (
                    <DetailTimeline
                      detailId={timelineRow.id}
                      anchorRef={popoverContainer}
                      onClose={() => setTimelineRow({})}
                      isMobile={isMobile}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {packages.isFetching ? (
                <CircularProgress />
              ) : !packages.items.length ? (
                <Alert severity="info"> Packages not found! </Alert>
              ) : (
                <Widget
                  button={
                    hasDownloadPackageLabelsPermission ? (
                      <Grid container>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            size="small"
                            color="blue"
                            onClick={handleGetPackageLabels}
                          >
                            Download Package Labels
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null
                  }
                >
                  {edit ? (
                    <EditPackage
                      packageData={selectedPackage}
                      onClose={handleEditClose}
                    />
                  ) : null}
                  <ConfirmDialog
                    open={packageStatus}
                    title={`${packageStatus} ${selectedPackage.name}`}
                    onClose={() => setPackageStatus("")}
                    onConfirm={() =>
                      handleUpdatePackageStatus(
                        packageStatus === "Complete" ? "Completed" : "Packing",
                      )
                    }
                  />
                  <ConfirmDialog
                    open={deletePack}
                    title={`Delete ${selectedPackage.name}`}
                    onClose={() => setDeletePack(true)}
                    onConfirm={() => handleDeletePackage()}
                  />
                  <Grid container spacing={4}>
                    <Grid item lg={7} xs={12}>
                      <DefaultTable
                        headers={packageHeaders}
                        data={packages}
                        rowOnClick={handleSelectPackage}
                        highlightFunc={(r) => r.id === selectedPackage.id}
                        highlightColor="#EAEDF0"
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      {!Object.keys(selectedPackage).length ? (
                        <Alert severity="info"> Please select a package </Alert>
                      ) : (
                        <DefaultTable
                          headers={packageDetailHeaders}
                          data={{
                            items: selectedPackage.details,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Widget>
              )}
            </TabPanel>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function EditPackage({ packageData, onClose }) {
  const layoutDispatch = useLayoutDispatch();
  const [data, setData] = useState({
    name: packageData.name,
    note: packageData.note,
  });

  const handleTextFieldChange = (prop) => ({ target: { value } }) => {
    setData((prev) => ({ ...prev, [prop]: value }));
  };

  const handleUpdatePackage = () => {
    updatePackage({
      id: packageData.id,
      body: data,
      responseSetter: (res) => {
        const { success, message } = res;

        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        onClose(true);
      },
    });
  };

  return (
    <Dialog open={true} onClose={() => onClose()}>
      <DialogTitle>{packageData.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              value={data.name}
              placeholder="Name"
              onChange={handleTextFieldChange("name")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              value={data.note}
              placeholder="Note"
              onChange={handleTextFieldChange("note")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose()}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePackage}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
