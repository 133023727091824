import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import { locationBulkUpdate } from "api/inventory";

import { StatusLabel } from "components/Custom";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { LocationTypes, LOCATION_POSITIONS } from "utils/constants";

const headers = [
  {
    id: "Code",
  },
  {
    id: "Location Type",
    render: (r) => (
      <StatusLabel
        text={r["Location Type"]}
        status={!locationTypeValidation(r["Location Type"]) ? "danger" : "-"}
      />
    ),
  },
  {
    id: "Position",
    render: (r) => (
      <StatusLabel
        text={r["Position"]}
        status={!positionTypeValidation(r["Position"]) ? "danger" : "-"}
      />
    ),
  },
  {
    id: "Countable",
    render: (r) => (
      <StatusLabel
        text={r["Countable"] || "-"}
        status={!booleanTypeValidation(r["Countable"]) ? "danger" : "-"}
      />
    ),
  },
  {
    id: "Active",
    render: (r) => (
      <StatusLabel
        text={r["Active"] || "-"}
        status={!booleanTypeValidation(r["Active"]) ? "danger" : "-"}
      />
    ),
  },
  {
    id: "Multiple SKU Allowed",
    render: (r) => (
      <StatusLabel
        text={r["Multiple SKU Allowed"] || "-"}
        status={
          !booleanTypeValidation(r["Multiple SKU Allowed"]) ? "danger" : "-"
        }
      />
    ),
  },
];

const booleanTypeValidation = (data) => {
  if (!data) {
    return true;
  }
  const dataToLower = data.toLowerCase();
  if (dataToLower !== "true" && dataToLower !== "false") {
    return false;
  }

  return true;
};

const locationTypeValidation = (locationType) => {
  return (
    !locationType ||
    LocationTypes.map((lt) => lt.label.toLowerCase()).includes(
      locationType.toLowerCase(),
    )
  );
};

const positionTypeValidation = (position) => {
  return (
    !position ||
    LOCATION_POSITIONS.map((lt) => lt.label.toLowerCase()).includes(
      position.toLowerCase(),
    )
  );
};

export default function LocationUpdate({ updateData, onClose }) {
  const layoutDispatch = useLayoutDispatch();
  const [validRows, setValidRows] = useState([]);

  useEffect(() => {
    if (updateData.length) {
      updateData.forEach((data) => {
        const locationType = data["Location Type"];
        const position = data["Position"];
        const countable = data["Countable"];
        const active = data["Active"];
        const multipleSkuAllowed = data["Multiple SKU Allowed"];

        if (
          locationTypeValidation(locationType) &&
          positionTypeValidation(position) &&
          booleanTypeValidation(countable) &&
          booleanTypeValidation(active) &&
          booleanTypeValidation(multipleSkuAllowed)
        ) {
          setValidRows((prev) => [...prev, data]);
        }
      });
    }
  }, [updateData]);

  const handleSubmit = () => {
    const payload = validRows.map((row) => {
      const locationType = row["Location Type"];
      const position = row["Position"];
      const countable = row["Countable"];
      const active = row["Active"];
      const multipleSkuAllowed = row["Multiple SKU Allowed"];
      const data = {
        code: row["Code"],
      };

      if (locationType) {
        data["location_type"] = LocationTypes.find(
          (lt) => lt.label.toLowerCase() === locationType.toLowerCase(),
        ).value;
      }

      if (position) {
        data["position"] = LOCATION_POSITIONS.find(
          (lt) => lt.label.toLowerCase() === position.toLowerCase(),
        ).value;
      }

      if (countable) {
        data["countable"] = countable.toLowerCase() === "true";
      }

      if (active) {
        data["is_deleted"] = active.toLowerCase() !== "true";
      }

      if (multipleSkuAllowed) {
        data["is_multiple_sku_allowed"] =
          multipleSkuAllowed.toLowerCase() === "true";
      }

      return data;
    });

    locationBulkUpdate({
      body: payload,
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        } else {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Done",
            status: "success",
          });
        }

        onClose(true);
      },
    });
  };

  return (
    <Dialog
      open={!!updateData.length}
      fullWidth
      maxWidth="md"
      onClose={() => onClose(false)}
    >
      <DialogContent>
        <DefaultTable
          headers={headers}
          data={{
            count: updateData.length,
            items: updateData,
            isFetching: false,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose(false)}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!validRows}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
