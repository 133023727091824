const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MONTH_VALUES = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const getMonths = () => {
  const today = new Date();
  let month = today.getMonth();

  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(MONTHS[month]);
    if (month === 11) {
      month = 0;
    } else {
      month++;
    }
  }
  return months;
};

const getSales = (sales_history, year, month) => {
  const months = getMonths();
  const janIndex = months.indexOf("Jan");
  const index = months.indexOf(month);
  year = "20" + (index < janIndex ? year.slice(0, 2) : year.slice(3));
  if (janIndex === 0) {
    year = (parseInt(year) - 1).toString();
  }
  const date = `${year}-${MONTH_VALUES[month]}`;
  return getMonthlySales(sales_history, date);
};

const getMonthlySales = (sales_history, date) => {
  for (let i = 0; i < sales_history.length; i++) {
    if (sales_history[i].date.startsWith(date)) {
      return sales_history[i].qty;
    }
  }
  return 0;
};

const getTotalSales = (sales_history, year) => {
  const months = getMonths();
  let total = 0;
  for (let i = 0; i < months.length; i++) {
    total += getSales(sales_history, year, months[i]);
  }
  return total;
};

const getStockoutForEachYear = (product) => {
  if (!product.stock_out_history) {
    return [0, 0, 0];
  }
  const months = getMonths();
  const years = product.forecast.year_labels.slice(0, 3);
  const sums = [0, 0, 0];
  for (let i = 0; i < years.length; i++) {
    for (let j = 0; j < months.length; j++) {
      sums[i] += getStockout(product.stock_out_history, years[i], months[j]);
    }
  }
  return sums;
};

const getSalesForEachYear = (product) => {
  if (!product.sales_history) {
    return [0, 0, 0];
  }
  const years = product.forecast.year_labels.slice(0, 3);
  const sums = [0, 0, 0];
  for (let i = 0; i < years.length; i++) {
    sums[i] = getTotalSales(product.sales_history, years[i]);
  }
  return sums;
};

const getDate = (year, month) => {
  const months = getMonths();
  const janIndex = months.indexOf("Jan");
  const index = months.indexOf(month);
  year = "20" + (index < janIndex ? year.slice(0, 2) : year.slice(3));
  if (janIndex === 0) {
    year = (parseInt(year) - 1).toString();
  }
  const date = `${year}-${MONTH_VALUES[month]}-01`;
  return date;
};

const getForecast = (forecast) => {
  if (!forecast) return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  return forecast.modified_forecast || forecast.forecast || [];
};

const getTotalForecast = (forecast) => {
  if (!forecast) return 0;
  return getForecast(forecast).reduce((a, b) => a + b, 0);
};

function getDaysInMonth(year, month) {
  const months = getMonths();
  const janIndex = months.indexOf("Jan");
  const index = months.indexOf(month);
  year = "20" + (index < janIndex ? year.slice(0, 2) : year.slice(3));
  if (janIndex === 0) {
    year = (parseInt(year) - 1).toString();
  }
  return new Date(year, MONTH_VALUES[month], 0).getDate();
}

const getStockout = (stock_out_history, year, month) => {
  const months = getMonths();
  const janIndex = months.indexOf("Jan");
  const index = months.indexOf(month);
  year = "20" + (index < janIndex ? year.slice(0, 2) : year.slice(3));
  if (janIndex === 0) {
    year = (parseInt(year) - 1).toString();
  }
  const date = `${year}-${MONTH_VALUES[month]}`;
  for (let i = 0; i < stock_out_history.length; i++) {
    if (stock_out_history[i].date.startsWith(date)) {
      return stock_out_history[i].days;
    }
  }
  return 0;
};

const getStockOutSum = (product) => {
  if (!product.stock_out_history) {
    return 0;
  }
  const months = getMonths();
  const year = product.forecast.year_labels[2];
  let total = 0;
  for (let i = 0; i < months.length; i++) {
    total += getStockout(product.stock_out_history, year, months[i]);
  }
  return total;
};

export {
  MONTH_VALUES,
  getMonths,
  getSales,
  getTotalSales,
  getMonthlySales,
  getStockoutForEachYear,
  getSalesForEachYear,
  getDate,
  getForecast,
  getTotalForecast,
  getDaysInMonth,
  getStockout,
  getStockOutSum,
};
