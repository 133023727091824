import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";

export default function Dropdown({
  label,
  title,
  id,
  value,
  items,
  onChange,
  disabled,
  LeftIcon,
  all_option_active = true,
  hasBorder = false,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div
      className={`${classes.container} ${
        hasBorder ? classes.containerBordered : ""
      }`}
    >
      {LeftIcon && (
        <div className={classes.leftIcon}>
          {<LeftIcon color={theme.palette.colors.blue_icon} />}
        </div>
      )}
      <FormControl fullWidth variant="standard" className={classes.formControl}>
        <InputLabel id={label} className={classes.textLabel}>
          {title}
        </InputLabel>
        <Select
          autoWidth
          labelId={label}
          id={id}
          value={value}
          label={title}
          onChange={onChange}
          disabled={disabled}
          className={classes.dropdown}
          disableUnderline={true}
          variant="standard"
          {...props}
        >
          {all_option_active && (
            <MenuItem key={"type_all"} value={""}>
              All
            </MenuItem>
          )}
          {items.map((item, i) => (
            <MenuItem key={`type_${item.value}_${i}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
