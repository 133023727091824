import * as React from "react";

const SvgMergeType = ({ color, size }) => (
  <svg
    viewBox="0 0 25 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m6.566 19 1.41 1.41 6-6V8h3.5l-4.5-4.5-4.5 4.5h3.5v5.59L6.566 19zm11.407 1.41-3.408-3.407 1.4-1.407 3.41 3.408-1.402 1.406z"
      fill={color}
    />
  </svg>
);

export default SvgMergeType;
