import * as React from "react";

const SvgAssignment2 = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.333 4.8h-3.487C13.504 3.756 12.588 3 11.5 3s-2.004.756-2.346 1.8H5.667C4.746 4.8 4 5.606 4 6.6v12.6c0 .995.746 1.8 1.667 1.8h11.666c.921 0 1.667-.805 1.667-1.8V6.6c0-.994-.746-1.8-1.667-1.8Zm-5.833 0c.458 0 .833.4.833.9s-.375.9-.833.9c-.458 0-.833-.4-.833-.9s.375-.9.833-.9Zm1.667 12.6H7.333v-1.8h5.834v1.8Zm2.5-3.6H7.333V12h8.334v1.8Zm0-3.6H7.333V8.4h8.334v1.8Z"
      fill={color}
    />
  </svg>
);

export default SvgAssignment2;
