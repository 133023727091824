const getCarrierTrackingLink = (ship_via, tracking_number) => {
  if (ship_via.match(/^FEDEX/))
    return `https://www.fedex.com/fedextrack/?trknbr=${tracking_number}`;
  if (ship_via.match(/^UPS/))
    return `https://www.ups.com/track?loc=en_US&tracknum=${tracking_number}`;

  return "#";
};

export { getCarrierTrackingLink };
