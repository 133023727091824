import * as React from "react";

const SvgClouddownload = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M8 16a5 5 0 0 1-.916-9.916 5.002 5.002 0 0 1 9.832 0A5.002 5.002 0 0 1 16 16M9 19l3 3M12 22l3-3M12 22V10" />
    </g>
  </svg>
);

export default SvgClouddownload;
