import React, { useCallback, useEffect, useRef, useState } from "react";

import _ from "lodash";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import { dashboardTransactions, getShipmentDetails } from "api/shipment";

import { Circle } from "components/Charts/Circle";
import { ExternalLink, InternalLink } from "components/Custom/Links";
import { QuantityResult, Spacer, StatusLabel } from "components/Custom";
import { StatusCircle } from "components/Dashboard/StatusCircle";
import {
  Dropdown,
  InputContainer,
  SwitchButton,
  SwitchButtonGroup,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { Class, Person, QrCode2, Speed, TextSnippet, X as Clear } from "icons";

import DetailTimeline from "pages/shipments/ShipmentDetailTimeline";
import { getCarrierTrackingLink } from "pages/shipments/utils";

import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";

import { isExpress, isUps } from "utils/carrier";
import { initialAPIListValue, ProductTypes } from "utils/constants";
import { toTime } from "utils/date";
import { updateDocumentTitle } from "utils/dom";
import { toTitleCase } from "utils/string";

import useStyles from "./styles";

export default function ShipperDashboard() {
  const classes = useStyles();
  const tableRef = useRef(null);
  const [details, setDetails] = useState({
    Printed: [],
    Assigned: [],
    Picked: [],
    Shipped: [],
    Invoiced: [],
  });
  const [cancelled, setCancelled] = useState(initialAPIListValue);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    picker: "",
    status: "",
    customer: "",
    sku: "",
    ship_via: "",
    product_type: 1, // 1: RUG, 2: FURNITURE
    is_express: false,
    is_repack: false,
    has_priority: false,
    is_large_rug: false,
  });
  const [aggregatedResults, setAggregatedResults] = useState([]);
  const [timelineRow, setTimelineRow] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("shipment_number");
  const [headerCounts, setHeaderCounts] = useState({});

  const theme = useTheme();

  const cells = [
    {
      id: "user",
      render: (r) => (
        <span className={classes.user} style={{ marginLeft: 0 }}>
          {toTitleCase(r.user)}
        </span>
      ),
    },
    {
      id: "shipment_number",
      label: "Shipment #",
      render: (r) => {
        const totalQuantity = aggregatedResults
          .filter((i) => i.shipment_number === r.shipment_number)
          .reduce((acc, curr) => acc + curr.quantity, 0);
        return (
          <InternalLink to={`/app/shipments/${r.shipment_id}`}>
            {r.shipment_number} {totalQuantity > 1 ? `(${totalQuantity})` : ""}
          </InternalLink>
        );
      },
      sortable: true,
    },
    {
      id: "customer",
      label: "Customer",
      render: (r) => r.customer,
      sortable: true,
    },
    {
      id: "product_sku",
      label: "SKU",
      render: (r) =>
        r.quantity > 1 ? `${r.product_sku} (${r.quantity})` : r.product_sku,
      sortable: true,
    },
    {
      id: "location",
      sortable: true,
    },
    {
      id: "ship_via",
      render: (r) => {
        return isUps(r) ? (
          <div className={classes.carrierContainer}>
            <Avatar src={UpsLogo} variant="square" />
            {isExpress(r.ship_via) && <Speed color="red" />}
          </div>
        ) : (
          <div className={classes.carrierContainer}>
            <Avatar src={FedexLogo} variant="square" />
            {isExpress(r.ship_via) && <Speed color="red" />}
          </div>
        );
      },
    },
    {
      id: "tracking_number",
      render: (r) => (
        <ExternalLink
          to={getCarrierTrackingLink(r.ship_via, r.tracking_number)}
          children={r.tracking_number}
        />
      ),
    },
    {
      id: "status",
      render: (r) => <StatusLabel status={r.status} />,
    },
    {
      id: "transaction_time",
      label: "Time",
      render: (r) => {
        return (
          <span
            onClick={(e) => {
              tableRef.current = e.target;
              setTimelineRow(r);
            }}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {toTime(r.updated_at)}
          </span>
        );
      },
      sortable: true,
    },
  ];

  updateDocumentTitle("Shipper DB");

  const refreshDetails = useCallback(async () => {
    setIsLoading(true);
    dashboardTransactions({
      body: { status: "Printed" },
      responseSetter: (res) =>
        setDetails((prev) => ({ ...prev, Printed: res.details })),
    });
    dashboardTransactions({
      body: { status: "Assigned" },
      responseSetter: (res) =>
        setDetails((prev) => ({ ...prev, Assigned: res.details })),
    });
    dashboardTransactions({
      body: { status: "Picked" },
      responseSetter: (res) =>
        setDetails((prev) => ({ ...prev, Picked: res.details })),
    });
    dashboardTransactions({
      body: { status: "Shipped" },
      responseSetter: (res) =>
        setDetails((prev) => ({
          ...prev,
          Shipped: res.details,
        })),
    });
    dashboardTransactions({
      body: { status: "Invoiced" },
      responseSetter: (res) =>
        setDetails((prev) => ({
          ...prev,
          Invoiced: res.details,
        })),
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const users = [];
    details.Assigned.forEach((item) => {
      const user = item.user;
      if (!users.includes(user)) {
        users.push(user);
      }
    });
    details.Picked.forEach((item) => {
      const user = item.user;
      if (!users.includes(user)) {
        users.push(user);
      }
    });
    details.Shipped.forEach((item) => {
      const user = item.user;
      if (!users.includes(user)) {
        users.push(user);
      }
    });
    setUsers(users);
    setAggregatedResults(
      _.uniqBy(
        [
          ...details.Assigned,
          ...details.Picked,
          ...details.Shipped,
          ...details.Invoiced,
        ],
        "id",
      ),
    );
  }, [details.Assigned, details.Picked, details.Shipped, details.Invoiced]);

  useEffect(() => {
    refreshDetails();
  }, [refreshDetails]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshDetails();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [refreshDetails]);

  useEffect(() => {
    if (cancelled.isFetching) {
      getShipmentDetails({
        params: {
          status: "Pending_Cancellation",
        },
        responseSetter: (data) => {
          const items = data.results.map((r) => {
            let pickTransaction = r.transactions.find(
              (t) => t.is_cancelled === false && t.action_type === "Picked",
            );
            if (pickTransaction) {
              r.picker = pickTransaction.user.username;
            }
            r.assignees = r.transactions
              .filter((t) => t.action_type === "Assigned")
              .map((t) => t.user.username)
              .join(",");
            return r;
          });
          setCancelled({
            items: items,
            isFetching: false,
            count: data.count,
          });
          setTimeout(() => {
            setCancelled((prev) => ({ ...prev, isFetching: true }));
          }, 60000);
        },
      });
    }
  }, [cancelled.isFetching]);

  const updateResults = useCallback(
    (row) => {
      if (filters.product_type && row.product_type !== filters.product_type) {
        return false;
      }
      if (filters.is_express && !isExpress(row.ship_via)) {
        return false;
      }
      if (filters.picker) {
        if (row.user !== filters.picker) {
          return false;
        }
      }
      if (filters.is_repack && row.is_packed_goods !== true) {
        return false;
      }
      if (
        filters.has_priority &&
        !["AMAZON-SC", "DECORSIFY"].includes(row.customer.toUpperCase())
      ) {
        return false;
      }
      if (filters.is_large_rug && row.class_name !== "9x/10x/11x/12x") {
        return false;
      }
      if (filters.status) {
        if (filters.status === "Shipped") {
          if (!(row.status === "Shipped" || row.status === "Invoiced")) {
            return false;
          }
        } else if (row.status !== filters.status) {
          return false;
        }
      }
      if (
        filters.customer &&
        !row.customer.toUpperCase().includes(filters.customer.toUpperCase())
      ) {
        return false;
      }
      if (
        filters.sku &&
        !row.product_sku.toLowerCase().includes(filters.sku.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.ship_via &&
        !row.ship_via.toLowerCase().includes(filters.ship_via.toLowerCase())
      ) {
        return false;
      }
      return true;
    },
    [filters],
  );

  useEffect(() => {
    const items = aggregatedResults.filter(updateResults);
    const headerCounts = {
      shipment_number: _.size(_.groupBy(items, "shipment_id")),
      customer: _.size(_.groupBy(items, "customer")),
      product_sku: _.size(_.groupBy(items, "product_sku")),
      location: _.size(_.groupBy(items, "location")),
    };
    setHeaderCounts(headerCounts);
  }, [aggregatedResults, updateResults]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={7} md={12}>
          <Widget>
            {!isLoading ? (
              <Grid container spacing={0} className={classes.containerCenter}>
                <Grid item xs={4} lg={4}>
                  <StatusCircle status="Assigned" content={details} />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <StatusCircle status="Picked" content={details} />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <StatusCircle
                    status="Shipped"
                    content={details}
                    secondStatus="Invoiced"
                  />
                </Grid>
              </Grid>
            ) : (
              <CircularProgress />
            )}
            <Spacer height="2rem" />
            <Grid
              container
              spacing={0}
              className={(classes.containerCenter, classes.tableContainer)}
            >
              <Grid item xs={12} lg={12}>
                {users
                  .sort((a, b) => (a > b ? 1 : -1))
                  .map((user, index) => (
                    <Grid
                      container
                      key={user}
                      className={[
                        classes.containerCenter,
                        classes.tableRow,
                        index % 2 === 0 ? classes.rowEven : classes.rowOdd,
                      ].join(" ")}
                    >
                      <Grid item xs={4} lg={4}>
                        <span className={classes.user}>
                          {toTitleCase(user)}
                        </span>
                        <span
                          style={{
                            color: theme.palette.colors.amber500,
                            paddingRight: "3rem",
                          }}
                        >
                          {details.Assigned.filter(
                            (r) => r.user === user,
                          ).reduce((total, sd) => total + sd.quantity, 0) || 0}
                        </span>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <span style={{ color: theme.palette.colors.blue400 }}>
                          {details.Picked.filter((r) => r.user === user).reduce(
                            (total, sd) => total + sd.quantity,
                            0,
                          ) || 0}
                        </span>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <span
                          style={{ color: theme.palette.colors.emerald400 }}
                        >
                          {aggregatedResults
                            .filter(
                              (r) =>
                                r.user === user &&
                                ["shipped", "invoiced"].includes(
                                  r.status.toLowerCase(),
                                ),
                            )
                            .reduce((total, sd) => total + sd.quantity, 0) || 0}
                        </span>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={5} md={12}>
          <Widget>
            <Grid container spacing={0} className={classes.containerCenter}>
              <Grid item xs={12} lg={6}>
                {!isLoading ? (
                  <Circle
                    number={cancelled.items ? cancelled.items.length : 0}
                    series={[
                      cancelled.items ? cancelled.items.length : 0,
                      cancelled.items ? cancelled.items.length : 0,
                    ]}
                    title="Cancellation"
                    color={theme.palette.colors.rose500}
                    size={250}
                  />
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
            <Spacer height="2rem" />
            <Grid
              container
              spacing={0}
              className={[classes.container, classes.tableContainer].join(" ")}
            >
              <Grid item xs={12} lg={12}>
                {cancelled.items && cancelled.items.length > 0
                  ? cancelled.items.map((cl, index) => (
                      <Grid
                        container
                        key={cl.id}
                        className={[
                          classes.tableRow,
                          index % 2 === 0 ? classes.rowEven : classes.rowOdd,
                        ].join(" ")}
                      >
                        <Grid item xs={6} lg={2}>
                          <span className={classes.user}>
                            {cl.picker
                              ? toTitleCase(cl.picker)
                              : cl.assignees
                              ? cl.assignees
                                  .split(",")
                                  .map(toTitleCase)
                                  .join(", ")
                              : "-"}
                          </span>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                          {cl.product.sku}
                        </Grid>
                        <Grid item xs={2} lg={2}>
                          {isUps(cl.shipment.ship_via) ? (
                            <div className={classes.carrierContainer}>
                              <Avatar src={UpsLogo} variant="square" />
                              {isExpress(cl.shipment.ship_via) && (
                                <Speed color="red" />
                              )}
                            </div>
                          ) : (
                            <div className={classes.carrierContainer}>
                              <Avatar src={FedexLogo} variant="square" />
                              {isExpress(cl.shipment.ship_via) && (
                                <Speed color="red" />
                              )}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={2} lg={2}>
                          <InternalLink
                            to={`/app/shipments/${cl.shipment.id}`}
                            children={cl.shipment.shipment_number}
                          />
                        </Grid>
                        <Grid item xs={2} lg={2}>
                          {cl.customer}
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Spacer height="8vh" />
      <Grid container spacing={4} className={classes.filterContainer}>
        <SwitchButtonGroup
          defaultValue={1}
          xs={12}
          sm={6}
          md={3}
          lg={2}
          onChange={(value) => {
            setFilters({ ...filters, product_type: value });
            aggregatedResults.filter(updateResults);
          }}
        >
          {ProductTypes.map(({ label, value }, index) => (
            <SwitchButton value={value} key={index}>
              <QuantityResult
                label={label}
                value={aggregatedResults
                  .filter((i) => i.product_type === value)
                  .reduce((total, i) => {
                    total += i.quantity;
                    return total;
                  }, 0)}
              />
            </SwitchButton>
          ))}
        </SwitchButtonGroup>
        <Box
          component={Grid}
          item
          lg={2}
          md={3}
          sm={6}
          xs={12}
          style={{
            display: {
              lg: "block",
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
        />
        <SwitchButtonGroup
          xs={12}
          sm={6}
          md={4}
          lg={3}
          onChange={(value) => {
            setFilters({
              ...filters,
              is_express: value === "express" ? !filters.is_express : false,
              is_repack: value === "repack" ? !filters.is_repack : false,
              has_priority:
                value === "priority" ? !filters.has_priority : false,
            });
          }}
          clearEnabled={true}
        >
          <SwitchButton value="express" xs={4}>
            <QuantityResult
              label="Express"
              value={aggregatedResults
                .filter((i) => isExpress(i.ship_via))
                .reduce((total, i) => {
                  total += i.quantity;
                  return total;
                }, 0)}
            />
          </SwitchButton>
          <SwitchButton value="repack" xs={4}>
            <QuantityResult
              label="Re-Roll"
              value={aggregatedResults
                .filter((i) => i.is_packed_goods)
                .reduce((total, i) => {
                  total += i.quantity;
                  return total;
                }, 0)}
            />
          </SwitchButton>
          <SwitchButton value="priority" xs={4}>
            <QuantityResult
              label="Priority"
              value={aggregatedResults
                .filter((i) =>
                  ["AMAZON-SC", "DECORSIFY"].includes(i.customer.toUpperCase()),
                )
                .reduce((total, i) => {
                  total += i.quantity;
                  return total;
                }, 0)}
            />
          </SwitchButton>
        </SwitchButtonGroup>
      </Grid>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid item lg={12} xs={12}>
          <InputContainer style={{ marginTop: 20 }}>
            <Grid container spacing={0}>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <Dropdown
                  id="user"
                  title="User"
                  value={filters.picker}
                  onChange={({ target }) =>
                    setFilters({ ...filters, picker: target.value })
                  }
                  items={users.map((item) => ({
                    value: item,
                    label: toTitleCase(item),
                  }))}
                  LeftIcon={Person}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <Dropdown
                  id="status"
                  title="Status"
                  value={filters.status}
                  onChange={({ target }) =>
                    setFilters({ ...filters, status: target.value })
                  }
                  items={["", "Assigned", "Picked", "Shipped"].map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  LeftIcon={Class}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Customer"
                  variant="outlined"
                  value={filters.customer || ""}
                  onChange={({ target }) => {
                    setFilters({ ...filters, customer: target.value });
                    aggregatedResults.filter(updateResults);
                  }}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      aggregatedResults.filter(updateResults);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setFilters({ ...filters, customer: "" });
                          }}
                          edge="end"
                        >
                          {filters.customer && filters.customer.length > 0 && (
                            <Clear />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  LeftIcon={TextSnippet}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="SKU"
                  variant="outlined"
                  value={filters.sku || ""}
                  onChange={({ target }) =>
                    setFilters({ ...filters, sku: target.value })
                  }
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      aggregatedResults.filter(updateResults);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setFilters({ ...filters, sku: "" });
                          }}
                          edge="end"
                        >
                          {filters.sku && filters.sku.length > 0 && <Clear />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  LeftIcon={QrCode2}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormattedTextField
                  fullWidth
                  label="Ship Via"
                  variant="outlined"
                  value={filters.ship_via || ""}
                  onChange={({ target }) =>
                    setFilters({ ...filters, ship_via: target.value })
                  }
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      aggregatedResults.filter(updateResults);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setFilters({ ...filters, ship_via: "" });
                          }}
                          edge="end"
                        >
                          {filters.ship_via && filters.ship_via.length > 0 && (
                            <Clear />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  LeftIcon={TextSnippet}
                />
              </Grid>
            </Grid>
          </InputContainer>
        </Grid>
      </Grid>
      <Spacer height="1vh" />
      <Grid container spacing={0}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={{
              items: aggregatedResults.filter(updateResults),
            }}
            headers={cells}
            headerCounts={headerCounts}
            sortActions={{
              onSort: (header) => {
                const isAsc = orderBy === header && orderDirection === "asc";
                const newDirection = isAsc ? "desc" : "asc";
                setOrderDirection(newDirection);
                setOrderBy(header);
                setAggregatedResults(
                  _.orderBy(aggregatedResults, [header], [newDirection]),
                );
              },
              orderBy,
              orderDirection,
            }}
            filters={{
              ...filters,
              has_priority: filters.has_priority ? "True" : null,
              is_express: filters.is_express ? "True" : null,
              is_repack: filters.is_repack ? "True" : null,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {timelineRow && timelineRow.id && (
            <DetailTimeline
              detailId={timelineRow.id}
              anchorRef={tableRef}
              onClose={() => setTimelineRow({})}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
