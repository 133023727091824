import { Avatar, CardHeader, Grid, Typography } from "@material-ui/core";

import FedexLogo from "themes/logos/fedex.svg";
import UpsLogo from "themes/logos/ups.svg";

export default function CarrierData({ fedex, ups, other, express = null }) {
  return (
    <Grid container>
      <Grid
        item
        xs={other ? 4 : 6}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex-row",
          textAlign: "center",
        }}
      >
        <CardHeader
          avatar={<Avatar src={FedexLogo} variant="square" />}
          title={<b>{fedex}</b>}
        />
        {express ? <b>Express {express.fedex}</b> : null}
      </Grid>
      <Grid
        item
        xs={other ? 4 : 6}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex-row",
          textAlign: "center",
        }}
      >
        <CardHeader
          avatar={<Avatar src={UpsLogo} variant="square" />}
          title={<b>{ups}</b>}
        />
        {express ? <b>Express {express.ups}</b> : null}
      </Grid>
      {other && (
        <Grid item xs={4}>
          <Typography noWrap>OTHER</Typography>
          <Typography size="md">{other}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
