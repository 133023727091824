import React from "react";
import { generateAWSURL, getSignedMediaURL } from "utils/image";

const title = "wms-logo";
const url = generateAWSURL("static/wms-logo.png");
const src = getSignedMediaURL(url, title);

export default function WMSImageLogo({ className }) {
  return <img src={src} alt={title} className={className} />;
}
