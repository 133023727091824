import * as React from "react";

const SvgReceiptLong = ({ color, size }) => (
  <svg
    viewBox="0 0 25 25"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M19.5 19.5c0 .55-.45 1-1 1s-1-.45-1-1v-3h-9v-11h11v14z"
      fill={color}
    />
    <path
      d="m20 4-1.5-1.5L17 4l-1.5-1.5L14 4l-1.5-1.5L11 4 9.5 2.5 8 4 6.5 2.5v14h-3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-17L20 4zm-.5 15.5c0 .55-.45 1-1 1s-1-.45-1-1v-3h-9v-11h11v14z"
      fill={color}
    />
    <path
      d="M15.5 7.5h-6v2h6v-2zm3 0h-2v2h2v-2zm-3 3h-6v2h6v-2zm3 0h-2v2h2v-2z"
      fill={color}
    />
  </svg>
);

export default SvgReceiptLong;
