import * as React from "react";

const SvgSortdescending = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 7.5a1 1 0 0 1 .993.883L18 8.5v9.584l2.293-2.291a1 1 0 0 1 1.497 1.32l-.083.094-4 4a1.006 1.006 0 0 1-.088.079l.088-.079a1.008 1.008 0 0 1-.395.243l-.02.007a.998.998 0 0 1-.063.017l-.026.005A1.003 1.003 0 0 1 17 21.5h-.032l-.054-.004-.03-.003-.027-.003a1.003 1.003 0 0 1-.086-.016l-.032-.008a.998.998 0 0 1-.03-.01l-.02-.006a.992.992 0 0 1-.396-.243l-4-4a1 1 0 0 1 1.32-1.497l.094.083L16 18.085V8.5a1 1 0 0 1 1-1Zm-5 4a1 1 0 0 1 .117 1.993L12 13.5H3a1 1 0 0 1-.117-1.993L3 11.5h9Zm0-4a1 1 0 0 1 .117 1.993L12 9.5H3a1 1 0 0 1-.117-1.993L3 7.5h9Zm4-4a1 1 0 0 1 .117 1.993L16 5.5H3a1 1 0 0 1-.117-1.993L3 3.5h13Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgSortdescending;
