import * as React from "react";

const SvgRotateRight = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.15 7.846 11.153 4v2.595C7.685 7.009 5 9.849 5 13.297c0 3.449 2.685 6.289 6.152 6.703v-1.707c-2.492-.402-4.394-2.485-4.394-4.996 0-2.51 1.902-4.593 4.394-4.995v3.305l3.999-3.761ZM19 12.452c-.15-1.175-.637-2.307-1.428-3.288l-1.244 1.196c.47.638.77 1.353.897 2.092H19Zm-6.09 5.836V20a7.116 7.116 0 0 0 3.427-1.36l-1.261-1.214a5.408 5.408 0 0 1-2.166.862Zm3.418-2.053 1.244 1.195c.79-.98 1.279-2.112 1.428-3.287h-1.775a4.928 4.928 0 0 1-.897 2.091Z"
      fill={color}
    />
  </svg>
);

export default SvgRotateRight;
