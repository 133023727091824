import * as React from "react";

const SvgCallToAction = ({ color, size, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.364 5H4.636C3.736 5 3 5.75 3 6.667v11.666C3 19.25 3.736 20 4.636 20h14.728c.9 0 1.636-.75 1.636-1.667V6.667C21 5.75 20.264 5 19.364 5Zm0 13.333H4.636v-2.5h14.728v2.5Z"
      fill={color}
    />
  </svg>
);

export default SvgCallToAction;
