import * as React from "react";

const SvgCheck = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.293 6.293a1 1 0 0 1 1.497 1.32l-.083.094-10 10a1 1 0 0 1-1.32.083l-.094-.083-4-4a1 1 0 0 1 1.32-1.497l.094.083L9 15.585l9.293-9.292Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgCheck;
