import {
  getWithExpiry,
  setWithExpiry,
  HOURINMILLISECONDS,
} from "utils/storage";

export class API {
  constructor() {
    this.url = `${process.env.REACT_APP_API_URL}api/`;
    this.lastRefreshCall = Date.now();
  }

  async refreshToken() {
    const url = `${process.env.REACT_APP_API_URL}api/token/refresh/`;
    const refresh = getWithExpiry("refresh");

    if (!refresh) {
      return { error: true };
    }
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "requested-by": "wms",
      },
      body: JSON.stringify({
        refresh,
      }),
    };
    const updateToken = async (url, options) => {
      try {
        const res = await fetch(url, options);
        if (res.status === 200) {
          const json = await res.json();
          setWithExpiry("access", json.access, HOURINMILLISECONDS);
          this.token = json.access;
          return json;
        } else {
          return { error: true };
        }
      } catch (error) {
        return { error: true };
      }
    };
    return await updateToken(url, options);
  }

  headers() {
    let token = getWithExpiry("access");
    if (!token) {
      this.refreshToken();
      token = getWithExpiry("access");
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
      "requested-by": "wms",
    };
  }

  async authenticateErrorHandler({ setError }) {
    if (Date.now() - this.lastRefreshCall > 60000) {
      const { error } = await this.refreshToken();
      const message = "Authentication error!";
      if (error) {
        localStorage.removeItem("refresh");
        localStorage.removeItem("access");
        window.location.reload();
        if (setError) {
          setError(message);
          return;
        } else {
          throw new Error(message);
        }
      }
    }
  }

  serverErrorHandler({ setError }) {
    const message = "Server Error!";
    if (setError) {
      setError(message);
    } else {
      throw new Error(message);
    }
  }

  validationErrorHandler({ data, setError, responseSetter }) {
    if (responseSetter) {
      responseSetter(data);
    } else if (setError) {
      setError(data);
    } else {
      return data;
    }
  }

  async fetchData({
    res,
    endpoint,
    paginated,
    method,
    setError,
    responseSetter,
    setFunc,
    body,
  }) {
    try {
      if (res.status === 200 || res.status === 201) {
        let data = await res.json();
        if (data) {
          if (responseSetter) {
            return responseSetter(data);
          }
          if (setFunc) {
            if (method === "GET") {
              if (paginated) {
                setFunc({
                  customCounts: data.customCounts,
                  items: data.results,
                  isFetching: false,
                  count: data.count,
                });
              } else {
                setFunc({
                  items: data,
                  isFetching: false,
                  count: data.length,
                });
              }
            } else {
              setFunc({
                ...data,
                isFetching: false,
              });
            }
          }

          return data;
        }
        return;
      } else {
        if (res.status === 401 || res.status === 403) {
          await this.authenticateErrorHandler({ setError });

          let data = null;
          setTimeout(() => {
            if (method === "GET") {
              data = this.list({
                endpoint,
                setFunc,
                setError,
                paginated,
                responseSetter,
              });
            } else if (method === "POST") {
              data = this.create({
                endpoint,
                setFunc,
                setError,
                responseSetter,
                body,
              });
            } else if (method === "PATCH") {
              data = this.update({
                endpoint,
                setFunc,
                setError,
                responseSetter,
                body,
              });
            }
          }, 500);

          return data;
        }
        if (res.status === 500) {
          this.serverErrorHandler({ setError });
        } else if (res.status === 400) {
          let data = await res.json();
          this.validationErrorHandler({ data, setError, responseSetter });
        } else if (res.status === 404) {
          if (setError) {
            setError("Not found!");
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (setError) {
        setError("Server error!");
      }
    }
  }

  async list({ endpoint, setFunc, setError, paginated, responseSetter }) {
    const options = {
      method: "GET",
      headers: this.headers(),
    };

    const url = `${this.url}${endpoint}`;
    const res = await fetch(url, options);

    this.fetchData({
      res,
      endpoint,
      options,
      setError,
      setFunc,
      paginated,
      method: "GET",
      responseSetter,
    });
  }

  async create({ endpoint, setFunc, setError, responseSetter, body }) {
    const options = {
      method: "POST",
      headers: this.headers(),
      body: JSON.stringify(body),
    };

    const url = `${this.url}${endpoint}`;
    const res = await fetch(url, options);
    this.fetchData({
      res,
      endpoint,
      body,
      setError,
      setFunc,
      responseSetter,
      method: "POST",
    });
  }

  async update({ endpoint, setFunc, setError, responseSetter, body }) {
    const options = {
      method: "PATCH",
      headers: this.headers(),
      body: JSON.stringify(body),
    };

    const url = `${this.url}${endpoint}`;
    const res = await fetch(url, options);
    this.fetchData({
      res,
      endpoint,
      body,
      setError,
      setFunc,
      responseSetter,
      method: "PATCH",
    });
  }

  async delete({ endpoint, setFunc, setError, responseSetter, body }) {
    const options = {
      method: "DELETE",
      headers: this.headers(),
      body: JSON.stringify(body),
    };

    const url = `${this.url}${endpoint}`;
    const res = await fetch(url, options);
    this.fetchData({
      res,
      endpoint,
      body,
      setError,
      setFunc,
      responseSetter,
      method: "DELETE",
    });
  }
}

export function addParamsToEndpoint(endpoint, params) {
  if (!params || !Object.keys(params).length) {
    return endpoint;
  }
  let parameters = Object.keys(params)
    .map((param) =>
      params[param] || typeof params[param] === "boolean"
        ? `${param}=${params[param]}`
        : null,
    )
    .filter(Boolean)
    .join("&");
  return `${endpoint}?${parameters}`;
}
