import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

import { updateShipmentDetail } from "api/shipment";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

const EditNote = ({ item, handleClose }) => {
  const layoutDispatch = useLayoutDispatch();
  const [note, setNote] = useState(item.note);

  const handleSubmit = () => {
    updateShipmentDetail({
      id: item.id,
      body: {
        note,
      },
      responseSetter: (res) => {
        popupNotification({
          dispatch: layoutDispatch,
          status: "success",
          message: "Note updated!",
        });

        handleClose(true);
      },
    });
  };

  return (
    <Dialog
      open={!!item.id}
      maxWidth="sm"
      fullWidth
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        {item.product.sku} - {item.location.code}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          minRows={3}
          placeholder="Note"
          value={note}
          onChange={({ target: { value } }) => setNote(value)}
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "0 1rem" }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClose(false)}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditNote;
