import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const movement = ({ setError, body, setFunc }) => {
  api.create({
    endpoint: "warehouse/movement/",
    body,
    setError,
    setFunc,
  });
};

const getMovements = ({ setFunc, setError, paginated, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/movement/", params),
    setError,
    setFunc,
    paginated,
  });
};

const createOrderPick = ({ body, responseSetter, setError }) => {
  api.create({
    endpoint: "custom/order-pick/",
    body,
    responseSetter,
    setError,
  });
};

export { movement, getMovements, createOrderPick };
