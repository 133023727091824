import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

import _ from "lodash";

import { createLTLShipmentLabels } from "api/shipment";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { downloadPDF } from "utils/base64";

export default function LTLLabelsModal({ details, shipment_number, onClose }) {
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [grouppedSKUS, setGrouppedSKUS] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const uniqueDetailList = _.uniqBy(details, (detail) => detail.product.sku);
    setGrouppedSKUS(_.orderBy(uniqueDetailList, ["product.sku"], ["asc"]));
    let initialFormData = {};
    uniqueDetailList.forEach((detail) => {
      initialFormData[`${detail.product.sku}-case_pack`] = 1;
    });
    setFormData(initialFormData);
  }, [details]);

  const handleChange = (sku, param) => ({ target: { value } }) => {
    if (param === "case_pack" && value !== "" && value < 1) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Case Pack Qty cannot be negative!",
      });
    }
    setFormData((prev) => ({
      ...prev,
      [`${sku}-${param}`]:
        param === "case_pack" && value ? Number(value) : value,
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    createLTLShipmentLabels({
      body: {
        shipment_number,
        form_data: { ...formData },
      },
      responseSetter: (res) => {
        const { success, message, base64 } = res;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          setIsLoading(false);
          return;
        }

        if (base64) {
          downloadPDF(base64, `LTL Labels - #${shipment_number}`);
        }

        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
        setIsLoading(false);
        onClose();
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="confirm-dialog">
        <span>Labels</span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {grouppedSKUS.map((detail) => (
            <Grid item lg={12}>
              <Grid container spacing={1}>
                <Grid item lg={5}>
                  <TextField
                    variant="outlined"
                    label="SKU"
                    value={`${detail.product.sku} (${detail.quantity})`}
                    disabled={true}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Case Pack Qty"
                    value={formData[`${detail.product.sku}-case_pack`]}
                    onChange={handleChange(detail.product.sku, "case_pack")}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    variant="outlined"
                    label="Note"
                    onChange={handleChange(detail.product.sku, "note")}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={20} />}
          Download Labels
        </Button>
      </DialogActions>
    </Dialog>
  );
}
