import * as React from "react";

const SvgEyeoff = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="m2.542 3 3.589 3.589M20.542 21l-3.589-3.589M13.417 18.825a10.05 10.05 0 0 1-1.875.175C7.065 19 3.274 16.057 2 12a9.97 9.97 0 0 1 1.563-3.029M9.42 9.879a3 3 0 1 1 4.243 4.243M9.42 9.879l4.243 4.242M9.42 9.879l-3.289-3.29M13.663 14.121 6.131 6.589M13.663 14.121l3.29 3.29" />
      <path d="M6.13 6.59A9.953 9.953 0 0 1 11.543 5c4.478 0 8.268 2.943 9.542 7a10.025 10.025 0 0 1-4.131 5.411" />
    </g>
  </svg>
);

export default SvgEyeoff;
