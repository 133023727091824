import * as React from "react";

const SvgSortascending = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m17 7.5.019.001h.04l.03.003L17 7.5a1.008 1.008 0 0 1 .625.22l.082.073 4 4a1 1 0 0 1-1.32 1.497l-.094-.083L18 10.915V20.5a1 1 0 0 1-1.993.117L16 20.5v-9.585l-2.293 2.292a1 1 0 0 1-1.497-1.32l.083-.094 4-4 .082-.073.008-.007-.09.08a1.008 1.008 0 0 1 .648-.291l.04-.002H17Zm-8 4a1 1 0 0 1 .117 1.993L9 13.5H3a1 1 0 0 1-.117-1.993L3 11.5h6Zm3-4a1 1 0 0 1 .117 1.993L12 9.5H3a1 1 0 0 1-.117-1.993L3 7.5h9Zm4-4a1 1 0 0 1 .117 1.993L16 5.5H3a1 1 0 0 1-.117-1.993L3 3.5h13Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgSortascending;
