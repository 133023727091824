import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  searchIcon: {
    marginLeft: 10,
    marginRight: 2,
  },
  searchButton: {
    backgroundColor: theme.palette.colors.light_hover,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: `${theme.palette.colors.dark_green_grey}00`,
      height: "10vh",
      "&:hover": {
        backgroundColor: theme.palette.colors.dark_green_grey,
      },
    },
  },
  selectBox: {
    ml: "auto",
    mr: 2,
    py: 0.5,
    px: 1,
    background: theme.palette.colors.slate200,
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    my: 5,
  },
  searchBox: {
    maxHeight: 650,
  },
  listTitle: {
    width: "33%",
    flexShrink: 0,
    color: theme.palette.colors.slate500,
  },
  listSecondary: {
    color: theme.palette.colors.slate800,
  },
}));
