import * as React from "react";

const SvgLibrary = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth={2}
    >
      <path d="M8 13.703v2.919M12 13.703v2.919M16 13.703v2.919M3 20.514h18M3 9.811h18M3 6.892 12 3l9 3.892M4 9.81h16v10.704H4z" />
    </g>
  </svg>
);

export default SvgLibrary;
