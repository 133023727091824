import * as React from "react";

const SvgChevrondoubleup = ({ color, size }) => (
  <svg
    viewBox="0 0 24 24"
    width={`${24 * (size || 1)}px`}
    height={`${24 * (size || 1)}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.613 12.21.094.083 7 7a1 1 0 0 1-1.32 1.497l-.094-.083L12 14.415l-6.293 6.292a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 7-7a1 1 0 0 1 1.32-.083Zm0-8 .094.083 7 7a1 1 0 0 1-1.32 1.497l-.094-.083L12 6.415l-6.293 6.292a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 7-7a1 1 0 0 1 1.32-.083Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgChevrondoubleup;
