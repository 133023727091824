import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  label: {
    fontSize: "0.675rem",
    margin: "1rem 0 0 0",
    color: "#a1a1a1",
  },
  link: {
    fontSize: "0.875rem",
    marginTop: "1rem",
  },
  listIconItem: {
    minWidth: "2rem",
  },
  actionIcon: {
    paddingLeft: "0.1rem",
  },
  actionHover: {
    cursor: "pointer",
  },
  column: {
    color: "#1a75ff",
  },
  columnHover: {
    cursor: "pointer",
    textShadow:
      "-0.5px 0 #1a75ff, 0 0.5px #1a75ff, 0.5px 0 #1a75ff, 0 -0.5px #1a75ff",
  },
  packagingButton: {
    color: "#fff",
    height: "3rem",
    position: "absolute",
    "margin-left": "-45%",
    left: "50%",
    width: "90%",
    bottom: "4rem",
  },
  red: {
    backgroundColor: theme.palette.colors.rose700,
  },
  yellow: {
    backgroundColor: theme.palette.colors.amber700,
  },
  blue: {
    backgroundColor: theme.palette.colors.blue700,
  },
  rowTitle: {
    fontSize: "1rem",
    fontWeight: "normal",
  },
}));
